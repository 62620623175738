import { AtomEffect, atomFamily } from "recoil";

const localStorageEffect =
	(key: string): AtomEffect<string[]> =>
	({ setSelf, onSet }: any) => {
		const savedValue = localStorage.getItem(key);
		if (savedValue != null) {
			setSelf(JSON.parse(savedValue));
		}

		onSet((newValue: any, _: any, isReset: any) => {
			isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
		});
	};

export const collapsedFolderState = atomFamily<string[], string>({
	key: "collapsedFolderState",
	default: [],
	effects: (key) => [localStorageEffect(key)],
});
