import { memo } from "react";

import { useGuestBoardsListener } from "./modules/board/hooks/useGuestBoardsListener";
import { useInviteListener } from "./modules/members/hooks/useInviteListener";
import { useMembersListener } from "./modules/members/hooks/useMembersListener";
import { useNotificationsListener } from "./modules/notifications/hooks/useNotificationsListener";
import { useCanny } from "./modules/page/hooks/useCanny";
import { usePlanUsageListener } from "./modules/plans/hooks/usePlanUsageListener";
import { useUserPresenceListener } from "./modules/presence/hooks/useUserPresence";
import { useSubscriptionDetailsListener } from "./modules/profile/hooks/useSubscriptionDetails";

export const Listeners = memo(() => {
	useCanny();

	useGuestBoardsListener();
	useSubscriptionDetailsListener();
	usePlanUsageListener();

	useNotificationsListener();
	useUserPresenceListener(); // Currently required as data is used in selector to get profile
	useMembersListener(); // Currently required as there is a member counter in the sidebar
	useInviteListener(); // Split from members listener could be refactored lower into the tree

	return null;
});

Listeners.displayName = "Listeners";
