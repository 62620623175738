import styled from "styled-components";
import { background, color, space, SpaceProps, textAlign, width } from "styled-system";

type InputProps = {
	isError?: boolean;
	plain?: boolean;
	hover?: boolean;
} & SpaceProps;

export const Input = styled.input<InputProps>`
	background-color: transparent;
	border: ${(props) => (props.plain ? "none" : "var(--border)")};
	border-color: ${(props) => (props.isError ? props.theme.colors.error : "var(--color-border)")};
	border-radius: 4px;
	padding: 10px 14px;
	font-family: ${(props) => props.theme.fonts.sans};
	font-size: inherit;
	box-shadow: none;
	line-height: 20px;
	outline: none;
	width: 100%;
	font-weight: inherit;
	-webkit-appearance: none;
	appearance: none;

	&:focus {
		border-color: var(--color-accent);
	}

	&:hover {
		background-color: ${(props) => (props.hover ? "var(--color-hover)" : "none")};
	}

	::placeholder {
		color: var(--color-text);
		opacity: 0.5;
	}

	::-webkit-calendar-picker-indicator {
		filter: var(--calendar-icon);
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: var(--color-text) !important;
	}

	${space};
	${color};
	${background};
	${width};
	${textAlign};
`;

Input.defaultProps = {
	mb: 3,
};

export const InputWrapper = styled.div`
	position: relative;
	margin-bottom: 24px;
	min-width: 180px;
	${space}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-wrap: wrap;

	> div {
		margin-right: 20px;
		margin-bottom: 24px;
	}
`;

type ValidationErrorProps = {
	showError: boolean;
};

export const ValidationError = styled.label<ValidationErrorProps>`
	display: block;
	margin-top: 4px;
	margin-bottom: -12px;
	font-size: 12px;
	opacity: ${({ showError }) => (showError ? 1 : 0)};
	transform: translateY(${({ showError }) => (showError ? 0 : "-2px")});
	color: ${({ theme }) => theme.colors.error};
	transition:
		opacity 200ms ease-in-out,
		transform 200ms ease-in-out;
`;

export default Input;
