import * as RadixMenu from "@radix-ui/react-dropdown-menu";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { UserDetails } from "@/modules/members/primitives/UserDetails";
import { getProfileWithPresence } from "@/modules/profile/selectors/getProfileWithPresence";
import routes from "@/shared/core/routes";
import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger } from "@/shared/system";
import SignOutButton from "@/shared/system/SignOut";
import { User } from "@/types/db";

export default function UserDropdown() {
	const user = useRecoilValue(getProfileWithPresence) as User;
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(routes.profile);
	};

	return (
		<Dropdown>
			<Trigger>
				<UserDetails name={user.username} email={user.email} user={user} width={176} />
			</Trigger>
			<RadixMenu.Portal>
				<Content>
					<DropdownMenuItem onSelect={handleClick}>
						<FiUser />
						Account Settings
					</DropdownMenuItem>
					<SignOutButton />
				</Content>
			</RadixMenu.Portal>
		</Dropdown>
	);
}

const Trigger = styled(DropdownTrigger)`
	padding: 8px;
	margin-left: -6px;
	margin-right: -6px;
	border-radius: 4px;

	:hover {
		background: var(--color-hover);
	}
`;

const Content = styled(DropdownContent)`
	width: 265px;
`;
