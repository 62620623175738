import { SyntheticEvent, useEffect, useState } from "react";

import styled from "styled-components";

import { useTreeDepth } from "@/modules/board/meta/hooks/useTreeDepth";

import { Select } from "../../../../shared/system";

export const MoreDepth = () => {
	const [depth, setDepth] = useState<string | undefined>(undefined);
	const depthOptions = useTreeDepth();

	useEffect(() => {
		return () => {
			setDepth("auto");
		};
	}, []);

	const handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
		const depth = e.currentTarget.value;
		const option = depthOptions.find((option) => option.depth === depth);

		if (option) {
			option.action();
			setDepth(depth);
		}
	};

	return (
		<Root>
			<div>Tree depth</div>
			<div>
				<Select value={depth} onChange={handleChange} variant="thin">
					{depthOptions.map(({ depth }) => (
						<option key={depth} value={depth}>
							{depth}
						</option>
					))}
					<option value="auto">Auto</option>
				</Select>
			</div>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 6px;
	color: var(--color-text-heading);

	> * {
		flex-grow: 0;
		flex-shrink: 0;
	}
`;
