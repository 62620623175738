import { useCallback } from "react";

import * as RadixMenu from "@radix-ui/react-dropdown-menu";
import { FiMoreHorizontal } from "react-icons/fi";
import styled from "styled-components";

import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger, Hover } from "../../../shared/system";
import { archiveNotifications } from "../../notifications/notificationFunctions";

type Props = {
	notificationId: string;
	handleMarkAsRead: (read?: boolean) => void;
};

export function InboxMessageMore({ notificationId, handleMarkAsRead }: Props) {
	const handleArchiveNotification = useCallback(() => {
		archiveNotifications([notificationId]);
	}, [notificationId]);

	return (
		<Dropdown>
			<DropdownTrigger>
				<Icon>
					<FiMoreHorizontal />
				</Icon>
			</DropdownTrigger>
			<RadixMenu.Portal>
				<DropdownContent>
					<DropdownMenuItem
						onSelect={() => {
							handleMarkAsRead();
						}}>
						Mark as read
					</DropdownMenuItem>
					<DropdownMenuItem
						onSelect={() => {
							handleMarkAsRead(false);
						}}>
						Mark as unread
					</DropdownMenuItem>
					<DropdownMenuItem onSelect={handleArchiveNotification}>Clear notification</DropdownMenuItem>
				</DropdownContent>
			</RadixMenu.Portal>
		</Dropdown>
	);
}

const Icon = styled(Hover)`
	display: flex;
	align-items: center;
	padding: 4px;
	line-height: 1;
	font-size: 15px;
	margin: -3px 0;
	min-height: 28px;
`;
