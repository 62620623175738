import { FieldValue } from "firebase/firestore";

import { Command } from "../../../shared/core/hooks/useActionHistory";
import { updateBoard } from "../boardFunctions";

type UpdateMetaVisibilityBoard = (args: { boardId: string; newValue: FieldValue; oldValue: string[] }) => Command;

export const updateMetaVisibilityBoard: UpdateMetaVisibilityBoard = ({ boardId, newValue, oldValue }) => {
	const action = () => {
		updateBoard(boardId, {
			"meta.hiddenNodeMetaIds": newValue,
		});
	};
	const undo = () => {
		updateBoard(boardId, {
			"meta.hiddenNodeMetaIds": oldValue,
		});
	};

	return new Command({ name: "Updated Meta Visibility", action, undo, value: undefined });
};
