import { FiPlus, FiSettings, FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import routes from "../../../shared/core/routes";
import { DropdownHr, DropdownMenuItem } from "../../../shared/system";
import { updateActiveWorkspace } from "../../profile/functionsProfile";
import { useProfileLegacy } from "../../profile/hooks/useProfileLegacy";
import { usePermissions } from "../../workspace/hooks/usePermissions";
import { getCurrentWorkspace } from "../../workspace/selectors/getCurrentWorkspace";
import { getWorkspacesEnumerable } from "../../workspace/selectors/getWorkspacesEnumerable";
import { createWorkspaceWithNodeTypes } from "../../workspace/workspaceFunctions";
import { WorkspaceItem } from "./WorkspaceItem";

type Props = {
	selectedId: string;
};

export default function WorkspaceSelect({ selectedId }: Props) {
	const navigate = useNavigate();
	const user = useProfileLegacy();
	const { role } = usePermissions("workspace");
	const workspaces = useRecoilValue(getWorkspacesEnumerable);
	const activeWorkspace = useRecoilValue(getCurrentWorkspace);

	const isAdmin = role === "admin";

	const handleAddWorkspace = async () => {
		await createWorkspaceWithNodeTypes(user?.id);
		navigate(routes.home);
	};

	const handleSetActiveWorkspace = (workspaceId: string) => {
		if (workspaceId === activeWorkspace?.id) {
			return;
		}

		updateActiveWorkspace(workspaceId);
		navigate(routes.home);
	};

	const handleRenameWorkspace = () => {
		navigate(routes.account);
	};

	const handleWorkspaceMembers = () => {
		navigate(routes.members);
	};

	return (
		<>
			{workspaces.map((workspace) => {
				const isSelected = workspace.id === selectedId;
				return (
					<DropdownMenuItem onSelect={() => handleSetActiveWorkspace(workspace.id)} key={workspace.id}>
						<WorkspaceItem workspace={workspace} selected={isSelected} showPlan />
					</DropdownMenuItem>
				);
			})}
			<DropdownHr />
			<DropdownMenuItem onSelect={handleAddWorkspace} data-testid="workspace-select__new">
				<FiPlus />
				New workspace
			</DropdownMenuItem>
			<DropdownHr />
			<DropdownMenuItem onSelect={handleWorkspaceMembers}>
				<FiUsers /> Workspace members ({activeWorkspace?.users.allIds.length})
			</DropdownMenuItem>
			<DropdownMenuItem onSelect={handleRenameWorkspace} disabled={!isAdmin}>
				<FiSettings /> Workspace settings
			</DropdownMenuItem>
		</>
	);
}
