import { Timestamp } from "firebase/firestore";

import { EventModel } from "@/domains/nodes/models/eventsModel";

import { TaskNodeDateData } from "../db";
import { PublicProfile } from "./boards";

export enum NotificationType {
	activity = "activity",
	comment = "comment",
	reminder = "reminder",
}

interface BaseNotification {
	archivedAt: Timestamp | null;
	boardId: string;
	createdAt: Timestamp;
	from: PublicProfile;
	nodeId: string;
	nodeTitle: string;
	seenAt: Timestamp | null;
	emailedAt: Timestamp | null;
	userId: string;
	workspaceId: string;
}

export interface NotificationActivity extends BaseNotification {
	type: NotificationType.activity;
	activity: Pick<EventModel, "type" | "value">;
}

export interface NotificationComment extends BaseNotification {
	type: NotificationType.comment;
	body: string;
}
export interface NotificationReminder extends BaseNotification {
	type: NotificationType.reminder;
	data: TaskNodeDateData;
}
export type Notification = NotificationActivity | NotificationComment | NotificationReminder;
