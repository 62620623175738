import { doc, updateDoc, writeBatch } from "firebase/firestore";

import { db, fb } from "../../shared/infra/init";

export const markAsRead = async (notificationId: string, { read }: { read: boolean }) => {
	const seenAt = read ? fb.timestamp() : null;
	try {
		const notificationsDocument = doc(db.notifications, notificationId);
		updateDoc(notificationsDocument, { seenAt });
	} catch (e) {
		console.log(e);
	}
};

export const archiveNotifications = async (notificationIds: string[]) => {
	const batch = writeBatch(fb.firestore);

	notificationIds.forEach((id) => {
		const notificationDocument = doc(db.notifications, id);
		batch.update(notificationDocument, { archivedAt: fb.timestamp() });
	});

	try {
		batch.commit();
	} catch (e) {
		console.log(e);
	}
};
