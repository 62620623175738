import React from "react";

import { BaseError } from "./components/BaseError";

export function Error500() {
	return (
		<BaseError
			code="500"
			title="We've run into a problem"
			description="DevOps we have a problem... We've logged this issue and will look to resolve it as soon as possible."
		/>
	);
}
