import { atom } from "recoil";

import { IWorkspaceProps } from "@/modules/authentication/utils/workspace";

import { WithId } from "../../../types/db/utils";

export const workspacesState = atom<Record<string, WithId<IWorkspaceProps>>>({
	key: "workspacesState",
	default: {},
});
