import { useCallback } from "react";

import { BubbleMenu, Editor } from "@tiptap/react";
import {
	AiOutlineBold,
	AiOutlineCheckSquare,
	AiOutlineCode,
	AiOutlineItalic,
	AiOutlineLink,
	AiOutlineOrderedList,
	AiOutlineUnorderedList,
} from "react-icons/ai";
import styled from "styled-components";

import HeadingOneIcon from "./assets/headingOne.svg?react";
import HeadingTwoIcon from "./assets/headingTwo.svg?react";
import { MenuItem } from "./MenuItem";
import { MenuLink } from "./MenuLink";

type Props = {
	editor: Editor;
};

export const MenuBar = ({ editor }: Props) => {
	const showLinkToolbar = editor.isActive("link");

	const command = useCallback(
		(property: string, attributes?: any): (() => void) => {
			return () => (editor.chain().focus() as any)[property](attributes).run();
		},
		[editor],
	);

	const isActive = useCallback(
		(property: string, attribute?: any) => (editor.isActive(property, attribute) ? "active" : "inactive"),
		[editor],
	);

	if (!editor) {
		return null;
	}

	return (
		<Root tippyOptions={{ duration: 100 }} editor={editor}>
			<MenuItem onClick={command("toggleBold")} appearance={isActive("bold")}>
				<AiOutlineBold />
			</MenuItem>
			<MenuItem onClick={command("toggleItalic")} appearance={isActive("italic")}>
				<AiOutlineItalic />
			</MenuItem>
			<MenuItem onClick={command("toggleLink")} appearance={isActive("link")}>
				<AiOutlineLink />
			</MenuItem>
			<MenuItem onClick={command("toggleCode")} appearance={isActive("code")}>
				<AiOutlineCode />
			</MenuItem>
			<MenuDivider />
			<MenuItem onClick={command("toggleHeading", { level: 1 })} appearance={isActive("heading", { level: 1 })}>
				<HeadingOneIcon />
			</MenuItem>
			<MenuItem onClick={command("toggleHeading", { level: 2 })} appearance={isActive("heading", { level: 2 })}>
				<HeadingTwoIcon />
			</MenuItem>
			<MenuItem onClick={command("toggleBulletList")} appearance={isActive("bulletList")}>
				<AiOutlineUnorderedList />
			</MenuItem>
			<MenuItem onClick={command("toggleOrderedList")} appearance={isActive("orderedList")}>
				<AiOutlineOrderedList />
			</MenuItem>
			<MenuItem onClick={command("toggleTaskList")} appearance={isActive("taskList")}>
				<AiOutlineCheckSquare />
			</MenuItem>
			{showLinkToolbar && (
				<LinkToolBar>
					<MenuLink editor={editor} />
				</LinkToolBar>
			)}
		</Root>
	);
};

const Root = styled(BubbleMenu)`
	position: relative;
	display: flex;
	padding: 4px;
	max-width: auto !important;
	border-radius: 4px;
	white-space: nowrap;
	border: var(--border);
	box-shadow:
		0.7px 1.3px 1.1px rgba(0, 0, 0, 0.014),
		1.6px 3.2px 2.6px rgba(0, 0, 0, 0.02),
		3px 6px 5px rgba(0, 0, 0, 0.025),
		5.4px 10.7px 9.1px rgba(0, 0, 0, 0.03),
		10px 20.1px 17px rgba(0, 0, 0, 0.036),
		24px 48px 40px rgba(0, 0, 0, 0.05);
	background: var(--color-body);

	> *:not(:last-child) {
		margin-right: 4px;
	}
`;

const MenuDivider = styled.div`
	width: 1px;
	background: var(--color-border);
	margin: 4px 4px;
	border-radius: 2px;
`;

const LinkToolBar = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
