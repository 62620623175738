import { useCallback } from "react";

import { useRecoilValue } from "recoil";
import styled, { css } from "styled-components";

import { Notification } from "@/types/db";

import { Box, Tabs } from "../../../shared/system";
import { getNotificationGroupedByType } from "../../notifications/notificationsData";
import { InboxMessages } from "./InboxMessages";

const unreadNotifications = (notification: Notification) => !notification.seenAt;

interface Props {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
}

export const InboxModal = ({ isOpen, setOpen }: Props) => {
	const groupedNotifications = useRecoilValue(getNotificationGroupedByType);

	const handleCloseModal = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	if (!isOpen) {
		return null;
	}

	const commentNotifications = groupedNotifications["comment"]?.slice(0).reverse() || [];
	const unreadComments = commentNotifications.filter(unreadNotifications).length;

	const activityNotifications = groupedNotifications["activity"]?.slice(0).reverse() || [];
	const unreadActivity = activityNotifications.filter(unreadNotifications).length;

	return (
		<>
			<Overlay onClick={handleCloseModal} />
			<Root>
				<Tabs defaultTab={1}>
					<Box label={`Comments (${unreadComments})`}>
						<InboxMessages notifications={commentNotifications} handleClose={handleCloseModal} />
					</Box>
					<Box label={`Following (${unreadActivity})`}>
						<InboxMessages notifications={activityNotifications} handleClose={handleCloseModal} />
					</Box>
				</Tabs>
			</Root>
		</>
	);
};

const Overlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	z-index: 2;
`;

export const modalRoot = css`
	[role="tablist"] {
		padding: 8px 16px 0;
		border-bottom: var(--border);
	}

	[role="tab"][aria-selected="true"] {
		border-bottom: 3px solid var(--color-accent-hover);
		padding-bottom: 9px;
	}

	[role="tabcontent"] {
		padding-top: 0;
		padding-bottom: 0;
		flex-grow: 1;
		overflow: auto;
	}
`;

const Root = styled.div`
	position: fixed;
	top: 20px;
	left: 300px;
	display: flex;
	flex-direction: column;
	width: 500px;
	border-radius: 4px;
	border: var(--border);
	box-shadow: var(--drop-shadow-l2);
	background: var(--color-body);
	max-height: 80vh;
	overflow: hidden;
	z-index: 10;

	${modalRoot}
`;
