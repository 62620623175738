import { useRecoilValue } from "recoil";

import { activeNodeMetaDocumentId } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaListener";
import { nodeMetaService } from "@/domains/nodes/services";

export const useNodeMetaActions = () => {
	const metaDocumentId = useRecoilValue(activeNodeMetaDocumentId);

	return {
		addNodeMetaCollection: nodeMetaService.createNodeMeta.bind(nodeMetaService, metaDocumentId),
		deleteNodeMeta: nodeMetaService.deleteMetaType.bind(nodeMetaService, metaDocumentId),
		updateNodeMetaLabel: nodeMetaService.updateNodeMetaLabel.bind(nodeMetaService, metaDocumentId),
		updateNodeMetaOrder: nodeMetaService.updateNodeMetaOrder.bind(nodeMetaService, metaDocumentId),
		updateNodeMetaType: nodeMetaService.updateNodeMetaType.bind(nodeMetaService, metaDocumentId),
		updateNodeMetaValue: nodeMetaService.updateNodeMetaValue.bind(nodeMetaService, metaDocumentId),
		updateNodeMetaAtId: nodeMetaService.addMetaValue.bind(nodeMetaService, metaDocumentId),
	};
};
