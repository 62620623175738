import { selectorFamily } from "recoil";

import { sidebarNavigationState } from "./sidebarNavigationState";

export const getSidebarNavigationItemEditing = selectorFamily<boolean, string>({
	key: "getSidebarNavigationItemEditing",
	get:
		(projectId) =>
		({ get }) => {
			const [activeId, state] = get(sidebarNavigationState);
			return projectId === activeId && state === "rename";
		},
});
