import { useMemo } from "react";

import { format } from "date-fns";
import minBy from "lodash/minBy";
import {
	VictoryArea,
	VictoryAxis,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryScatter,
	VictoryThemeDefinition,
	VictoryTooltip,
} from "victory";

import { KeyResultModel } from "@/types/db";

import {
	areaStyle,
	chartPadding,
	lineStyle,
	scatterStyle,
	tooltipLabelStyle,
	tooltipPadding,
	tooltipStyle,
} from "../../utils/chartHelpers";
import { isGetTo } from "../../utils/keyResultsUtils";

const dateFormat = "d MMM";

export const chartTheme: VictoryThemeDefinition = {
	axis: {
		style: {
			axis: {
				fill: "transparent",
				stroke: "var(--color-border)",
				strokeWidth: 2,
				strokeLinecap: "round",
				strokeLinejoin: "round",
			},
			axisLabel: {
				textAnchor: "middle",
				fontSize: 14,
				letterSpacing: "normal",
				padding: 8,
				fill: "var(--color-text-secondary)",
				stroke: "transparent",
				strokeWidth: 0,
			},
			grid: {
				fill: "none",
				stroke: "var(--color-border)",
				opacity: 0.66,
				strokeDasharray: "10, 5",
				strokeLinecap: "round",
				strokeLinejoin: "round",
				pointerEvents: "painted",
			},
			ticks: {
				fill: "transparent",
				size: 5,
				stroke: "var(--color-border)",
				strokeWidth: 1,
				strokeLinecap: "round",
				strokeLinejoin: "round",
			},
			tickLabels: {
				fontSize: 14,
				letterSpacing: "normal",
				padding: 8,
				fill: "var(--color-text-secondary)",
				stroke: "transparent",
				strokeWidth: 0,
			},
		},
	},
};

export type ChartPoints = {
	x: Date;
	y: number;
}[];

type Props = {
	goalType: KeyResultModel["goalType"];
	currentData: ChartPoints;
	targetData: any[];
	startingValue: KeyResultModel["startingValue"];
};

export const KeyResultsChart: React.FC<Props> = ({ goalType, currentData, targetData, startingValue }) => {
	const minDomain = useMemo(() => {
		const minValue = minBy(currentData, ({ y }) => y);
		return { y: Math.min(minValue?.y || 0, parseInt(startingValue, 10)) };
	}, [currentData, startingValue]);

	const chartMinDomain = isGetTo(goalType) ? minDomain : undefined;

	return (
		<div>
			<VictoryChart width={700} height={350} padding={chartPadding} minDomain={chartMinDomain} theme={chartTheme}>
				<VictoryAxis dependentAxis />
				<VictoryAxis scale="time" tickLabelComponent={<VictoryLabel dy={4} />} tickFormat={(t) => format(t, dateFormat)} />
				<VictoryArea style={areaStyle} data={targetData} />
				<VictoryLine style={lineStyle} data={currentData} />
				<VictoryScatter
					data={currentData}
					size={5}
					labels={({ datum }) => [format(datum.x, dateFormat), datum.y]}
					labelComponent={
						<VictoryTooltip
							cornerRadius={4}
							dy={-16}
							flyoutStyle={tooltipStyle}
							flyoutPadding={tooltipPadding}
							labelComponent={<VictoryLabel lineHeight={1} style={tooltipLabelStyle} />}
						/>
					}
					style={scatterStyle}
				/>
			</VictoryChart>
		</div>
	);
};
