import { useRecoilValue } from "recoil";

import { EventGroup } from "@/domains/nodes/models/eventsModel";
import { commentsState } from "@/modules/comments/hooks/useComments";
import { UserComment, WithId } from "@/types/db";

export type ActivityModel = EventGroup | WithId<UserComment>;

/**
 * TODO: Currently is you modify the node via the tree view this will always create a new
 * activity group rather than upsert similar activity as there is no activity in the store
 * to check for recent updates.
 *
 * One possible solution to resolve this would be to create a global session/group-id that is used
 * for all events while also keeping a register of all events performed within the user session.
 * it could be possible to use the event store for the command history
 */
export const useActivity = (nodeId: string) => {
	const comments = useRecoilValue(commentsState(nodeId));

	return comments;
};
