import { FiRotateCcw, FiRotateCw } from "react-icons/fi";
import styled from "styled-components";

import { useActionHistory } from "../../../../shared/core/hooks/useActionHistory";
import { DropdownMenuButton } from "../../../../shared/system";

export const MoreUndo = () => {
	const { undo, redo, canUndo, canRedo } = useActionHistory();

	const disabled = !canUndo();
	const redoDisabled = !canRedo();

	return (
		<>
			<DropdownMenuButton onClick={undo} disabled={disabled}>
				<FiRotateCcw /> Undo{" "}
				<Shortcut>
					<kbd>⌘</kbd>
					<kbd>Z</kbd>
				</Shortcut>
			</DropdownMenuButton>
			<DropdownMenuButton onClick={redo} disabled={redoDisabled}>
				<FiRotateCw /> Redo{" "}
				<Shortcut>
					<kbd>⌘</kbd>
					<kbd>⇧</kbd>
					<kbd>Z</kbd>
				</Shortcut>
			</DropdownMenuButton>
		</>
	);
};

const Shortcut = styled.span`
	margin-left: auto;
	align-self: flex-end;
	color: var(--color-text-secondary);
`;
