import { saveAs } from "file-saver";
import { toJpeg, toPng, toSvg } from "html-to-image";
import { SubmitHandler, useForm } from "react-hook-form";
import { getRectOfNodes, getTransformForBounds, Node, useReactFlow } from "reactflow";
import styled from "styled-components";

import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { useErrorService } from "@/shared/core/hooks/useErrorService";

import { Button, Modal, Select, Toggle } from "../../../../shared/system";
import { ModalActionGroup, ModalHeader } from "../../../../shared/system/Modal/Modal";
import { ProjectBoard } from "../../../../types/db";
import { WithId } from "../../../../types/db/utils";
import { useExportBoardToCsv } from "../hooks/useExportBoardToCsv";

export const toFilename = (string: string) => {
	return `poda-${string.replace(/[/\\?%*:|"<> ]/g, "-").toLowerCase()}`;
};

const filter = (node: HTMLElement) => {
	if (node.classList) {
		return !node?.classList.contains("hide-from-export");
	}
	return true;
};

const getTransformedCanvas = (nodes: Node[]) => {
	const nodesBounds = getRectOfNodes(nodes);
	const imageWidth = nodesBounds.width + 200;
	const imageHeight = nodesBounds.height + 200;
	const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

	return {
		width: imageWidth,
		height: imageHeight,
		style: {
			width: String(imageWidth),
			height: String(imageHeight),
			transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
		},
	};
};

type ExportForm = {
	pixelRatio: string;
	background: boolean;
	details: boolean;
	fileType: "png" | "jpg" | "svg" | "csv";
};

type Props = {
	board: WithId<ProjectBoard>;
	isOpen: boolean;
	setOpen: (isOpen: boolean) => void;
};

export const MoreExportModal = ({ board, isOpen, setOpen }: Props) => {
	const errorService = useErrorService();
	const analyticsService = useAnalyticsService();
	const { getNodes } = useReactFlow();

	const { register, setValue, watch, handleSubmit, formState } = useForm<ExportForm>({
		defaultValues: {
			pixelRatio: "1",
			background: true,
			details: false,
			fileType: "png",
		},
	});

	const background = watch("background");
	const details = watch("details");
	const fileType = watch("fileType");

	const toCsv = useExportBoardToCsv(board.id, details);

	const handleSetBackground = async () => setValue("background", !background);
	const handleSetExportDetails = async () => setValue("details", !details);

	const onSubmit: SubmitHandler<ExportForm> = async (data) => {
		const fileMap = {
			png: toPng,
			svg: toSvg,
			jpg: toJpeg,
			csv: toCsv,
		} as const;

		const fileFunction = fileMap[data.fileType];
		const projectElement = document.querySelector(".react-flow__viewport") as HTMLElement;

		if (!projectElement) {
			errorService.logWarning("Project not found for export", { projectId: board.id });
			return;
		}

		const backgroundColor = data.background ? getComputedStyle(document.body).getPropertyValue("--color-body") : "";

		const blob = await fileFunction(projectElement, {
			filter,
			backgroundColor,
			pixelRatio: parseInt(data.pixelRatio, 10),
			...getTransformedCanvas(getNodes()),
		});

		if (!blob) {
			errorService.logError("Unable to save export", { projectId: board.id });

			return;
		}

		saveAs(blob, `${toFilename(board.data.name)}.${data.fileType}`);

		analyticsService.projectExported({
			boardId: board.id,
			workspaceId: board.workspaceId,
			format: data.fileType,
		});
	};

	const isImageType = ["png", "jpg"].includes(fileType);

	return (
		<Modal isOpen={isOpen} width="324px" onClose={setOpen}>
			<ModalHeader>Export as</ModalHeader>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ToggleGroup>
					<span>File format</span>{" "}
					<Select {...register("fileType")} variant="thin">
						<option value="jpg">JPG</option>
						<option value="png">PNG</option>
						<option value="svg">SVG</option>
						<option value="csv">CSV</option>
					</Select>
				</ToggleGroup>
				{isImageType && (
					<>
						<ToggleGroup>
							<div>Size</div>
							<OptionGroup>
								<input type="radio" id="1x" {...register("pixelRatio")} value="1" />
								<label htmlFor="1x">1x</label>
								<input type="radio" id="2x" {...register("pixelRatio")} value="2" />
								<label htmlFor="2x">2x</label>
							</OptionGroup>
						</ToggleGroup>

						<ToggleGroup>
							<span>Include Background</span> <Toggle selected={background} size="small" onClick={handleSetBackground} />
						</ToggleGroup>
					</>
				)}
				{fileType === "csv" && (
					<ToggleGroup>
						<span>Include Sub pages</span> <Toggle selected={details} size="small" onClick={handleSetExportDetails} />
					</ToggleGroup>
				)}
				<ModalActionGroup>
					<Button type="submit" disabled={formState.isSubmitting}>
						Download
					</Button>
				</ModalActionGroup>
			</form>
		</Modal>
	);
};

const OptionGroup = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--color-accent);
	border-radius: 4px;

	input {
		display: none;
	}

	label {
		display: block;
		font-size: 12px;
		line-height: 1;
		padding: 6px 12px;
		cursor: pointer;
	}

	input:checked + label {
		background: var(--color-accent);
		color: var(--color-body);
	}
`;

const ToggleGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0;

	span {
		padding-right: 12px;
		flex-shrink: 0;
	}

	select {
		margin-bottom: 0;
		width: auto;
	}

	:first-child {
		margin-top: 20px;
	}
`;
