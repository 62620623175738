import { ActivityModel } from "@/domains/nodes/components/details/activity/hooks/useActivity";

import { ActivityItem } from "./ActivityItem";

type Props = {
	activity: ActivityModel;
	lastItem: boolean;
};

export const ActivityCluster = ({ activity, lastItem }: Props) => {
	if (activity.type === "group") {
		return (
			<div className="grouped">
				{activity.items.map((item, index) => {
					return item && <ActivityItem activity={item} key={item.id} showTimestamp={index === 0} lastItem={lastItem} />;
				})}
			</div>
		);
	}

	return <ActivityItem activity={activity} lastItem={lastItem} showTimestamp={false} />;
};
