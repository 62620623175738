import { doc, updateDoc } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";
import { Result } from "@/shared/utils/result";
import { PublicProfile } from "@/types/db";

export const projectSetPublic = (projectId: string, isPublic: boolean, user: PublicProfile): Result<void> => {
	try {
		const projectDocument = doc(db.projects, projectId);
		updateDoc(projectDocument, {
			"meta.public": isPublic,
			updatedAt: fb.timestamp(),
			updatedBy: user,
		});

		return Result.ok();
	} catch (e) {
		return Result.fail("Failed to update project");
	}
};
