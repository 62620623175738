import { z } from "zod";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { useProject } from "@/domains/projects/hooks/useProject";
import { workspacePermissions } from "@/modules/authentication/utils/workspace";
import { useProfile } from "@/modules/profile/hooks/useProfile";
import { useWorkspace } from "@/modules/workspace/hooks/useWorkspace";

import { WithId, Workspace, WorkspaceMemberRole } from "../../../types/db";

type EntityPermissions = z.infer<typeof workspacePermissions>;
type PermissionGroups = ("edit" | "comment" | "users" | "keyResults" | "view")[];

const permissionComment: PermissionGroups = ["view", "comment"];
const permissionsEdit: PermissionGroups = ["edit", "view", "comment", "users", "keyResults"];
const permissionsNone: PermissionGroups = [];
const permissionsReadOnly: PermissionGroups = ["view"];

const permissionRoleCapabilities: { [key in WorkspaceMemberRole]: PermissionGroups } = {
	admin: permissionsEdit,
	editor: permissionsEdit,
	owner: permissionsEdit,
	viewer: permissionsReadOnly,
	commenter: permissionComment,
	suspended: permissionsNone,
	invite: permissionsNone,
};

class Permissions {
	public role: EntityPermissions["role"];
	public status: EntityPermissions["status"];
	public permissions: PermissionGroups;

	public canView: boolean;
	public canEdit: boolean;
	public canComment: boolean;

	private constructor(props: EntityPermissions) {
		this.role = props.role;
		this.status = props.status;

		const isActive = this.status === "active";

		this.permissions = isActive ? permissionRoleCapabilities[this.role] : [];

		this.canEdit = this.permissions.includes("edit");
		this.canView = this.permissions.includes("view");
		this.canComment = this.permissions.includes("comment");
	}

	public static create(props: EntityPermissions) {
		return new Permissions(props);
	}
}

const permissionDefault = Permissions.create({ role: "viewer", scope: "guest", status: "active" });

const buildPermissionsProject = (project: WithId<ProjectDtoBoard>, userId: string): Permissions => {
	const permissionsProject = project.access?.[userId];

	if (!permissionsProject) {
		return permissionDefault;
	}

	return Permissions.create(permissionsProject);
};

const buildPermissionsWorkspace = (workspace: WithId<Workspace>, userId: string): Permissions => {
	const permissionsWorkspace = workspace.users.byId[userId];

	if (!permissionsWorkspace) {
		return permissionDefault;
	}

	/**
	 * Currently when accepting a workspace invite it does not include a status key rather deletes the
	 * user record when then profile has been suspended.
	 */
	return Permissions.create({ status: "active" as const, ...permissionsWorkspace });
};

export const usePermissions = (scope: "workspace" | "project"): Permissions => {
	const workspaceOrError = useWorkspace();
	const projectOrError = useProject();
	const profileOrError = useProfile();

	if (profileOrError.isFailure) {
		return permissionDefault;
	}

	const profile = profileOrError.getValue();

	if (scope === "workspace" && workspaceOrError.isSuccess) {
		return buildPermissionsWorkspace(workspaceOrError.getValue(), profile.id);
	}

	if (scope === "project" && projectOrError.isSuccess) {
		const project = projectOrError.getValue();

		if (project.visibility === "workspace" && workspaceOrError.isSuccess) {
			const workspace = workspaceOrError.getValue();

			if (project.workspaceId === workspace.id) {
				return buildPermissionsWorkspace(workspaceOrError.getValue(), profile.id);
			}
		}

		return buildPermissionsProject(project, profile.id);
	}

	return permissionDefault;
};
