import { NodeCardKeyResults } from "@/domains/nodes/components/cards/components/NodeCardKeyResults";
import { NodeMeta } from "@/domains/nodes/components/cards/components/NodeMeta";
import { NodeTitle } from "@/domains/nodes/components/cards/components/NodeTitle";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

type Props = {
	node: NodeModel;
};

export const NodeCardDetails: React.FC<Props> = ({ node }) => {
	return (
		<div>
			<NodeTitle node={node} />
			<NodeMeta node={node} />
			<NodeCardKeyResults node={node} />
		</div>
	);
};
