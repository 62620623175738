import { z } from "zod";

const hexColor = z
	.string()
	.length(7, {
		message: "Please enter a valid 6 character hex code",
	})
	.regex(/^#/, {
		message: "Please enter a valid 6 character hex code",
	});

export const themeNames = z.union([
	z.literal("inherit"),
	z.literal("light"),
	z.literal("grey"),
	z.literal("dark"),
	z.literal("neon"),
	z.literal("teal"),
	z.literal("custom"),
]);

export const themeModel = z.object({
	body: hexColor,
	accent: hexColor,
});

export type Theme = z.infer<typeof themeModel>;
export type ThemeName = z.infer<typeof themeNames>;
