import styled from "styled-components";

type Props = { statusColor?: string; bgColor?: string };

export default styled.progress<Props>`
	height: 4px;
	margin-bottom: 6px;
	border: 0;
	display: block;
	width: 100%;

	&::-webkit-progress-bar {
		background-color: var(--color-border);
		border-radius: 8px;
		border: 0;
	}

	&::-webkit-progress-value {
		background-color: var(--color-accent);
		border-radius: 8px;
	}
`;
