import { z } from "zod";

import {
	integrationSlack,
	projectData,
	projectDto,
	projectDtoBoard,
	projectDtoFolder,
	projectMeta,
	projectStatus,
	projectVisibility,
	publicProfile,
	seenBy,
} from "@/domains/projects/dtos/projectsDto";
import { projectImport, projectImportStatus } from "@/domains/projects/dtos/projectsImportDto";

export type ProjectData = z.infer<typeof projectData>;
export type ProjectStatus = z.infer<typeof projectStatus>;
export type ProjectVisibility = z.infer<typeof projectVisibility>;
export type ProjectIntegrations = z.infer<typeof integrationSlack>;
export type ProjectMeta = z.infer<typeof projectMeta>;
export type ProjectBoard = z.infer<typeof projectDtoBoard>;
export type ProjectFolder = z.infer<typeof projectDtoFolder>;
export type ProjectSeenBy = z.infer<typeof seenBy>;
export type ProjectDto = z.infer<typeof projectDto>;

/**
 * Import Sub collection
 */
export type ProjectImportStatus = z.infer<typeof projectImportStatus>;
export type ProjectImport = z.infer<typeof projectImport>;

/**
 * Needs new home
 */

export type PublicProfile = z.infer<typeof publicProfile>;

export enum SystemFolderName {
	favorite = "Favorites",
	workspace = "Workspace",
	shared = "Shared",
	private = "Private",
}

/**
 * Deprecated code
 */

// @deprecated use union instead
export enum ActivityTypes {
	StatusUpdate = "nodeUpdateStatus",
	Created = "nodeCreated",
}
// @deprecated use union instead
export enum DetailsSections {
	Cover = "cover",
	Title = "title",
	Meta = "meta",
	Results = "results",
	Children = "children",
	Activity = "activity",
}
