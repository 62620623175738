/**
 * NOTE: When changing board routes there are functions that assume anything with
 * a boardId has only a single segment before the param see
 *
 * src/modules/node/NodeCard.js -> getParentPath()
 */

const routes = {
	// Public pages
	home: "/",
	pricing: "/pricing",
	promotions: "/promotions/:productCode",
	terms: "/legal/terms-conditions",
	privacy: "/legal/privacy-policy",
	signUp: "/sign-up",
	appSumo: "/appsumo",
	login: "/login",
	ourRoadmap: "/product-roadmap",
	workflows: "/workflows",

	// Auth pages
	account: "/account",
	board: "/b/:boardId/*",
	boardEmbed: "/e/:boardId/*",
	boardEmbedNode: "/e/:boardId/:nodeId",
	boardSettings: "/s/:boardId",
	emailActions: "/email-actions",
	forgotPassword: "/forgot-password",
	members: "/members",
	node: ":nodeId",
	plans: "/plans",
	profile: "/profile",
	kanban: "/r/:boardId/*",
	kanbanNode: "/r/:boardId/:nodeId",
	welcome: "/welcome",
	workspace: "/w",
};

export type ProjectParams = { boardId: string };
export type NodeParams = { boardId: string; nodeId: string };

export default routes;
