import { SyntheticEvent } from "react";

import { arrayRemove, arrayUnion } from "firebase/firestore";
import styled from "styled-components";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { DropdownHeader, Tooltip } from "@/shared/system";

import { useBoardActions } from "../../hooks/useBoardActions";

type MetaScope = "project" | "node";

export const useHiddenMetaState = (docId: string, scope: MetaScope = "project"): [string[], (metaId: string) => void] => {
	const isBoard = scope === "project";
	const project = useGetProject();
	const nodesStore = useNodesStore();

	const node = nodesStore.getNode(docId);

	const selectorState = project?.meta.hiddenNodeMetaIds || [];
	const state = node.isFailure ? selectorState : NodeMetaUtil.getHiddenMetaIds(node.getValue(), project);

	const nodesService = useNodesService();
	const { updateMetaVisibilityBoard } = useBoardActions();

	const handleToggleMeta = (metaId: string) => {
		const metaHidden = !state.includes(metaId) ? arrayUnion(metaId) : arrayRemove(metaId);

		if (isBoard) {
			updateMetaVisibilityBoard(docId, metaHidden);
		} else {
			nodesService.update(docId, { metaHidden });
		}
	};

	return [state, handleToggleMeta];
};

type Props = {
	docId: string;
	boardId: string;
	scope: MetaScope;
	onClick?: VoidFunction;
};

export const MoreMetaTags = ({ docId, scope, onClick = () => {} }: Props) => {
	const tags = useNodeMetaStore(({ meta }) => [...meta.values()]);
	const project = useGetProject();
	const filteredAtBoard = project?.meta.hiddenNodeMetaIds || [];
	const [hiddenMeta, setHiddenMeta] = useHiddenMetaState(docId, scope);

	return (
		<div>
			<DropdownHeader>Display properties ({scope})</DropdownHeader>
			<TagGroup>
				{tags.map((tag) => {
					const active = !hiddenMeta.includes(tag.id);
					const hiddenAtProjectLevel = filteredAtBoard.includes(tag.id);
					const Wrapper = active ? TagActive : TagInactive;

					if (scope === "node" && hiddenAtProjectLevel) {
						return (
							<Tooltip label="Hidden for project" key={tag.id}>
								<TagDisabled key={tag.id}>{tag.label}</TagDisabled>
							</Tooltip>
						);
					}

					const handleClick = (e: SyntheticEvent) => {
						e.preventDefault();

						if (active) {
							setHiddenMeta(tag.id);
						} else {
							setHiddenMeta(tag.id);
						}

						onClick();
					};

					return (
						<Wrapper key={tag.id} onClick={handleClick}>
							{tag.label}
						</Wrapper>
					);
				})}
			</TagGroup>
		</div>
	);
};

const TagGroup = styled.div`
	max-width: 220px;
	padding-top: 4px;
`;

const TagActive = styled.button`
	display: inline-block;
	padding: 1px 7px;
	border: 0;
	border-radius: 4px;
	background: var(--color-hover);
	color: var(--color-text-heading);
	border: 1px solid transparent;
	margin: 2px;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 203px;

	:hover {
		border: var(--border);
	}
`;

const TagInactive = styled(TagActive)`
	background: transparent;
	border: var(--border);
`;

const TagDisabled = styled(TagInactive)`
	opacity: 0.33;
`;
