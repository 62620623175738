import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";

import { getNodeMetaObject } from "../../../../../modules/nodeMeta/utils/getNodeMetaValue";
import { generateColorHsl } from "../../../../../shared/system/Avatar/AvatarStatic";
import { Node, NodeMetaSystemLabels, NodeMetaValueSelect } from "../../../../../types/db";

export const useNodeHighlight = (node: Node) => {
	const nodeTypeOrError = useNodeMetaStore((state) => state.getByLabel(NodeMetaSystemLabels.nodeType));

	if (nodeTypeOrError.isFailure) {
		return;
	}

	const value = getNodeMetaObject(node, nodeTypeOrError.getValue()) as NodeMetaValueSelect | undefined;

	if (!value) {
		return generateColorHsl("yolo");
	}

	return generateColorHsl(value.title);
};
