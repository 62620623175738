import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useErrorService } from "@/shared/core/hooks/useErrorService";

import { useCloneSubTree } from "../../nodeContextMenu/hooks/useCloneSubTree";

export const useDuplicateNode = (node: NodeModel) => {
	const cloneSubTree = useCloneSubTree();
	const treeService = useTreeService();
	const errorService = useErrorService();

	const parentOrError = treeService.getParent(node.id);

	if (parentOrError.isFailure) {
		errorService.logError(parentOrError.getErrorValue());

		return () => {};
	}

	const parentDocument = parentOrError.getValue().data.document;

	return () => cloneSubTree(node, parentDocument);
};
