import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import { useProfileLegacy } from "../../../profile/hooks/useProfileLegacy";
import { upsertBoardMeta } from "../../meta/metaFunctions";
import { Filters } from "../filterModel";
import { filtersState } from "../filterState";

export const useFilterState = (): [Filters, (filter: Filters) => void] => {
	const profile = useProfileLegacy();
	const { boardId } = useParams() as { boardId: string };
	const [boardFilters, setBoardFilters] = useRecoilState(filtersState(boardId));

	return [
		boardFilters,
		(filter: Filters) => {
			if (!profile?.id || !boardId) {
				return;
			}
			upsertBoardMeta(profile.id, {
				filters: {
					[boardId]: filter,
				},
			});

			setBoardFilters(filter);
		},
	];
};
