const isGoalType = (type: any) => (goalType: any) => goalType === type;

export const isNoTarget = isGoalType("no-target");
export const isGetTo = isGoalType("get-to");

export const isStayAboveBelow = (goalType: any) => goalType === "stay-above" || goalType === "stay-below";

export function getChange(current: string, starting: string) {
	return parseInt(current, 10) - parseInt(starting, 10);
}

const targetPlaceholder = "*value*";
export const targetRegex = /^[^-0-9]*((?:-{0,1})\d+(?:,{1}\d+)*(?:\.{1}\d+){0,1}).*$/;

export const formatTarget = (value = "") => {
	const [match, target] = value.match(targetRegex) || [];
	const [prefix, suffix] = (target && match?.replace(target, targetPlaceholder).split(targetPlaceholder)) || [];

	return {
		target: target?.replace(",", "") || "0",
		suffix,
		prefix,
		appendUnits: (value: number | string | undefined = "0") => {
			const formattedValue = typeof value === "string" ? parseInt(value, 10) : value;
			return `${prefix}${Math.abs(formattedValue)} ${suffix}`;
		},
	};
};
