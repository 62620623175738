import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { GlobalStyle } from "../../../App";
import { Button, Heading, Text } from "../../../shared/system";
import { ThemeGlobalVariables } from "../../profile/theme/ThemeGlobalVariables";

interface Props {
	title: string;
	description: string;
	code: string;
}

export function BaseError({ title, description, code }: Props) {
	function navigateHome() {
		window.location.href = "/";
	}

	return (
		<Root>
			<GlobalStyle />
			<ThemeGlobalVariables />
			<Helmet>
				<title>{title}</title>
				<meta content={description} name="description" />
			</Helmet>
			<Container>
				<Heading.H1 fontSize={128} mb={1} fontWeight={400}>
					{code}
				</Heading.H1>
				<Heading.H2 mb={2} fontWeight={400}>
					{description}
				</Heading.H2>
				<Text fontSize={[1, 2]} mb={4}>
					Try heading back to the home page to get your bearings.
				</Text>
				<Button rounded onClick={navigateHome}>
					Head back home
				</Button>
			</Container>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	min-width: 50%;
	margin: auto;
`;

const Container = styled.div`
	max-width: 860px;
	margin: 0 auto;
	padding: 0;
`;
