import styled from "styled-components";

type NodeCardBaseProps = {
	$image?: string;
	$isDragging?: boolean;
	$isActive?: boolean;
	hoveringRight?: boolean;
	hoveringLeft?: boolean;
};

export const NodeCardBase = styled.div<NodeCardBaseProps>`
	padding: 18px;
	border-radius: 8px;
	background-color: var(--color-body);

	> * {
		opacity: ${({ $isActive }) => ($isActive ? `0.25` : `1`)};
	}

	&::before {
		content: "";
		width: 4px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -12px;
		background: var(--color-accent);
		box-shadow: var(--drop-shadow);
		display: ${({ hoveringLeft }) => (hoveringLeft ? `block` : `none`)};
	}

	&::after {
		content: "";
		width: 4px;
		border-radius: 4px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: -12px;
		background: var(--color-accent);
		display: ${({ hoveringRight }) => (hoveringRight ? `block` : `none`)};
		box-shadow: var(--drop-shadow);
	}
`;
