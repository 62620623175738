import { z } from "zod";

import { nodeMetaTypeText } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { DropdownText } from "@/shared/system/DropdownText/DropdownText";
import { Node, WithId } from "@/types/db";

interface Props {
	meta: z.infer<typeof nodeMetaTypeText>;
	node: WithId<Node>;
}

export const NodeMetaText = ({ node, meta }: Props) => {
	const currentValue = node?.meta?.[meta.id];
	const placeholderText = `Add ${meta.label.toLowerCase()}...`;
	const nodesService = useNodesService();

	const handleSave = (text: string) => {
		nodesService.updateMeta(node.id, meta.id, text);
	};

	const displayValue = <div>{currentValue}</div>;

	const text = typeof currentValue === "string" ? currentValue : "";

	return (
		<DropdownText
			label={meta.label}
			displayValue={displayValue}
			value={text}
			placeholderText={placeholderText}
			onComplete={handleSave}
			openOnClick
		/>
	);
};
