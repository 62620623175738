import { TbFileExport } from "react-icons/tb";
import styled from "styled-components";

import { DropdownMenuButton } from "../../../../shared/system";

type Props = {
	onClick: VoidFunction;
};

export const MoreExport = ({ onClick }: Props) => {
	const handleClick = () => {
		onClick();
	};
	return (
		<DropdownMenuButton onClick={handleClick}>
			<Icon>
				<TbFileExport />
			</Icon>
			<div>
				Export
				<SubText>JPG, PNG, SVG or CSV</SubText>
			</div>
		</DropdownMenuButton>
	);
};

const Icon = styled.div`
	align-self: flex-start;
	margin-top: 2px;
`;

const SubText = styled.div`
	font-size: 12px;
	color: var(--color-text);
`;
