import { useLayoutEffect, useRef } from "react";

import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { ResizableTextarea } from "@/shared/system";

type Props = {
	node: NodeModel;
};

export const NodeTitle: React.FC<Props> = ({ node }) => {
	const nodesService = useNodesService();

	const ref = useRef<HTMLTextAreaElement>(null);

	const isEditing = !!node.creating;

	useBindShortcut("return", () => ref.current?.blur(), ref);
	useBindShortcut("escape", () => nodesService.localClear(), ref);

	useLayoutEffect(() => {
		if (isEditing) {
			setTimeout(() => {
				ref.current?.select();
			}, 0);
		}
	}, [isEditing]);

	const handleEditBlur = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
		nodesService.localCommit(event.currentTarget.value);
	};

	return (
		<Root>
			{isEditing ? (
				<ResizableTextarea
					autoFocus
					defaultValue={node.title}
					onBlur={handleEditBlur}
					placeholder="Add a title"
					data-testid="nodeLayoutAddTitle"
					styledProps={textAreaStyle}
					disabled={!isEditing}
					el={ref}
				/>
			) : (
				node.title
			)}
		</Root>
	);
};

const Root = styled.div`
	font-size: 16px;
	line-height: 24px;
	color: var(--color-text-heading);
	font-weight: 500;
	white-space: pre-line;
`;

const textAreaStyle = {
	background: "transparent",
	isPlain: true,
	noHover: true,
	mb: 0,
	p: 0,
};
