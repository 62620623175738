import styled from "styled-components";

import { InputMetric } from "@/domains/nodes/components/keyResults/components/forms/components/InputMetric";
import { formValues } from "@/domains/nodes/components/keyResults/components/forms/components/KeyResultForm";
import { IKeyResultsInput, KeyResultsFormFields } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { targetRegex } from "@/domains/nodes/components/keyResults/utils/keyResultsUtils";
import { Input, InputWrapper, Label, Select, utility, Validation } from "@/shared/system";
import { KeyResultModel } from "@/types/db";

interface Props extends IKeyResultsInput<KeyResultsFormFields> {
	goalType: KeyResultModel["goalType"];
	targetValue: KeyResultModel["targetValue"];
}

export const InputGroupGoal: React.FC<Props> = ({ register, errors, goalType, targetValue }) => {
	const inputNameGoalType = "goalType" as const;
	const inputNameTargetValue = "targetValue" as const;
	const inputNameStartingValue = "startingValue" as const;

	const inputPropsGoalType = register(inputNameGoalType);

	const inputPropsTargetValue =
		goalType !== "no-target" &&
		register(inputNameTargetValue, {
			required: true,
			pattern: targetRegex,
		});

	return (
		<Root>
			<GoalTypeInput>
				<Label htmlFor={formValues.goalType}>Goal Type</Label>
				<InputWrapper>
					<Select id={formValues.goalType} {...inputPropsGoalType}>
						<option value="get-to">Get to</option>
						<option value="stay-above">Stay above</option>
						<option value="stay-below">Stay below</option>
						<option value="no-target">No target</option>
					</Select>
				</InputWrapper>
			</GoalTypeInput>
			{goalType !== "no-target" && (
				<div>
					<Label htmlFor={inputNameTargetValue}>Target Value</Label>
					<InputWrapper>
						<Input
							type="text"
							id={inputNameTargetValue}
							mb="0"
							isError={!!errors[inputNameTargetValue]}
							placeholder="$50 ATV, 5 New Clients"
							{...inputPropsTargetValue}
						/>
						<Validation label="Target Value" error={errors[inputNameTargetValue]} />
					</InputWrapper>
				</div>
			)}
			{goalType === "get-to" && (
				<>
					<InputMetric
						label="Starting Value"
						template={targetValue}
						name={inputNameStartingValue}
						errors={errors}
						register={register}
						required={true}
					/>
				</>
			)}
		</Root>
	);
};

const Root = styled.div`
	display: flex;

	> :not(:last-child) {
		margin-right: ${utility.space(3)}px;
	}
`;

const GoalTypeInput = styled.div``;
