import React, { useEffect } from "react";

import { getMarkRange } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FiExternalLink, FiTrash } from "react-icons/fi";
import styled from "styled-components";

const includesHttp = (string: string): boolean => {
	const pattern = new RegExp("https?://", "i");
	return !!pattern.test(string);
};

const posAtEndOfMark = (editor: Editor) => {
	const markLink = editor.view.state.schema.marks.link;

	if (!markLink) {
		return 0;
	}

	return getMarkRange(editor.view.state.doc.resolve(editor.view.state.selection.$anchor.pos), markLink)?.to || 0;
};

type LinkForm = {
	href: string;
};

interface Props {
	editor: Editor;
}

export const MenuLink: React.FC<Props> = ({ editor }) => {
	const { register, handleSubmit, setFocus, setValue } = useForm<LinkForm>();

	const linkHref = editor.getAttributes("link").href || "";
	const linkPosAtEnd = posAtEndOfMark(editor);

	useEffect(() => {
		setValue("href", linkHref);
	}, [linkHref, setValue]);

	useEffect(() => {
		if (!linkHref) {
			setFocus("href");
		}
	}, [linkHref, setFocus]);

	const handleOpenLink = () => window.open(linkHref, "_blank");

	const handleUnsetLink = () => {
		editor.chain().unsetLink().focus().setTextSelection(linkPosAtEnd).run();
	};

	const onSubmit: SubmitHandler<LinkForm> = ({ href }) => {
		if (href) {
			const url = includesHttp(href) ? href : `https://${href}`;
			editor.chain().setLink({ href: url, target: "_blank" }).focus().setTextSelection(linkPosAtEnd).run();
		} else {
			handleUnsetLink();
		}
	};

	return (
		<Root onSubmit={handleSubmit(onSubmit)}>
			<LinkInput defaultValue={linkHref} placeholder="https://example.com" {...register("href")} />
			{linkHref && (
				<LinkAction onClick={handleOpenLink} type="button">
					<FiExternalLink />
				</LinkAction>
			)}
			<LinkAction onClick={handleUnsetLink} type="button">
				<FiTrash />
			</LinkAction>
		</Root>
	);
};

const Root = styled.form`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	background: var(--color-body);
	border-radius: 4px;
	padding: 8px 4px;
`;

const LinkInput = styled.input`
	flex-grow: 2;
	border: 0;
	margin: 4px 4px 4px 0;
	padding: 4px 8px;
	color: var(--color-text);
	background: var(--color-body);
	border-right: var(--border);

	:active,
	:focus {
		outline: 0;
	}
`;

const LinkAction = styled.button`
	display: flex;
	align-items: center;
	background: transparent;
	border: 0;
	border-radius: 4px;
	padding: 6px;
	color: var(--color-text);
	cursor: pointer;
	font-size: 16px;

	:hover {
		color: var(--color-accent-hover);
		background: var(--color-hover);
	}
`;
