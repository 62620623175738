import { selectorFamily } from "recoil";

import { getWorkspaceMembers } from "./getWorkspaceMembers";

export const getMembersById = selectorFamily({
	key: "filterMembers",
	get:
		(ids: string[] = []) =>
		({ get }) => {
			const members = get(getWorkspaceMembers);
			return members.filter(({ id }) => ids.includes(id));
		},
});
