import { useCallback } from "react";

import { FiEyeOff, FiList, FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { NodeActionsAddContainer } from "@/domains/nodes/components/actions/components/NodeActionsAddContainer";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import Treeview from "@/modules/page/assets/treeview.svg?react";

type Props = {
	node: NodeModel;
};

export function NodeActionsGroup({ node }: Props) {
	const nodesService = useNodesService();

	const nodeId = node.id;

	const variant = node.variant || "tree";
	const isListView = node.variant === "list";

	const LayoutIcon = isListView ? Treeview : FiList;

	const handleCollapse = useCallback(() => {
		nodesService.collapseNode(nodeId);
	}, [nodeId, nodesService]);

	const handleToggleList = useCallback(() => {
		const variant = isListView ? "tree" : "list";
		nodesService.setVariant(nodeId, variant);
	}, [nodeId, isListView, nodesService]);

	return (
		<Root>
			{!isListView && (
				<NodeActionsAddContainer node={node}>
					<ActionIcon data-testid="nodeActions--add">
						<FiPlus />
					</ActionIcon>
				</NodeActionsAddContainer>
			)}
			<ActionIcon onClick={handleToggleList} data-testid={`nodeActions--${variant}`}>
				<LayoutIcon />
			</ActionIcon>
			<ActionIcon onClick={handleCollapse} data-testid="nodeActions--hide">
				<FiEyeOff />
			</ActionIcon>
		</Root>
	);
}

const Root = styled.div`
	display: inline-flex;
	cursor: default;
	align-items: center;
	border-radius: 50px;
	padding: 2px;
	box-shadow: var(--drop-shadow-l1);
	border: 1px solid var(--color-border);
	border-width: 2px;
	background-color: var(--color-body);
`;

const ActionIcon = styled.div`
	display: flex;
	align-items: center;
	padding: 7px;
	border-radius: 4px;
	color: var(--color-text-secondary);
	cursor: pointer;

	:hover {
		background: var(--color-hover);
		border-radius: 50%;
	}
`;
