import { Command } from "../../../shared/core/hooks/useActionHistory";
import { updateBoard } from "../boardFunctions";

type UpdateFolderName = (args: { projectId: string; newValue: string; oldValue: string }) => Command;

export const updateFolderName: UpdateFolderName = ({ projectId, newValue, oldValue }) => {
	const action = () => {
		updateBoard(projectId, {
			"data.name": newValue,
		});
	};
	const undo = () => {
		updateBoard(projectId, {
			"data.name": oldValue,
		});
	};

	return new Command({ name: "Updated Folder Name", action, undo, value: undefined });
};
