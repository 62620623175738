import { FiCheck } from "react-icons/fi";
import styled from "styled-components";
import { z } from "zod";

import { nodeMetaTypeList, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";

import { NodeMetaSelectOptionsItemEdit } from "./NodeMetaSelectOptionsItemEdit";

export type DropdownOption = {
	metaId: string;
	valueId: string;
	value?: any;
	match: string;
	editable: boolean;
	selected: boolean;
	type: string;
	onClick: () => void;
	element: JSX.Element;
};

type Props = {
	option: DropdownOption;
	meta: z.infer<typeof nodeMetaTypeSelect> | z.infer<typeof nodeMetaTypeList>;
};

export const NodeMetaSelectOptionsItem = ({ option, meta }: Props) => {
	const { onClick, element, value, editable } = option;

	return (
		<>
			<Option onClick={onClick} className="selected">
				<CheckMark>{option.selected && <FiCheck className="checkMark" />}</CheckMark>
				{element}
			</Option>
			{editable && (
				<OptionWrapperEdit>
					<NodeMetaSelectOptionsItemEdit value={value} meta={meta} />
				</OptionWrapperEdit>
			)}
		</>
	);
};

const OptionWrapperEdit = styled.div`
	position: absolute;
	top: 50%;
	right: 8px;
	bottom: 0;
	margin: auto;
	height: 32px;
	visibility: hidden;
	svg {
		display: block;
	}

	.selected & {
		visibility: visible;
	}
`;

const CheckMark = styled.div`
	display: flex;
	justify-content: center;
	margin-right: 6px;
	width: 20px;

	svg {
		display: block;
	}
`;

const Option = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	min-width: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 4px 6px;
`;
