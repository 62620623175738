import React from "react";

import { Card } from "rebass";
import styled from "styled-components";

export const Panel = (props: any) => <Root p={3} borderRadius={1} {...props} />;

const Root = styled(Card)``;

Panel.Header = styled.div`
	${({ theme }) => `
        padding: ${theme.space[3]}px;
        border-bottom: 1px solid ${theme.colors.bgGrey};
    `}
`;

Panel.Dark = styled(Panel)`
	box-shadow: none;
	${({ theme }) => ({
		background: theme.colors.hover,
	})}
`;

export default Panel;
