import { EventModel } from "@/domains/nodes/models/eventsModel";
import { Avatar } from "@/shared/system";
import { UserComment } from "@/types/db";
import { WithId } from "@/types/db/utils";

import { ActivityItemWrapper } from "./ActivityItemWrapper";
import { MessageAction } from "./MessageAction";
import { MessageComment } from "./MessageComment";

const Circle = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="#C1C7DA">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"></path>
	</svg>
);

type Props = {
	activity: EventModel | WithId<UserComment>;
	showTimestamp: boolean;
	lastItem?: boolean;
};

export const ActivityItem = ({ activity, lastItem, showTimestamp }: Props) => {
	const user = activity.type === "comment" ? activity.author : activity.createdBy;

	switch (activity.type) {
		case "comment": {
			return (
				<ActivityItemWrapper icon={<Avatar small user={user} />} lastChild={lastItem}>
					<MessageComment comment={activity} user={user} />
				</ActivityItemWrapper>
			);
		}
		case "created":
		case "title":
		case "meta":
		case "details": {
			return (
				<ActivityItemWrapper icon={<Circle />} lastChild={lastItem}>
					<MessageAction activity={activity} showTimestamp={showTimestamp} />
				</ActivityItemWrapper>
			);
		}
		default: {
			return null;
		}
	}
};
