import { Helmet } from "react-helmet-async";

import { ProjectDto } from "@/types/db";

interface Props {
	board: ProjectDto;
}

export function BoardMetaTags({ board }: Props) {
	const title = board.data.name;
	const description = board.data.description || "";

	return (
		<Helmet>
			<title>{title} Project: Poda</title>
			<meta content={description} name="description" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
		</Helmet>
	);
}
