import React from "react";

import styled from "styled-components";

import { IKeyResultsInput } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { formatTarget } from "@/domains/nodes/components/keyResults/utils/keyResultsUtils";
import { Input, InputWrapper, Label, Validation } from "@/shared/system";

import { InputWithUnitsWrapper } from "./InputWithUnitsWrapper";

const validationMessages = {
	pattern: "Please enter a numeric value",
};

interface Props extends IKeyResultsInput<any> {
	template: string;
	name: "startingValue" | "value";
	label: string;
	required: boolean;
	placeholder?: string;
	defaultValue?: string;
}

export const InputMetric: React.FC<Props> = (props) => {
	const { template, placeholder, defaultValue, name, register, errors, label, required = false } = props;
	const { suffix, prefix } = formatTarget(template);

	const inputProps = register(name, { required, pattern: /^[0-9]+$/ });
	const error = errors[name];

	return (
		<Root>
			<Label htmlFor={name}>{label}</Label>
			<InputWithUnitsWrapper>
				{prefix && <div>{prefix}</div>}
				<Input
					type="text"
					id={name}
					mb={0}
					isError={!!error}
					placeholder={placeholder}
					defaultValue={defaultValue}
					{...inputProps}
				/>
				{suffix && <div>{suffix}</div>}
			</InputWithUnitsWrapper>
			<Validation label={label} error={error} messages={validationMessages} />
		</Root>
	);
};

const Root = styled(InputWrapper)`
	flex-grow: 1;
`;
