import { useMemo } from "react";

import { deleteDoc, doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

import { KeyResultsFormFields } from "@/domains/nodes/components/keyResults/components/forms/components/KeyResultForm";
import { KeyResultsDto } from "@/domains/nodes/components/keyResults/dtos/keyResultsDto";
import { useEventsService } from "@/domains/nodes/hooks/useEventsService";
import { useProfileLegacy } from "@/modules/profile/hooks/useProfileLegacy";
import { NodeParams } from "@/shared/core/routes";
import { db, fb } from "@/shared/infra/init";
import { KeyResultModel, KeyResultModelUpdate } from "@/types/db";

export const timestampFromDate = (date: string) => Timestamp.fromDate(new Date(date));

type KeyResultCreateArgs = KeyResultsFormFields & Pick<KeyResultModel, "workspaceId" | "boardId" | "nodeId">;

export interface IKeyResultsService {
	create: (data: KeyResultCreateArgs) => Promise<void>;
	update: (id: string, next: KeyResultsFormFields, previous: KeyResultModel) => Promise<void>;
	remove: (id: string, previous: KeyResultModel) => Promise<void>;
	createResult: (
		id: string,
		newResult: Omit<KeyResultModelUpdate, "createdAt" | "id">,
		previous: KeyResultModel,
	) => Promise<void>;
	removeResult: (id: string, updateId: number, previous: KeyResultModel) => Promise<void>;
}

export const useKeyResultsService = (): IKeyResultsService => {
	const profile = useProfileLegacy();
	const { boardId, nodeId } = useParams<NodeParams>();
	const { addEvent } = useEventsService();

	return useMemo(
		() => ({
			create: async ({ startDate, endDate, ...rest }) => {
				const keyResultData: KeyResultsDto = {
					...rest,
					startDate: timestampFromDate(startDate),
					endDate: timestampFromDate(endDate),
					createdAt: fb.timestamp(),
					createdBy: profile.id,
					updatedAt: fb.timestamp(),
					status: "inactive",
					updates: [],
					currentValue: "0",
				};

				const keyResultsDoc = doc(db.keyResults);

				await setDoc(keyResultsDoc, keyResultData);

				addEvent({
					type: "key-results",
					action: "create",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: keyResultsDoc.id,
					newData: keyResultData,
				});
			},
			update: async (id, next, previous) => {
				const { startDate, endDate } = next;

				const keyResultData = {
					...next,
					startDate: timestampFromDate(startDate),
					endDate: timestampFromDate(endDate),
					updatedAt: fb.timestamp(),
				};

				const keyResultsDoc = doc(db.keyResults, id);
				await updateDoc(keyResultsDoc, keyResultData);

				addEvent({
					type: "key-results",
					action: "update",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: keyResultsDoc.id,
					newData: keyResultData,
					oldData: previous,
				});
			},
			remove: async (id, previous) => {
				const keyResultsDoc = doc(db.keyResults, id);
				deleteDoc(keyResultsDoc);

				addEvent({
					type: "key-results",
					action: "remove",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: keyResultsDoc.id,
					newData: previous,
				});
			},
			createResult: async (id, newResult, previous) => {
				const keyResultData = {
					...previous,
					currentValue: newResult.value || "0",
					status: newResult.status,
					updatedAt: fb.timestamp(),
					updates: [
						...previous.updates,
						{
							...newResult,
							createdAt: fb.timestamp(),
						},
					],
				};

				const keyResultsDoc = doc(db.keyResults, id);
				updateDoc(keyResultsDoc, keyResultData);

				addEvent({
					type: "key-results",
					action: "update",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: keyResultsDoc.id,
					newData: keyResultData,
					oldData: previous,
				});
			},
			removeResult: async (id, updateId, previous) => {
				const newUpdates = previous.updates.filter((arr) => arr.id !== updateId);
				const keyResultData = { ...previous, updates: newUpdates };

				const keyResultsDoc = doc(db.keyResults, id);
				updateDoc(keyResultsDoc, keyResultData);

				addEvent({
					type: "key-results",
					action: "update",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: keyResultsDoc.id,
					newData: keyResultData,
					oldData: previous,
				});
			},
		}),
		[addEvent, boardId, nodeId, profile.id],
	);
};
