import { lazy } from "react";

import { storageAvailable } from "@/shared/utils/storageAvailable";

type LazyImport = () => any;

const storageKeyChunkLoadError = "force-refreshed";
const keyMissing = "false";
const keyFound = "true";

export const lazyWithRetry = (componentImport: LazyImport) =>
	lazy(async () => {
		const storageKey = storageAvailable("localStorage")
			? window?.localStorage.getItem(storageKeyChunkLoadError)
			: keyMissing;

		const hasReloaded = JSON.parse(storageKey || keyMissing);

		try {
			const component = await componentImport();
			window?.localStorage.setItem(storageKeyChunkLoadError, keyMissing);

			return component;
		} catch (error) {
			if (!hasReloaded) {
				window?.localStorage.setItem(storageKeyChunkLoadError, keyFound);
				return window.location.reload();
			}

			throw error;
		}
	});
