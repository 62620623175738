import { IconType } from "react-icons";

import { Node, NodeMetaAllTypes, NodeMetaTypeUnion, WithId } from "@/types/db";

/**
 * Data Models
 */

export enum FilterComparators {
	is = "is",
	isEmpty = "is empty",
	isNot = "is not",
	isNotEmpty = "is not empty",
	includes = "includes",
	doesNotInclude = "does not include",

	isBefore = "before",
	isAfter = "after",
	isOverdue = "overdue",
}

export enum FilterTypes {
	meta = "meta",
	rootNode = "rootNode",
}

export type FilterValueTypes = NodeMetaTypeUnion | "rootNode";
export type FilterOptionNode = { type: "rootNode"; value: WithId<Node> };

interface FilterBase {
	key: string;
	comparator: keyof typeof FilterComparators;
}

export interface FilterMeta extends FilterBase {
	type: FilterTypes.meta;
	metaId: string;
	value: string;
}

export interface FilterRootNode extends FilterBase {
	type: FilterTypes.rootNode;
	value: WithId<Node>;
}

export type Filter = FilterMeta | FilterRootNode;

export type Filters = Filter[];

/**
 * View Models
 */

export type FilterItem = {
	icon: IconType;
	label: string;
	type: FilterValueTypes;
	showOptions: boolean;
	options: NodeMetaAllTypes | FilterOptionNode;
};
