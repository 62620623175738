import { TbFileImport } from "react-icons/tb";

import { DropdownMenuButton } from "../../../../shared/system";

type Props = {
	onClick: VoidFunction;
};

export const MoreImport = ({ onClick }: Props) => {
	const handleClick = () => {
		onClick();
	};
	return (
		<DropdownMenuButton onClick={handleClick}>
			<TbFileImport />
			Import from CSV
		</DropdownMenuButton>
	);
};
