import { useCallback } from "react";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

type UseShortcutFilters = (node: NodeModel) => void;

export const useShortcutsFilters: UseShortcutFilters = (node) => {
	const nodesService = useNodesService();
	const { setNodeFilter, clearFilters } = useFilterService();

	const handlePromoteToRoot = useCallback(() => {
		setNodeFilter(node);
	}, [node, setNodeFilter]);

	const handleToggleHighlight = useCallback(() => {
		nodesService.setHighlightPath(node.id, !node.highlighted);
	}, [node.highlighted, node.id, nodesService]);

	useBindShortcut("shift+p", handlePromoteToRoot);
	useBindShortcut("shift+h", handleToggleHighlight);
	useBindShortcut("c f", clearFilters);
};
