import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { VscGripper } from "react-icons/vsc";
import styled from "styled-components";

type Props = {
	listeners?: SyntheticListenerMap;
};

export const ListHandle: React.FC<Props> = ({ listeners, children }) => {
	return (
		<Root>
			<Handle {...listeners}>
				<VscGripper />
			</Handle>
			<Content>{children}</Content>
		</Root>
	);
};

const Handle = styled.div`
	visibility: hidden;
	color: var(--color-text-secondary);
	font-size: 16px;
	margin-right: 8px;

	svg {
		display: block;
	}
`;

const Root = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% + 30px);
	margin-left: -26px;

	:hover {
		${Handle} {
			visibility: visible;
		}
	}
`;

const Content = styled.div`
	flex-grow: 1;
`;
