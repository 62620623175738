import styled from "styled-components";

import { Tooltip, utility } from "../../../../shared/system";
import { KeyResultModel } from "../../../../types/db";
import { keyResultStatusMap } from "./utils/keyResultStatusMap";

type Props = {
	results: KeyResultModel[];
};

export const KeyResultsCards = ({ results }: Props) => {
	return (
		<>
			<Title>Key Results</Title>
			<ResultsList>
				{results.map(({ id, title, updates }) => {
					const lastUpdate = updates.slice(-1)[0];
					return (
						<Tooltip label={title} key={title}>
							<Result status={lastUpdate?.status || "inactive"} data-testid={`keyResult--${id}`} />
						</Tooltip>
					);
				})}
			</ResultsList>
		</>
	);
};

const Title = styled.div`
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 8px;
`;

const ResultsList = styled.div`
	display: flex;
`;

const Result = styled.div<{ status: KeyResultModel["status"] }>`
	border-radius: 50%;
	width: 16px;
	height: 16px;
	margin-right: 4px;
	background-color: ${({ theme, status }) => utility.color(keyResultStatusMap[status]?.color)({ theme })};
`;
