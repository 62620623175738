import { ChangeEventHandler, useEffect, useState } from "react";

import { format, formatISO, isAfter, isBefore, isValid, parse, parseISO } from "date-fns";
import { DateRange, DayPicker, SelectRangeEventHandler } from "react-day-picker";
import styled from "styled-components";

import { Input } from "../../../../../../../shared/system";
import { NodeMetaDataDateTypes, NodeMetaTypes } from "../../../../../../../types/db";
import { DatePickerProps } from "./DatePickerSingle";

const castValueAsRange = (value?: NodeMetaDataDateTypes): { from: Date; to: Date } | undefined => {
	if (!value) {
		return undefined;
	}
	const type = value.type;

	switch (type) {
		case "date": {
			return {
				from: parseISO(value.data.date),
				to: parseISO(value.data.date),
			};
		}
		case "dateRange": {
			const { to, from } = value.data;
			return { from: parseISO(from), to: parseISO(to) };
		}
		default: {
			const _exhaustiveCheck: never = type;
			return _exhaustiveCheck;
		}
	}
};

export const DatePickerRange = ({ initialValue, label, dateFormat, onSelect }: DatePickerProps) => {
	const fmt = dateFormat === "relative" ? "dd/MM/yyyy" : dateFormat;
	const range = castValueAsRange(initialValue);

	const fromDate = range ? format(range.from, fmt) : "";
	const toDate = range ? format(range.to, fmt) : "";

	const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(range);
	const [fromValue, setFromValue] = useState<string>(fromDate);
	const [toValue, setToValue] = useState<string>(toDate);

	useEffect(() => {
		if (!range) {
			setSelectedRange(undefined);
			setFromValue("");
			setToValue("");
		}
	}, [range]);

	const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setFromValue(e.target.value);

		const date = parse(e.target.value, fmt, new Date());

		if (!isValid(date)) {
			return setSelectedRange({ from: undefined, to: undefined });
		}

		if (selectedRange?.to && isAfter(date, selectedRange.to)) {
			setSelectedRange({ from: selectedRange.to, to: date });
		} else {
			setSelectedRange({ from: date, to: selectedRange?.to });
		}
	};

	const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setToValue(e.target.value);

		const date = parse(e.target.value, fmt, new Date());

		if (!isValid(date)) {
			return setSelectedRange({ from: selectedRange?.from, to: undefined });
		}

		if (selectedRange?.from && isBefore(date, selectedRange.from)) {
			setSelectedRange({ from: date, to: selectedRange.from });
		} else {
			setSelectedRange({ from: selectedRange?.from, to: date });
		}
	};

	const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
		setSelectedRange(range);

		if (range?.from) {
			setFromValue(format(range.from, fmt));
		} else {
			setFromValue("");
		}

		if (range?.to) {
			setToValue(format(range.to, fmt));
		} else {
			setToValue("");
		}

		if (range?.from && range?.to) {
			onSelect({
				type: NodeMetaTypes.dateRange,
				version: "1",
				data: {
					from: formatISO(range.from, { representation: "date" }),
					to: formatISO(range.to, { representation: "date" }),
					label,
					format: dateFormat,
					reminderOffset: initialValue?.data?.reminderOffset || null,
				},
			});
		}
	};

	return (
		<>
			<DateInputGroup>
				<Input placeholder="From Date" value={fromValue} onChange={handleFromChange} p="4px 8px" mb="12px" />
				<Input placeholder="To Date" value={toValue} onChange={handleToChange} p="4px 8px" mb="12px" />
			</DateInputGroup>
			<DayPicker mode="range" defaultMonth={selectedRange?.from} selected={selectedRange} onSelect={handleRangeSelect} />
		</>
	);
};

const DateInputGroup = styled.form`
	display: flex;

	input {
		flex-basis: 50%;
		width: 100px;

		& + * {
			margin-left: 8px;
		}
	}
`;
