import styled from "styled-components";

import { keyResultStatusMap } from "@/domains/nodes/components/keyResults/utils/keyResultStatusMap";
import { formatTarget } from "@/domains/nodes/components/keyResults/utils/keyResultsUtils";
import { Progress } from "@/shared/system";
import { KeyResultModel } from "@/types/db";

type ProgressArgs = {
	target?: string;
	currentValue: KeyResultModel["currentValue"];
	keyResult: KeyResultModel;
};

type ICalculateProgress = (args: ProgressArgs) => string;

const progressGetTo: ICalculateProgress = ({ target = "0", currentValue, keyResult: { startingValue } }) => {
	const goal = parseInt(target, 10) - parseInt(startingValue, 10);
	const current = parseInt(currentValue, 10) - parseInt(startingValue, 10);
	const progress = parseInt(currentValue, 10) !== 0 ? current / goal : 0;
	return (progress * 100).toFixed();
};

const progressStayAbove: ICalculateProgress = ({ currentValue, keyResult: { startingValue } }) => {
	return ((parseInt(currentValue, 10) / parseInt(startingValue, 10)) * 100).toFixed();
};
const progressStayBelow: ICalculateProgress = ({ currentValue, keyResult: { startingValue } }) => {
	return ((parseInt(startingValue, 10) / parseInt(currentValue, 10)) * 100).toFixed();
};

// const progressTimeElapsed: ICalculateProgress = ({ keyResult: { startDate, endDate } }) => {
// 	const start = fromUnixTime(startDate?.seconds);
// 	const stop = fromUnixTime(endDate?.seconds);
// 	const today = new Date();

// 	const duration = differenceInDays(stop, start);
// 	const daysElapsed = differenceInDays(today, start);

// 	return (daysElapsed === 0 ? 100 : (daysElapsed / duration) * 100).toFixed();
// };

const calculateProgress: Record<KeyResultModel["goalType"], ICalculateProgress> = {
	"get-to": progressGetTo,
	"no-target": () => "100",
	"stay-above": progressStayAbove,
	"stay-below": progressStayBelow,
};

type Props = {
	keyResult: KeyResultModel;
};

export const KeyResultsProgress: React.FC<Props> = ({ keyResult }) => {
	const { goalType, targetValue, updates } = keyResult;

	const { target } = formatTarget(targetValue);

	const lastUpdate = updates.slice(-1)[0] || { value: "0", authorId: "", status: "" };

	const statusColor = !lastUpdate.status ? "transparent" : keyResultStatusMap[lastUpdate.status].color;

	const getProgress = calculateProgress[goalType];
	const progress = getProgress({
		target,
		currentValue: lastUpdate.value,
		keyResult,
	});

	return (
		<Root>
			<Progress max="100" value={progress} statusColor={statusColor} bgColor="var(--color-border-dark)" />
		</Root>
	);
};

const Root = styled.div`
	position: relative;
	height: 4px;
	margin-bottom: 6px;

	> progress {
		position: absolute;
	}
`;
