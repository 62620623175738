import React from "react";

import styled from "styled-components";

import NodeCard from "@/landing/home/components/StaticNode";

import { statusColors } from "../../../modules/workspace/workspaceTemplates";
import { breakpoints, TextWithEmoji } from "../../../shared/system";
import { StaticNodeMeta } from "./StaticNodeMeta";

const baseCardProps = {
	borderColor: "blueBorder",
	handleReveal: () => {},
	members: [],
	meta: null,
	showColor: "",
	isActive: true,
	isCreating: false,
	isMetaActive: false,
	staticShadow: true,
};

// @ts-expect-error TS(7031) FIXME: Binding element 'refs' implicitly has an 'any' typ... Remove this comment to see the full error message
export default function NodeGroupOne({ refs }) {
	const [nodeOne, nodeTwo, nodeThree] = refs;

	return (
		<Root>
			<NodeWrapper>
				<NodeRoot ref={nodeOne}>
					<NodeCard
						{...baseCardProps}
						nodeId="1"
						nodeType={<TextWithEmoji text="🚀️ Objective" />}
						title={"> 1000 Customers have create two or more detailed project plans"}
						type={<StaticNodeMeta color={statusColors.blue} status="Now" showStatus={true} />}
					/>
				</NodeRoot>
			</NodeWrapper>
			<NodeRow>
				<div ref={nodeTwo}>
					<NodeWrapper>
						<NodeCard
							{...baseCardProps}
							nodeType={<TextWithEmoji text="📚 Theme" />}
							nodeId="2"
							title={"Keep users engaged with regular project updates"}
							type={<StaticNodeMeta color={statusColors.blue} status="Now" showStatus={true} />}
						/>
					</NodeWrapper>
				</div>
				<MobileHidden ref={nodeThree}>
					<NodeWrapper>
						<NodeCard
							{...baseCardProps}
							borderColor="strokeLight"
							nodeType={<TextWithEmoji text="📚 Theme" />}
							nodeId="3"
							title={"Guide new users to that “Aha” moment"}
							type={<StaticNodeMeta color={statusColors.purple} status="Next" showStatus={true} />}
						/>
					</NodeWrapper>
				</MobileHidden>
			</NodeRow>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const NodeRoot = styled.div`
	margin-bottom: 28px;
	${breakpoints.md`
			margin-bottom: 44px;
		`}
`;

const NodeWrapper = styled.div`
	width: 260px;

	* {
		opacity: 1;
	}
`;

const NodeRow = styled.div`
	display: flex;
	align-items: center;
	padding-top: 38px;

	> :first-child {
		${breakpoints.md`
			margin-right: 32px;
		`}
	}
`;

const MobileHidden = styled.div`
	display: none;

	${breakpoints.md`
		display: block;
	`}
`;
