import { RefObject, useLayoutEffect } from "react";

import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";

export const useBindShortcut = (
	shortcutCode: string | string[] | undefined,
	handlerFunction: (e: ExtendedKeyboardEvent, combo: string) => any,
	element?: RefObject<HTMLElement>,
) => {
	useLayoutEffect(() => {
		const binding = element?.current ? new Mousetrap(element.current) : new Mousetrap();

		if (!shortcutCode) {
			return;
		}

		binding.bind(shortcutCode, handlerFunction);
		return () => {
			binding.unbind(shortcutCode);
		};
	}, [shortcutCode, handlerFunction, element]);
};
