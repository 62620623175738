import { useLayoutEffect } from "react";

import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";
import { FiRotateCcw, FiRotateCw } from "react-icons/fi";

import { ControlButton, ControlGroup } from "@/domains/projects/components/treeView/components/Controls/Controls.styled";
import { useActionHistory } from "@/shared/core/hooks/useActionHistory";
import { Tooltip } from "@/shared/system";

export const ActionHistory: React.FC = () => {
	const { undo, redo, canUndo: canUndoFn, canRedo: canRedoFn } = useActionHistory();

	useLayoutEffect(() => {
		const handleUndo = (e: ExtendedKeyboardEvent) => {
			e.preventDefault();
			undo();
		};

		const handleRedo = (e: ExtendedKeyboardEvent) => {
			e.preventDefault();
			redo();
		};

		Mousetrap.bind("command+z", handleUndo);
		Mousetrap.bind("command+shift+z", handleRedo);

		Mousetrap.bind("ctrl+z", handleUndo);
		Mousetrap.bind("ctrl+shift+z", handleRedo);

		return () => {
			Mousetrap.unbind("command+z");
			Mousetrap.unbind("command+shift+z");

			Mousetrap.unbind("ctrl+z");
			Mousetrap.unbind("ctrl+shift+z");
		};
	}, [redo, undo]);

	const canUndo = canUndoFn();
	const canRedo = canRedoFn();

	const undoCombo = (
		<>
			<kbd>⌘</kbd>
			<kbd>Z</kbd>
		</>
	);

	const redoCombo = (
		<>
			<kbd>⌘</kbd>
			<kbd>⇧</kbd>
			<kbd>Z</kbd>
		</>
	);

	return (
		<ControlGroup>
			<Tooltip label="Undo" combo={undoCombo}>
				<ControlButton disabled={!canUndo} onClick={undo}>
					<FiRotateCcw />
				</ControlButton>
			</Tooltip>
			<Tooltip label="Redo" combo={redoCombo}>
				<ControlButton disabled={!canRedo} onClick={redo}>
					<FiRotateCw />
				</ControlButton>
			</Tooltip>
		</ControlGroup>
	);
};
