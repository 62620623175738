import { useLayoutEffect } from "react";

import Mousetrap from "mousetrap";

import { MenuUtils } from "@/domains/nodes/components/menu/utils/menuUtils";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { NodeMetaSelect, NodeMetaSystemLabels, NodeMetaValueSelect } from "@/types/db";

const bindings = {
	nodeType: (index: number) => `shift+alt+${index + 1}`,
	status: (index: number) => `command+alt+${index + 1}`,
	statusWindows: (index: number) => `ctrl+alt+${index + 1}`,
};

type UseShortcutMeta = (node: NodeModel) => void;

export const useShortcutsMeta: UseShortcutMeta = (node) => {
	const nodesService = useNodesService();

	const [nodeTypeId, nodeTypes] = useNodeMetaStore(NodeMetaUtil.getEntryByLabel(NodeMetaSystemLabels.nodeType)) as [
		string,
		NodeMetaSelect,
	];

	const [statusId, status] = useNodeMetaStore(NodeMetaUtil.getEntryByLabel(NodeMetaSystemLabels.status)) as [
		string,
		NodeMetaSelect,
	];

	const nodeTypeValues = nodeTypes?.value;

	useLayoutEffect(() => {
		if (!nodeTypeValues) {
			return;
		}

		const handleUpdateNodeType = (value: NodeMetaValueSelect) => (event: Mousetrap.ExtendedKeyboardEvent) => {
			event.preventDefault();
			nodesService.updateMeta(node.id, nodeTypeId, value.id);
		};

		nodeTypeValues.forEach((nodeType, index) => {
			Mousetrap.bind(bindings.nodeType(index), handleUpdateNodeType(nodeType));
		});

		return () => {
			nodeTypeValues.forEach((nodeType, index) => {
				MenuUtils.unbindShortcut(bindings.nodeType(index));
			});
		};
	}, [node.id, nodeTypeId, nodeTypeValues, nodesService]);

	const statusValues = status?.value;

	useLayoutEffect(() => {
		if (!statusValues) {
			return;
		}

		const handleUpdateStatus = (status: NodeMetaValueSelect) => (event: Mousetrap.ExtendedKeyboardEvent) => {
			event.preventDefault();
			nodesService.updateMeta(node.id, statusId, status.id);
		};

		statusValues.forEach((status, index) => {
			Mousetrap.bind(bindings.status(index), handleUpdateStatus(status));
		});

		return () => {
			statusValues.forEach((status, index) => {
				MenuUtils.unbindShortcut(bindings.status(index));
			});
		};
	}, [node.id, nodesService, statusId, statusValues]);
};
