import React, { useCallback } from "react";

import styled from "styled-components";

import { Notification, WithId } from "../../../types/db";
import { markAsRead } from "../../notifications/notificationFunctions";
import { InboxMessageComment } from "./InboxMessageComment";
import { InboxMessageMore } from "./InboxMessageMore";

type Props = {
	notification: WithId<Notification>;
	handleClose: () => void;
};

export function InboxMessage({ notification, handleClose }: Props) {
	const handleMarkAsRead = useCallback(
		(read = true) => {
			markAsRead(notification.id, { read });
		},
		[notification.id],
	);

	return (
		<Body>
			<InboxMessageComment notification={notification} handleClose={handleClose} handleMarkAsRead={handleMarkAsRead} />
			<BodyActions>
				<InboxMessageMore notificationId={notification.id} handleMarkAsRead={handleMarkAsRead} />
			</BodyActions>
		</Body>
	);
}

const Body = styled.div`
	display: flex;
	width: 100%;
	padding: 24px;
	border-bottom: var(--border);

	&:last-of-type {
		border: 0;
	}
`;

const BodyActions = styled.div``;
