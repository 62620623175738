import { ReactNode, RefObject, useState } from "react";

import { matchSorter } from "match-sorter";
import styled from "styled-components";

import { DropdownOption } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectOptionsItem";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { useErrorService } from "@/shared/core/hooks/useErrorService";

const defaultOptionFilter = (items: DropdownOption[], inputValue: string) => {
	return matchSorter(items, inputValue, {
		keys: ["match"],
		threshold: matchSorter.rankings.CONTAINS,
		sorter: (rankedItems) => rankedItems,
	});
};

type Props = {
	search: string;
	options: DropdownOption[];
	inputEl: RefObject<HTMLInputElement>;
	children: (option: DropdownOption) => ReactNode;
};

export const SelectOptions = ({ options, children, search, inputEl }: Props) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const errorService = useErrorService();

	const filteredOptions = search ? defaultOptionFilter(options, search) : options;
	const isNoMatch = filteredOptions.length === 0;

	const handleSetNextSelected = () => selectedIndex > 0 && setSelectedIndex(selectedIndex - 1);

	const handleSetPreviousSelected = () => selectedIndex < filteredOptions.length - 1 && setSelectedIndex(selectedIndex + 1);

	const handleSetSelected = () => {
		const option = filteredOptions[selectedIndex];

		if (typeof option?.onClick === "function") {
			return option.onClick();
		}

		errorService.logInfo("Unable to apply filter", filteredOptions);
	};

	useBindShortcut("up", handleSetNextSelected, inputEl);
	useBindShortcut("down", handleSetPreviousSelected, inputEl);
	useBindShortcut("return", handleSetSelected, inputEl);

	if (isNoMatch) {
		return null;
	}

	return (
		<Root>
			{filteredOptions.map((option, index) => {
				const handleHover = () => setSelectedIndex(index);
				const activeClass = index === selectedIndex ? "selected" : "";

				return (
					<Option key={option.valueId} onMouseEnter={handleHover} className={activeClass}>
						{children(option)}
					</Option>
				);
			})}
		</Root>
	);
};

const Root = styled.div`
	padding: 8px;
`;

const Option = styled.div`
	position: relative;

	&.selected {
		background-color: var(--color-hover);
		border-radius: 4px;
	}
`;
