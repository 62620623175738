import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import logoWhiteSrc from "../../../assets/images/logo-text-white.svg";
import logoSrc from "../../../assets/images/logo-text.svg";
import routes from "../../../shared/core/routes";
import breakpoints, { breakpointsMax } from "../../../shared/system/breakpoint";
import { Hamburger } from "./Hamburger";

export default function Header({ children }: any) {
	const [isNavOpen, setNavOpen] = useState(false);

	const mobileNavActiveClass = isNavOpen ? "nav-open" : "";
	const logo = isNavOpen ? logoWhiteSrc : logoSrc;

	const handleToggleNavigation = () => {
		setNavOpen((open) => !open);
	};

	useEffect(() => {
		const addRemove = isNavOpen ? "add" : "remove";
		const body = document.querySelector("body");
		// @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
		body.classList[addRemove]("nav-active");
	}, [isNavOpen]);

	return (
		<Root className={mobileNavActiveClass}>
			<Container>
				<LogoWrapper>
					<Link to={routes.home} className="logo">
						<Logo src={logo} width="128" height="40" alt="Poda: Opportunity solution trees" />
					</Link>
				</LogoWrapper>
				<Inner>
					<NavWrapper>{children}</NavWrapper>
				</Inner>
				{/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
				<Hamburger onClick={handleToggleNavigation} isOpen={isNavOpen}>
					<span />
				</Hamburger>
			</Container>
		</Root>
	);
}

const Root = styled.header`
	transition: padding 0.3s ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999999999999999; // Stupid z-index so it goes over feedback
	line-height: 1.2;
	padding: 18px 0;
	min-height: 80px;
	background: rgba(255, 255, 255, 0.8);

	${breakpoints.md`
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(4px);
	`}

	${breakpoints.lg`
		padding: 7px 0;
	`}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 1200px;
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
	margin: 0 auto;

	:before {
		${breakpointsMax.md`
			content: " ";
			backface-visibility: hidden;
			will-change: transform;
			transition: -webkit-transform 0.3s linear;
			transition: transform 0.3s linear;
			width: 100%;
			background: var(--color-accent);
			position: absolute;
			top: -32px;
			bottom: -32px;
			left: 0;
			right: 0;
			z-index: 9998;
			transform: translate3d(100%, 0, 0);

			.nav-open & {
				transition-delay: 0s;
				transform: translate3d(0, 0, 0);
			}
		`}
	}
`;

const LogoWrapper = styled.div`
	position: relative;
	z-index: 9999;

	${breakpoints.md`
		position: absolute;
		top: 50%;
		left: 30px;
		z-index: 2;
		transform: translateY(-50%);
	`}
`;

const Logo = styled.img`
	display: block;
	cursor: pointer;

	${breakpoints.sm`
		width: 128px;
	`};
`;

const Inner = styled.div`
	width: 100%;

	${breakpointsMax.md`
		backface-visibility: hidden;
		will-change: transform;
		display: block;
		background: var(--color-accent);
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		transition: transform 0.3s linear;
		padding: 124px 30px 17px;
		z-index: 9997;
		transform: translate3d(100%, 0, 0);

		.nav-open & {
			transition-delay: 0s;
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	`}
`;

const NavWrapper = styled.div`
	position: relative;
	z-index: 1;

	${breakpointsMax.md`
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
	`}

	${breakpoints.md`
		padding: 0 128px;
	`}
`;
