import { QuerySnapshot } from "firebase/firestore";

import { Dictionary, WithId } from "../../types/db/utils";

export const extractFromCollectionWithId = <T>(collection: QuerySnapshot<T>): WithId<T>[] => {
	return collection.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
};

export const queryToViewModel = <Z, T>(
	collection: QuerySnapshot<T>,
	predicate: (id: string, doc: T) => Z | undefined,
): Record<string, Z> => {
	return collection.docs.reduce<Record<string, Z>>((carry, doc) => {
		const data = doc.data();
		const id = doc.id;

		const transformedData = predicate(id, data);

		if (transformedData !== undefined) {
			carry[id] = transformedData;
		}

		return carry;
	}, {});
};

export const extractDataAsDictionary = <T>(collection: QuerySnapshot<T>) => {
	const byId: Dictionary<T> = {};

	collection.forEach((entity) => {
		byId[entity.id] = {
			...entity.data(),
			id: entity.id,
		};
	});

	return byId;
};

export const extractDataFromCollection = <T>(collection: QuerySnapshot<T>) => {
	const byId: Dictionary<T> = {};
	const allIds: string[] = [];

	collection.forEach((entity) => {
		byId[entity.id] = {
			...entity.data(),
			id: entity.id,
		};
		allIds.push(entity.id);
	});

	return { byId, allIds };
};
