import { useCallback, useState } from "react";

import "react-day-picker/dist/style.css";
import { FiClock, FiSettings } from "react-icons/fi";
import styled from "styled-components";

import { useNodeMetaActions } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaActions";
import { DatePickerRange } from "@/domains/nodes/components/details/meta/types/date/DatePickerRange";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { DropdownHr, Toggle } from "@/shared/system";
import { Menu } from "@/shared/system/Menu/Menu";
import { MenuGroup } from "@/shared/system/Menu/MenuGroup";
import { MenuItem } from "@/shared/system/Menu/MenuItem";
import { NodeMetaDataDateTypes, NodeMetaTypes } from "@/types/db";

import { DatePickerSelectedValue } from "./DatePickerSelectedValue";
import { DatePickerSingle } from "./DatePickerSingle";

const reminderOptions: { label: string; value: number | null }[] = [
	{
		value: null,
		label: "None",
	},
	{
		value: 0,
		label: "On the day",
	},
	{
		value: 1,
		label: "1 day before",
	},
	{
		value: 2,
		label: "2 days before",
	},
	{
		value: 7,
		label: "1 week before",
	},
	{
		value: 14,
		label: "2 weeks before",
	},
];

const dateFormatOptions: { label: string; value: string | null }[] = [
	{
		value: "do LLL, yyyy",
		label: "Full Date",
	},
	{
		value: "dd/LL/yyyy",
		label: "Day/Month/Year",
	},
	{
		value: "LL/dd/yyyy",
		label: "Month/Day/Year",
	},
	{
		value: "yyyy/LL/dd",
		label: "Year/Month/Day",
	},
	{
		value: "relative",
		label: "Relative",
	},
];

type Props = {
	nodeId: string;
	metaId: string;
	label: string;
	format: string;
	value?: NodeMetaDataDateTypes;
};

export const NodeMetaDateValue = ({ nodeId, metaId, label, format, value: currentValue }: Props) => {
	const [isDateRange, setIsDateRange] = useState<boolean>(currentValue?.type === NodeMetaTypes.dateRange);
	const nodesService = useNodesService();
	const { updateNodeMetaValue } = useNodeMetaActions();

	const defaultReminder = !isDateRange ? null : currentValue?.data?.reminderOffset;
	const reminderSelected = reminderOptions.find((option) => option.value === defaultReminder);

	const dateFormatSelected = dateFormatOptions.find((option) => option.value === format);

	const reminder = {
		id: "metaId",
		label: (
			<>
				<FiClock />
				<CompoundLabel>
					Reminder <div>{reminderSelected?.label}</div>
				</CompoundLabel>
			</>
		),
		icon: FiClock,
		disabled: false,
		children: reminderOptions,
	};

	const dateFormat = {
		id: "dateFormat",
		label: (
			<>
				<FiSettings />
				<CompoundLabel>
					Format <div>{dateFormatSelected?.label}</div>
				</CompoundLabel>
			</>
		),
		icon: FiSettings,
		disabled: false,
		children: dateFormatOptions,
	};

	const DatePicker = isDateRange ? DatePickerRange : DatePickerSingle;

	const handleUpdate = useCallback(
		(date: NodeMetaDataDateTypes) => {
			nodesService.updateMeta(nodeId, metaId, { ...currentValue, format, label, ...date });
		},
		[nodesService, nodeId, metaId, currentValue, format, label],
	);

	const handleClear = useCallback(() => {
		nodesService.updateMeta(nodeId, metaId, null);
	}, [metaId, nodeId, nodesService]);

	const handleSetReminder = useCallback(
		(reminder: number | null) => {
			nodesService.updateMeta(nodeId, metaId, {
				...currentValue,
				data: { ...currentValue?.data, label, format, reminderOffset: reminder },
			});
		},
		[nodesService, nodeId, metaId, currentValue, label, format],
	);

	const handleSetFormat = useCallback(
		(newFormat: string | null) => {
			updateNodeMetaValue(metaId, { format: newFormat });
		},
		[metaId, updateNodeMetaValue],
	);

	const trigger = <DatePickerSelectedValue value={currentValue} dateFormat={format} />;

	return (
		<Menu label={trigger} placement="bottom-start" testId="node-meta-date">
			<MenuGroup>
				<PickerRoot>
					<DatePicker initialValue={currentValue} label={label} dateFormat={format} onSelect={handleUpdate} />
				</PickerRoot>
				<DropdownHr />
			</MenuGroup>
			<Menu label={reminder.label} testId="node-meta-date-reminder">
				{reminder.children.map((item) => {
					const handleClick = () => handleSetReminder(item.value);
					return <MenuItem label={item.label} handleClick={handleClick} key={item.value} />;
				})}
			</Menu>
			<MenuGroup>
				<DropdownHr />
				<ToggleItem>
					End date{" "}
					<Toggle
						selected={isDateRange}
						size="small"
						onClick={async () => setIsDateRange(!isDateRange)}
						testId="node-meta-date-value--range-toggle"
					/>
				</ToggleItem>
				<DropdownHr />
			</MenuGroup>
			<Menu label={dateFormat.label} testId="node-meta-date-format">
				{dateFormat.children.map((item) => {
					const handleClick = () => handleSetFormat(item.value);
					return <MenuItem label={item.label} handleClick={handleClick} key={item.value} />;
				})}
			</Menu>
			<MenuGroup>
				<DropdownHr />
			</MenuGroup>
			<MenuItem label="Clear" handleClick={handleClear} />
		</Menu>
	);
};

const ToggleItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 8px;
`;

const PickerRoot = styled.div`
	padding: 4px;
`;

const CompoundLabel = styled.div`
	display: flex;
	flex-grow: 1;
	padding-right: 4px;
	text-transform: none;
	margin-left: 8px;

	> div {
		margin-left: auto;
		color: var(--color-text-secondary);
	}
`;
