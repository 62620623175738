import { createStore as _createStore, StateCreator } from "zustand";

const resetters: (() => void)[] = [];

export const createStore = (<T>(f: StateCreator<T> | undefined) => {
	if (f === undefined) return createStore;
	const store = _createStore(f);
	const initialState = store.getState();
	resetters.push(() => {
		store.setState(initialState, true);
	});
	return store;
}) as typeof _createStore;

export const resetAllStores = () => {
	for (const resetter of resetters) {
		resetter();
	}
};
