import React from "react";

import styled from "styled-components";

import { useProfileLegacy } from "@/modules/profile/hooks/useProfileLegacy";
import { TextWithEmoji } from "@/shared/system";
import { hexToHsl } from "@/shared/utils/hexToHsl";
import { UserTheme } from "@/types/db";

type Props = {
	color?: string | null;
	value?: string;
	children?: React.ReactNode;
};

export const NodeMetaSelectValue = ({ color, value, children }: Props) => {
	const profile = useProfileLegacy();
	const pallet = typeof color === "string" && hexToHsl(color);
	const backgroundColor = pallet ? pallet?.original : undefined;

	return (
		<Tag backgroundColor={backgroundColor} theme={profile?.theme}>
			{value ? <TextWithEmoji text={value} /> : children}
		</Tag>
	);
};

const Tag = styled.div<{ backgroundColor?: string; theme: UserTheme }>`
	display: inline-flex;
	min-width: 0;
	max-width: 98%;
	border-radius: 24px;
	padding: ${({ backgroundColor }) => (backgroundColor ? "1px 12px" : "1px 0px")};
	margin-right: 4px;

	${({ backgroundColor, theme }) => {
		if (!backgroundColor) {
			return;
		}

		const dark = `
				color:  var(--color-body);
				background-color: color-mix(in srgb, ${backgroundColor} 70%, var(--color-body));
			`;
		const light = `
			color: ${backgroundColor};
			background-color: color-mix(in srgb, ${backgroundColor} 15%, var(--color-body));
			`;

		switch (theme) {
			case UserTheme.dark: {
				return dark;
			}
			case UserTheme.light: {
				return light;
			}
			default: {
				return `
					@media (prefers-color-scheme: dark) {
						${dark}
					}

					@media (prefers-color-scheme: light) {
						${light}
					}
				`;
			}
		}
	}};

	span {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		min-width: 0;
		display: block;
	}
`;
