export const wheel = {
	yellow: "#FFCA5C",
	green: "hsl(150, 54%, 51%)",
	greenLight: "hsl(150, 50%, 90%)",
	greenBorder: "hsl(140, 54%, 82%)",
	blue: "hsl(205, 79%, 56%)",
	blueLight: "hsl(207, 73%, 90%)",
	blueBorder: "hsl(205, 80%, 86%)",
	purple: "hsl(265, 66%, 64%)",
	purpleLight: "hsl(265, 66%, 96%)",
	purpleBorder: "#CFC2F0",
	red: "hsl(357, 79%, 65%)",
	redLight: "hsl(0, 80%, 92%)",
	redBorder: "hsl(357, 78%, 86%)",
};

const STROKE_LIGHT = "#E6EAF2";
const STROKE_DARK = "#C1C7DA";

const theme = {
	breakpoints: ["40em", "52em", "64em"],
	fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
	radii: [0, 2, 4, 8],
	colors: {
		blackDark: "#20292B",
		blackLight: "#3A4E6A",
		brandDark: "#4047A1",
		brandLight: "#b9a0f8",
		brandHover: "#6C38F0",
		brand: "#8154F2",
		secondary: "#7F8CB3",
		strokeDark: STROKE_DARK,
		strokeLight: STROKE_LIGHT,
		background: "#F6F7F9",
		error: "#EB4C00",
		blueHover: "#eff8fb",

		headingPlaceholder: "#878792",
		bgGrey: "hsl(220, 20%, 95%)",
		bgGreyDarker: "hsl(220, 20%, 92%)",
		hover: "#F4F5F9",
		...wheel,
	},
	borders: {
		light: `1px solid ${STROKE_LIGHT}`,
		dark: `1px solid ${STROKE_DARK}`,
	},
	space: [0, 4, 8, 16, 32, 64, 128, 256],
	fonts: {
		sans: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
		mono: "Menlo, monospace",
	},
	fontWeights: {
		normal: 400,
		medium: 500,
		bold: 600,
	},
	shadows: {
		levelOne: "0px 4px 8px rgba(32, 41, 43, 0.04)",
		levelOneHover: "0px 6px 14px rgba(32, 41, 43, 0.06)",
		levelTwo: "0px 8px 16px rgba(32, 41, 43, 0.125)",
		levelTwoHover: "0px 10px 22px rgba(32, 41, 43, 0.16)",
	},
};

/**
 * Theme utility functions
 */
const getFromTheme =
	(key: any) =>
	(value: any) =>
	({ theme }: any) =>
		theme[key][value];

export const utility = {
	color: getFromTheme("colors"),
	shadow: getFromTheme("shadows"),
	space: getFromTheme("space"),
	border: getFromTheme("borders"),
	fontSize: getFromTheme("fontSizes"),
};

export default theme;
