import styled from "styled-components";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { FilterAdd } from "./components/FilterAdd";
import FilterGroup from "./components/FilterGroup";
import { useFilterState } from "./hooks/useFilterState";

export const FilterForm = () => {
	const board = useGetProject();
	const [boardFilters] = useFilterState();

	if (!board) {
		return null;
	}

	const hasFilters = boardFilters.length > 0;

	return (
		<Root>
			{boardFilters.map((filter) => (
				<FilterGroup board={board} filter={filter} key={filter.key} />
			))}
			{hasFilters && <FilterAdd board={board} compact />}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
`;
