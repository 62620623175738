import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { useBoardMembers } from "@/modules/members/hooks/useBoardMembers";
import { useProfileService } from "@/modules/profile/hooks/useProfileService";
import { Button } from "@/shared/system";
import { Node, WithId } from "@/types/db";

import { ActivitySubscribeMore } from "./ActivitySubscribeMore";

type Props = {
	node: WithId<Node>;
};

export const ActivitySubscribe: React.FC<Props> = ({ node }) => {
	const members = useBoardMembers();
	const profileService = useProfileService();
	const nodesService = useNodesService();

	const profileOrError = profileService.getPublicProfile();

	if (profileOrError.isFailure) {
		return null;
	}

	const profile = profileOrError.getValue();

	const handleToggleSubscriber = (userId: string) => {
		const isSubscribed = NodeUtils.isUserSubscribed(userId, node);
		const newSubscribers = isSubscribed ? node.subscribers.filter((id) => id !== userId) : [...node.subscribers, userId];

		nodesService.update(node.id, { subscribers: newSubscribers });
	};

	const handleToggleSubscribe = () => handleToggleSubscriber(profile.id);

	const isSubscribed = NodeUtils.isUserSubscribed(profile.id, node);
	const actionText = isSubscribed ? "Unsubscribe" : "Subscribe";

	return (
		<Root>
			<ToggleSubscribe onClick={handleToggleSubscribe}>{actionText}</ToggleSubscribe>
			<ActivitySubscribeMore members={members} node={node} toggleSubscribed={handleToggleSubscriber} />
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;

	> *:not(:last-child) {
		margin-right: 12px;
	}
`;

const ToggleSubscribe = styled(Button.Plain)`
	color: var(--color-text);
	padding: 0;
`;
