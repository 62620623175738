import styled from "styled-components";

import { Button } from "@/shared/system";

export const ControlGroup = styled.div<{ isEmbedded?: boolean }>`
	display: flex;
	border-radius: 32px;
	padding: 0 16px;
	gap: 8px;
	${({ isEmbedded }) => ({
		boxShadow: isEmbedded ? "0" : "var(--drop-shadow-l2)",
		background: isEmbedded ? "var(--color-body)" : "var(--color-overlay)",
	})}
`;

export const ControlButton = styled(Button.Link)`
	display: block;

	padding: 14px 4px;
	border-radius: 0;
	color: ${({ active }) => (active ? "var(--color-accent)" : "var(--color-text-heading)")};

	&:disabled {
		opacity: 1;

		svg {
			opacity: 0.3;
		}
	}

	svg {
		margin: 0;
	}
`;
