import React, { MutableRefObject, ReactNode } from "react";

import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";

import routes from "../../../shared/core/routes";
import { breakpoints, Link, utility } from "../../../shared/system";
import Title from "../../components/PageHeader";
import gridPattern from "../assets/g12.svg";

// const exploreDetails = ["support@poda.io", "u2(Qh8ZP]%dc8?7%"];

// const handleExplore = async () => {
// 	await firebase.auth.setPersistence(firebase.app.auth.Auth.Persistence.NONE);
// 	await firebase.auth.signInWithEmailAndPassword(...exploreDetails);
// 	track.exploreProduct();
// };

type Props = {
	title: string | ReactNode;
	subtitle: string | ReactNode;
	hero?: ReactNode;
	refs?: MutableRefObject<null>;
	actions?: boolean;
	header?: boolean;
	withGrid?: boolean;
};

export default function Callout({
	title,
	subtitle,
	refs,
	hero = null,
	actions = true,
	header = false,
	withGrid = false,
}: Props) {
	return (
		<Root withGrid={withGrid} header={header}>
			<Content>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</Content>
			{hero}
			{actions && (
				<ActionWrapper>
					<CalloutExplore to={routes.signUp} ref={refs}>
						Start planning today <FiArrowRight />
					</CalloutExplore>
					<p>Free forever — no credit card required</p>
				</ActionWrapper>
			)}
		</Root>
	);
}

const Root = styled.div<{ withGrid: boolean; header: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: ${({ header }) => (header ? "88px" : 0)};
	padding-left: 12px;
	padding-right: 12px;
	text-align: center;

	${({ withGrid }) =>
		withGrid &&
		`
			background-image: linear-gradient(#fff, rgba(255, 255, 255, 0) 55% 80%, #fff 110%),
				url(${gridPattern});
	`}

	${breakpoints.sm`
		padding-top: 124px;
	`}

	${breakpoints.lg`
		padding-top: 140px;
	`}

	> * {
		margin-bottom: 16px;

		${breakpoints.lg`
		margin-bottom: 24px;
	`}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;

	${breakpoints.md`
		padding: 0;
	`}
`;

const Subtitle = styled.p`
	line-height: 160%;
	letter-spacing: 0.01em;
	margin-top: 0;
	margin-bottom: 0;
	max-width: 445px;

	${breakpoints.sm`
		max-width: 600px;
		font-size: 16px;
	`}

	${breakpoints.lg`
		max-width: 800px;
		font-size: 20px;
	`}
`;

const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 0 12px;

	${breakpoints.sm`
		padding-left: 8px;
		padding-right: 8px;
		padding-bottom: 20px;
	`}

	p {
		color: var(--color-text-secondary);
	}
`;

const CalloutExplore = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 32px;
	flex-shrink: 0;
	background: ${utility.color("blackLight")};
	color: #fff;
	border-radius: 4px;
	text-decoration: none;
	min-width: 150px;
	font-weight: 600;
	margin-bottom: 8px;
	border-radius: 60px;
	width: 100%;

	svg {
		margin-left: 8px;
	}

	:focus,
	:hover:not(:disabled) {
		background: var(--color-accent);
	}

	${breakpoints.sm`
		width: auto;
	`}
`;
