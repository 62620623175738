import { useCallback, useState } from "react";

import { useAuthenticationListener } from "../../modules/authentication/hooks/useAuthenticationListener";
import { useProfileListener } from "../../modules/profile/hooks/useProfileListener";

/**
 * Blocks rendering until authentication check is complete. Then if authenticated
 * prime the customer profile.
 *
 * Currently for the best loading experience we have the following data dependencies:
 * -> Authentication check
 * ----> Get profile (to fetch the last active workspaces)
 * ------> Get workspace (this is how most collections are queried)
 */
export const AuthenticationContainer: React.FC = ({ children }) => {
	const [checkingAuth, setCheckingAuth] = useState(true);
	const handleOnComplete = useCallback(() => setCheckingAuth(false), []);

	useProfileListener();
	useAuthenticationListener(handleOnComplete);

	if (checkingAuth) {
		return null;
	}

	return <>{children}</>;
};
