import React, { FunctionComponent } from "react";

import { Heading, HeadingProps } from "rebass";
import styled from "styled-components";
import { size, space } from "styled-system";

export const BaseHeadingStyles = styled(Heading)`
	font-weight: 400;
	color: var(--color-text-heading);
	${size}
	${space}
`;

const PageHeader = styled(BaseHeadingStyles)`
	font-size: 24px;
	margin-bottom: 48px;
	font-weight: 400;
	line-height: 1;
	color: var(--color-text-heading);
`;

const H2 = styled(Heading)`
	font-weight: 400;
	color: var(--color-text-heading);
	font-size: 24px;
	line-height: 1.42;
	letter-spacing: -0.01em;
`;

type Levels = "PageHeader" | "H1" | "H2" | "H3" | "H4" | "H5";

const HeadingStyled: Record<Levels, FunctionComponent<HeadingProps>> = {
	PageHeader: (props) => <PageHeader as="h1" {...props} />,
	H1: (props: any) => <BaseHeadingStyles as="h1" fontWeight="500" fontSize={[5, 6]} lineHeight={1.15} mb={3} {...props} />,
	H2: (props) => <H2 as="h2" {...props} />,
	H3: (props) => <BaseHeadingStyles as="h3" fontSize={2} letterSpacing="0.01em" fontWeight="500" mb={"24px"} {...props} />,
	H4: (props) => <BaseHeadingStyles as="h4" fontSize={2} fontWeight="medium" mb={1} {...props} />,
	H5: (props) => <BaseHeadingStyles as="h5" fontSize={1} lineHeight={1.62} fontWeight="medium" mb={0} {...props} />,
};

export default HeadingStyled;
