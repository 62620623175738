import { useEffect } from "react";

import { onSnapshot, query, where } from "firebase/firestore";
import { atom, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../shared/utils/dataUtils";
import { WorkspaceInviteV2 } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export const invitesState = atom<WithId<WorkspaceInviteV2>[]>({
	key: "invites",
	default: [],
});

export const v1v2InviteUpdate = (invite: any): WithId<WorkspaceInviteV2> => {
	if (invite.version === 2) {
		return invite;
	}

	return {
		...invite,
		type: "workspace",
		version: 2,
		workspace: invite.workspace,
	};
};

export const useInviteListener = () => {
	const workspace = useActiveWorkspace();
	const [, setInvites] = useRecoilState(invitesState);

	const workspaceId = workspace?.id;

	useEffect(() => {
		if (workspaceId) {
			const inviteQuery = query(
				db.workspaceInvites,
				where("workspace", "==", workspaceId),
				where("acceptedAt", "==", null),
				where("rejectedAt", "==", null),
			);
			return onSnapshot(
				inviteQuery,
				(snapshot) => {
					const invites = extractFromCollectionWithId(snapshot);
					const updatedInvites = invites.map(v1v2InviteUpdate);
					setInvites(updatedInvites);
				},
				onSnapshotError("useInviteListener"),
			);
		}
	}, [setInvites, workspaceId]);
};
