import styled from "styled-components";

export function InboxEmpty() {
	return (
		<Root>
			<span role="img" aria-label="relived">
				😌
			</span>
			<strong>Your all caught up</strong>
			<div>Whenever we see some action in your workspace we'll update you here.</div>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 200px;
	color: var(--color-text-secondary);

	> strong {
		font-weight: 500;
	}

	> span {
		font-size: 48px;
		line-height: 1;
		margin-bottom: 12px;
		stroke-width: 0.03em;
	}

	> div {
		max-width: 330px;
		text-align: center;
	}
`;
