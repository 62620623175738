import { ProjectDtoFolder } from "@/domains/projects/dtos/projectsDto";
import { IProjectProps } from "@/modules/authentication/utils/project";
import { fb } from "@/shared/infra/init";
import { SystemFolderName } from "@/types/db";

export const buildProjectFolder = (
	overrides: Pick<IProjectProps, "workspaceId" | "data" | "visibility"> & Partial<IProjectProps>,
	creator: IProjectProps["createdBy"],
	userId: string,
) => {
	const { workspaceId, data, visibility } = overrides;
	const project: ProjectDtoFolder = {
		workspaceId,
		data,
		visibility,
		type: "folder" as const,
		status: "active" as const,
		access: {
			[userId]: {
				status: "active",
				role: "admin",
				scope: "workspace",
			},
		},
		createdAt: fb.timestamp(),
		updatedAt: fb.timestamp(),
		createdBy: creator,
		updatedBy: creator,
		childIds: [],
		version: "1" as const,
	};

	return project;
};

const userSystem = {
	id: "0",
	username: "system",
	photoURL: null,
};

export const buildWorkspaceFolder = (workspaceId: string, userId: string) => {
	return buildProjectFolder(
		{
			workspaceId: workspaceId,
			visibility: "workspace" as const,
			data: {
				name: SystemFolderName.workspace,
				description: null,
			},
		},
		userSystem,
		userId,
	);
};

export const buildPrivateFolder = (workspaceId: string, userId: string) => {
	return buildProjectFolder(
		{
			workspaceId: workspaceId,
			visibility: "private" as const,
			data: {
				name: SystemFolderName.private,
				description: null,
			},
		},
		userSystem,
		userId,
	);
};

export const buildFavouriteFolder = (workspaceId: string, userId: string) => {
	return buildProjectFolder(
		{
			workspaceId: workspaceId,
			visibility: "private" as const,
			data: {
				name: SystemFolderName.favorite,
				description: null,
			},
		},
		userSystem,
		userId,
	);
};
