import { SyntheticEvent, useCallback, useState } from "react";

import { ExtendedKeyboardEvent } from "mousetrap";
import { Outlet } from "react-router-dom";

import { Sidebar } from "@/domains/accounts/components/sidebar/Sidebar";
import { ListenerProject } from "@/ListenerProject";
import { BoardConfirmDelete } from "@/modules/board/BoardConfirmDelete";
import { Toasts } from "@/modules/toast/Toasts";

import { HelpSidebar } from "../../domains/accounts/components/help/HelpSidebar";
import { Listeners } from "../../Listeners";
import { Layout } from "./Layout";

export const LayoutApplication = () => {
	const [isExpanded, setIsExpanded] = useState(true);

	const handleToggleSidebar = useCallback(
		(e: SyntheticEvent | ExtendedKeyboardEvent) => {
			e.preventDefault();
			setIsExpanded(!isExpanded);
		},
		[isExpanded],
	);

	const sidebar = <Sidebar isExpanded={isExpanded} toggleSidebar={handleToggleSidebar} />;

	return (
		<>
			<Listeners />
			<ListenerProject />
			<Toasts />
			<Layout sidebar={sidebar}>
				<Outlet />
				<HelpSidebar />
			</Layout>
			<BoardConfirmDelete />
		</>
	);
};
