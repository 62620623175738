/* eslint-disable */
import { useEffect } from "react";

import { fb } from "../../../shared/infra/init";
import { useProfileLegacy } from "../../profile/hooks/useProfileLegacy";

export const useCanny = () => {
	const createdAt = fb?.auth?.currentUser?.metadata?.creationTime;
	const profile = useProfileLegacy();

	const apiKey = process.env.VITE_CANNY_API_KEY;

	useEffect(() => {
		if (!profile?.id || !apiKey) {
			return () => {};
		}

		(function (w, d, i, s) {
			function l() {
				if (!d.getElementById(i)) {
					var f = d.getElementsByTagName(s)[0],
						e = d.createElement(s);
					(e.type = "text/javascript"), (e.async = !0), (e.src = "https://canny.io/sdk.js"), f.parentNode.insertBefore(e, f);
				}
			}
			if ("function" != typeof w.Canny) {
				var c = function () {
					c.q.push(arguments);
				};
				(c.q = []),
					(w.Canny = c),
					"complete" === d.readyState ? l() : w.attachEvent ? w.attachEvent("onload", l) : w.addEventListener("load", l, !1);
			}
		})(window, document, "canny-jssdk", "script");

		Canny("identify", {
			appID: apiKey,
			user: {
				// Replace these values with the current user's data
				email: profile?.email,
				name: profile?.username,
				id: profile?.id,

				// These fields are optional, but recommended:
				avatarURL: profile?.photoURL,
				created: new Date(createdAt).toISOString(),
			},
		});

		Canny("initChangelog", {
			appID: apiKey,
			position: "top",
			align: "left",
		});
	}, [profile]);

	return null;
};
