import { Theme, ThemeName } from "@/modules/board/settings/components/theme/models/themeModel";

export const themes: Record<ThemeName, Theme> = {
	inherit: {
		accent: "",
		body: "",
	},
	light: {
		accent: "#8154f2",
		body: "#fdfdfd",
	},
	grey: {
		accent: "#393e46",
		body: "#eeeeee",
	},
	dark: {
		accent: "#b48df3",
		body: "color(display-p3 0.02 0.05 0.11 / 1)",
	},
	neon: {
		accent: "#f11a7b",
		body: "#191825",
	},
	teal: {
		accent: "#08D9D6",
		body: "#252A34",
	},
	custom: {
		accent: "",
		body: "",
	},
};
