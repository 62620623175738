import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import routes from "../../shared/core/routes";
import { Button, Progress } from "../../shared/system";
import { useActiveWorkspace } from "../workspace/hooks/useActiveWorkspace";
import { workspaceUsageState } from "./hooks/usePlanUsageListener";
import { getPlanDetails } from "./selectors/getPlanDetails";

const freeNodes = parseInt(process.env.VITE_FREE_NODE_COUNT || "0", 10);

export const PlanUpgrade = () => {
	const navigate = useNavigate();

	const { totalNodes } = useRecoilValue(workspaceUsageState);
	const workspace = useActiveWorkspace();
	const planDetails = useRecoilValue(getPlanDetails);

	const handleUpgrade = () => {
		navigate(routes.plans);
	};

	if (planDetails?.isPaidPlan) {
		return null;
	}

	const count = totalNodes !== undefined ? totalNodes : workspace.nodeCount || 0;

	const percentUsed = (count / freeNodes) * 100;

	return (
		<Root>
			<Upgrade onClick={handleUpgrade}>
				<Content>
					<strong>Upgrade to go unlimited</strong>
					{/* <Promo>$49 Lifetime Deal – Limited Time </Promo> */}
					This workspace has used {count} of its {freeNodes} free nodes ({Math.floor(percentUsed)}
					%)
				</Content>
				<ProgressBar max="100" value={percentUsed} statusColor="purple" bgColor="var(--color-border-dark)" />
				<span>Upgrade plan</span>
			</Upgrade>
		</Root>
	);
};

/**
 * Optional promotion call out temporarily suspended while trialing app sumo
 *
 *
 <Promo>Only $9 per month </Promo>

 const Promo = styled.div`
 	display: inline-block;
 	background: ${utility.color("green")};
 	color: #fff;
 	font-weight: 500;
 	padding: 2px 8px;
 	border-radius: 4px;
 	margin: 8px 0;
 	text-align: center;
 `;
 */

const Root = styled.div`
	margin: 0 -6px;
`;

const Content = styled.div`
	margin-bottom: 16px;
	color: var(--color-text);
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;

	strong {
		color: var(--color-text-heading);
		display: block;
		font-weight: 500;
		margin-bottom: 4px;
	}
`;

const ProgressBar = styled(Progress)`
	height: 8px;
	margin-bottom: 8px;

	&::-webkit-progress-value {
		background-color: var(--color-text-secondary);
	}
`;

const Upgrade = styled(Button.Outline)`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	background: var(--color-hover);
	border: 0;
	text-align: left;

	&:focus,
	&:hover:not(:disabled) {
		background-color: var(--color-hover);
	}

	:hover span {
		color: var(--color-accent-hover);
	}

	span {
		color: var(--color-accent);
		margin-top: 8px;
		font-weight: 500;
	}
`;
