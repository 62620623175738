import { captureException, captureMessage } from "@sentry/browser";
import * as SentrySDK from "@sentry/react";
import { Integrations } from "@sentry/tracing";

/**
 * Blocked list sourced from https://docs.sentry.io/clients/javascript/tips/
 */
const sentryConfig: SentrySDK.BrowserOptions = {
	dsn: process.env.VITE_SENTRY_KEY,
	allowUrls: [
		process.env.VITE_HOSTING_URL || "https://poda.io",
		"https://firestore.googleapis.com",
		"https://cdn.segment.com",
	],
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"top.GLOBALS",
		"originalCreateNotification",
		"canvas.contentDocument",
		"MyApp_RemoveAllHighlights",
		"http://tt.epicplay.com",
		"Can't find variable: ZiteReader",
		"jigsaw is not defined",
		"ComboSearch is not defined",
		"http://loading.retry.widdit.com/",
		"atomicFindClose",
		"fb_xd_fragment",
		"bmi_SafeAddOnload",
		"EBCallBackMessageReceived",
		"conduitPage",
		"Script error.",
		"_avast_submit",
	],
	denyUrls: [
		/pagead\/js/i,
		/graph\.facebook\.com/i,
		/connect\.facebook\.net\/en_US\/all\.js/i,
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		/extensions\//i,
		/^chrome:\/\//i,
		/127\.0\.0\.1:4001\/isrunning/i,
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],
	beforeBreadcrumb(breadcrumb, hint) {
		if (breadcrumb.category === "ui.click" && hint) {
			const { target } = hint.event;
			if (target.ariaLabel) {
				breadcrumb.message = target.ariaLabel;
			}
		}
		return breadcrumb;
	},
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new Integrations.BrowserTracing(), new SentrySDK.Replay()],
	tracesSampleRate: 0.5,
	environment: process.env.NODE_ENV,
	release: `WebUI@${process.env.VITE_VERSION}`,
};

export class ErrorLogger {
	public static init() {
		SentrySDK.init(sentryConfig);
	}

	public static captureException(
		message: string,
		context: Record<string, any> = {},
		level: SentrySDK.SeverityLevel = "error",
	) {
		captureException(message, (scope) => {
			scope.setLevel(level);
			scope.setExtras(context);
			return scope;
		});
	}

	public static captureMessage(message: string, context: Record<string, any> = {}) {
		captureMessage(message, (scope) => {
			scope.setExtras(context);
			return scope;
		});
	}
}
