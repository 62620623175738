import styled from "styled-components";

import { breakpoints } from "../../shared/system";

type ContainerSizeArgs = {
	size?: keyof typeof breakpoints;
};

const containerSize = ({ size }: ContainerSizeArgs): string => {
	switch (size) {
		case "sm":
			return "600px";
		case "md":
			return "800px";
		default:
			return "1264px";
	}
};

export default styled.div`
	max-width: ${containerSize};
	width: 100%;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;

	${breakpoints.sm`
		padding-left: ${({ theme }) => theme.space[4]}px;
		padding-right: ${({ theme }) => theme.space[4]}px;
	`}
`;
