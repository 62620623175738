import { selector } from "recoil";

import { activeWorkspaceIdState } from "../../authentication/hooks/useAuthenticationListener";
import { userPresenceState } from "../../presence/hooks/useUserPresence";
import { membersState } from "../hooks/useMembersListener";

export const getWorkspaceMembers = selector({
	key: "workspaceMembersWithStatus",
	get: ({ get }) => {
		const activeWorkspaceId = get(activeWorkspaceIdState);
		const members = get(membersState);
		const membersPresence = get(userPresenceState(activeWorkspaceId));

		return Object.values(members).map((member) => {
			const presence = membersPresence.find(({ id }) => id === member.id);
			return {
				...presence,
				...member,
			};
		});
	},
});
