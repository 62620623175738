import React, { useRef } from "react";

import styled from "styled-components";

import { NodeContextMenu } from "@/domains/nodes/components/menu/NodeContextMenu";
import { NodeKeyboardShortcuts } from "@/domains/nodes/components/menu/NodeKeyboardShortcuts";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { BoardDnDContext } from "@/modules/board/components/BoardDnDContext";
import { ThemeStyles } from "@/modules/board/settings/components/theme/ThemeStyles";
import { lazyWithRetry } from "@/shared/utils/lazyWithRetry";

import { BoardMetaTags } from "./BoardMetaTags";

const Error404 = lazyWithRetry(() => import("../../errors/Error404"));
const Error401 = lazyWithRetry(() => import("../../errors/Error401"));

export const BoardContainer: React.FC = ({ children }) => {
	const contextRootEl = useRef<HTMLDivElement>(null);
	const projectSingle = useProjectsStore((state) => state.projectSingle);

	if (projectSingle?.code === 404) {
		return <Error404 />;
	}

	if (projectSingle?.code === 401) {
		return <Error401 />;
	}

	if (!projectSingle?.data || projectSingle.data.type !== "board") {
		return null;
	}

	return (
		<Root ref={contextRootEl}>
			<ThemeStyles project={projectSingle.data} />
			<NodeContextMenu rootEl={contextRootEl} />
			<BoardDnDContext>
				<BoardMetaTags board={projectSingle.data} />
				<NodeKeyboardShortcuts />
				{children}
			</BoardDnDContext>
		</Root>
	);
};

const Root = styled.div`
	height: 100%;
`;
