import keyBy from "lodash/keyBy";
import { useRecoilValue } from "recoil";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { getBoardMembers } from "../selectors/getBoardMembers";

export const useBoardMembers = () => {
	const board = useGetProject();

	const boardId = board?.id || "";
	const workspaceId = board?.workspaceId || "";
	return keyBy(useRecoilValue(getBoardMembers({ boardId, workspaceId })), (_) => _.id);
};
