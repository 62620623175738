export const getScaledHeight = (el: HTMLImageElement | null): number => {
	if (el === null) {
		return 0;
	}

	const naturalHeight = el.naturalHeight;
	const width = el.width;
	const naturalWidth = el.naturalWidth;
	const scale = width / naturalWidth;

	return naturalHeight * scale;
};
