import { Box } from "rebass";
import styled from "styled-components";
import { SpaceProps } from "styled-system";

export const Divider: React.FC<SpaceProps> = (props) => {
	return <Line {...props} as="hr" />;
};

const Line = styled(Box)`
	border: 0;
	height: 1px;
	background-color: var(--color-border);
`;
