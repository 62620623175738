import { useMemo } from "react";

import { Unsubscribe } from "firebase/auth";
import { orderBy, query, QuerySnapshot, where } from "firebase/firestore";

import { EventsDto } from "@/domains/nodes/dtos/eventsDto";
import { EventUtils } from "@/domains/nodes/utils/eventUtils";
import { IEventsStore } from "@/domains/nodes/zustand/eventsStore";
import { IErrorService, useErrorService } from "@/shared/core/hooks/useErrorService";
import { BaseApi } from "@/shared/infra/services/BaseApi";
import { queryToViewModel } from "@/shared/utils/dataUtils";

type ConstructorArgs = {
	errorService: IErrorService;
};

class EventsRepository extends BaseApi<EventsDto> {
	private errorService;

	constructor({ errorService }: ConstructorArgs) {
		super("events");
		this.errorService = errorService;
	}

	public listen = (nodeId: string, save: IEventsStore["setEvents"]): Unsubscribe => {
		const eventsQuery = query(this.collection, where("refId", "==", nodeId), orderBy("createdAt", "asc"));

		const setState = (snapshot: QuerySnapshot<EventsDto>) => {
			if (snapshot.metadata.fromCache) {
				return;
			}
			const eventModel = queryToViewModel(snapshot, EventUtils.toViewModel);

			save(eventModel);
		};

		return this.querySnapshot(eventsQuery, setState);
	};

	public create = (event: EventsDto) => {
		const { id } = this.getDocument();

		try {
			this.post(id, event);
		} catch (error) {
			this.errorService.logError("Failed to create event", { error });
		}
	};

	public update = (eventId: string, event: EventsDto) => {
		try {
			// eventsDto.parse(event);
			this.put(eventId, event);
		} catch (error) {
			this.errorService.logError("Failed to create event", { error });
		}
	};
}

export const useEventsRepository = () => {
	const errorService = useErrorService();

	const eventsRepository = useMemo(() => new EventsRepository({ errorService }), [errorService]);

	return eventsRepository;
};
