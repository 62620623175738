import { createGlobalStyle } from "styled-components";

export const EditorStyles = createGlobalStyle`
	.ProseMirror {
		position: relative;
		word-wrap: break-word;
		white-space: pre-wrap;
		white-space: break-spaces;
		-webkit-font-variant-ligatures: none;
		font-variant-ligatures: none;
		font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
		font-size: 15px;

		:focus,
		:active {
			outline: none;
		}

		p:only-child {
			margin-bottom: 0;
		}
	

		ul, ol {
			padding-left: 24px;
		}

		/* a {
			color: var(--color-accent);
			text-decoration: none;
		} */



		li {
			position: relative;
			margin: 0;

			p {
				margin: 0;
			}
		}

		pre {
			white-space: pre-wrap;
		}

		img {
			max-width: 100%;
		}
	}

	.ProseMirror:focus p.is-editor-empty:first-child::before {
		content: ""
	}

	.ProseMirror p.is-editor-empty:first-child::before {
		content: attr(data-placeholder);
		float: left;
		pointer-events: none;
		height: 0;
		color: var(--color-accent);
	}

	p.is-editor-empty {
		margin-bottom: 0;
	}

	.ProseMirror-hideselection *::selection {
		background: transparent;
	}
	.ProseMirror-hideselection {
		caret-color: transparent;
	}

	.ProseMirror-selectednode,
	.ProseMirror.focus-visible {
		outline: none;
	}

	/* Protect against generic img rules */

	img.ProseMirror-separator {
		display: inline !important;
		border: none !important;
		margin: 0 !important;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding-left: 0;

		li {
			display: flex;
			align-items: center;
		}

		label {
			display: flex;
			align-items: center;
			user-select: none;
		}

		input {
			margin: 0 8px 0 0;
		}
	}
`;
