import React from "react";

import * as Sentry from "@sentry/react";

import { Error500 } from "./Error500";

interface Props {
	children: React.ReactNode;
}

export const ErrorBoundary = ({ children }: Props) => {
	const fallback = () => <Error500 />;

	return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
};
