import { useCallback, useLayoutEffect } from "react";

import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useReactFlow, useStore } from "reactflow";

import { ControlButton, ControlGroup } from "@/domains/projects/components/treeView/components/Controls/Controls.styled";
import { Tooltip } from "@/shared/system";

type Props = {
	isEmbedded: boolean;
};

export const Zoom: React.FC<Props> = ({ isEmbedded }) => {
	const { zoomIn, zoomOut, fitView } = useReactFlow();

	const zoom = useStore((state) => state.transform[2]);
	const zoomPercentage = Math.round(zoom * 100);

	const canZoomOut = zoomPercentage > 50;
	const canZoomIn = zoomPercentage < 200;

	const zoomInTip = canZoomIn ? "Zoom in" : "Max zoom reached";
	const zoomOutTip = canZoomOut ? "Zoom out" : "Min zoom reached";

	const handleZoomIn = useCallback(
		(e: ExtendedKeyboardEvent) => {
			e.preventDefault();
			zoomIn({ duration: 200 });
		},
		[zoomIn],
	);

	const handleZoomOut = useCallback(
		(e: ExtendedKeyboardEvent) => {
			e.preventDefault();
			zoomOut({ duration: 200 });
		},
		[zoomOut],
	);

	const handleFitView = useCallback(
		() =>
			fitView({
				duration: 200,
				maxZoom: 1,
				minZoom: 0.5,
			}),
		[fitView],
	);

	useLayoutEffect(() => {
		Mousetrap.bind("-", handleZoomOut);
		Mousetrap.bind("=", handleZoomIn);
		Mousetrap.bind("command+-", handleZoomOut);
		Mousetrap.bind("command+=", handleZoomIn);
		Mousetrap.bind("ctrl+-", handleZoomOut);
		Mousetrap.bind("ctrl+=", handleZoomIn);
		Mousetrap.bind("1", handleFitView);

		return () => {
			Mousetrap.unbind("-");
			Mousetrap.unbind("=");
			Mousetrap.unbind("command+-");
			Mousetrap.unbind("command+=");
			Mousetrap.unbind("ctrl+-");
			Mousetrap.unbind("ctrl+=");
			Mousetrap.unbind("1");
		};
	}, [handleFitView, handleZoomIn, handleZoomOut]);

	return (
		<ControlGroup isEmbedded={isEmbedded}>
			<Tooltip label={zoomOutTip} combo={<kbd>-</kbd>}>
				<ControlButton disabled={!canZoomOut} onClick={handleZoomOut} isEmbedded={isEmbedded}>
					<FiMinus />
				</ControlButton>
			</Tooltip>
			{!isEmbedded && (
				<Tooltip label="Fit content" combo={<kbd>1</kbd>}>
					<ControlButton onClick={handleFitView} style={{ width: "48px" }}>
						{zoomPercentage}%
					</ControlButton>
				</Tooltip>
			)}
			<Tooltip label={zoomInTip} combo={<kbd>=</kbd>}>
				<ControlButton disabled={!canZoomIn} onClick={handleZoomIn} isEmbedded={isEmbedded}>
					<FiPlus />
				</ControlButton>
			</Tooltip>
		</ControlGroup>
	);
};
