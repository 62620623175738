import { UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { NavigationTreeNode } from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";

const getDragDepth = (offset: number, indentationWidth: number) => {
	return Math.round(offset / indentationWidth);
};

const getMaxDepth = (node?: NavigationTreeNode) => {
	if (!node) {
		return 0;
	}

	return node.data.type === "folder" ? node.depth : node.depth - 1;
};

const getMinDepth = (node?: NavigationTreeNode) => {
	return node?.depth ?? 0;
};

export const getNextParent = (
	items: NavigationTreeNode[],
	rootNode: NavigationTreeNode,
	activeId: UniqueIdentifier | null,
	overId: UniqueIdentifier | null,
	dragOffset: number,
	indentationWidth: number,
) => {
	if (!activeId && !overId) {
		return null;
	}

	const overItemIndex = items.findIndex((node) => node.data.id === overId);
	const activeItemIndex = items.findIndex((node) => node.data.id === activeId);

	const activeItem = items[activeItemIndex];

	if (!activeItem) {
		return;
	}

	const newItems = arrayMove(items, activeItemIndex, overItemIndex);

	const previousItem = newItems[overItemIndex - 1];
	const nextItem = newItems[overItemIndex + 1];

	const dragDepth = getDragDepth(dragOffset, indentationWidth);
	const projectedDepth = activeItem.depth - 1 + dragDepth;

	const maxDepth = getMaxDepth(previousItem);
	const minDepth = getMinDepth(nextItem);

	let depth = projectedDepth;

	if (projectedDepth >= maxDepth) {
		depth = maxDepth;
	} else if (projectedDepth < minDepth) {
		depth = minDepth;
	}

	return { depth, maxDepth, minDepth, parentId: getParentId() };

	function getParentId() {
		if (depth === 0 || !previousItem) {
			return rootNode.data.id || "";
		}

		if (depth === previousItem.depth - 1) {
			return previousItem.parent?.data.id || "";
		}

		if (depth > previousItem.depth - 1) {
			return previousItem.data.id || "";
		}

		const newParent = newItems
			.slice(0, overItemIndex)
			.reverse()
			.find((item) => item.depth === depth);

		return newParent?.parent?.data.id ?? rootNode.data.id;
	}
};
