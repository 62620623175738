import React, { useMemo } from "react";

import { FiMoreHorizontal } from "react-icons/fi";
import styled from "styled-components";

import { NodeDto } from "@/domains/nodes/dtos/nodesDto";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { Avatar, AvatarList, DropdownHeader, DropdownList, Hover } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover } from "@/shared/system/Dropdown/Popover";
import { User } from "@/types/db";

type Props = {
	node: NodeDto;
	members: Record<string, User>;
	toggleSubscribed: (userId: string) => void;
};

export const ActivitySubscribeMore: React.FC<Props> = ({ node, members, toggleSubscribed }) => {
	const membersArray = Object.values(members);

	const subscribedProfiles = useMemo(() => node.subscribers.map((_) => members[_]), [members, node]);

	const render = () => {
		return (
			<PopoverContent data-testid="activitySubscribe--members">
				<DropdownHeader>Change subscribers</DropdownHeader>
				{membersArray.map((member) => {
					return (
						<DropdownList
							checked={NodeUtils.isUserSubscribed(member.id, node)}
							onChange={() => toggleSubscribed(member.id)}
							id={`subscribe-${member.id}`}
							key={member.id}>
							<Avatar user={member} size={18} />
							<Username>{member.username}</Username>
						</DropdownList>
					);
				})}
			</PopoverContent>
		);
	};

	return (
		<Popover render={render} placement="bottom-start">
			<Icon data-testid="activitySubscribe--more">
				<Avatars users={subscribedProfiles} userCap={10} size={18} />
				<FiMoreHorizontal />
			</Icon>
		</Popover>
	);
};

const Icon = styled(Hover)`
	display: flex;
	align-items: center;
	padding: 4px;
	line-height: 1;
	font-size: 15px;
	margin: 0 -4px;
	min-height: 28px;
`;

const Avatars = styled(AvatarList)`
	margin-right: 12px;
`;

const Username = styled.span`
	display: inline-block;
	margin-left: 4px;
`;
