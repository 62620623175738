import { format, fromUnixTime } from "date-fns";
import {
	VictoryArea,
	VictoryAxis,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryScatter,
	VictoryThemeDefinition,
	VictoryTooltip,
} from "victory";

import { Timestamp } from "@/shared/infra/firebase/firestore";
import { KeyResultModel } from "@/types/db";

import {
	areaStyle,
	hiddenAxisStyle,
	lineStyle,
	scatterStyle,
	tooltipLabelStyle,
	tooltipPadding,
	tooltipStyle,
} from "../../utils/chartHelpers";
import { keyResultStatusMap } from "../../utils/keyResultStatusMap";
import { chartTheme } from "./KeyResultsChart";

const theme: VictoryThemeDefinition = {
	...chartTheme,
	axis: {
		style: {
			...(chartTheme?.axis?.style || {}),
			axis: {
				fill: "transparent",
				stroke: "var(--color-border)",
				strokeWidth: 2,
				strokeLinecap: "round",
				strokeLinejoin: "round",
			},

			ticks: {
				fill: "transparent",
				size: 5,
				stroke: "var(--color-border)",
				strokeWidth: 1,
				strokeLinecap: "round",
				strokeLinejoin: "round",
			},
		},
	},
};

const dateFormat = "d MMM";

const formatDate = (firebaseDate: Timestamp) => fromUnixTime(firebaseDate.seconds);

const buildUpdateData = ({ startDate, updates }: KeyResultModel) => {
	const updatePoints = updates.map(({ status, createdAt }) => ({
		x: formatDate(createdAt),
		y: 0,
		status,
	}));

	updatePoints.splice(0, 0, {
		x: formatDate(startDate),
		y: 0,
		status: "inactive",
	});

	return updatePoints;
};

const buildTargetData = ({ startDate, endDate }: KeyResultModel) => {
	return [
		{ x: formatDate(startDate), y: 0 },
		{ x: formatDate(endDate), y: 0 },
	];
};

type Props = {
	result: KeyResultModel;
};

export const KeyResultsNoTarget: React.FC<Props> = ({ result }) => {
	const targetData = buildTargetData(result);
	const currentData = buildUpdateData(result);

	return (
		<div>
			<VictoryChart width={800} theme={theme}>
				<VictoryAxis crossAxis dependentAxis domain={[-10, 10]} style={hiddenAxisStyle} />
				<VictoryAxis
					tickCount={5}
					tickFormat={(t) => format(t, dateFormat)}
					tickLabelComponent={<VictoryLabel angle={45} dy={10} />}
				/>
				<VictoryArea data={targetData} style={areaStyle} />
				<VictoryLine data={currentData} style={lineStyle} />
				<VictoryScatter
					data={currentData}
					size={5}
					labels={({ datum }) => [
						format(datum.x, dateFormat),
						keyResultStatusMap[datum.status as KeyResultModel["status"]].label,
					]}
					labelComponent={
						<VictoryTooltip
							cornerRadius={4}
							flyoutStyle={tooltipStyle}
							flyoutPadding={tooltipPadding}
							labelComponent={<VictoryLabel lineHeight={1} style={tooltipLabelStyle} />}
						/>
					}
					style={scatterStyle}
				/>
			</VictoryChart>
		</div>
	);
};
