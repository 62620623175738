import styled from "styled-components";
import { space } from "styled-system";

export const DropdownHr = styled.hr<{ m?: string }>`
	margin-top: 0;
	margin-bottom: 0;
	background-color: var(--color-border);
	height: 1px;
	margin: 4px -8px;
	border: 0;
	${space}
`;
