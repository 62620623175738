import { captureException } from "@sentry/browser";
import { doc, writeBatch } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";
import { WorkspaceMemberRole } from "@/types/db";

type UpdateGuestRole = (args: { projectId: string; userId: string; role: WorkspaceMemberRole }) => void;

export const updateGuestRole: UpdateGuestRole = ({ projectId, userId, role }) => {
	try {
		const projectDocument = doc(db.projects, projectId);
		const userDocument = doc(db.users, userId);

		const batch = writeBatch(fb.firestore);
		// @ts-expect-error TS(2345) FIXME: Argument of type '{ [x: string]: WorkspaceMemberRo... Remove this comment to see the full error message
		batch.update(projectDocument, { [`access.${userId}.role`]: role });
		// @ts-expect-error TS(2345) FIXME: Argument of type '{ [x: string]: WorkspaceMemberRo... Remove this comment to see the full error message
		batch.update(userDocument, { [`access.${projectId}.role`]: role });

		batch.commit();
	} catch (e) {
		captureException(e);
	}
};
