import produce, { enableMapSet } from "immer";

import { ProjectModel, ProjectSingle } from "@/domains/projects/models/projectsModel";
import { Result } from "@/shared/utils/result";
import { createStore } from "@/shared/zustand/createStore";
import { Dictionary } from "@/types/db";

enableMapSet();

export interface IProjectsStore {
	projects: Map<ProjectModel["id"], ProjectModel>;
	setProjects: (projects: Dictionary<ProjectModel>) => void;
	getProject: (projectId: ProjectModel["id"]) => Result<ProjectModel>;
	removeProject: (projectId: ProjectModel["id"]) => void;
	getProjectsGroup: (group: ProjectModel["group"]) => ProjectModel[];
	projectSingle: ProjectSingle | null;
	setProjectSingle: (project: ProjectSingle) => void;
}

export const projectsStore = createStore<IProjectsStore>((set, get) => ({
	projects: new Map(),
	projectSingle: null,
	setProjects: (newProjects) => {
		const projects = get().projects;
		const newProjectMap = new Map(Object.entries(newProjects));

		set({ projects: new Map([...projects, ...newProjectMap]) });
	},
	getProject: (projectId) => {
		const project = get().projects.get(projectId);
		if (!project) {
			return Result.fail("Project not found");
		}
		return Result.ok(project);
	},
	removeProject: (projectId) => {
		set((state) => ({
			projects: produce(state.projects, (draft) => {
				draft.delete(projectId);
			}),
		}));
	},
	setProjectSingle: (project) => {
		const projects = get().projects;

		set({ projectSingle: project });

		if (project?.code === 200) {
			const data = {
				...project.data,
				group: "single" as const,
			};
			set({ projects: projects.set(data.id, data) });
		}
	},
	getProjectsGroup: (group) => {
		const projects = get().projects;
		return Array.from(projects.values()).filter((project) => project.group === group);
	},
}));
