import React from "react";

import styled from "styled-components";
import { border, borderRadius, color, space } from "styled-system";

export const ButtonIcon = ({ src, children, ...props }: any) => {
	return (
		<ButtonIconWrapper {...props}>
			{src}
			{children}
		</ButtonIconWrapper>
	);
};
export const ButtonIconWrapper = styled.button<{ offsetPadding?: boolean }>`
	padding: 10px;
	margin: ${({ offsetPadding }) => (offsetPadding ? "-10px" : "0")};
	border-radius: 100%;
	border: none;
	background: none;
	cursor: pointer;
	vertical-align: middle;
	font-size: ${(props) => (props as any).size + "px" || "inherit"};
	color: var(--color-text-secondary);

	svg {
		display: block;
	}

	:focus {
		outline: none;
	}

	&:hover {
		color: var(--color-accent-hover);
	}

	${space}
	${color}
  ${border}
  ${borderRadius}
`;

export default ButtonIcon;
