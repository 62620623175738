import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger } from "../../../../shared/system";
import { Filter, FilterComparators, FilterTypes, FilterValueTypes } from "../filterModel";

type MetaComparatorOptions = { label: string; comparator: keyof typeof FilterComparators };

export const buildOptions = (metaType: FilterValueTypes): MetaComparatorOptions[] => {
	switch (metaType) {
		case "person":
		case "list": {
			return [
				{ label: "includes", comparator: "includes" },
				{ label: "does not include", comparator: "doesNotInclude" },
				{ label: "is empty", comparator: "isEmpty" },
				{ label: "is not empty", comparator: "isNotEmpty" },
			];
		}
		case "date": {
			return [
				{ label: "before", comparator: "isBefore" },
				{ label: "after", comparator: "isAfter" },
				{ label: "overdue", comparator: "isOverdue" },
				{ label: "is empty", comparator: "isEmpty" },
				{ label: "is not empty", comparator: "isNotEmpty" },
			];
		}
		case "rootNode": {
			return [{ label: "is", comparator: "is" }];
		}
		case "select":
		case "dateRange": {
			return [
				{ label: "is", comparator: "is" },
				{ label: "is not", comparator: "isNot" },
				{ label: "is empty", comparator: "isEmpty" },
				{ label: "is not empty", comparator: "isNotEmpty" },
			];
		}
		case "link":
		case "text":
		case "email": {
			return [
				{ label: "is empty", comparator: "isEmpty" },
				{ label: "is not empty", comparator: "isNotEmpty" },
			];
		}
		default: {
			const _exhaustiveCheck: never = metaType;
			return _exhaustiveCheck;
		}
	}
};

type Props = {
	filter: Filter;
	metaType: FilterValueTypes;
};

export const FilterGroupComparator = ({ filter, metaType }: Props) => {
	const options = buildOptions(metaType);
	const isDisabled = options.length < 2;

	const { updateMetaFilter } = useFilterService();

	if (filter.type === FilterTypes.rootNode) {
		return (
			<Dropdown>
				<DropdownTrigger disabled>{FilterComparators[filter.comparator]}</DropdownTrigger>
			</Dropdown>
		);
	}

	return (
		<Dropdown>
			<DropdownTrigger disabled={isDisabled} data-testid={"filter-group-comparator"}>
				{FilterComparators[filter.comparator]}
			</DropdownTrigger>
			<DropdownContent>
				{options.map(({ label, comparator }) => {
					const handleSelect = () => updateMetaFilter({ ...filter, comparator });
					return (
						<DropdownMenuItem onSelect={handleSelect} data-testid={`filter-group-comparator[${comparator}]`} key={label}>
							{label}
						</DropdownMenuItem>
					);
				})}
			</DropdownContent>
		</Dropdown>
	);
};
