import { useCallback } from "react";

import dayjs from "dayjs";

import { useKeyResultsService } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsService";
import { KeyResultsSetScreen } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { ModalHeader } from "@/shared/system/Modal/Modal";
import { KeyResultModel } from "@/types/db";

import { KeyResultForm, KeyResultsFormFields } from "./components/KeyResultForm";

const DATE_INPUT_FORMAT = "YYYY-MM-DD";

type Props = {
	keyResult: KeyResultModel;
	onComplete: KeyResultsSetScreen;
};

export const KeyResultEdit: React.FC<Props> = ({ keyResult, onComplete }) => {
	const { update: updateKeyResult } = useKeyResultsService();

	const defaultEditFormValues = {
		...keyResult,
		startDate: dayjs.unix(keyResult.startDate.seconds).format(DATE_INPUT_FORMAT),
		endDate: dayjs.unix(keyResult.endDate.seconds).format(DATE_INPUT_FORMAT),
	};

	const onSubmit = useCallback(
		async (data: KeyResultsFormFields) => {
			await updateKeyResult(keyResult.id, data, keyResult);
			onComplete("details");
		},
		[keyResult, onComplete, updateKeyResult],
	);

	const onCancel = useCallback(() => {
		onComplete("details");
	}, [onComplete]);

	return (
		<>
			<ModalHeader>Edit Key Result</ModalHeader>
			<KeyResultForm cta="Update" onSubmit={onSubmit} onCancel={onCancel} defaultValues={defaultEditFormValues} />
		</>
	);
};
