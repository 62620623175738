import React from "react";

import styled from "styled-components";

import { formatTarget, getChange } from "@/domains/nodes/components/keyResults/utils/keyResultsUtils";
import { KeyResultModel } from "@/types/db";

import { utility } from "../../../../../shared/system";

const getDirection = (difference: any) => {
	switch (Math.sign(difference)) {
		case -1:
			return "-";
		case 1: {
			return "+";
		}
		case 0:
		default:
			return;
	}
};

type Props = {
	keyResult: KeyResultModel;
	nextValue: string;
	previousValue: string;
};

export const KeyResultsResultChange: React.FC<Props> = ({ keyResult, nextValue, previousValue }) => {
	const { goalType, targetValue } = keyResult;

	const { appendUnits } = formatTarget(targetValue);

	const difference = getChange(nextValue, previousValue);
	const differenceWithUnit = appendUnits(Math.abs(difference));

	const direction = getDirection(difference);

	const colorDifference = goalType === "stay-below" ? difference * -1 : difference;
	const color = Math.sign(colorDifference) === -1 ? "red" : "green";

	if (!difference) {
		return <Root>No change</Root>;
	}

	return (
		<Root $color={color}>
			{direction}
			{differenceWithUnit}
		</Root>
	);
};

const Root = styled.span<{ $color?: "red" | "green" }>`
	display: flex;
	align-items: center;
	margin-left: 8px;
	color: ${({ theme, $color }) => utility.color($color)({ theme })};
`;
