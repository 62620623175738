import { differenceInCalendarDays, parseISO } from "date-fns";

import { Node, NodeMetaDataDateTypes, NodeMetaDataTypes, NodeMetaTypes } from "../../../../types/db";
import { FilterComparators, Filters } from "../filterModel";

type CompareDates = (args: { timestamp: string; offset: string; comparator: string }) => boolean;

const compareDates: CompareDates = ({ timestamp, offset, comparator }) => {
	const metaDate = parseISO(timestamp);
	const daysBetween = differenceInCalendarDays(metaDate, new Date());
	const offsetNumber = parseInt(offset, 10);

	if (comparator === "<") {
		return daysBetween < offsetNumber;
	} else if (comparator === ">") {
		return daysBetween > offsetNumber;
	} else if (comparator === "-1") {
		return daysBetween < 0;
	}
	return true;
};

const filterDate = (match: string, current: NodeMetaDataTypes | undefined, comparator: string) => {
	const dateMeta = current as NodeMetaDataDateTypes;
	if (dateMeta?.type === NodeMetaTypes.date) {
		return compareDates({
			timestamp: dateMeta.data.date,
			offset: match,
			comparator,
		});
	} else if (dateMeta?.type === NodeMetaTypes.dateRange) {
		return compareDates({
			timestamp: dateMeta.data.to,
			offset: match,
			comparator,
		});
	}
	return false;
};

type Comparator = (match: string, current: NodeMetaDataTypes | undefined) => boolean;

const comparators: Record<keyof typeof FilterComparators, Comparator> = {
	is: (match, current) => (Array.isArray(current) ? current.includes(match) : current === match),
	isNot: (match, current) => (Array.isArray(current) ? !current.includes(match) : current !== match),
	isEmpty: (match, current) => (Array.isArray(current) ? current.length === 0 : !current),
	isNotEmpty: (match, current) => (Array.isArray(current) ? current.length > 0 : !!current),
	includes: (match, current) => Array.isArray(current) && current.includes(match),
	doesNotInclude: (match, current) => !current || (Array.isArray(current) && !current.includes(match)),
	isBefore: (match, current) => filterDate(match, current, "<"),
	isAfter: (match, current) => filterDate(match, current, ">"),
	isOverdue: (match, current) => filterDate(match, current, "-1"),
};

type CheckIsInFilter = (filters: Filters, node: Node) => boolean;

export const checkIsInFilter: CheckIsInFilter = (filters, node) => {
	return filters.reduce<boolean>((match, filter) => {
		if (!match || filter.type === "rootNode") {
			return match;
		}

		const { metaId, value, comparator } = filter;

		const compareFunction = comparators[comparator];

		if (!compareFunction) {
			console.warn("Comparator not found", comparator);
			return match;
		}

		const nodeValue = node.meta?.[metaId];
		const filterValue = value;

		return compareFunction(filterValue, nodeValue);
	}, true);
};
