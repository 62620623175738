import { useMemo } from "react";

import { arrayRemove, doc, query, runTransaction, Unsubscribe, UpdateData, where } from "firebase/firestore";
import { compose } from "lodash/fp";

import { INodeCreate } from "@/domains/nodes/components/cards/nodeCardTypes";
import { NodeDto } from "@/domains/nodes/dtos/nodesDto";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { IErrorService, useErrorService } from "@/shared/core/hooks/useErrorService";
import { db, fb } from "@/shared/infra/init";
import { BaseApi } from "@/shared/infra/services/BaseApi";
import { Result } from "@/shared/utils/result";

type ConstructorArgs = {
	errorService: IErrorService;
};

export class NodesRepository extends BaseApi<NodeDto> {
	private errorService;

	constructor({ errorService }: ConstructorArgs) {
		super("nodes");

		this.errorService = errorService;
	}

	public listen(projectId: string, next: any): Unsubscribe {
		const nodeQuery = query(this.collection, where("board", "==", projectId), where("archived", "==", false));

		const setState = compose(next, NodeUtils.toViewModel);
		return this.querySnapshot(nodeQuery, setState);
	}

	public async createNode({ node, note, parent }: INodeCreate): Promise<Result<void>> {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id: nodeId, creating, ...nodeDto } = node;
		const { id: noteId, ...noteDto } = note;
		const { id: parentId, ...parentNodeDto } = parent;

		try {
			const nodeDocument = this.getDocument(nodeId);
			const parentDocument = this.getDocument(parentId);

			const noteDocument = doc(db.nodeNotes(nodeId), noteId);

			await runTransaction(fb.firestore, async (transaction) => {
				transaction.set(nodeDocument, nodeDto);
				transaction.set(noteDocument, noteDto);
				transaction.update(parentDocument, parentNodeDto);
			});
			return Result.ok();
		} catch (exception) {
			this.errorService.logWarning("Failed to create node", { exception });
			return Result.fail("Failed to create node");
		}
	}

	public async set(nodeId: string, node: Partial<NodeDto>): Promise<Result<void>> {
		try {
			this.put(nodeId, node);
			return Result.ok();
		} catch (e) {
			this.errorService.logError("Unable to set node", { e });
			return Result.fail("Unable to set node");
		}
	}

	public async updateNode(nodeId: string, node: UpdateData<NodeModel>): Promise<Result<void>> {
		try {
			await this.patch(nodeId, node);
			return Result.ok();
		} catch (e) {
			this.errorService.logError("Unable to update node", { e });
			return Result.fail("Failed ot update node");
		}
	}

	public async archive(nodeId: string, parentId: string): Promise<Result<void>> {
		try {
			const nodeDocument = this.getDocument(nodeId);
			const parentDocument = this.getDocument(parentId);

			await runTransaction(fb.firestore, async (transaction) => {
				transaction.update(nodeDocument, { archived: true });
				transaction.update(parentDocument, { children: arrayRemove(nodeId) });
			});

			return Result.ok();
		} catch (e) {
			this.errorService.logError("Failed to archive node", { nodeId });
			return Result.fail("Failed to archive node");
		}
	}

	public async unarchive(nodeId: string, parentId: string, parent: any): Promise<Result<void>> {
		try {
			const nodeDocument = this.getDocument(nodeId);
			const parentDocument = this.getDocument(parentId);

			await runTransaction(fb.firestore, async (transaction) => {
				transaction.update(nodeDocument, { archived: false });
				transaction.update(parentDocument, parent);
			});
			return Result.ok();
		} catch (e) {
			this.errorService.logError("Failed to restore node", { e });
			return Result.fail("Failed to restore node");
		}
	}

	public async newParent(incomingParent: NodeModel, outgoingParent: NodeModel): Promise<Result<void>> {
		const incomingDocument = this.getDocument(incomingParent.id);
		const outgoingDocument = this.getDocument(outgoingParent.id);

		if (incomingParent.id === outgoingParent.id) {
			return this.updateNode(incomingDocument.id, { children: incomingParent.children });
		}

		try {
			await runTransaction(fb.firestore, async (transaction) => {
				transaction.update(incomingDocument, { children: incomingParent.children });
				transaction.update(outgoingDocument, { children: outgoingParent.children });
			});
			return Result.ok();
		} catch (exception) {
			this.errorService.logWarning("Failed to move node", { exception });
			return Result.fail("Failed to move node");
		}
	}
}

export const useNodesRepository = () => {
	const errorService = useErrorService();

	const nodesRepository = useMemo(() => new NodesRepository({ errorService }), [errorService]);

	return nodesRepository;
};
