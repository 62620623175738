import { z } from "zod";

import { SelectedValues } from "@/domains/nodes/components/details/meta/components/SelectedValues";
import { NodeMetaPersonOptions } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPersonOptions";
import { NodeMetaPersonValue } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPersonValue";
import { NodeMetaPersonValueUnassigned } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPersonValueUnassigned";
import { nodeMetaTypePerson } from "@/domains/nodes/dtos/nodeMetaDto";
import { SelectDropdown, SelectDropdownOptionsFn } from "@/shared/system/SelectDropdown/SelectDropdown";
import { Node, WithId } from "@/types/db";

type Props = {
	node: WithId<Node>;
	meta: z.infer<typeof nodeMetaTypePerson>;
};

export const NodeMetaPerson = ({ node, meta }: Props) => {
	const currentValue = node?.meta?.[meta.id];

	const memberList = Array.isArray(currentValue) ? currentValue : [];

	const placeholderText = `Change ${meta.label.toLowerCase()}...`;

	const values = (
		<SelectedValues values={memberList} empty={<NodeMetaPersonValueUnassigned />}>
			{memberList.map((userId) => {
				return <NodeMetaPersonValue userId={userId} key={userId} />;
			})}
		</SelectedValues>
	);

	const options: SelectDropdownOptionsFn = ({ close, inputEl, search }) => {
		return (
			<NodeMetaPersonOptions
				nodeId={node.id}
				metaId={meta.id}
				search={search}
				inputEl={inputEl}
				close={close}
				selected={memberList}
			/>
		);
	};

	return <SelectDropdown values={values} options={options} placeholderText={placeholderText} />;
};
