import styled from "styled-components";

import { Icon } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemFolderName";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Tooltip } from "@/shared/system";

export const IconPlaceholder: React.FC = () => {
	const { canEdit } = usePermissions("project");

	if (!canEdit) {
		return <FauxIcon />;
	}

	return (
		<Tooltip label="Add an icon">
			<FauxIcon isEditable={canEdit} />
		</Tooltip>
	);
};

const FauxIcon = styled(Icon)<{ isEditable?: boolean }>`
	--color: var(--border--dark-color);
	--hover-color: var(--color-accent);

	border: var(--border);
	width: 80px;
	height: 80px;
	line-height: 1;
	border-radius: 50%;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: var(--color);

	background: var(--gradient-striped);

	${({ isEditable }) =>
		isEditable &&
		`
            &:hover {
            color: var(--hover-color);
            border-color: var(--hover-color);
        }
    `}
`;
