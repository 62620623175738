import * as RadixMenu from "@radix-ui/react-dropdown-menu";
import { FiCheck } from "react-icons/fi";
import { VscTriangleDown } from "react-icons/vsc";
import styled from "styled-components";

import { Dropdown, DropdownContent, DropdownHr, DropdownMenuItem, DropdownTrigger } from "../../../shared/system";

type Option = {
	label: string;
	onClick: () => void;
	disabled?: boolean;
};

type Props = {
	currentRole: string;
	roles: Option[];
	options: Option[];
	disabled?: boolean;
};

export const RoleSelector = ({ currentRole, roles, options, disabled }: Props) => {
	const showDivider = roles.length > 0 && options.length > 0;

	return (
		<Dropdown data-testid="member-actions-dropdown">
			<Trigger disabled={disabled}>
				{currentRole} {!disabled && <VscTriangleDown />}
			</Trigger>
			<RadixMenu.Portal>
				<DropdownContent>
					{roles.map(({ label, disabled, onClick }) => (
						<DropdownMenuItem onSelect={onClick} key={label} disabled={disabled}>
							{label}
							{label.toLowerCase() === currentRole.toLowerCase() && (
								<Mark>
									<FiCheck />
								</Mark>
							)}
						</DropdownMenuItem>
					))}
					{showDivider && <DropdownHr />}
					{options.map(({ label, disabled, onClick }) => {
						return (
							<DropdownMenuItem onSelect={onClick} key={label} disabled={disabled}>
								{label}
							</DropdownMenuItem>
						);
					})}
				</DropdownContent>
			</RadixMenu.Portal>
		</Dropdown>
	);
};

const Trigger = styled(DropdownTrigger)`
	font-size: 14px;
	line-height: 1.1;
	text-transform: capitalize;

	> svg {
		margin-left: 8px;
	}
`;

const Mark = styled.div`
	position: absolute;
	right: 0;
	width: 20px;
	margin-right: 4px;

	> svg {
		display: block;
		margin: 0;
	}
`;
