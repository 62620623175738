import { useCallback } from "react";

import { FiTrash } from "react-icons/fi";
import { useSetRecoilState } from "recoil";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { boardDeleteState } from "@/modules/board/BoardConfirmDelete";
import { WithId } from "@/types/db";

import { DropdownMenuButton } from "../../../../shared/system";

type Props = {
	project: WithId<ProjectDtoBoard>;
	onClick: VoidFunction;
};

export const MoreArchive = ({ project, onClick }: Props) => {
	const handleDelete = useSetRecoilState(boardDeleteState);

	const handleArchiveBoard = useCallback(() => {
		handleDelete(project.id);
		onClick();
	}, [handleDelete, project.id, onClick]);

	return (
		<DropdownMenuButton onClick={handleArchiveBoard}>
			<FiTrash /> Delete project
		</DropdownMenuButton>
	);
};
