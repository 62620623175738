import { useCallback } from "react";

import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { WithId } from "@/types/db";

import { DropdownMenuButton } from "../../../../shared/system";

type Props = {
	board: WithId<ProjectDtoBoard>;
};

export const MoreSettings = ({ board }: Props) => {
	const navigate = useNavigate();
	const { canEdit } = usePermissions("project");

	const boardId = board.id;

	const handleClick = useCallback(() => {
		navigate(`/s/${boardId}`);
	}, [boardId, navigate]);

	const disabled = !canEdit;

	return (
		<DropdownMenuButton onClick={handleClick} disabled={disabled}>
			<FiSettings /> Project Settings
		</DropdownMenuButton>
	);
};
