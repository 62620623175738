import { arrayRemove, arrayUnion } from "firebase/firestore";
import { FiImage, FiList, FiMessageSquare, FiSmile } from "react-icons/fi";
import { TbHierarchy } from "react-icons/tb";
import styled from "styled-components";

import KeyResultsIcon from "@/domains/nodes/components/details/assets/keyResultsIcon.svg?react";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { useBoardActions } from "@/modules/board/hooks/useBoardActions";
import { DropdownHeader, Toggle } from "@/shared/system";
import { DetailsSections } from "@/types/db";

const sections = [
	{
		key: DetailsSections.Cover,
		icon: FiImage,
		label: "Cover Image",
	},
	{
		key: DetailsSections.Title,
		icon: FiSmile,
		label: "Page Title & Icon",
	},
	{
		key: DetailsSections.Meta,
		icon: FiList,
		label: "Meta tags",
	},
	{
		key: DetailsSections.Results,
		icon: KeyResultsIcon,
		label: "Key Results",
	},
	{
		key: DetailsSections.Children,
		icon: TbHierarchy,
		label: "Children",
	},
	{
		key: DetailsSections.Activity,
		icon: FiMessageSquare,
		label: "Discussion",
	},
];

type Props = {
	boardId: string;
};

export const NoteMoreLayoutOptions = ({ boardId }: Props) => {
	const { updateDetailsSectionVisibility } = useBoardActions();
	const project = useGetProject();
	const hiddenSections = project?.meta.hiddenDetailSectionKeys || [];

	return (
		<>
			<DropdownHeader>Details Layout</DropdownHeader>
			{sections.map((section) => {
				const Icon = section.icon;
				const isActive = !!hiddenSections && !hiddenSections.includes(section.key);

				const handleClick = async () => {
					const value = isActive ? arrayUnion(section.key) : arrayRemove(section.key);
					updateDetailsSectionVisibility(boardId, value);
				};

				return (
					<ToggleItem key={section.key}>
						<Label>
							<Icon />
							{section.label}
						</Label>
						<Toggle selected={isActive} size="small" onClick={handleClick} />
					</ToggleItem>
				);
			})}
		</>
	);
};

const Label = styled.div`
	display: flex;
	align-items: center;
	margin-right: auto;
`;

const ToggleItem = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 6px;
	border-radius: 4px;
	line-height: 22px;
	font-size: 14px;
	width: 100%;
	color: var(--color-text-heading);

	svg {
		display: block;
		margin-right: 8px;
	}
`;
