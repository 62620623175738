import { z } from "zod";

import { nodeMetaSelectValue, nodeMetaTypeList, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";

import { Node, WithId } from "../../../types/db";
import { transformValueToArray } from "../utils/transformValueToArray";

export const getNodeMetaSelectValue = (
	node: WithId<Node>,
	meta: z.infer<typeof nodeMetaTypeSelect> | z.infer<typeof nodeMetaTypeList>,
) => {
	const metaValues = node.meta && node.meta[meta.id];

	if (!metaValues || !meta) {
		return [];
	}

	//Does magic for bad data types
	const value = transformValueToArray(metaValues, meta.type);

	return value
		.map((metaValueId) => {
			return meta.value.find(({ id, title }) => id === metaValueId || title === metaValueId);
		})
		.filter(Boolean) as z.infer<typeof nodeMetaSelectValue>[];
};
