import React from "react";

import styled from "styled-components";

import TextWithEmoji from "../Text/TextWithEmoji";
import { utility } from "../theme";

type Props = {
	id: string;
	label: string;
	name: string;
	value: string;
	color?: string;
	type?: string;
	register?: any;
};

export default function Option(props: Props) {
	const { id, type = "checkbox", label, color, name, value, register = {} } = props;

	return (
		<Root color={color}>
			<Input type={type} id={id} value={value} {...register(name)} />
			<label htmlFor={id}>
				<TextWithEmoji text={label} />
			</label>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	align-items: center;
	text-transform: capitalize;

	${({ color, theme }) => {
		return {
			color: color ? utility.color(color)({ theme }) : "inherit",
		};
	}}
`;

export const Input = styled.input`
	margin: 0;
	margin-right: 8px;
	height: 14px;
	width: 14px;
	appearance: none;
	border: var(--border);
	border-radius: 2px;
	outline: none;
	background-color: transparent;
	transition-duration: 0.2s;
	background-position: 1px 1px;
	background-repeat: no-repeat;
	cursor: pointer;

	&:checked {
		border: 1px solid var(--color-accent);
		background-color: var(--color-accent);
		background-image: url("data:image/svg+xml;utf8,%3Csvg%20width=%2210%22%20height=%229%22%20viewBox=%220%200%2010%208%22%20xmlns=%22http://www.w3.org/2000/svg%22%20fill=%22%23FFFFFF%22%3E%3Cpath%20d=%22M3.46975%205.70757L1.88358%204.1225C1.65832%203.8974%201.29423%203.8974%201.06897%204.1225C0.843675%204.34765%200.843675%204.7116%201.06897%204.93674L3.0648%206.93117C3.29006%207.15628%203.65414%207.15628%203.8794%206.93117L8.93103%201.88306C9.15633%201.65792%209.15633%201.29397%208.93103%201.06883C8.70578%200.843736%208.34172%200.843724%208.11646%201.06879C8.11645%201.0688%208.11643%201.06882%208.11642%201.06883L3.46975%205.70757Z%22%20stroke-width=%220.2%22%20/%3E%3C/svg%3E");
	}

	&:active {
		border: 2px solid #34495e;
	}
`;
