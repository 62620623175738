import styled from "styled-components";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { TreeView } from "@/domains/projects/components/treeView/TreeView";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";

import { BoardSeenBy } from "../../dashboard/components/BoardSeenBy";
import { useNavigationShortcuts } from "../hooks/useNavigationShortcuts";

const Nodes = () => {
	const board = useGetProject();
	const isNodeMetaLoaded = useNodeMetaStore(({ meta }) => meta.size > 0);

	useNavigationShortcuts();

	if (!board || !isNodeMetaLoaded) {
		return null;
	}

	return (
		<>
			<TreeView />
			<SeenByList>
				<BoardSeenBy seenBy={board.seenBy} size={28} />
			</SeenByList>
		</>
	);
};

const SeenByList = styled.div`
	position: absolute;
	bottom: 24px;
	left: 24px;
`;

export default Nodes;
