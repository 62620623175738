import { useCallback, useState } from "react";

import { FiInbox } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { getUnreadMessageCount } from "@/modules/notifications/notificationsData";

import { InboxModal } from "./components/InboxModal";

export function Inbox() {
	const [isModalOpen, setModalOpen] = useState(false);
	const notificationsCount = useRecoilValue(getUnreadMessageCount);

	const handleClick = useCallback(() => setModalOpen(true), []);

	return (
		<>
			<Trigger onClick={handleClick}>
				<FiInbox />
				Inbox ({notificationsCount})
			</Trigger>
			<InboxModal isOpen={isModalOpen} setOpen={setModalOpen} />
		</>
	);
}

const Trigger = styled.button`
	display: flex;
	align-items: center;
	text-align: left;
	background: none;
	border: 0;
	font-size: inherit;
	color: inherit;
	padding: 0;
	padding: 8px 12px;
	border-radius: 4px;
	cursor: pointer;
	color: var(--color-text-heading);
	width: 100%;

	> svg {
		margin-right: 8px;
	}

	&.selected,
	&:focus {
		background-color: var(--color-hover);
		outline: none;
	}

	&:hover:not(:disabled) {
		background-color: var(--color-hover);
	}
`;
