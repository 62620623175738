import { useCallback } from "react";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { useNotesRepository } from "@/domains/nodes/hooks/useNotesRepository";
import { NoteUtils } from "@/domains/nodes/utils/noteUtils";
import { Result } from "@/shared/utils/result";

export interface INotesService {
	createEmptyNote(): Promise<Result<void>>;
}

export const useNotesService = (nodeId: string): INotesService => {
	const nodeRepository = useNotesRepository(nodeId);
	const getNode = useNodesStore((state) => state.getNode);

	const createEmptyNote: INotesService["createEmptyNote"] = useCallback(async () => {
		const nodeOrError = getNode(nodeId);

		if (nodeOrError.isFailure) {
			return Result.fail("Unable to create note: node not found");
		}

		const node = nodeOrError.getValue();

		const note = NoteUtils.create(nodeId, node.workspace);
		return nodeRepository.createNote(note);
	}, [nodeRepository, getNode, nodeId]);

	return {
		createEmptyNote,
	};
};
