import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";

const sensorOptions = {
	activationConstraint: {
		distance: 15,
	},
};

type Props = {
	onDrop?: VoidFunction;
};

export const SortableListContext: React.FC<Props> = ({ children, onDrop = () => {} }) => {
	const mouseSensor = useSensor(MouseSensor, sensorOptions);
	const touchSensor = useSensor(TouchSensor, sensorOptions);

	const sensors = useSensors(mouseSensor, touchSensor);

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDrop}>
			{children}
		</DndContext>
	);
};
