import { useCallback, useRef, useState } from "react";

import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";

import { NodeListAdd } from "@/domains/nodes/components/lists/components/NodeListAdd";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { ChildNodeAdd } from "@/modules/note/childNodes/components/ChildNodeAdd";

const handleStyle = { border: 0, background: "transparent" };

export const TreeNodeListAdd: React.FC<NodeProps<NodeModel>> = ({ data }) => {
	const nodeRef = useRef<HTMLDivElement>(null);
	const node = data;

	const [showAddNode, setShowAddNode] = useState(false);

	const handleAddNode = useCallback(() => {
		setShowAddNode(true);
	}, []);

	const handleCancelAddNode = useCallback(() => {
		setShowAddNode(false);
	}, []);

	return (
		<>
			<Handle type="target" id={`target-${node.id}`} position={Position.Top} style={handleStyle} />
			<ListCard ref={nodeRef}>
				<Footer>
					{!showAddNode && <ChildNodeAdd onClick={handleAddNode} text="Add Node" />}
					{showAddNode && <NodeListAdd parent={node} onCancel={handleCancelAddNode} />}
				</Footer>
			</ListCard>
		</>
	);
};

const ListCard = styled.div`
	position: relative;
	border-radius: 4px;
	width: 500px;
`;

const Footer = styled.div`
	> button {
		padding-bottom: 0;
	}
`;
