import { useCallback } from "react";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { ImageLink, Props as ImageLinkProps } from "@/modules/note/coverImage/components/ImageLink";

type Props = {
	node: NodeModel;
	onComplete: VoidFunction;
};

export const IconImageLink: React.FC<Props> = ({ node, onComplete }) => {
	const nodesService = useNodesService();

	const nodeId = node.id;

	const onSubmit: ImageLinkProps["onSubmit"] = useCallback(
		({ src }) => {
			const icon = { type: "image" as const, src, local: false };
			nodesService.update(nodeId, { icon });
			onComplete();
		},
		[nodeId, nodesService, onComplete],
	);

	return <ImageLink onSubmit={onSubmit} />;
};
