import { FiCopy } from "react-icons/fi";

import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { DropdownMenuButton } from "../../../shared/system";
import { useToast } from "../../toast/hooks/useToast";
import { useDuplicateNode } from "../hooks/useDuplicateNode";

type Props = {
	node: NodeModel;
	close: VoidFunction;
};

export const NoteMoreDuplicate = ({ node, close }: Props) => {
	const duplicateNode = useDuplicateNode(node);
	const setToast = useToast();

	const handleDuplicate = () => {
		duplicateNode();
		setToast({ status: "success", message: "Tree successfully cloned" });
		close();
	};

	return (
		<DropdownMenuButton onClick={handleDuplicate}>
			<FiCopy /> Duplicate
		</DropdownMenuButton>
	);
};
