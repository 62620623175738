import { arrayRemove, arrayUnion } from "firebase/firestore";

import { Command } from "../../../shared/core/hooks/useActionHistory";
import { updateBoard } from "../boardFunctions";

type AddProjectToFolder = (args: { folderId: string; projectId: string }) => Command;

export const addProjectToFolder: AddProjectToFolder = ({ folderId, projectId }) => {
	const action = () => {
		updateBoard(folderId, {
			childIds: arrayUnion(projectId),
		});
	};
	const undo = () => {
		updateBoard(folderId, {
			childIds: arrayRemove(projectId),
		});
	};

	return new Command({ name: "Add project to folder", action, undo, value: undefined });
};
