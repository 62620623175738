import { selector } from "recoil";

import { userPresenceState } from "../../presence/hooks/useUserPresence";
import { getCurrentWorkspace } from "../../workspace/selectors/getCurrentWorkspace";
import { profileState } from "./profileState";

export const getProfileWithPresence = selector({
	key: "getProfileWithPresence",
	get: ({ get }) => {
		const profile = get(profileState);
		const workspace = get(getCurrentWorkspace);
		const usersPresence = get(userPresenceState(workspace?.id));
		const presence = usersPresence.find((_) => _.id === profile?.id);
		return {
			...profile,
			...presence,
		};
	},
});
