import { NodeContainer } from "@/domains/nodes/components/cards/components/NodeContainer";
import { NodeLayoutBackground } from "@/domains/nodes/components/cards/components/NodeLayoutBackground";
import { NodeLayoutIcon } from "@/domains/nodes/components/cards/components/NodeLayoutIcon";
import { NodeLayoutStacked } from "@/domains/nodes/components/cards/components/NodeLayoutStacked";
import { INodeCard } from "@/domains/nodes/components/cards/nodeCardTypes";

export const Node: React.FC<INodeCard> = ({ node }) => {
	const variant = node.layout || "stacked";

	const isBackground = variant === "background";
	const isIcon = variant === "icon";
	const isDefault = variant === "stacked";

	const containerVariant = !isIcon ? "highlight" : "plain";

	return (
		<NodeContainer node={node} variant={containerVariant} className="draggable">
			{isBackground && <NodeLayoutBackground node={node} />}
			{isIcon && <NodeLayoutIcon node={node} />}
			{isDefault && <NodeLayoutStacked node={node} />}
		</NodeContainer>
	);
};
