import { MenuNodeOptions } from "@/domains/nodes/components/menu/components/MenuNodeOptions";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

interface Props {
	rootEl: React.RefObject<HTMLDivElement>;
}

export const NodeContextMenu: React.FC<Props> = ({ rootEl }) => {
	const { canEdit } = usePermissions("project");

	const focusedNodeOrError = useNodesStore((state) => state.getFocusedNode());
	const isEditing = useNodesStore((state) => state.getIsEditing());

	if (isEditing || !canEdit || focusedNodeOrError.isFailure) {
		return null;
	}

	const node = focusedNodeOrError.getValue();

	return <MenuNodeOptions variant="context-menu" node={node} rootEl={rootEl} />;
};
