const doc = (...nodes: any[]) => ({
	blocks: nodes,
	time: 1607029375931,
	version: "2.19.0",
});

const textNodesMap = {
	h1: { level: 1, type: "header" },
	h2: { level: 2, type: "header" },
	h3: { level: 3, type: "header" },
	h4: { level: 4, type: "header" },
	h5: { level: 5, type: "header" },
	h6: { level: 6, type: "header" },
	p: { level: null, type: "paragraph" },
};

const textBlock =
	({ type, level }: any) =>
	(text: any) => {
		return {
			data: {
				level,
				text,
			},
			type,
		};
	};

const textNodesFunctionsMap = Object.keys(textNodesMap).reduce(
	(carry, type) => ({
		...carry,
		// @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		[type]: textBlock(textNodesMap[type]),
	}),
	{},
);

const editorNodes = {
	...textNodesFunctionsMap,
	// @ts-expect-error TS(7006) FIXME: Parameter 'items' implicitly has an 'any' type.
	list: (items) => {
		return {
			data: {
				items,
				style: "unordered",
			},
			type: "list",
		};
	},
	// @ts-expect-error TS(7006) FIXME: Parameter 'items' implicitly has an 'any' type.
	taskList: (items) => {
		return {
			type: "checklist",
			data: {
				items,
			},
		};
	},
};

const getParentPath = () => {
	const pathname = window.location.pathname;
	return pathname?.substring(0, pathname.indexOf("/", 1));
};

export { doc, editorNodes, getParentPath };
