import { UseFormRegister } from "react-hook-form";

import { KeyResultsActivityFormFields } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { InputGroup, Label, Option } from "@/shared/system";

type BuildOption = (args: { value: string; label: string; color: string }) => JSX.Element;

type Props = {
	register: UseFormRegister<KeyResultsActivityFormFields>;
	errors: any;
};

export const InputStatus: React.FC<Props> = ({ register }) => {
	const key = "status";

	const buildOption: BuildOption = ({ value, label }) => (
		<Option id={value} value={value} name={`${key}`} register={register} label={label} type="radio" />
	);

	return (
		<div>
			<Label htmlFor={key}>Status</Label>
			<InputGroup>
				{buildOption({ value: "onTrack", label: "On Track", color: "green" })}
				{buildOption({ value: "atRisk", label: "Unsure", color: "yellow" })}
				{buildOption({ value: "offTrack", label: "Off Track", color: "red" })}
			</InputGroup>
		</div>
	);
};
