import { useState } from "react";

import { FiChevronLeft } from "react-icons/fi";
import styled from "styled-components";

import { SidebarRight } from "../../../../../shared/system";
import { HelpActionsList } from "./HelpActionsList";
import { HelpFooter } from "./HelpFooter";
import { HelpKeyboardShortcuts } from "./HelpKeyboardShortcuts";

type Props = {
	onClose: (name: string | null) => void;
};

export const HelpContainer = ({ onClose }: Props) => {
	const [currentPane, setPane] = useState<"home" | "shortcuts">("home");

	const isHome = currentPane === "home";
	const isShortcuts = currentPane === "shortcuts";

	const handleGoBack = () => setPane("home");

	return (
		<SidebarRight onClose={onClose}>
			<Header>
				{!isHome && (
					<BackButton onClick={handleGoBack}>
						<FiChevronLeft />
					</BackButton>
				)}
				{isHome && <HeaderText>Help Center</HeaderText>}
				{isShortcuts && <HeaderText>Keyboard shortcuts</HeaderText>}
			</Header>
			<Content>
				{isHome && <HelpActionsList setPane={setPane} />}
				{isShortcuts && <HelpKeyboardShortcuts />}
			</Content>
			<Footer>
				<HelpFooter />
			</Footer>
		</SidebarRight>
	);
};

const Header = styled.div`
	padding: 24px;
	display: flex;
	align-items: center;
`;

export const HeaderText = styled.h2`
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	margin: 0;
`;

const BackButton = styled.button`
	border: 0;
	margin: 0;
	padding: 0;
	margin-right: 6px;
	background: transparent;
	font-size: 18px;
	color: var(--color-text-secondary);
	cursor: pointer;
	line-height: 0.5;

	:focus {
		outline: 0;
	}
`;

const Content = styled.div`
	overflow: auto;
	padding: 24px;
	padding-top: 0;
`;

const Footer = styled.div`
	margin-top: auto;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 24px;
`;
