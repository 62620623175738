import React from "react";

import { Trigger } from "@radix-ui/react-dropdown-menu";
import { VscTriangleDown } from "react-icons/vsc";
import styled, { css } from "styled-components";

type Props = {
	disabled?: boolean;
	chevron?: boolean;
};

export const DropdownTrigger: React.FC<Props> = ({ children, chevron, disabled = false, ...menuButtonProps }) => {
	const Icon = VscTriangleDown;
	return (
		<Button disabled={disabled} {...menuButtonProps}>
			<>
				{children}
				{chevron && <Icon />}
			</>
		</Button>
	);
};

const triggerStyle = css`
	display: flex;
	align-items: center;
	font-size: inherit;
	text-align: left;
	background: transparent;
	border: 0;
	padding: 0;
	line-height: 1.6;
	cursor: pointer;

	:active,
	:focus {
		outline: 0;
	}

	> svg {
		font-size: 0.8em;
	}
`;

const Button = styled(Trigger)`
	${triggerStyle};
`;
