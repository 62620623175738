import { useEffect } from "react";

import { onSnapshot, orderBy, query } from "firebase/firestore";
import { atom, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractDataFromCollection } from "../../../shared/utils/dataUtils";
import { User } from "../../../types/db";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export const membersState = atom<Record<string, User>>({
	key: "members",
	default: {},
});

export const useMembersListener = () => {
	const workspace = useActiveWorkspace();
	const [, setMembers] = useRecoilState(membersState);

	const workspaceId = workspace?.id;

	useEffect(() => {
		if (workspaceId) {
			const membersQuery = query(db.users, orderBy(`access.${workspaceId}`));

			return onSnapshot(
				membersQuery,
				(snapshot) => {
					const { byId } = extractDataFromCollection(snapshot);
					setMembers(byId);
				},
				onSnapshotError("useMembersListener"),
			);
		}
	}, [setMembers, workspaceId]);
};
