import { SyntheticEvent } from "react";

import styled from "styled-components";

import { Button, Tooltip } from "@/shared/system";

type Props = {
	icon: any;
	tooltip: string;
	onClick: (e: SyntheticEvent) => void;
};

export const SidebarNavigationItemButton = ({ onClick, icon, tooltip }: Props) => {
	const Icon = icon;

	return (
		<Tooltip label={tooltip}>
			<Action onClick={onClick}>
				<Icon />
			</Action>
		</Tooltip>
	);
};

const Action = styled(Button.Plain)`
	padding: 3px;
	font-size: 12px;
	color: inherit;
	border-radius: 4px;

	:hover {
		background: var(--color-hover) !important;
	}
`;
