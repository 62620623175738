import { AnimatePresence, motion } from "framer-motion";

import { useActivity } from "@/domains/nodes/components/details/activity/hooks/useActivity";

import { Node } from "../../../../../types/db";
import { WithId } from "../../../../../types/db/utils";
import { ActivityCluster } from "./components/ActivityCluster";

const animationProps = {
	initial: { height: 0, opacity: 0 },
	transition: { type: "spring", duration: 0.3, bounce: 0.2 },
	animate: { height: "auto", opacity: 1 },
};

type Props = {
	node: WithId<Node>;
	canComment: boolean;
};

export const NodeActivity = ({ node, canComment }: Props) => {
	const activity = useActivity(node.id);

	const activityLoaded = activity.length > 0;

	if (!activityLoaded) {
		return null;
	}

	const isLast = (index: number) => !canComment && index === activity.length - 1;

	return (
		<AnimatePresence initial={false}>
			{activity.map((activity, index) => {
				const lastItem = isLast(index);
				return (
					<motion.div
						animate={animationProps.animate}
						initial={animationProps.initial}
						transition={animationProps.transition}
						exit={animationProps.initial}
						key={activity.id}>
						<ActivityCluster activity={activity} lastItem={lastItem} />
					</motion.div>
				);
			})}
		</AnimatePresence>
	);
};
