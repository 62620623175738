import { doc, QuerySnapshot } from "firebase/firestore";

import { NotesDto, notesDto } from "@/domains/nodes/dtos/notesDto";
import { db } from "@/shared/infra/init";
import { Result } from "@/shared/utils/result";
import { Note, WithId } from "@/types/db";

type NoteModel = WithId<Note>;

export class NoteUtils {
	public static createNodeNoteId(nodeId: string): string {
		return doc(db.nodeNotes(nodeId)).id;
	}

	public static create(nodeId: string, workspaceId: string) {
		const id = NoteUtils.createNodeNoteId(nodeId);

		const document = notesDto.parse({
			workspace: workspaceId,
			bodySchemaVersion: "4",
			body: JSON.stringify({ type: "doc", content: [{ type: "paragraph" }] }),
		});

		return {
			id,
			...document,
		};
	}

	public static toViewModel(snapshot: QuerySnapshot<NotesDto>): Result<NoteModel> {
		const noteDocument = snapshot.docs[0];

		if (!noteDocument) {
			return Result.fail<NoteModel>("Failed to get note for node");
		}

		return Result.ok<NoteModel>({
			id: noteDocument.id,
			...noteDocument.data(),
		});
	}
}
