import styled from "styled-components";

import { DatePickerSelectedValue } from "@/domains/nodes/components/details/meta/types/date/DatePickerSelectedValue";
import { Truncate } from "@/shared/system";

import { NodeMetaPersonValue } from "../../../domains/nodes/components/details/meta/types/person/NodeMetaPersonValue";
import { NodeMetaSelectValue } from "../../../domains/nodes/components/details/meta/types/select/NodeMetaSelectValue";
import { NodeMetaAllTypes, NodeMetaDataDateTypes, NodeMetaDataTypes } from "../../../types/db";
import { transformValueToArray } from "../utils/transformValueToArray";

interface Props {
	meta: { value: NodeMetaDataTypes; definition: NodeMetaAllTypes };
	showLabel: boolean;
}

export const CardMetaValue = ({ meta, showLabel }: Props) => {
	const { definition, value } = meta;

	if (!value) {
		return null;
	}

	const label = showLabel && <Definition>{definition.label}:</Definition>;

	switch (definition.type) {
		case "select":
		case "list": {
			const tagIds = transformValueToArray(value, definition.type);

			return (
				<>
					{tagIds.map((tagId) => {
						const metaValue = definition.value.find(({ id }) => id === tagId);
						const metaString = metaValue?.title || "";
						return (
							metaString && (
								<TagSpacer key={tagId}>
									{label}
									<NodeMetaSelectValue color={metaValue?.color} value={metaString} />
								</TagSpacer>
							)
						);
					})}
				</>
			);
		}
		case "person": {
			const userIds = Array.isArray(value) ? value : [];

			return (
				<TagSpacer>
					{label}
					{Array.isArray(userIds) && userIds.map((userId) => <NodeMetaPersonValue userId={userId} key={userId} />)}
				</TagSpacer>
			);
		}
		case "date":
		case "dateRange": {
			const date = value as NodeMetaDataDateTypes;

			return (
				<TagSpacer>
					{label}
					<DatePickerSelectedValue value={date} dateFormat={definition.value.format} />
				</TagSpacer>
			);
		}
		case "link": {
			const link = typeof value === "string" ? value : "";

			try {
				const domain = new URL(link);
				const shortDomain = domain.hostname.replace("www.", "");

				return (
					<TagSpacer>
						{label}

						<a href={domain.toString()} target="_blank" rel="nofollow noreferrer" onClick={(e) => e.stopPropagation()}>
							{shortDomain}
						</a>
					</TagSpacer>
				);
			} catch (e) {
				return (
					<TagSpacer>
						{label}
						<LongText>{link}</LongText>
					</TagSpacer>
				);
			}
		}
		case "text":
		case "email": {
			const text = typeof value === "string" ? value : "";

			return (
				<TagSpacer>
					{label}
					<LongText>{text}</LongText>
				</TagSpacer>
			);
		}
		default: {
			const _exhaustiveCheck: never = definition;
			return _exhaustiveCheck;
		}
	}
};

const LongText = styled.div`
	word-break: break-all;
`;

const TagSpacer = styled(Truncate)`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 6px;

	:last-child {
		margin-bottom: 0;
	}
`;

const Definition = styled.div`
	display: flex;
	flex-shrink: 0;
	margin-right: 12px;
	align-items: center;
	font-style: italic;

	svg {
		margin-right: 6px;
	}
`;
