import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import { profileState } from "@/modules/profile/selectors/profileState";
import { Result } from "@/shared/utils/result";
import { PublicProfile, User } from "@/types/db";

type ConstructorArgs = {
	profile?: User;
};

export class ProfileService {
	profile;

	constructor({ profile }: ConstructorArgs) {
		this.profile = profile;
	}

	getPublicProfile(): Result<PublicProfile> {
		if (!this.profile) {
			return Result.fail("Unable to get profile");
		}
		const { username, photoURL = null, id } = this.profile;

		return Result.ok({
			username,
			photoURL,
			id,
		});
	}
}

export const useProfileService = () => {
	// TODO: This should access state via an adapter rather than querying recoil directly
	const profile = useRecoilValue(profileState);
	const profileService = useMemo(() => new ProfileService({ profile }), [profile]);

	return profileService;
};
