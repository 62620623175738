import { KeyResultsDetailsListCard } from "@/domains/nodes/components/keyResults/components/details/components/KeyResultDetailsListCard";
import { KeyResultsDetailsListEmpty } from "@/domains/nodes/components/keyResults/components/details/components/KeyResultsDetailsListEmpty";
import { KeyResultsList } from "@/domains/nodes/components/keyResults/components/KeyResultsList";
import { KeyResultModel } from "@/types/db";

type Props = {
	onAdd: VoidFunction;
};

export const KeyResultsDetailsList: React.FC<Props> = ({ onAdd }) => {
	const renderCard = (keyResult: KeyResultModel) => <KeyResultsDetailsListCard keyResult={keyResult} key={keyResult.id} />;
	const renderEmpty = () => <KeyResultsDetailsListEmpty onAdd={onAdd} />;

	return <KeyResultsList render={renderCard} renderEmpty={renderEmpty} />;
};
