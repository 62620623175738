import { atomFamily } from "recoil";

import { Filters } from "@/modules/board/filter/filterModel";

type BoardId = string;

export const filtersState = atomFamily<Filters, BoardId>({
	key: "filters",
	default: [],
});
