import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Input, Label } from "../../../../shared/system";

type FormValues = {
	src: string;
};

export type Props = {
	onSubmit: SubmitHandler<FormValues>;
};

export const ImageLink = ({ onSubmit }: Props) => {
	const { register, handleSubmit } = useForm<FormValues>();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Label htmlFor="src">Image URL</Label>
			<Input placeholder="Paste a link to an image" mb="20px" id="src" {...register("src", { required: true })} />
			<Button type="submit" rounded width="100%">
				Save
			</Button>
		</form>
	);
};
