import React, { MutableRefObject } from "react";

import { Box, Link } from "../../shared/system";
import Callout from "../home/components/Callout";

interface Props {
	calloutRef?: MutableRefObject<null>;
}

// Starting only takes a minute.

export const FooterCta = ({ calloutRef }: Props) => {
	return (
		<Box pt="48px" pb={[4, 6]}>
			<Callout
				title="Your roadmap is only minutes away"
				subtitle={
					<span>
						Empower your team with outcome driven roadmaps in just a few clicks, you’ve got nothing to loose. Still have
						questions? <Link.Href href="mailto: support@poda.io">Lets chat</Link.Href>.
					</span>
				}
				refs={calloutRef}
			/>
		</Box>
	);
};
