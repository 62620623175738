import React from "react";

import Avatar from "../Avatar/Avatar";
import Flex from "../Flex/Flex";

export const AvatarList = ({
	users,
	small = true,
	userCap = 1,
	size,
	vertical = false,
	className,
	AvatarComponent = Avatar,
}: any) => {
	if (users.length === 0) {
		return null;
	}
	const showCount = users.length > userCap;
	const avatarUsers = showCount ? users.slice(0, userCap) : users;

	const countAdditionalMembers = users.length - userCap;

	return (
		<Flex
			alignItems="center"
			justifyContent="flex-end"
			flexDirection={vertical ? "column-reverse" : "row-reverse"}
			className={className}>
			{showCount && (
				// @ts-expect-error TS(2769) FIXME: No overload matches this call.
				<Flex mb={vertical && -2} mr={!vertical && -2}>
					<Avatar small={small} size={size} user={{ username: `+ ${countAdditionalMembers}`, state: null }} />
				</Flex>
			)}
			{/* @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type. */}
			{avatarUsers.map((user, index) => (
				// @ts-expect-error TS(2769) FIXME: No overload matches this call.
				<Flex mb={vertical && -2} mr={!vertical && -2} key={index}>
					<AvatarComponent small={small} size={size} user={user} />
				</Flex>
			))}
		</Flex>
	);
};

export default AvatarList;
