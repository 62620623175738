import { IconType } from "react-icons";
import {
	FiArrowDown,
	FiArrowLeft,
	FiArrowRight,
	FiCopy,
	FiEdit2,
	FiEye,
	FiEyeOff,
	FiGitPullRequest,
	FiLoader,
	FiSun,
	FiSunset,
	FiTag,
	FiTrash,
} from "react-icons/fi";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";
import { getNextNodeTypeId } from "@/modules/nodeMeta/utils/getNextNodeTypeId";

import { NodeMetaSelect, NodeMetaSystemLabels } from "../../../types/db";
import { usePublicProfile } from "../../profile/hooks/usePublicProfile";
import { useCloneSubTree } from "./useCloneSubTree";

export type ContextMenuItem = {
	key: string;
	icon?: IconType;
	text: string;
	onClick: (nodeId: string, item: ContextMenuItem) => void;
	items?: ContextMenuItem[];
	kbd?: VoidFunction;
	disabled?: boolean;
};

type BuildContextMenuItemChild = (
	action: any,
	metaId: string | undefined,
) => (itemType: { id: string; title: string }, index: number) => ContextMenuItem;

const buildNodeTypeOptions: BuildContextMenuItemChild =
	(updateMeta, metaId) =>
	({ id, title }, index) => {
		return {
			key: id,
			text: title,
			onClick: (nodeId, { key: id }) => {
				metaId && id && updateMeta(nodeId, metaId, id);
			},
			kbd: () => (
				<>
					<kbd>⇧</kbd>
					<kbd>⎇</kbd>
					<kbd>{index + 1}</kbd>
				</>
			),
		};
	};

const buildStatusOptions: BuildContextMenuItemChild =
	(updateMeta, metaId) =>
	({ id, title }, index) => {
		return {
			key: id,
			text: title,
			onClick: (nodeId, { key: id }) => {
				metaId && id && updateMeta(nodeId, metaId, id);
			},
			kbd: () => (
				<>
					<kbd>⌘</kbd>
					<kbd>⎇</kbd>
					<kbd>{index + 1}</kbd>
				</>
			),
		};
	};

export const useNodeContextMenu = (nodeId: string): ContextMenuItem[][] => {
	const nodesService = useNodesService();
	const treeService = useTreeService();
	const profile = usePublicProfile();
	const cloneSubTree = useCloneSubTree();
	const { setNodeFilter } = useFilterService();

	const nodeOrError = useNodesStore((state) => state.getNode(nodeId));
	const collapsedNodeIds = useNodesStore((state) => state.collapsedNodeIds);

	const nodeTypeOrError = useNodeMetaStore((state) => state.getByLabel(NodeMetaSystemLabels.nodeType));
	const statusOrError = useNodeMetaStore((state) => state.getByLabel(NodeMetaSystemLabels.status));

	const parentOrError = treeService.getParent(nodeId);

	const parent = parentOrError.isSuccess && parentOrError.getValue().data.document;
	const nodeTypes = nodeTypeOrError.isSuccess && (nodeTypeOrError.getValue() as NodeMetaSelect);
	const status = statusOrError.isSuccess && (statusOrError.getValue() as NodeMetaSelect);

	const nodeTypeId = nodeTypes && nodeTypes.id;
	const nodeTypeValues = nodeTypes ? nodeTypes.value : [];

	const statusId = status && status.id;
	const statusValues = status ? status.value : [];

	if (nodeOrError.isFailure) {
		return [];
	}

	const node = nodeOrError.getValue();

	const isCollapse = collapsedNodeIds.includes(nodeId);
	const isPathHighlighted = node.highlighted;

	const handleAddNode =
		(position: "end" | "left" | "right" | "start" | undefined): ContextMenuItem["onClick"] =>
		() => {
			const nodeRef = position === undefined ? node : parent;

			if (!nodeRef || !nodeTypeId || !nodeTypes) {
				return;
			}

			nodesService.localAdd({
				parentId: nodeRef.id,
				data: {
					meta: { [nodeTypeId]: getNextNodeTypeId(nodeTypes, nodeRef) },
				},
				position: {
					activeId: nodeId,
					position: position,
				},
			});
		};

	if (!profile) {
		return [];
	}

	return [
		[
			...(nodeTypeId
				? [
						{
							key: "nodeType",
							icon: FiTag,
							text: "Node Types",
							items: nodeTypeValues.slice(0, 8).map(buildNodeTypeOptions(nodesService.updateMeta, nodeTypeId)),
							onClick: () => {},
						},
					]
				: []),
			...(statusId
				? [
						{
							key: "status",
							text: "Status",
							icon: FiLoader,
							items: statusValues.slice(0, 8).map(buildStatusOptions(nodesService.updateMeta, statusId)),
							onClick: () => {},
						},
					]
				: []),

			{
				key: "rename",
				text: "Rename",
				icon: FiEdit2,
				onClick: (nodeId: any) => nodesService.localRename(nodeId),
				kbd: () => (
					<>
						<kbd>r</kbd>
					</>
				),
			},
			{
				key: "collapse",
				text: isCollapse ? "Expand" : "Collapse",
				icon: isCollapse ? FiEye : FiEyeOff,
				disabled: !node || node.children.length === 0,
				onClick: () => {
					nodesService.collapseNode(node.id);
				},
				kbd: () => (
					<>
						<kbd>h</kbd>
					</>
				),
			},
		],
		[
			{
				key: "promote",
				text: "Promote to project",
				icon: FiGitPullRequest,
				onClick: () => {
					setNodeFilter(node);
				},
				kbd: () => (
					<>
						<kbd>⇧</kbd>
						<kbd>p</kbd>
					</>
				),
			},
			{
				key: "highlight",
				text: isPathHighlighted ? "Dim Path" : "Highlight Path",
				icon: isPathHighlighted ? FiSunset : FiSun,
				onClick: () => {
					nodesService.setHighlightPath(node.id, !isPathHighlighted);
				},
				kbd: () => (
					<>
						<kbd>⌘</kbd>
						<kbd>h</kbd>
					</>
				),
			},
		],
		[
			{
				key: "duplicate",
				text: "Duplicate",
				icon: FiCopy,
				onClick: () => cloneSubTree(node, parent || undefined),
				kbd: () => null,
			},
			{
				key: "addLeft",
				text: "Add node to left",
				icon: FiArrowLeft,
				onClick: handleAddNode("left"),
				kbd: () => (
					<>
						<kbd>⌘</kbd>
						<kbd>←</kbd>
					</>
				),
			},
			{
				key: "addRight",
				text: "Add node to right",
				icon: FiArrowRight,
				onClick: handleAddNode("right"),
				kbd: () => (
					<>
						<kbd>⌘</kbd>
						<kbd>→</kbd>
					</>
				),
			},
			{
				key: "addChild",
				text: "Add node as child",
				icon: FiArrowDown,
				onClick: handleAddNode(undefined),
				kbd: () => (
					<>
						<kbd>⌘</kbd>
						<kbd>↓</kbd>
					</>
				),
			},
		],
		[
			{
				key: "delete",
				icon: FiTrash,
				text: "Delete",
				onClick: (nodeId: any) => nodesService.archive(nodeId),
				kbd: () => (
					<>
						<kbd>⌘</kbd>
						<kbd>⌫</kbd>
					</>
				),
			},
		],
	];
};
