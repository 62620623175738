import styled from "styled-components";
import { borderRadius, BorderRadiusProps, display, DisplayProps, space, SpaceProps } from "styled-system";

export const Image = styled.img<SpaceProps & DisplayProps & BorderRadiusProps>`
	max-width: 100%;
	max-height: 100%;
	${space}
	${display}
	${borderRadius}
`;
export default Image;
