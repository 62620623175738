import { useEffect } from "react";

import { useSetRecoilState } from "recoil";

import { useAuthenticationStatus } from "../../../authentication/hooks/useAuthenticationStatus";
import { filtersState } from "../../filter/filterState";
import { metaAsyncState, metaCanvasState, metaCollapsedState } from "../dataMeta";
import { getBoardMeta } from "../metaFunctions";

/**
 * TODO: This could be refactored to not include the boardId in the hook, as it is refetching the whole
 * document (all boards) each time the boardId changes anyway.
 *
 * TODO: This should be converted to a recoil selector
 */

export const useGetBoardMeta = (boardId: string) => {
	const userId = useAuthenticationStatus();
	const setCollapsed = useSetRecoilState(metaCollapsedState);

	const setCanvas = useSetRecoilState(metaCanvasState(boardId));
	const setAsyncMetaState = useSetRecoilState(metaAsyncState(boardId));
	const setFiltersState = useSetRecoilState(filtersState(boardId));

	useEffect(() => {
		if (userId) {
			const handleGetBoardMeta = async () => {
				const boardMeta: any = await getBoardMeta(userId);

				if (boardMeta?.boards?.[boardId]) {
					setCanvas(boardMeta.boards[boardId]);
				}
				if (boardMeta?.collapsed) {
					setCollapsed(boardMeta.collapsed);
				}
				if (boardMeta?.filters?.[boardId]) {
					setFiltersState(boardMeta.filters[boardId]);
				}
			};
			handleGetBoardMeta();
		}
	}, [boardId, setCanvas, setCollapsed, setFiltersState, userId]);

	useEffect(() => {
		return () => {
			setAsyncMetaState();
		};
	}, [setAsyncMetaState]);
};
