import { VictoryStyleInterface } from "victory";

import theme from "@/shared/system/theme";

import { keyResultStatusMap } from "./keyResultStatusMap";

export const chartPadding = { top: 12, bottom: 50, left: 40, right: 0 };

export const lineStyle = {
	data: {
		stroke: "var(--color-accent)",
		strokeWidth: 3,
	},
};

export const areaStyle = {
	data: {
		fill: "var(--color-accent-hover)",
		fillOpacity: 0.1,
		stroke: "none",
	},
};

export const scatterStyle: VictoryStyleInterface = {
	data: {
		fill: ({ datum }) => {
			return datum.status
				? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					theme.colors[keyResultStatusMap[datum.status].color]
				: "var(--color-accent)";
		},
		stroke: ({ datum }) => {
			return datum.status
				? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					theme.colors[keyResultStatusMap[datum.status].color]
				: "var(--color-accent)";
		},
		strokeWidth: 1,
		cursor: "pointer",
	},
};

export const hiddenAxisStyle = {
	axis: { stroke: "transparent" },
	ticks: { stroke: "transparent" },
	tickLabels: { fill: "transparent" },
};

export const tooltipLabelStyle = [
	{
		textTransform: "uppercase",
		fill: "var(--color-text-heading)",
		fontSize: "12px",
	},
	{
		fontWeight: "400",
		marginTop: "8px",
		fontSize: "16px",
		fill: "var(--color-text-heading)",
	},
];

export const tooltipStyle = {
	stroke: "var(--color-border)",
	fill: "var(--color-body)",
};

export const tooltipPadding = {
	top: 4,
	bottom: 4,
	left: 8,
	right: 8,
};
