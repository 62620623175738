export class Result<T> {
	isSuccess: boolean;
	isFailure: boolean;
	error: string;
	#value: T;

	private constructor(isSuccess: boolean, error?: string, value?: T) {
		if (isSuccess && error) {
			throw new Error(`InvalidOperation: A result cannot be 
          successful and contain an error`);
		}
		if (!isSuccess && !error) {
			throw new Error(`InvalidOperation: A failing result 
          needs to contain an error message`);
		}

		this.isSuccess = isSuccess;
		this.isFailure = !isSuccess;
		// @ts-expect-error
		this.error = error;
		// @ts-expect-error
		this.#value = value;

		Object.freeze(this);
	}

	public getValue(): T {
		if (!this.isSuccess) {
			throw new Error(`Cant retrieve the value from a failed result.`);
		}

		return this.#value as T;
	}

	public getErrorValue() {
		return this.error;
	}

	public static ok<U>(value?: U): Result<U> {
		return new Result<U>(true, undefined, value);
	}

	public static fail<U>(error: string): Result<U> {
		return new Result<U>(false, error);
	}

	public static combine(results: Result<any>[]): Result<any> {
		for (const result of results) {
			if (result.isFailure) return result;
		}
		return Result.ok<any>();
	}
}
