import adjectives from "./adjectives";
import nouns from "./nouns";

function capFirst(string: any) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

// @ts-expect-error TS(7006) FIXME: Parameter 'min' implicitly has an 'any' type.
function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min)) + min;
}

export default function generateName() {
	const name1 = adjectives;
	const name2 = nouns;

	const name = capFirst(name1[getRandomInt(0, name1.length + 1)]) + " " + capFirst(name2[getRandomInt(0, name2.length + 1)]);
	return name;
}
