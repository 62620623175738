import { useLayoutEffect } from "react";

import { AnimatePresence } from "framer-motion";
import Mousetrap from "mousetrap";
import { useRecoilState } from "recoil";

import { sidebarState } from "../../../../modules/page/selectors/sidebarState";
import { HelpContainer } from "./components/HelpContainer";

export const HelpSidebar = () => {
	const [activeSidebar, setSidebar] = useRecoilState(sidebarState);

	useLayoutEffect(() => {
		Mousetrap.bind("?", () => setSidebar("help"));

		return () => {
			Mousetrap.unbind("?");
		};
	}, [setSidebar]);

	return <AnimatePresence>{activeSidebar === "help" ? <HelpContainer onClose={setSidebar} /> : null}</AnimatePresence>;
};
