import { SyntheticEvent, useCallback } from "react";

import { atom, useRecoilState } from "recoil";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { useDeleteProject } from "@/modules/board/hooks/useDeleteProject";
import { Button, Modal } from "@/shared/system";
import { ModalActionGroup, ModalHeader, ModalSubHeader } from "@/shared/system/Modal/Modal";

export const boardDeleteState = atom<string | undefined>({
	key: "boardDeleteState",
	default: undefined,
});

export const BoardConfirmDelete = () => {
	const [projectId, setConfirmModalState] = useRecoilState(boardDeleteState);
	const getProject = useProjectsStore((state) => state.getProject);

	const deleteProject = useDeleteProject(projectId);

	const handleProjectDelete = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			setConfirmModalState(undefined);
			deleteProject();
		},
		[deleteProject, setConfirmModalState],
	);

	const handleClose = useCallback(() => {
		setConfirmModalState(undefined);
	}, [setConfirmModalState]);

	if (!projectId) {
		return null;
	}

	const projectOrError = getProject(projectId);

	if (projectOrError.isFailure) {
		return null;
	}

	const project = projectOrError.getValue();

	const projectType = project.type;
	const projectName = project.data.name;

	const isOpen = project !== undefined;
	const isFolder = projectType === "folder";

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalHeader>Do you want to delete this project?</ModalHeader>
			<ModalSubHeader>
				This action <strong>cannot</strong> be undone. This will permanently delete the {projectType} '
				<strong>{projectName}</strong>' {isFolder && <u>and all its contents</u>}.
			</ModalSubHeader>
			<ModalActionGroup>
				<Button.Secondary onClick={handleClose}>Cancel</Button.Secondary>
				<Button onClick={handleProjectDelete}>Confirm delete {projectType}</Button>
			</ModalActionGroup>
		</Modal>
	);
};
