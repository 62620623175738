import styled from "styled-components";

import { breakpoints, utility } from "../../shared/system";

export default styled.h1`
	font-weight: 300;
	line-height: 1.3;
	color: ${utility.color("blackLight")};
	font-size: 30px;
	max-width: 800px;

	${breakpoints.sm`
		font-size: 36px;
	`}

	${breakpoints.lg`
		font-size: 48px;
	`}
`;
