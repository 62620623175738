import { Node } from "@/types/db";
import { WithId } from "@/types/db/utils";

import { ChildNodeList } from "./components/ChildNodeList";

type Props = {
	node: WithId<Node>;
};

export const ChildNodes = ({ node }: Props) => {
	const hasChildren = node.children.length > 0;

	return <div>{hasChildren ? <ChildNodeList node={node} /> : null}</div>;
};
