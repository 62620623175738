import { SyntheticEvent } from "react";

import { FiMail } from "react-icons/fi";
import { z } from "zod";

import { nodeMetaTypeEmail } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { Button, Tooltip } from "@/shared/system";
import { DropdownText } from "@/shared/system/DropdownText/DropdownText";
import { Node, WithId } from "@/types/db";

interface Props {
	meta: z.infer<typeof nodeMetaTypeEmail>;
	node: WithId<Node>;
}

export const NodeMetaEmail = ({ node, meta }: Props) => {
	const currentValue = node?.meta?.[meta.id];

	const placeholderText = `Add ${meta.label.toLowerCase()}...`;
	const nodesService = useNodesService();

	const email = typeof currentValue === "string" ? currentValue : "";

	const handleSave = (text: string) => {
		nodesService.updateMeta(node.id, meta.id, text);
	};

	const displayValue = <div>{email}</div>;

	const handleSend = (e: SyntheticEvent) => {
		e.stopPropagation();
		window.location.href = `mailto:${email}?subject=${encodeURI(node.title)}`;
	};

	const actions = () => (
		<Tooltip label="Send mail">
			<Button.Plain onClick={handleSend} py={0} px="6px" data-testid="dropdown-text--mail">
				<FiMail size="16px" />
			</Button.Plain>
		</Tooltip>
	);

	return (
		<DropdownText
			label={meta.label}
			displayValue={displayValue}
			value={email}
			actions={actions}
			placeholderText={placeholderText}
			onComplete={handleSave}
			openOnClick
		/>
	);
};
