import styled from "styled-components";

import { EventModel } from "@/domains/nodes/models/eventsModel";
import { TextWithEmoji } from "@/shared/system";

import { MessageTimestamp } from "./MessageTimestamp";

// TODO: this be in activityUtils as its exported...
export const getDescription = (activity: EventModel) => {
	const activityType = activity.type;
	switch (activityType) {
		case "created": {
			return "created the node.";
		}
		case "details": {
			return "updated the details.";
		}
		case "title": {
			const { from, to } = activity.value;
			return (
				<>
					updated the title from <strong>{from}</strong> to <strong>{to}</strong>.
				</>
			);
		}
		case "meta": {
			const { label, value } = activity.value;
			if (!value) {
				return `cleared ${label}.`;
			}

			return (
				<>
					updated <strong>{label}</strong> to{" "}
					<strong>
						<TextWithEmoji text={value} />
					</strong>
					.
				</>
			);
		}
		default: {
			const _exhaustiveCheck: never = activityType;
			return _exhaustiveCheck;
		}
	}
};

type Props = {
	activity: EventModel;
	showTimestamp: boolean;
};

export const MessageAction: React.FC<Props> = ({ activity, showTimestamp }) => {
	const { createdAt, createdBy } = activity;

	return (
		<Root data-testid="messageAction">
			<Emphasis text={createdBy.username} /> {getDescription(activity)}
			{showTimestamp && <MessageTimestamp timestamp={createdAt} />}
		</Root>
	);
};

const Root = styled.div`
	font-size: 13px;
	margin-top: -2px;
	margin-bottom: 24px;

	strong {
		font-weight: 500;
		color: var(--color-text-secondary);
	}
`;

const Emphasis = styled(TextWithEmoji)`
	display: inline-block;
	letter-spacing: 0.02em;
	font-weight: 500;
	margin-right: 3px;
	color: var(--color-text-secondary);
`;
