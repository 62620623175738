export const NODE_STATUS_DONE = "Done";
export const NODE_STATUS_NOW = "Now";
export const NODE_STATUS_NEXT = "Next";
export const NODE_STATUS_LATER = "Later";

export const statusColors = {
	green: "#3DC581",
	blue: "#379EE8",
	purple: "#8154f2",
	red: "#eb4c00",
};

export const statusTemplate = [
	{
		label: NODE_STATUS_LATER,
		color: statusColors.red,
	},
	{
		label: NODE_STATUS_NEXT,
		color: statusColors.purple,
	},
	{
		label: NODE_STATUS_NOW,
		color: statusColors.blue,
	},
	{
		label: NODE_STATUS_DONE,
		color: statusColors.green,
	},
];

export const nodeTypesGist = ["🏆  Goal", "💡  Idea", "👟  Step", "📐  Task"];
export const nodeTypesOKRs = ["🎯  Objective", "📈  Key Result", "🏗  Project", "📐  Task"];
export const nodeTypesOST = ["📣  Outcome", "🔮  Opportunity", "🗒  Solution", "🧪  Experiment"];
export const nodeTypesAgile = ["💎  Epic", "📚  Story", "📐  Task", "✏️  Sub-Task"];

export const nodeTypeGroupEnum = {
	gist: "GIST",
	okr: "OKRs",
	ost: "Opportunity Solution Trees",
	agile: "Agile",
};

export const nodeTypeMaps = [
	{
		label: nodeTypeGroupEnum.gist,
		types: nodeTypesGist,
	},
	{
		label: nodeTypeGroupEnum.okr,
		types: nodeTypesOKRs,
	},
	{
		label: nodeTypeGroupEnum.ost,
		types: nodeTypesOST,
	},
	{
		label: nodeTypeGroupEnum.agile,
		types: nodeTypesAgile,
	},
];
