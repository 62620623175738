import { useMemo } from "react";

import { SeverityLevel } from "@sentry/browser";

import { useToast } from "@/modules/toast/hooks/useToast";
import { ErrorLogger } from "@/shared/infra/sentry/sentry";

type Logger = (message: string, context?: Record<string, any>) => void;

const ErrorAdapter = process.env.VITE_SENTRY_KEY
	? ErrorLogger
	: {
			captureException: console.error,
			captureMessage: console.log,
		};

export interface IErrorService {
	logError: Logger;
	logWarning: Logger;
	logInfo: Logger;
}

export const useErrorService = (): IErrorService => {
	const setToast = useToast();

	return useMemo(() => {
		const builderErrorLogger =
			(level: SeverityLevel): Logger =>
			(message, context) => {
				setToast({ status: "error", message });
				ErrorAdapter.captureException(message, context, level);
			};

		const logError: IErrorService["logError"] = builderErrorLogger("error");
		const logWarning: IErrorService["logError"] = builderErrorLogger("warning");

		const logInfo: IErrorService["logInfo"] = (message, context) => {
			setToast({ status: "error", message });
			ErrorAdapter.captureMessage(message, context);
		};

		return {
			logError,
			logWarning,
			logInfo,
		};
	}, [setToast]);
};
