import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { background, fontSize, space, textAlign } from "styled-system";

export const ResizableTextarea = ({ styledProps, className, el, ...props }: any) => {
	return (
		<WrapResizableTextarea {...styledProps} className={className}>
			<TextareaAutosize {...props} ref={el} />
		</WrapResizableTextarea>
	);
};

export const FixedTextarea = (props: any) => {
	const { styledProps, className, noHover, placeholder, register = {}, isError, id, rows } = props;
	return (
		<WrapResizableTextarea {...styledProps} isError={isError} noHover={noHover} className={className}>
			<textarea placeholder={placeholder} {...register} id={id} rows={rows} />
		</WrapResizableTextarea>
	);
};
export const WrapResizableTextarea = styled.div<{ isPlain: boolean; noHover: boolean; disabled: boolean }>`
	& > * {
		display: block;
		border: ${({ isPlain }) => (isPlain ? "none" : "var(--border)")};
		padding: 8px 12px;
		font-size: inherit;
		font-weight: inherit;
		font-family: ${(props) => props.theme.fonts.sans};
		line-height: inherit;
		letter-spacing: inherit;
		outline: none;
		width: 100%;
		resize: none;
		overflow: hidden;
		background-color: inherit;
		color: var(--color-text-heading);

		&::placeholder {
			opacity: 0.5;
			color: var(--color-text);
		}

		&:disabled {
			background: transparent;

			:hover {
				background: transparent;
			}
		}

		&:focus {
			border-color: ${({ noHover }) => (noHover ? "var(--color-accent)" : "transparent")};
		}

		&:focus,
		&:hover {
			background-color: ${({ noHover }) => !noHover && "var(--color-hover)"};
			border-color: ${({ noHover }) => !noHover && "transparent"};
		}

		${background}
		${textAlign}
		${fontSize}
		${space}
	}
`;
