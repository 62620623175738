import { motion } from "framer-motion";
import styled from "styled-components";
import { CubeGrid } from "styled-spinkit";

const AppLoadingBack = styled(motion.div)`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--color-body);
	z-index: 5000;
`;

export const AppLoading = () => (
	<AppLoadingBack initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
		<CubeGrid color={"var(--color-accent)"} />
	</AppLoadingBack>
);

export default AppLoading;
