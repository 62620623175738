import React, { useCallback } from "react";

import { motion } from "framer-motion";
import styled from "styled-components";

import { useBindShortcut } from "../../core/hooks/useBindShortcut";

const HOTKEY_CLOSE_SIDEBAR = "escape";

type Props = {
	onClose: (name: string | null) => void;
	width?: number;
	children: React.ReactNode;
};

const SidebarRight = ({ onClose, width = 420, children }: Props) => {
	const handleOnClose = useCallback(() => onClose(null), [onClose]);

	useBindShortcut(HOTKEY_CLOSE_SIDEBAR, handleOnClose);

	const initialX = width + 100;

	return (
		<>
			<Overlay
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ ease: "easeInOut", duration: 0.2 }}
				onClick={handleOnClose}
			/>
			<Root
				initial={{ x: initialX }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: initialX, opacity: 0 }}
				$width={width}
				transition={{ ease: "easeInOut", duration: 0.2 }}>
				{children}
			</Root>
		</>
	);
};

const Overlay = styled(motion.div)`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--color-overlay);
	overflow: auto;
	z-index: 110;
`;

const Root = styled<any>(motion.div)`
	position: absolute;
	display: flex;
	top: 0;
	right: 0;
	margin: 16px;
	border-radius: 8px;
	z-index: 111;
	flex-direction: column;
	width: ${({ $width }) => `${$width}px`};
	max-width: 100%;
	height: calc(100% - 32px);
	overflow: hidden;
	background-color: var(--color-body);
	box-shadow: var(--drop-shadow-l2);
`;

export default SidebarRight;
