import { FiPlus } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { ProjectModel, ProjectSingleFound } from "@/domains/projects/models/projectsModel";
import { FilterClear } from "@/modules/board/filter/components/FilterClear";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger } from "../../../../shared/system";
import { NodeMetaTypes } from "../../../../types/db";
import { getNodeMetaWithValuesHydrated } from "../../../nodeMeta/selectors/getNodeMetaWithValuesHydrated";
import { getNodeMetaTypeIcon } from "../../../nodeMeta/utils/getNodeMetaTypeIcon";
import { FilterTypes } from "../filterModel";
import { buildOptions } from "./FilterGroupComparator";

const getDefaultComparator = (metaType: NodeMetaTypes) => {
	const comparators = buildOptions(metaType);

	return comparators?.[0].comparator;
};

const getDefaultValueForType = (metaType: NodeMetaTypes, value: any) => {
	switch (metaType) {
		case NodeMetaTypes.date:
		case NodeMetaTypes.dateRange:
			return "7";
		default:
			return typeof value?.[0] === "string" ? value?.[0] : value?.[0]?.id || null;
	}
};

type Props = {
	board: ProjectModel | ProjectSingleFound["data"];
	compact?: boolean;
};

export const FilterAdd = ({ board, compact }: Props) => {
	const { filters, hasActiveFilter, setMetaFilter } = useFilterService();
	const nodeMeta = useNodeMetaStore(({ meta }) => meta);

	const allMeta = useRecoilValue(
		getNodeMetaWithValuesHydrated({ boardId: board.id, workspaceId: board.workspaceId, nodeMeta }),
	);

	// TODO: Selectors need to be typed better...
	const meta: any[] = Object.values(allMeta);

	const options = meta
		.map(({ id, label, type, value }) => {
			return {
				id,
				value: getDefaultValueForType(type, value),
				comparator: getDefaultComparator(type),
				Icon: getNodeMetaTypeIcon(type),
				label: <div>{label}</div>,
			};
		})
		.filter(({ id }) => id !== undefined);

	if (hasActiveFilter && !compact) {
		return <FilterClear />;
	}

	return (
		<Dropdown>
			<Trigger $compact={compact} data-testid="filter-add">
				<FiPlus /> <span>Add Filter</span>
			</Trigger>
			<DropdownContent>
				{options.map(({ id, Icon, label, value, comparator }) => {
					const handleSelect = () =>
						setMetaFilter({
							key: filters.length.toString(),
							type: FilterTypes.meta,
							metaId: id,
							value,
							comparator,
						});

					return (
						<DropdownMenuItem onSelect={handleSelect} data-testid={`filter-add[${id}]`} key={id}>
							<Icon />
							{label}
						</DropdownMenuItem>
					);
				})}
			</DropdownContent>
		</Dropdown>
	);
};

const Trigger = styled<any>(DropdownTrigger)`
	display: flex;
	align-items: center;
	border-radius: 4px;
	color: var(--color-accent);

	&:hover {
		color: var(--color-accent-hover);
	}

	span {
		padding-left: 6px;
	}
`;
