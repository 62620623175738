import { SyntheticEvent } from "react";

import { FiPlus } from "react-icons/fi";
import { useSetRecoilState } from "recoil";

import { createProjectModalState } from "@/domains/accounts/components/createProject/selectors/createProjectModalState";
import { SidebarNavigationItemButton } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemButton";

type Props = {
	projectId: string;
};

export const SidebarNavigationAddProject = ({ projectId }: Props) => {
	const openCreateProjectModal = useSetRecoilState(createProjectModalState);

	const handleClick = (e: SyntheticEvent) => {
		e.stopPropagation();
		openCreateProjectModal(projectId);
	};

	return <SidebarNavigationItemButton tooltip="Create new project" icon={FiPlus} onClick={handleClick} />;
};
