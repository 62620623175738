import { useGetProject } from "@/domains/projects/hooks/useGetProject";

import { DetailsSections } from "../../../types/db";

export const useDetailsSectionVisibility = () => {
	const project = useGetProject();
	const hiddenSections = project?.meta.hiddenDetailSectionKeys || [];

	const isVisible = (key: DetailsSections) => !!hiddenSections && !hiddenSections.includes(key);

	return {
		displayCover: isVisible(DetailsSections.Cover),
		displayTitle: isVisible(DetailsSections.Title),
		displayMeta: isVisible(DetailsSections.Meta),
		displayResults: isVisible(DetailsSections.Results),
		displayChildren: isVisible(DetailsSections.Children),
		displayActivity: isVisible(DetailsSections.Activity),
	};
};
