import { IconType } from "react-icons";
import styled from "styled-components";

const getHashOfString = (str: string) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	hash = Math.abs(hash);
	return hash;
};

const normalizeHash = (hash: number, min: number, max: number): number => {
	return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name: string): number[] => {
	const hash = getHashOfString(name);
	const h = normalizeHash(hash, 0, 360);
	const s = normalizeHash(hash, 50, 60);
	const l = normalizeHash(hash, 55, 65);
	return [h, s, l];
};

const HSLtoString = (hsl: number[], opacity: number): string => {
	return `hsla(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%, ${opacity})`;
};

export const generateColorHsl = (id: string = "", opacity: number = 1): string => {
	return HSLtoString(generateHSL(id), opacity);
};

const unknownInitials = "?";

const getInitials = (string = "", limit = 3) => {
	const names = string ? string.split(" ") : unknownInitials;
	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1 && limit > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

type AvatarShape = "circle" | "square";

type Props = {
	name: string;
	imageUrl?: string | null;
	icon?: IconType;
	size?: number;
	shape?: AvatarShape;
};

export const AvatarStatic = ({ name, icon, imageUrl, size = 24, shape = "circle" }: Props) => {
	const showInitials = !imageUrl && !icon;
	const Icon = icon;
	const initials = showInitials && getInitials(name, 2);
	const generatedColor = icon ? "var(--color-border)" : generateColorHsl(name);

	return (
		<Root imageUrl={imageUrl} $size={size} $color={generatedColor} $shape={shape}>
			{Icon && <Icon width=".5em" height=".5em" />}
			{initials}
		</Root>
	);
};

const borderRadius = (shape: AvatarShape) => (shape === "circle" ? "50%" : "4px");

const Root = styled.div<{
	imageUrl?: string | null;
	$size: number;
	$color?: string;
	$shape: AvatarShape;
}>`
	display: flex;
	width: ${({ $size }) => $size}px;
	height: ${({ $size }) => $size}px;
	font-size: ${({ $size }) => `${$size * 0.4}px`};
	border-radius: ${({ $shape: shape }) => borderRadius(shape)};
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-weight: bold;
	background-image: ${({ imageUrl }) => (imageUrl ? `url(${imageUrl})` : "none")};
	background-color: ${({ $color: color }) => color};
	background-size: contain;
	text-align: center;
	color: var(--color-body);
	box-sizing: content-box;

	svg {
		width: ${({ $size }) => $size * 0.5}px;
		height: ${({ $size }) => $size * 0.5}px;
	}
`;
