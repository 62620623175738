import styled from "styled-components";
import { z } from "zod";

import { nodeMetaTypes } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";

import { NodeMetaSystemLabels } from "../../types/db";
import { CardMetaValue } from "./components/CardMetaValue";

const filterNodeTypeMeta = ({ definition }: { value: string; definition: z.infer<typeof nodeMetaTypes> }): boolean =>
	definition.label !== NodeMetaSystemLabels.nodeType;

interface Props {
	node: NodeModel;
	showLabel?: boolean;
}

export const CardMetaList = ({ node, showLabel = true }: Props) => {
	const project = useGetProject();
	const nodeMeta = useNodeMetaStore(({ meta }) => meta);

	const nodeMetaInformation = NodeMetaUtil.mapNodeValues(node, nodeMeta);
	const hiddenMeta = NodeMetaUtil.getHiddenMetaIds(node, project);

	const filteredNodeMeta = nodeMetaInformation
		.filter(filterNodeTypeMeta)
		.filter(({ definition }) => !hiddenMeta.includes(definition.id));

	if (filteredNodeMeta.length === 0) {
		return null;
	}

	return (
		<Root>
			{filteredNodeMeta.map((mappedMeta) => (
				<CardMetaValue meta={mappedMeta} key={mappedMeta.definition.id} showLabel={showLabel} />
			))}
		</Root>
	);
};

const Root = styled.div`
	width: 100%;
	min-width: 0;
`;
