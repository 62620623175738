import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import styled from "styled-components";

import Button from "../Button/Button";
import { Input, InputWrapper, ValidationError } from "../Input/Input";

type Props = {
	label: string;
	onSubmit: any;
	name: string;
	value?: any;
	mb?: any;
	labelTwo?: any;
	secondaryAllowed?: boolean;
	alwaysShowActions?: boolean;
	onSecondary?: VoidFunction;
	resetOnSubmit?: boolean;
} & Record<string, any>;

export const SingleInput = ({
	label,
	onSubmit,
	labelTwo,
	onSecondary,
	value,
	name,
	mb,
	secondaryAllowed = false,
	resetOnSubmit = false,
	alwaysShowActions = false,
	...props
}: Props) => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		setValue,
	} = useForm<any>({
		defaultValues: { [name]: value },
	});

	useEffect(() => {
		if (value) {
			setValue(name, value);
		}
	}, [name, setValue, value]);

	const bindSubmit = (e: any) => {
		e.preventDefault();
		handleSubmit(onSubmit(setError))(e);
		resetOnSubmit && setValue(name, "");
	};

	const handleKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			bindSubmit(e);
		}
	};

	const error: any = errors[name];

	return (
		<Form onSubmit={bindSubmit}>
			<SingleInputWrapper mb={mb} forceVisible={alwaysShowActions}>
				<>
					<Input mb={0} onKeyDown={handleKeyDown} {...register(name, { required: false })} isError={!!error} {...props} />
					<BoxInput>
						<Action type="submit" aria-label={`${label} ${name}`}>
							{label}
						</Action>
						{labelTwo && onSecondary && (
							<Action type="button" onClick={onSecondary} disabled={!secondaryAllowed} aria-label={`${labelTwo} ${name}`}>
								{labelTwo}
							</Action>
						)}
					</BoxInput>
					{error && <ValidationError showError={true}>{error?.type === "server" && error.message}</ValidationError>}
				</>
			</SingleInputWrapper>
		</Form>
	);
};

const Form = styled.form`
	width: 100%;
`;

const SingleInputWrapper = styled<any>(InputWrapper)`
	div {
		opacity: ${({ forceVisible }) => (forceVisible ? 1 : 0)};
	}

	input:focus ~ div {
		opacity: 1;
	}
`;

const BoxInput = styled.div`
	transition: opacity 0.1s ease-in;
	position: absolute;
	top: 8px;
	right: 5px;
	bottom: 5px;
	padding-left: 24px;
	background: linear-gradient(90deg, transparent, var(--color-body) 33%);
`;

const Action = styled(Button.Link)`
	padding: 0 4px;
	margin: 0 4px;
`;

export default SingleInput;
