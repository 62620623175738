import { captureMessage } from "@sentry/browser";
import { Editor } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import { useRecoilValue } from "recoil";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { TreeModel } from "@/domains/projects/models/treeModel";

import { membersState } from "../../../members/hooks/useMembersListener";
import { getNote } from "../../../note/noteFunctions";
import { toCsv } from "../utils/toCsv";

export const useExportBoardToCsv = (boardId: string, includeDetails: boolean) => {
	const treeService = useTreeService();
	const members = useRecoilValue(membersState);
	const nodeMeta = useNodeMetaStore(({ meta }) => ({
		meta: Object.fromEntries(meta),
		order: [...meta.keys()],
		version: "1" as const, //TODO: this shouldn't be set here and the request below should be routed through the service
	}));

	const getNodeDetails = async (tree: TreeModel[]) => {
		const details: Record<string, string> = {};

		for (const descendant of tree) {
			const id = descendant.data.id;
			const note = await getNote(id);

			const noteDoc = note.docs[0];
			let content = undefined;

			if (noteDoc) {
				const data = noteDoc.data();

				content =
					typeof data.body === "string" // TEMP Fix to resolve version schema miss match
						? JSON.parse(data.body)
						: data.body;
			} else {
				captureMessage(`Failed to get note details for node: ${id}`);
			}

			const editor = new Editor({
				extensions: [
					StarterKit,
					TaskList,
					TaskItem,
					Link,
					Placeholder.configure({
						placeholder: "Add some details...",
					}),
				],
				content,
			});

			details[id] = editor.getText().replaceAll('"', "'");
		}

		return details;
	};

	return async () => {
		const treeOrError = treeService.getTree();

		if (treeOrError.isFailure) {
			alert(treeOrError.getErrorValue());
			return;
		}

		const tree = treeOrError.getValue();
		const descendants = tree.descendants();

		const details = includeDetails ? await getNodeDetails(descendants) : null;
		return toCsv({ tree: descendants, members, nodeMeta, details });
	};
};
