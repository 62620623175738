import { NodeMetaTypeUnion } from "../../../types/db";

/**
 * Value transformation was added to support the addition
 * of the new multi-select property type. There may be existing
 * node meta values of type string.
 */

export const transformValueToArray = (value: any, type: NodeMetaTypeUnion): string[] => {
	if (value?.data) {
		return [];
	}

	const transformedType = typeof value === "string" ? [value] : value;

	if (type === "select") {
		return transformedType?.slice(0, 1);
	} else if (type === "list") {
		return transformedType;
	}

	return [];
};
