import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import {
	NavigationTree,
	NavigationTreeNode,
	NavigationUtils,
} from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectModel, ProjectModelFolder } from "@/domains/projects/models/projectsModel";
import { activeWorkspaceIdState } from "@/modules/authentication/hooks/useAuthenticationListener";
import { SystemFolderName } from "@/types/db";

export interface INavigationService {
	tree: NavigationTree;
	getNodeById: (id: string, visibility: "workspace" | "private") => NavigationTreeNode | null;
	getSystemFolderByName: (name: SystemFolderName) => ProjectModelFolder | null;
	getNodePath: (nodeId: string) => ProjectModel[];
	getAllParents: (nodeId: string) => ProjectModel[];
}

/**
 * 🚨 TODO:
 * If you have multiple tabs open and change workspaces the new workspaces load
 * and the old workspaces get added to the 'shared' folder
 */

export const useNavigationService = (): INavigationService => {
	const workspaceId = useRecoilValue(activeWorkspaceIdState);
	const projects = useProjectsStore((state) => state.projects);

	/**
	 * 🚨 TODO:
	 * I should create an account root folder for the tree so I can easily
	 * traverse all items within the workspace (workspace and private)
	 * this would enable:
	 *
	 * 1. getNodeById not to required a "visibility" argument
	 * 2. getSystemFolderByName could then be refactored to getFolderByName
	 */
	const tree = useMemo(() => NavigationUtils.buildTree(workspaceId, projects), [projects, workspaceId]);

	const service: INavigationService = useMemo(() => {
		return {
			tree,
			getNodeById: (id, visibility) => {
				const root = tree[SystemFolderName[visibility]];
				return root.find((node) => node.data.id === id) || null;
			},
			getSystemFolderByName: (name) => {
				const folder = tree[name];
				if (folder?.data.type === "folder") {
					return folder.data;
				}
				return null;
			},
			getNodePath: (nodeId) => {
				const root = tree[SystemFolderName.workspace];
				const node = root && root.find((node) => node.data.id === nodeId);

				if (node) {
					const [, ...pathExcludingRoot] = root.path(node);
					return pathExcludingRoot.map((node) => node.data);
				}

				const self = projects.get(nodeId);

				return [self].filter((project): project is ProjectModel => {
					return project !== undefined;
				});
			},
			getAllParents: (nodeId) => {
				const iterableFolders = Object.values(tree);
				const parents: ProjectModel[] = [];

				iterableFolders.forEach((folder) => {
					const node = folder.find((node) => node.data.id === nodeId);
					if (node && node.parent) {
						parents.push(node.parent.data);
					}
				});
				return parents;
			},
		};
	}, [projects, tree]);

	return service;
};
