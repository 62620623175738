import { useCallback, useState } from "react";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { KeyResultsDetailsHeader } from "@/domains/nodes/components/keyResults/components/details/components/KeyResultsDetailsHeader";
import { KeyResultsDetailsList } from "@/domains/nodes/components/keyResults/components/details/components/KeyResultsDetailsList";
import { KeyResultAdd } from "@/domains/nodes/components/keyResults/components/forms/KeyResultsAdd";
import { keyResultsNode } from "@/domains/nodes/components/keyResults/recoil/dataKeyResults";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Divider, Modal } from "@/shared/system";

export const KeyResultsList: React.FC = () => {
	const { nodeId } = useParams() as { nodeId: string };
	const keyResults = useRecoilValue(keyResultsNode(nodeId));
	const { canEdit } = usePermissions("project");

	const [isOpenKeyResultsAdd, setKeyResultsAddIsOpen] = useState<boolean>(false);

	const handleToggleAddForm = useCallback(() => setKeyResultsAddIsOpen(!isOpenKeyResultsAdd), [isOpenKeyResultsAdd]);

	const hideIfEmpty = !canEdit && keyResults.length === 0;

	if (hideIfEmpty) {
		return null;
	}

	return (
		<>
			<KeyResultsDetailsHeader onAdd={handleToggleAddForm} />
			<KeyResultsDetailsList onAdd={handleToggleAddForm} />
			<Modal isOpen={isOpenKeyResultsAdd} onClose={setKeyResultsAddIsOpen} width="660px">
				<KeyResultAdd onComplete={handleToggleAddForm} onCancel={handleToggleAddForm} />
			</Modal>
			<Divider my="24px" />
		</>
	);
};
