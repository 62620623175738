import styled from "styled-components";

type Props = {
	icon: JSX.Element;
	content: JSX.Element;
	action: JSX.Element;
};

export const EmbedActionGroup: React.FC<Props> = ({ icon, content, action }) => {
	return (
		<Root>
			{icon}
			<Content>{content}</Content>
			<Action>{action}</Action>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	align-items: center;

	svg {
		font-size: 24px;
	}
`;

const Content = styled.div`
	font-size: 14px;
	line-height: 1.1;

	div {
		color: var(--color-text-heading);
		font-weight: 500;
		margin-bottom: 4px;
	}
`;

const Action = styled.div`
	margin-left: auto;
`;
