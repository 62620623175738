import { SyntheticEvent, useCallback, useRef, useState } from "react";

import { ExtendedKeyboardEvent } from "mousetrap";
import styled from "styled-components";

import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Button, ResizableTextarea } from "@/shared/system";

const textareaStyles = {
	mb: 0,
	mx: 0,
	p: 0,
	isPlain: true,
	noHover: true,
};

type Props = {
	onSubmit: (comment: string) => void;
};

export const CommentInput: React.FC<Props> = ({ onSubmit }) => {
	const [comment, setComment] = useState("");
	const inputEl = useRef<HTMLInputElement>(null);

	const handleSubmit = useCallback(
		(e: ExtendedKeyboardEvent) => {
			e.preventDefault();
			onSubmit(comment);

			setComment("");
		},
		[comment, onSubmit],
	);

	const handleChange = useCallback((e: SyntheticEvent<HTMLTextAreaElement>) => {
		setComment(e.currentTarget.value);
	}, []);

	const handleCommentAreaClick = useCallback(() => {
		if (inputEl.current === null) {
			return;
		}

		inputEl.current.focus();
	}, []);

	useBindShortcut("enter", handleSubmit, inputEl);

	return (
		<Root onClick={handleCommentAreaClick}>
			<ResizableTextarea
				placeholder="Leave a comment..."
				name="comment"
				value={comment}
				onChange={handleChange}
				styledProps={textareaStyles}
				el={inputEl}
			/>
			<Actions>
				<Submit onClick={handleSubmit} data-testid="commentInput--submit">
					Comment
				</Submit>
			</Actions>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 92px;
	justify-content: space-between;
	border-radius: 4px;
	padding: 12px 16px;
	margin-top: -12px;
	border: var(--border);
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
`;

const Submit = styled(Button.Secondary)``;
