import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { ResizableTextarea } from "../../../shared/system";
import { usePermissions } from "../../workspace/hooks/usePermissions";
import { updateSummary } from "../boardFunctions";

interface Props {
	projectId: string;
	rootId: string;
	boardDescription?: string | null;
}

export const BoardHeaderDescription = ({ projectId, rootId, boardDescription }: Props) => {
	const [description, setDescription] = useState(boardDescription);
	const { canEdit } = usePermissions("project");

	useEffect(() => {
		setDescription(boardDescription);
	}, [boardDescription]);

	const handleOnChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
		setDescription(e.currentTarget.value);
	};

	const handelUpdate = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
		updateSummary(projectId, rootId, e.currentTarget.value);
	};

	return (
		<Root
			value={description}
			onChange={handleOnChange}
			onBlur={handelUpdate}
			placeholder="Summarize your board"
			disabled={!canEdit}
		/>
	);
};

const Root = styled(ResizableTextarea)`
	width: 400px;
	margin: auto;
	margin-bottom: 12px;

	& > * {
		font-size: 18px;
		letter-spacing: 0.025rem;
		padding: 0;
		text-align: center;
		background: transparent;
		border: 0;
		opacity: 0.75;
	}
`;
