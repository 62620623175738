import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { inRange } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
	Node,
	NodeChange,
	NodeDragHandler,
	NodeMouseHandler,
	useEdgesState,
	useNodesState,
	useReactFlow,
	useStoreApi,
} from "reactflow";
import "reactflow/dist/style.css";
import styled from "styled-components";

import { useNodeFocus } from "@/domains/nodes/components/cards/hooks/useNodeFocus";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { ColorBand } from "@/domains/projects/components/layout/ColorBand";
import { Controls } from "@/domains/projects/components/treeView/components/Controls/Controls";
import { TreeLoading } from "@/domains/projects/components/treeView/components/TreeLoading";
import {
	nodeMaxCollisionDistance,
	nodeTempId,
	nodeTypes,
	proOptions,
} from "@/domains/projects/components/treeView/config/reactFlow";
import { useNodesAndEdges } from "@/domains/projects/components/treeView/hooks/useNodesAndEdges";
import { CollisionUtils } from "@/domains/projects/components/treeView/utils/collisionUtils";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useTreeStore } from "@/domains/projects/zustand/treeStore";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Result } from "@/shared/utils/result";

export const TreeView = () => {
	const [isLayedOut, setLayout] = useState(false);
	const treeService = useTreeService();
	const nodesService = useNodesService();
	const { boardId } = useParams();
	const navigate = useNavigate();

	const { nodes: nodesState, edges: edgesState } = useNodesAndEdges();
	const { canEdit } = usePermissions("project");

	const treeInfo = useTreeStore((state) => state.treeInfo);
	const setNodeHeight = useTreeStore((state) => state.setNodeHeight);
	const treeId = useTreeStore((state) => state.id);

	const { setFocused } = useNodeFocus();

	// const setFocused = useNodesStore((state) => state.setFocused);

	const store = useStoreApi();

	const timerRef = useRef<number>();

	const getIsEditing = useNodesStore((state) => state.getIsEditing);

	const [edges, setEdges, onEdgesChange] = useEdgesState(edgesState);
	const [nodes, setNodes, onNodesChange] = useNodesState(nodesState);

	const { setViewport } = useReactFlow();

	const listen = (changes: NodeChange[]) => {
		onNodesChange(changes);

		for (const change of changes) {
			if (change.type === "dimensions" && change.id !== nodeTempId) {
				const height = change.dimensions?.height;
				const currentHeight = treeInfo.get(change.id);

				if (height && height !== currentHeight) {
					setNodeHeight(change.id, height);
				}
			}
		}
	};

	const isEditing = getIsEditing();
	const isInteractive = canEdit && !isEditing;

	const clearClassName = (node: Node) => ({ ...node, className: "" });

	useEffect(() => {
		return () => {
			boardId && setLayout(false);
		};
	}, [boardId]);

	useLayoutEffect(() => {
		const pane = document.querySelector(".react-flow__pane");

		if (!isLayedOut && treeId === boardId && pane) {
			const rect = pane.getBoundingClientRect();
			setTimeout(() => {
				const quarterWidthSource = 150;
				const midPoint = rect.width / 2 - quarterWidthSource;

				setViewport({ x: midPoint, y: 64, zoom: 1 }, { duration: 0 });
			}, 1);

			setTimeout(() => {
				setLayout(true);
			}, 500);
		}
	}, [boardId, setViewport, isLayedOut, treeId, treeInfo.size]);

	useEffect(() => {
		setNodes(nodesState);
		setEdges(edgesState);
	}, [edgesState, nodesState, setEdges, setNodes]);

	const resetDraggingState = () => {
		setNodes(nodesState);
		setEdges(edgesState);
	};

	const handleDragStart: NodeDragHandler = (event, node) => {
		setNodes((nodes) => {
			return [
				{
					...node,
					id: nodeTempId,
					className: "opaque",
				},
				...nodes,
			];
		});

		setEdges((edges) => {
			return edges.map((edge) => {
				if (edge.target === node.id) {
					return {
						...edge,
						target: nodeTempId,
					};
				}
				if (edge.source === node.id) {
					return {
						...edge,
						source: nodeTempId,
					};
				}
				return edge;
			});
		});
	};

	const handleDragEnd: NodeDragHandler = (event, node) => {
		const originalNode = nodesState.find((n) => n.id === node.id);

		if (originalNode) {
			const originalPosition = originalNode.position;
			const currentPosition = node.position;

			if (
				inRange(currentPosition.x, originalPosition.x - 5, originalPosition.x + 5) &&
				inRange(currentPosition.y, originalPosition.y - 5, originalPosition.y + 5)
			) {
				resetDraggingState();
				return;
			}
		}

		const treeNodeOrError = treeService.getNode(node.id);

		if (treeNodeOrError.isFailure) {
			return;
		}

		const treeNode = treeNodeOrError.getValue();
		const originalPosition = { x: treeNode.x, y: treeNode.y };
		const matchedNode = (id: string) => id === node.id;

		setNodes((nodes) =>
			nodes
				.filter((n) => n.id !== nodeTempId)
				.map((n) => ({
					...n,
					position: matchedNode(n.id) ? originalPosition : n.position,
					className: "",
				})),
		);

		setEdges(edgesState);

		const nodes = store
			.getState()
			.getNodes()
			.filter(({ id }) => id !== node.id);

		const [over] = CollisionUtils.getClosestCenter(node, nodes);

		if (over && over.value > nodeMaxCollisionDistance) {
			resetDraggingState();
			return;
		}

		const activeId = node.id;
		const overId = over?.id;

		if (activeId === overId || !overId) {
			resetDraggingState();
			return;
		}

		const overParentOrError = over.relation === "child" ? treeService.getNode(overId) : treeService.getParent(overId);
		const activeParentOrError = treeService.getParent(activeId);
		const isAncestor = treeService.getIsAncestor(overId, activeId);

		const result = Result.combine([overParentOrError, activeParentOrError]);

		if (result.isFailure) {
			resetDraggingState();
			return;
		}

		if (isAncestor) {
			resetDraggingState();
			return;
		}

		const newParent = overParentOrError.getValue().data.document;
		const oldParent = activeParentOrError.getValue().data.document;

		const overIndex = newParent.children.findIndex((id) => id === overId);

		const overPosition = over.relation === "left" ? Math.max(overIndex, 0) : Math.max(overIndex + 1, 0);
		const newPosition = over.relation === "child" ? 0 : overPosition;

		nodesService.reorder(activeId, oldParent, newParent, newPosition);
	};

	const handleDragNode: NodeDragHandler = useCallback(
		(event, node) => {
			const descendantIds = treeService.getDescendants(node.id).map((tree) => tree.data.id);
			setFocused(null);

			const nodes = store
				.getState()
				.getNodes()
				.filter(({ id }) => id !== node.id)
				.filter(({ type }) => type !== "source")
				.filter(({ id }) => id !== nodeTempId)
				.filter(({ id }) => !descendantIds.includes(id));

			const [over] = CollisionUtils.getClosestCenter(node, nodes);

			// Clears class for drop indicator
			if (!over || (over && over.value > nodeMaxCollisionDistance)) {
				setNodes((nodes) =>
					nodes.map((node) => {
						if (node.id === nodeTempId) {
							return node;
						}

						if (descendantIds.includes(node.id)) {
							return {
								...node,
								className: "opaque",
							};
						}

						return clearClassName(node);
					}),
				);

				return;
			}

			// Adds class for drop indicator
			setNodes((ns) =>
				ns.map((n) => {
					if (n.id === nodeTempId || descendantIds.includes(n.id)) {
						return n;
					}
					return {
						...n,
						className: over.id === n.id ? over?.relation : "",
					};
				}),
			);
		},
		[setFocused, setNodes, store, treeService],
	);

	const handleNodeClick: NodeMouseHandler = useCallback(
		(event, node) => {
			if (isEditing) {
				return;
			}

			const target = event.target as Element;
			const isNodeCard = target.closest(`[data-node-id]`);

			if (isNodeCard) {
				navigate(NodeUtils.buildNodeUrl(node.data));
			}
		},
		[isEditing, navigate],
	);

	const handleNodeMouseEnter: NodeMouseHandler = useCallback(
		(event, node) => {
			timerRef.current = window.setTimeout(() => {
				setFocused(node.id);
			}, 150);
		},
		[setFocused],
	);

	const handleNodeMouseLeave: NodeMouseHandler = useCallback(() => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	}, []);

	const handlePaneClick = (event: React.MouseEvent) => {
		const target = event.target as Element;
		const isNodeCard = target.closest(`[data-node-id]`);

		if (!isNodeCard) {
			setFocused(null);
		}
	};

	return (
		<Root id="treeRoot">
			{!isLayedOut && <TreeLoading />}
			<ColorBand />
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				onNodeClick={handleNodeClick}
				onNodesChange={listen}
				onEdgesChange={onEdgesChange}
				zoomOnDoubleClick={false}
				panOnScroll={true}
				minZoom={0.25}
				onNodeMouseEnter={handleNodeMouseEnter}
				onNodeMouseLeave={handleNodeMouseLeave}
				onNodeDragStart={handleDragStart}
				onNodeDragStop={handleDragEnd}
				onNodeDrag={handleDragNode}
				onPaneClick={handlePaneClick}
				nodesDraggable={isInteractive}
				panOnDrag={!isEditing}
				proOptions={proOptions}
				nodesConnectable={false}
				elementsSelectable={false}
			/>
			<Controls />
		</Root>
	);
};

const Root = styled.div`
	width: calc(100% - 16px);
	height: 100%;
	margin: 0 8px;

	.react-flow__node {
		&.dragging {
			position: absolute;
			opacity: 0.9;
			z-index: 9999999;
		}

		&.highlighted :not([data-layout="icon"]) {
			.draggable {
				border: 2px solid var(--color-accent);
			}
		}

		&.opaque {
			border: var(--border);
			border-radius: 8px;
			background: var(--gradient-striped);

			> * {
				visibility: hidden;
			}
		}

		&.top,
		&.bottom,
		&.left,
		&.right {
			&::before,
			&::after {
				position: absolute;
				display: block;
				content: " ";
			}

			&::before {
				background: var(--color-accent);
			}

			&::after {
				height: 6px;
				width: 6px;
				border-radius: 6px;
				border: 2px solid var(--color-accent);
				background: var(--color-body);
			}
		}

		&.top,
		&.bottom {
			&::before,
			&::after {
				left: -16px;
			}

			&::before {
				width: calc(100% + 24px);
				height: 2px;
			}
		}

		&.top {
			&::before {
				top: -9px;
			}

			&::after {
				top: -13px;
			}
		}

		&.bottom {
			&::before {
				bottom: -8px;
			}

			&::after {
				bottom: -12px;
			}
		}

		&.left,
		&.right {
			&::before,
			&::after {
				top: -6px;
			}

			&::before {
				height: calc(100% + 12px);
				width: 2px;
			}
		}

		&.left {
			&::before {
				left: -14px;
			}

			&::after {
				left: -18px;
			}
		}

		&.right {
			&::before {
				right: -12px;
			}

			&::after {
				right: -16px;
			}
		}

		&.child {
			&::before {
				position: absolute;
				z-index: 2;
				bottom: -24px;
				left: calc(50% - 1px);
				display: block;
				content: " ";
				height: 24px;
				border-left: 2px solid var(--color-body);
			}

			&::after {
				position: absolute;
				bottom: -68px;
				display: block;
				content: " ";
				border: 1px solid var(--color-accent);
				background: var(--gradient-striped-accent);
				width: calc(100% - 6px);
				height: 48px;
				border-radius: 8px;
			}
		}
	}
`;
