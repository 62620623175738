import React from "react";

import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useDetailsSectionVisibility } from "@/modules/board/hooks/useDetailsSectionVisibility";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover, PopoverRender } from "@/shared/system/Dropdown/Popover";

import { IconPickerModal } from "./components/IconPickerModal";

type Props = {
	node: NodeModel;
};

export const IconPicker: React.FC<Props> = ({ node, children }) => {
	const { cover } = node;
	const { displayCover } = useDetailsSectionVisibility();
	const { canEdit } = usePermissions("project");

	const hasCover = !!cover && displayCover;

	if (!canEdit) {
		return (
			<Root hasCover={hasCover}>
				<div>{children}</div>
			</Root>
		);
	}

	const items: PopoverRender = ({ close }) => <IconPickerModal node={node} close={close} />;

	return (
		<Root hasCover={hasCover}>
			<Popover render={items}>
				<div>{children}</div>
			</Popover>
		</Root>
	);
};

const Root = styled.div<{ hasCover: boolean }>`
	position: relative;
	display: flex;
	margin-bottom: 8px;

	${({ hasCover }) => {
		return hasCover && `margin: -36px 0 8px;`;
	}}
`;
