import { captureException, captureMessage } from "@sentry/browser";
import { arrayRemove, doc, getDoc, getDocs, query, where, writeBatch } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";

type ProjectDelete = (args: { parentId: string; favoriteId?: string; projectId: string; archive: boolean }) => Promise<void>;

export const projectDelete: ProjectDelete = async ({ parentId, favoriteId, projectId, archive = false }) => {
	const nodeState = archive ? { archived: true } : { archived: true, deleted: true };
	const projectStatus = archive ? "archived" : "deleted";

	const initialBatch = writeBatch(fb.firestore);

	const parentFolder = doc(db.projects, parentId);
	initialBatch.update(parentFolder, { childIds: arrayRemove(projectId) });

	if (favoriteId) {
		const parentFolder = doc(db.projects, favoriteId);
		initialBatch.update(parentFolder, { childIds: arrayRemove(projectId) });
	}

	initialBatch.commit();

	const projectDoc = doc(db.projects, projectId);
	const project = (await getDoc(projectDoc)).data();

	if (project === undefined) {
		captureMessage("Tried deleting project, but project not found");
		return;
	}

	const batch = writeBatch(fb.firestore);

	try {
		batch.update(projectDoc, { status: projectStatus });

		switch (project.type) {
			case "board": {
				const projectNodesQuery = query(db.nodes, where("board", "==", projectId));
				const projectNodes = await getDocs(projectNodesQuery);

				projectNodes.docs.forEach((documentQuery) => {
					const nodeDoc = doc(db.nodes, documentQuery.id);
					batch.update(nodeDoc, nodeState);
				});

				break;
			}
			case "folder": {
				project.childIds.forEach((childId) => {
					projectDelete({ parentId: projectId, projectId: childId, archive });
				});
				break;
			}
			default: {
				break;
			}
		}

		batch.commit();
	} catch (e) {
		captureException(e);
	}
};
