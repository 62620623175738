import { useEffect } from "react";

import { isEmpty } from "lodash";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { mergeLocalNodeData } from "@/domains/nodes/zustand/nodesStore";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { HierarchyUtils } from "@/domains/projects/utils/hierarchyUtils";
import { useTreeStore } from "@/domains/projects/zustand/treeStore";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

export const useBuildTree = () => {
	const project = useGetProject();
	const nodes = useNodesStore((state) => state.nodes);
	const local = useNodesStore((state) => state.local);
	const collapsedNodeIds = useNodesStore((state) => state.collapsedNodeIds);

	const tree = useTreeStore((state) => state.tree);
	const treeInfo = useTreeStore((state) => state.treeInfo);
	const setTree = useTreeStore((state) => state.setTree);
	const setFilteredTree = useTreeStore((state) => state.setFilteredTree);

	const filterService = useFilterService();

	const projectId = project?.id;
	const rootId = filterService.getRootNodeId() || (project?.rootId as string);
	const nodesLoaded = !isEmpty(nodes); //TODO: Should probably use something like SWR

	useEffect(() => {
		if (nodesLoaded && projectId) {
			const treeOrError = HierarchyUtils.buildTree({ rootId, nodes, treeInfo });

			if (treeOrError.isFailure) {
				return;
			}

			setTree(projectId, treeOrError.getValue());
		}
	}, [nodes, nodesLoaded, projectId, rootId, setTree, treeInfo]);

	useEffect(() => {
		const mergedNodes = mergeLocalNodeData(nodes, local);

		const filteredTreeOrError = HierarchyUtils.buildFilteredTree({
			tree,
			nodes: mergedNodes,
			rootId,
			collapsedNodeIds,
			treeInfo,
		});

		if (filteredTreeOrError.isFailure) {
			return;
		}

		setFilteredTree(filteredTreeOrError.getValue());
	}, [collapsedNodeIds, local, nodes, rootId, setFilteredTree, tree, treeInfo]);
};
