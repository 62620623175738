import { FiUser } from "react-icons/fi";
import styled from "styled-components";

import { AvatarStatic } from "./AvatarStatic";

const Avatar = ({ user = {}, small = false, size = null, icon }: any) => {
	const avatarSize = size ? size : small ? 24 : 30;

	const { photoURL, username, email, active = true } = user;

	const isInvite = username === email;

	const name = isInvite ? "invite" : username;
	const inviteIcon = isInvite ? FiUser : icon;

	return (
		<Root active={active}>
			<AvatarStatic imageUrl={photoURL} name={name} icon={inviteIcon} size={avatarSize} />
		</Root>
	);
};

const Root = styled.div<{ active: boolean }>`
	flex-shrink: 0;
	opacity: ${({ active }) => (active ? 1 : 0.4)};
`;

export default Avatar;
