import { doc, writeBatch } from "firebase/firestore";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { ProjectInvite } from "@/modules/board/share/shareUsers/shareTypes";
import { createInvite, sendEmail } from "@/modules/members/memberFunctions";
import { db, fb } from "@/shared/infra/init";
import { User, WithId, WorkspaceMember, WorkspaceMemberRole } from "@/types/db";

type AddGuests = (args: {
	project: WithId<ProjectDtoBoard>;
	role: WorkspaceMemberRole;
	invitedBy: User;
	members: ProjectInvite[];
	message: string;
	notify: boolean;
}) => void;

export const addGuests: AddGuests = ({ project, invitedBy, role, members, message, notify }) => {
	const batch = writeBatch(fb.firestore);

	members.forEach(({ scope, data: member }) => {
		switch (scope) {
			case "member": {
				const memberInfo: WorkspaceMember = {
					role,
					status: "active",
					scope: "guest",
				};

				const userDoc = doc(db.users, member.id);

				batch.update(userDoc, {
					[`access.${project.id}`]: memberInfo,
				});

				const projectDoc = doc(db.projects, project.id);
				batch.update(projectDoc, {
					[`access.${member.id}`]: memberInfo,
				});

				if (notify) {
					sendEmail({
						email: member.email,
						name: project.data.name,
						id: project.id,
						type: "guest",
						role,
						authUser: invitedBy,
						message,
					});
				}
				return;
			}
			case "guest": {
				createInvite({
					entityId: project.id,
					entityTitle: project.data.name,
					email: member.email,
					role,
					type: "guest",
					user: invitedBy,
					message,
					workspaceId: project.workspaceId,
				});
				return;
			}
			default: {
				const _exhaustiveCheck: never = scope;
				return _exhaustiveCheck;
			}
		}
	});

	batch.commit();
};
