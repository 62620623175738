import { useCallback } from "react";

import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { projectUpdateView } from "@/modules/board/api/projectUpdateView";
import Roadmap from "@/modules/page/assets/roadmap.svg?react";
import Tree from "@/modules/page/assets/treeview.svg?react";
import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { ProjectBoard, ProjectMeta, WithId } from "@/types/db";

interface Props {
	board: WithId<ProjectBoard>;
}

export const BoardView = ({ board }: Props) => {
	const user = usePublicProfile();

	const handleSetView = useCallback(
		(view: ProjectMeta["view"]) => {
			projectUpdateView(board.id, view, user);
		},
		[board.id, user],
	);

	const handleSetTree = useCallback(() => handleSetView("tree"), [handleSetView]);
	const handleSetBoard = useCallback(() => handleSetView("board"), [handleSetView]);

	return (
		<IconGroup>
			<IconLink to={`/b/${board.id}`} onClick={handleSetTree}>
				<Tree />
			</IconLink>
			<IconLink to={`/r/${board.id}`} onClick={handleSetBoard}>
				<Roadmap />
			</IconLink>
		</IconGroup>
	);
};

const IconGroup = styled.div`
	display: flex;
	background: var(--color-hover);
	border-radius: 4px;
	padding: 4px;
`;

const IconLink = styled(NavLink)`
	display: flex;
	align-items: center;
	background: transparent;
	border: 0;
	padding: 8px 12px;
	font-size: 14px;
	color: var(--color-text-heading);

	svg {
		display: block;
	}

	&.active {
		background: var(--color-body);
		border: var(--border);
		border-radius: 4px;
		box-shadow: var(--drop-shadow);
	}
`;
