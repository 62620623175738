import styled from "styled-components";

import { NodeLayoutListItem } from "@/domains/nodes/components/lists/NodeLayoutListItem";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";

type Props = {
	node: NodeModel;
	variant?: "gripper";
};

export const NodeLayoutList = ({ node }: Props) => {
	const treeService = useTreeService();

	const childrenOrError = treeService.getChildren(node.id);

	if (childrenOrError.isFailure) {
		return null;
	}

	const nodes = childrenOrError.getValue().map((node) => ({
		...node.data.document,
	}));

	return (
		<Root>
			{nodes.map((node) => (
				<NodeLayoutListItem node={node} key={node.id} />
			))}
		</Root>
	);
};

const Root = styled.div`
	border: var(--border);
	border-radius: 8px;
	overflow: hidden;
`;
