import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { TreeModel } from "@/domains/projects/models/treeModel";

import { Node, NodeMeta, User } from "../../../../types/db";
import { WithId } from "../../../../types/db/utils";
import { getNodeMetaValue } from "../../../nodeMeta/utils/getNodeMetaValue";

dayjs.extend(localizedFormat);

const buildMetaHeaders = (metaDictionary: NodeMeta): string[] => {
	const { order, meta: nodeMeta } = metaDictionary;

	return order.map((id: string) => {
		return nodeMeta[id].label.toLowerCase().replace(" ", "_");
	});
};

const metaToValues = (node: WithId<Node>, metaDictionary: NodeMeta, members: Record<string, User>): any => {
	const { order, meta: nodeMeta } = metaDictionary;

	const iterableMeta = Object.entries(nodeMeta);

	const valueLookup = iterableMeta.reduce<Record<string, string>>((carry, [key, metaValue]) => {
		const value = getNodeMetaValue(node, metaValue, members) || "";
		carry[key] = value;
		return carry;
	}, {});

	return order.map((id) => valueLookup[id]);
};

type ToCsvRow = (args: {
	treeNode: TreeModel;
	rootNode: TreeModel | undefined;
	members: Record<string, User>;
	nodeMeta: NodeMeta;
	details: Record<string, string> | null;
}) => (string | null)[];

const toCsvRow: ToCsvRow = ({ treeNode, rootNode, members, nodeMeta, details }) => {
	const node = treeNode.data.document;
	const { id, title, createdAt, createdBy = "" } = node;

	const parent = treeNode.parent;

	const createdByUsername = members[createdBy]?.username;
	const createdAtString = dayjs.unix(createdAt.seconds).format("L");
	const metaColumns = metaToValues(node, nodeMeta, members);
	const nodeDetails = details?.[id] || "";

	const rootId = rootNode?.data.id;
	const parentId = parent?.data.id;

	const pId = parentId !== rootId ? parentId : "";

	return [id, pId, title, createdAtString, createdByUsername, ...metaColumns, ...(details ? [`"${nodeDetails}"`] : [])];
};

type ToCsv = (args: {
	tree: TreeModel[];
	members: Record<string, User>;
	nodeMeta: NodeMeta;
	details: Record<string, string> | null;
}) => Blob;

export const toCsv: ToCsv = ({ tree, members, nodeMeta, details }) => {
	// // Board is the first item
	const rootNode = tree.shift();
	const headers = buildMetaHeaders(nodeMeta);

	// This is coupled to the order returned in toCsvRow
	const header = ["id", "parent_id", "title", "created_on", "created_by", ...headers, ...(details ? ["details"] : [])].join(
		",",
	);

	const rows = tree
		.map((treeNode) => toCsvRow({ treeNode, rootNode, members, nodeMeta, details }))
		.map((row) => row.join(","));

	const data = `${header}\n${rows.join("\n")}`;

	return new Blob([data], { type: "text/csv;charset=utf-8;" });
};
