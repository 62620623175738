import { useEffect } from "react";

import Mousetrap from "mousetrap";

import { useTreeDepth } from "@/modules/board/meta/hooks/useTreeDepth";

export const useShortcutsDepth = () => {
	const depthOptions = useTreeDepth();

	useEffect(() => {
		depthOptions.forEach(({ depth, action }) => {
			Mousetrap.bind(`alt+${depth}`, action);
		});

		return () => {
			depthOptions.forEach(({ depth }) => {
				Mousetrap.unbind(`alt+${depth}`);
			});
		};
	}, [depthOptions]);
};
