import { loadStripe } from "@stripe/stripe-js/pure";
import { addDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { db, onSnapshotError } from "../../shared/infra/init";
import { fb } from "../../shared/infra/init";

export const getProducts = async () => {
	const productsQuery = query(db.products, where("active", "==", true));
	return getDocs(productsQuery);
};

export const getProductPricing = async (productId: string) => {
	return getDocs(db.productPricing(productId));
};

type PurchaseProduct = (args: { workspaceId: string; lineItems: any; stripe?: any }) => Promise<void>;

export const purchaseProduct: PurchaseProduct = async ({ workspaceId, lineItems, stripe }: any) => {
	const stripeSdk = (await stripe) || (await loadStripe(process.env.VITE_STRIPE_API_KEY as string));

	const userId = fb?.auth.currentUser?.uid;

	if (!stripeSdk || !userId) {
		return;
	}

	const checkoutSessionCollection = db.billingCheckoutSession(userId);

	const docRef = await addDoc(checkoutSessionCollection, {
		line_items: lineItems,
		allow_promotion_codes: false,
		metadata: { workspace_id: workspaceId },
		billing_address_collection: "auto",
		success_url: window.location.origin,
		cancel_url: window.location.origin,
	});

	// Wait for the CheckoutSession to get attached by the extension
	onSnapshot(
		docRef,
		async (snapshot: any) => {
			const { error, sessionId } = snapshot.data();
			if (error) {
				// Show an error to your customer and
				// inspect your Cloud Function logs in the Firebase console.
				alert(`An error occurred: ${error.message}`);
				// onComplete();
			}
			if (sessionId) {
				// We have a session, let's redirect to Checkout
				// Init Stripe
				await stripeSdk.redirectToCheckout({ sessionId });
				// onComplete();
			}
		},
		onSnapshotError("purchaseProduct"),
	);
};

export const portalLink = async (billingId: string) => {
	const getLinkFunction = httpsCallable(fb.functions, "createPortalLink");

	return getLinkFunction({ billingId, returnUrl: window.location.origin });
};
