import { FiUploadCloud } from "react-icons/fi";
import styled from "styled-components";

type Props = {
	rootProps: any;
	inputProps: any;
	message: string | JSX.Element;
};

export const FileUpload = ({ rootProps, inputProps, message }: Props) => {
	return (
		<CoverImageDropper {...rootProps}>
			<input {...inputProps} />
			<IconWrapper>
				<FiUploadCloud />
			</IconWrapper>
			<div>{message}</div>
		</CoverImageDropper>
	);
};

const CoverImageDropper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 16px 24px;
	border: var(--border);
	text-align: center;
	border-radius: 4px;
	cursor: pointer;
	strong {
		color: var(--color-accent);
		font-weight: 500;
	}
`;
const IconWrapper = styled.div`
	padding: 12px;
	border-radius: 50%;
	background: var(--color-hover);
	margin-bottom: 12px;
	svg {
		display: block;
		font-size: 24px;
	}
`;
