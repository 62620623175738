import styled from "styled-components";
import { color, display, DisplayProps, space, SpaceProps, TypographyProps, width, WidthProps } from "styled-system";

type ButtonProps = {
	small?: boolean;
	rounded?: boolean;
	children: React.ReactNode;
	onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
} & WidthProps &
	TypographyProps &
	DisplayProps &
	SpaceProps;

export const Button: any = styled.button<ButtonProps>`
	color: var(--color-body);
	background-color: var(--color-accent);
	font-weight: 500;

	svg {
		vertical-align: middle;
		margin-right: 6px;
		font-size: 1.25em;
		transform: translateY(-1px);
	}

	${({ small, rounded }) => ({
		fontSize: small ? "14px" : "16px",
		border: "none",
		borderRadius: rounded ? "36px" : "8px",
		cursor: "pointer",
		paddingTop: "10px",
		paddingBottom: "10px",
		paddingLeft: "18px",
		paddingRight: "18px",
		lineHeight: small ? "18px" : "22px",
		opacity: 1,
		transition: "box-shadow 200ms ease-in-out",
	})};

	${color};
	${display};
	${space};
	${width};

	&:focus,
	&:hover:not(:disabled) {
		background-color: var(--color-accent-hover);
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	&:focus {
		outline: none;
	}
`;

Button.defaultProps = {
	small: true,
};

Button.Plain = styled(Button)`
	display: flex;
	align-items: center;
	background-color: transparent;
	color: var(--color-text-secondary);

	&:focus,
	&:hover:not(:disabled) {
		background-color: transparent;
	}

	svg {
		margin-right: 0;
	}
`;

Button.Secondary = styled(Button)`
	color: var(--color-text-heading);
	background-color: var(--color-border);

	&:focus,
	&:hover:not(:disabled) {
		background-color: var(--color-border-dark);
	}
`;

Button.Text = styled(Button)`
	background-color: transparent;
	color: var(--color-text-heading);
	${space}

	&:focus,
	&:hover:not(:disabled) {
		background-color: transparent;
		color: var(--color-accent-hover);
	}
`;

Button.Link = styled(Button)`
	background-color: transparent;
	padding: 0;
	line-height: 1;
	color: var(--color-accent);

	&:focus,
	&:hover:not(:disabled) {
		background-color: transparent;
		color: var(--color-accent-hover);
	}
`;

Button.Outline = styled(Button)`
	background: transparent;
	border: 1px solid var(--color-border-dark);
	text-align: center;
	color: var(--color-text-heading);

	:disabled {
		opacity: 1;
	}

	&:focus,
	&:hover:not(:disabled) {
		background-color: transparent;
	}
`;

Button.Negative = styled(Button)`
	background: transparent;
	border: var(--border);
	color: var(--color-text-heading);

	:disabled {
		opacity: 1;
	}

	&:focus,
	&:hover:not(:disabled) {
		background-color: transparent;
	}
`;

Button.White = styled(Button)`
	background: var(--color-body);
	vertical-align: middle;
	color: var(--color-text-heading);

	img {
		vertical-align: middle;
	}

	${({ theme }) => `
		color: ${theme.colors.blackLight};
		box-shadow: var(--drop-shadow);

		&:hover {
			color: var(--color-body);
			background-color: ${theme.colors.blackLight};
		}
  `}

	${space};
`;

export default Button;
