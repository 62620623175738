import React from "react";

import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { KeyResultsCards } from "@/domains/nodes/components/keyResults/KeyResultsCards";
import { keyResultsNode } from "@/domains/nodes/components/keyResults/recoil/dataKeyResults";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

type Props = {
	node: NodeModel;
};

export const NodeCardKeyResults: React.FC<Props> = ({ node }) => {
	const keyResults = useRecoilValue(keyResultsNode(node.id));

	if (!keyResults.length) {
		return null;
	}

	return (
		<Root>
			<KeyResultsCards results={keyResults} />
		</Root>
	);
};

const Root = styled.div`
	margin-top: 8px;
`;
