import { FiX } from "react-icons/fi";
import styled from "styled-components";

import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

export const FilterClear = () => {
	const { clearFilters } = useFilterService();

	return (
		<Trigger onClick={clearFilters}>
			<span>Clear filters</span>
			<FiX />
		</Trigger>
	);
};

const Trigger = styled.button`
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px dashed var(--color-border);
	font-size: 12px;
	padding: 4px 8px;
	background: transparent;
	color: var(--color-text-heading);
	cursor: pointer;

	span {
		padding-right: 4px;
	}
`;
