import { useCallback, useEffect, useMemo } from "react";

import { formatISO, parseISO } from "date-fns";
import { ActiveModifiers, DayPicker, useInput } from "react-day-picker";

import { Input } from "@/shared/system";
import { NodeMetaDataDateTypes, NodeMetaTypes } from "@/types/db";

const castValueAsDate = (initialValue?: NodeMetaDataDateTypes): Date | undefined => {
	if (!initialValue) {
		return undefined;
	}

	const type = initialValue.type;

	switch (type) {
		case "date": {
			return parseISO(initialValue.data.date);
		}
		case "dateRange": {
			const { from } = initialValue.data;
			return parseISO(from);
		}
		default: {
			const _exhaustiveCheck: never = type;
			return _exhaustiveCheck;
		}
	}
};

export interface DatePickerProps {
	initialValue?: NodeMetaDataDateTypes;
	dateFormat: string;
	label: string;
	onSelect: (date: NodeMetaDataDateTypes) => void;
}

export const DatePickerSingle = ({ initialValue, label, dateFormat, onSelect }: DatePickerProps) => {
	const date = useMemo(() => castValueAsDate(initialValue), [initialValue]);

	const format = dateFormat === "relative" ? "dd/MM/yyyy" : dateFormat;

	const { inputProps, dayPickerProps, reset } = useInput({
		defaultSelected: date,
		fromYear: 2020,
		toYear: 2100,
		format,
		required: true,
	});

	useEffect(() => {
		if (!date) {
			reset();
		}
		// Don't include reset as an effect dependency as its not memoized correctly and puts the component into a render loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, format]);

	const handleDayClick = useCallback(
		(...args: [Date, ActiveModifiers, React.MouseEvent]) => {
			dayPickerProps.onDayClick && dayPickerProps.onDayClick(...args);
			onSelect({
				type: NodeMetaTypes.date,
				version: "1",
				data: {
					date: formatISO(args[0], { representation: "date" }),
					label,
					format: dateFormat,
					reminderOffset: initialValue?.data?.reminderOffset || null,
				},
			});
		},
		[dateFormat, dayPickerProps, initialValue?.data?.reminderOffset, label, onSelect],
	);

	return (
		<>
			<Input type="text" {...inputProps} p="4px 8px" mb="12px" />
			<DayPicker {...dayPickerProps} onDayClick={handleDayClick} />
		</>
	);
};
