import { useRecoilValue } from "recoil";

import { Dropdown, DropdownContent, DropdownTrigger } from "../../../shared/system";
import { getCurrentWorkspace } from "../../workspace/selectors/getCurrentWorkspace";
import { WorkspaceItem } from "./WorkspaceItem";
import WorkspaceSelect from "./WorkspaceSelect";

export const WorkspaceDropdown = () => {
	const workspace = useRecoilValue(getCurrentWorkspace);

	if (!workspace) {
		return null;
	}

	return (
		<Dropdown>
			<DropdownTrigger data-testid="workspace-dropdown__trigger">
				<WorkspaceItem workspace={workspace}></WorkspaceItem>
			</DropdownTrigger>
			<DropdownContent>
				<WorkspaceSelect selectedId={workspace.id} />
			</DropdownContent>
		</Dropdown>
	);
};
