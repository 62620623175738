import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectBoard, ProjectFolder, WithId } from "@/types/db";

export type FileItem<T> = (props: T) => JSX.Element;

export type FileTreeFileMap = {
	board: FileItem<{ project: WithId<ProjectBoard>; onClick?: VoidFunction }>;
	folder: FileItem<{
		project: WithId<ProjectFolder>;
		fileTypeMap: FileTreeFileMap;
		onClick?: VoidFunction;
	}>;
};

type Props = {
	projectId: string;
	type?: "board" | "folder";
	onClick?: (projectId?: string) => void;
	fileTypeMap: FileTreeFileMap;
};

export const FileTree = ({ projectId, type, fileTypeMap, onClick }: Props) => {
	// TODO: this should use getProject
	const projects = useProjectsStore((state) => state.projects);
	const project = projects.get(projectId);

	if (!project || (type && type !== project.type)) {
		return null;
	}
	const projectType = project.type;

	switch (projectType) {
		case "board": {
			const Component = fileTypeMap[project.type];
			return <Component project={project} onClick={onClick} />;
		}
		case "folder": {
			const Component = fileTypeMap[project.type];
			return <Component project={project} fileTypeMap={fileTypeMap} onClick={onClick} />;
		}
		default: {
			const _exhaustiveCheck: never = projectType;
			return _exhaustiveCheck;
		}
	}
};
