import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { nodeMetaService } from "@/domains/nodes/services";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Button } from "@/shared/system";

type Props = {
	metaId: string;
};

export const NodeMetaAdd: React.FC<Props> = ({ metaId }) => {
	const { canEdit } = usePermissions("project");

	const handleAddMeta = () => {
		nodeMetaService.addMetaType(metaId, NodeMetaUtil.createNewNodeMetaTemplate());
	};

	if (!canEdit) {
		return null;
	}

	return (
		<AddPropertyButton onClick={handleAddMeta}>
			<FiPlus /> Add property
		</AddPropertyButton>
	);
};

const AddPropertyButton = styled(Button.Text)`
	display: block;
	color: var(--color-text);
	margin-top: 4px;
	padding: 4px;
`;
