import React from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styled from "styled-components";

import { Avatar, Tooltip } from "../../../shared/system";

dayjs.extend(relativeTime);

export function BoardSeenByAvatar(props: any) {
	const { user } = props;

	const lastSeen = `Viewed ${dayjs.unix(user.seenAt?.seconds).fromNow()}`;

	return (
		<Tooltip
			label={
				<Tip>
					{user.username} <br /> <span>{lastSeen}</span>
				</Tip>
			}>
			<div>
				<Avatar {...props} />
			</div>
		</Tooltip>
	);
}

const Tip = styled.div`
	text-align: center;
	line-height: 140%;

	> span {
		opacity: 0.6;
	}
`;
