import { SyntheticEvent } from "react";

import { captureException } from "@sentry/browser";
import { FiCopy } from "react-icons/fi";
import styled from "styled-components";

import { useToast } from "@/modules/toast/hooks/useToast";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Tooltip } from "@/shared/system";
import Button from "@/shared/system/Button/Button";
import { Popover, PopoverRender, PopoverRenderTrigger } from "@/shared/system/Dropdown/Popover";
import { DropdownTextValue } from "@/shared/system/DropdownText/DropdownTextValue";

export type ActionsFn = (args: { open: (isOpen: boolean) => void }) => JSX.Element;

export type SelectDropdownProps = {
	label: string;
	displayValue: JSX.Element;
	value: string;
	placeholderText?: string;
	openOnClick?: boolean;
	actions?: ActionsFn;
	onComplete: (value: string) => void;
};

export const DropdownText = ({
	label,
	displayValue,
	value,
	placeholderText,
	actions,
	onComplete,
	openOnClick = false,
}: SelectDropdownProps) => {
	const pushToast = useToast();
	// TODO: This should not know about workspace permissions as its a generic component and can be used anywhere
	const { canEdit } = usePermissions("project");

	const handleCopyToClipboard = async (e: SyntheticEvent) => {
		e.stopPropagation();
		try {
			await navigator.clipboard.writeText(value);
			pushToast({ status: "success", message: `${label} copied` });
		} catch (e) {
			pushToast({ status: "error", message: `Copy Failed` });
			captureException(e);
		}
	};

	const hasValue = !!value;

	const trigger: PopoverRenderTrigger = ({ open, triggerProps }) => {
		const { ref, onClick } = triggerProps;
		const handleClick = openOnClick ? onClick : () => {};

		return (
			<Trigger isInteractive={canEdit} onClick={handleClick} ref={ref}>
				{hasValue ? (
					<>
						<TriggerValue>{displayValue}</TriggerValue>
						{actions && actions({ open })}
						<Tooltip label="Copy to clipboard">
							<Button.Plain onClick={handleCopyToClipboard} py={0} px="6px" data-testid="dropdown-text--copy">
								<FiCopy />
							</Button.Plain>
						</Tooltip>
					</>
				) : (
					<>Empty</>
				)}
			</Trigger>
		);
	};

	const render: PopoverRender = ({ close }) => (
		<DropdownTextValue value={value} close={close} placeholder={placeholderText} onComplete={onComplete} />
	);

	return (
		<Popover render={render} offset={-36} isTriggerWidth>
			{trigger}
		</Popover>
	);
};

const Trigger = styled.div<{ isInteractive?: boolean }>`
	display: flex;
	flex-grow: 1;
	padding: 6px;
	width: 100%;
	border-radius: 4px;
	max-height: 600px;
	overflow-y: auto;

	> svg {
		margin-left: 6px;
	}

	button {
		visibility: hidden;
	}

	&:hover {
		background: ${({ isInteractive }) => (isInteractive ? "var(--color-hover)" : "transparent")};

		button {
			visibility: visible;
		}
	}
`;

const TriggerValue = styled.div`
	flex: 1;
`;
