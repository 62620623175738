import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import breakpoints, { breakpointsMax } from "../../../shared/system/breakpoint";

const linkStyle = css`
	display: block;
	font-weight: 300;
	text-decoration: none;
	color: #fff;

	${breakpoints.md`
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		padding: 22px 16px;
		color: var(--color-text);
	`}

	${breakpointsMax.md`
		font-size: 26px;
		line-height: 1;
	`}
`;

export const NavLink = styled(Link)`
	${linkStyle}
`;

export const NavLinkExternal = styled.a`
	${linkStyle}
`;
