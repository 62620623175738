import { Plugin } from "prosemirror-state";

export type UploadFn = (image: File) => Promise<string | undefined>;

export const uploadImagePlugin = (upload: UploadFn) => {
	return new Plugin({
		props: {
			handlePaste(view, event) {
				const items = Array.from(event.clipboardData?.items || []);
				const { schema } = view.state;

				items.forEach((item) => {
					const file = item.getAsFile();

					if (file?.type.indexOf("image") === 0) {
						event.preventDefault();

						if (upload && file) {
							upload(file).then((src) => {
								const node = schema.nodes.image.create({
									src: src,
								});
								const transaction = view.state.tr.replaceSelectionWith(node);
								view.dispatch(transaction);
							});
						}
					}
				});
				return false;
			},
		},
	});
};
