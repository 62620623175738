import { z } from "zod";

import { nodeMetaTypePerson, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";

import { guid } from "../../shared/utils/guid";
import { NodeMetaSystemLabels, NodeMetaTypes } from "../../types/db";
import { statusTemplate } from "../workspace/workspaceTemplates";

export const createNodeMetaNodeTypeTemplate = (nodeTypesSimple: string[]): z.infer<typeof nodeMetaTypeSelect> => {
	return {
		id: guid(),
		type: NodeMetaTypes.select,
		label: NodeMetaSystemLabels.nodeType,
		value: nodeTypesSimple.map((nodeTypeTitle) => ({
			id: guid(),
			title: nodeTypeTitle,
			color: null,
		})),
	};
};

type Status = {
	label: string;
	color: string;
};

export const createNodeMetaStatusTemplate = (statuses: Status[] = statusTemplate): z.infer<typeof nodeMetaTypeSelect> => {
	return {
		id: guid(),
		type: NodeMetaTypes.select,
		label: NodeMetaSystemLabels.status,
		value: statuses.map(({ label, color }) => ({
			id: guid(),
			title: label,
			color,
		})),
	};
};

export const createNodeMetaCollaboratorTemplate = (): z.infer<typeof nodeMetaTypePerson> => {
	return {
		id: guid(),
		type: "person",
		label: "Collaborators",
		value: null,
	};
};
