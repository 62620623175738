import styled from "styled-components";

export const ColorBand: React.FC = () => {
	return <Banner />;
};

const Banner = styled.div`
	left: 0;
	right: 0;
	height: 200px;
	position: absolute;
	background: linear-gradient(var(--color-accent) 0%, transparent 90%);
	opacity: 0.1;
	margin: 8px;
	border-radius: 6px;
`;
