import { CSSProperties } from "react";

import { UniqueIdentifier } from "@dnd-kit/core";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import styled from "styled-components";

import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { ProjectDto, WithId } from "@/types/db";

import { SIDEBAR_NAVIGATION_INDENTATION_WIDTH } from "./SidebarNavigationFolderContainer";
import { SidebarNavigationFolderContainerSortableItem } from "./SidebarNavigationFolderContainerSortableItem";

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => (isSorting || wasDragging ? false : true);

interface Props {
	id: UniqueIdentifier;
	item: ProjectModel;
	collapsed?: boolean;
	onCollapse?: (project: WithId<ProjectDto>) => void;
	clone?: boolean;
	depth: number;
}

export function SidebarNavigationFolderContainerSortable(props: Props) {
	const { id, depth, item, clone, collapsed, onCollapse } = props;

	const { attributes, isDragging, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef, transform, transition } =
		useSortable({
			id,
			animateLayoutChanges,
		});

	const variant = isDragging ? "ghost" : clone ? "clone" : "";

	const style = {
		"--spacing": `${SIDEBAR_NAVIGATION_INDENTATION_WIDTH * depth}px`,
		pointerEvents: isSorting ? "none" : "auto",
	} as CSSProperties;

	return (
		<Root className={variant} ref={setDroppableNodeRef} style={style}>
			<SidebarNavigationFolderContainerSortableItem
				draggableRef={setDraggableNodeRef}
				transform={transform}
				transition={transition}
				item={item}
				collapsed={collapsed}
				onCollapse={onCollapse}
				clone={clone}
				handleProps={{
					...attributes,
					...listeners,
				}}
			/>
		</Root>
	);
}

const Root = styled.div`
	box-sizing: border-box;
	padding-left: var(--spacing);
	margin-bottom: 1px;

	&.clone {
		pointer-events: none;
		padding: 0;
		opacity: 0.8;
		box-shadow: var(--drop-shadow-l2-hover);
		background: var(--color-body);
		border-radius: 4px;

		.TreeItem {
			background: var(--color-hover);
		}
	}

	&.ghost {
		opacity: 1;
		position: relative;
		z-index: 1;
		margin-bottom: -1px;

		.TreeItem {
			position: relative;
			padding: 0;
			height: 2px;
			border-color: var(--color-accent);
			background-color: var(--color-accent);

			&:before {
				position: absolute;
				display: block;
				content: "";
				width: 6px;
				height: 6px;
				border-radius: 50%;
				border: 2px solid var(--color-accent);
				background: var(--color-body);
			}

			> * {
				/* Items are hidden using height and opacity to retain focus */
				opacity: 0;
				height: 0;
			}
		}
	}
`;
