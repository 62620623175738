const dictionary = [
	"aback",
	"abaft",
	"abandoned",
	"abashed",
	"aberrant",
	"abiding",
	"abounding",
	"abrupt",
	"absent",
	"absorbed",
	"absorbing",
	"abstracted",
	"absurd",
	"abundant",
	"acceptable",
	"accessible",
	"accidental",
	"accurate",
	"acidic",
	"acoustic",
	"actually",
	"adamant",
	"adventurous",
	"afraid",
	"agonizing",
	"agreeable",
	"ahead",
	"ajar",
	"alert",
	"alike",
	"alive",
	"alleged",
	"alluring",
	"aloof",
	"amazing",
	"ambiguous",
	"ambitious",
	"amuck",
	"amused",
	"amusing",
	"ancient",
	"angry",
	"animated",
	"annoyed",
	"annoying",
	"anxious",
	"apathetic",
	"aquatic",
	"aromatic",
	"ashamed",
	"available",
	"average",
	"awake",
	"aware",
	"awesome",
	"awful",
	"axiomatic",
	"bad",
	"barbarous",
	"bashful",
	"beautiful",
	"befitting",
	"belligerent",
	"beneficial",
	"bent",
	"berserk",
	"best",
	"better",
	"bewildered",
	"bloody",
	"blue",
	"blushing",
	"boiling",
	"boorish",
	"bouncy",
	"boundless",
	"brainy",
	"brash",
	"brave",
	"brawny",
	"breakable",
	"breezy",
	"brief",
	"bright",
	"bumpy",
	"burly",
	"bustling",
	"busy",
	"cagey",
	"calculating",
	"calm",
	"capable",
	"capricious",
	"careful",
	"careless",
	"caring",
	"cautious",
	"ceaseless",
	"cheerful",
	"chemical",
	"chief",
	"childlike",
	"chilly",
	"chivalrous",
	"clammy",
	"classy",
	"clean",
	"cluttered",
	"coherent",
	"cold",
	"colorful",
	"colossal",
	"combative",
	"comfortable",
	"common",
	"complete",
	"complex",
	"concerned",
	"condemned",
	"confused",
	"conscious",
	"cooing",
	"cool",
	"cooperative",
	"coordinated",
	"courageous",
	"cowardly",
	"crabby",
	"craven",
	"crazy",
	"creepy",
	"crooked",
	"crowded",
	"cruel",
	"cuddly",
	"cultured",
	"cumbersome",
	"curious",
	"curly",
	"cut",
	"cute",
	"cynical",
	"daffy",
	"daily",
	"damaged",
	"damaging",
	"damp",
	"deafening",
	"dear",
	"debonair",
	"decisive",
	"decorous",
	"delightful",
	"demonic",
	"delirious",
	"dependent",
	"depressed",
	"deranged",
	"descriptive",
	"deserted",
	"detailed",
	"determined",
	"devilish",
	"dizzy",
	"domineering",
	"doubtful",
	"drab",
	"draconian",
	"dramatic",
	"dreary",
	"dry",
	"dull",
	"dusty",
	"dynamic",
	"dysfunctional",
	"empty",
	"enchanted",
	"enchanting",
	"encouraging",
	"endurable",
	"energetic",
	"enormous",
	"entertaining",
	"enthusiastic",
	"envious",
	"equable",
	"equal",
	"erect",
	"erratic",
	"ethereal",
	"evanescent",
	"evasive",
	"exotic",
	"expensive",
	"exultant",
	"faithful",
	"fallacious",
	"fanatical",
	"fancy",
	"fantastic",
	"far",
	"fast",
	"fat faulty",
	"festive",
	"few fierce",
	"filthy",
	"fine",
	"finicky",
	"first",
	"flaky",
	"flashy",
	"flat",
	"flawless",
	"flimsy",
	"flowery",
	"fluffy",
	"fluttering",
	"foregoing",
	"forgetful",
	"fortunate",
	"four frail",
	"fragile",
	"frantic",
	"free",
	"funny",
	"fuzzy ",
	"gabby",
	"gainful",
	"gamy",
	"gaping",
	"garrulous",
	"gaudy",
	"giant",
	"giddy",
	"gifted",
	"gigantic",
	"graceful",
	"grandiose",
	"guarded",
	"guiltless",
	"half",
	"hallowed",
	"halting",
	"handsome",
	"handsomely",
	"handy",
	"hanging",
	"hapless",
	"happy",
	"hard",
	"harmonious",
	"harsh",
	"hateful",
	"heady",
	"healthy",
	"helpful",
	"helpless",
	"hesitant",
	"highfalutin",
	"hilarious",
	"hissing",
	"historical",
	"holistic",
	"hollow",
	"hospitable",
	"hot huge",
	"hulking",
	"humdrum",
	"humorous",
	"hungry",
	"hurried",
	"hurt",
	"hushed",
	"husky",
	"hypnotic",
	"hysterical",
	"icky",
	"icy",
	"idiotic",
	"ignorant",
	"ill",
	"illegal",
	"imaginary",
	"immense",
	"imminent",
	"impartial",
	"impossible",
	"inexpensive",
	"infamous",
	"insidious",
	"instinctive",
	"intelligent",
	"interesting",
	"internal",
	"invincible",
	"irate",
	"irritating",
	"itchy",
	"jaded",
	"jagged",
	"jazzy",
	"jealous",
	"jittery",
	"jobless",
	"jolly",
	"joyous",
	"judicious",
	"juicy",
	"jumbled",
	"jumpy",
	"juvenile",
	"kaput",
	"keen",
	"kind",
	"kindly",
	"knotty",
	"knowing",
	"labored",
	"lacking",
	"lame",
	"languid",
	"lavish",
	"lazy",
	"lean",
	"learned",
	"left",
	"legal",
	"lethal",
	"level",
	"lewd",
	"light",
	"like",
	"likeable",
	"limping",
	"literate",
	"little",
	"lively",
	"lively",
	"living",
	"lonely",
	"long",
	"longing",
	"loose",
	"lopsided",
	"loud",
	"loutish",
	"lovely",
	"loving",
	"low",
	"lowly",
	"lucky",
	"ludicrous",
	"lumpy",
	"macabre",
	"macho",
	"maddening",
	"madly",
	"magenta",
	"magical",
	"magnificent",
	"majestic",
	"makeshift",
	"male",
	"malicious",
	"mammoth",
	"maniacal",
	"many",
	"marked",
	"massive",
	"married",
	"marvelous",
	"material",
	"mature",
	"mean",
	"measly",
	"meaty",
	"medical",
	"meek",
	"mellow",
	"melodic",
	"melted",
	"merciful",
	"mere",
	"messy",
	"mighty",
	"military",
	"milky",
	"mindless",
	"moaning",
	"modern",
	"moldy",
	"momentous",
	"muddled",
	"mundane",
	"murky",
	"mushy",
	"mute",
	"mysterious",
	"naive",
	"nappy",
	"neat",
	"nebulous",
	"necessary",
	"needless",
	"needy",
	"neighborly",
	"nervous",
	"new",
	"next",
	"nice",
	"nifty",
	"nimble",
	"nine",
	"nippy",
	"noiseless",
	"noisy",
	"nonchalant",
	"nondescript",
	"nonstop",
	"normal",
	"nostalgic",
	"nosy",
	"noxious",
	"oceanic",
	"odd",
	"offbeat",
	"old",
	"omniscient",
	"one",
	"onerous",
	"open",
	"opposite",
	"optimal",
	"orange",
	"ordinary",
	"organic",
	"ossified",
	"outgoing",
	"outrageous",
	"outstanding",
	"oval",
	"overconfident",
	"overjoyed",
	"overrated",
	"overt",
	"overwrought",
	"painful",
	"painstaking",
	"pale",
	"paltry",
	"panicky",
	"penitent",
	"perfect",
	"periodic",
	"permissible",
	"perpetual",
	"petite",
	"petite",
	"phobic",
	"physical",
	"picayune",
	"pink",
	"piquant",
	"placid",
	"plain",
	"plant",
	"plastic",
	"plausible",
	"pleasant",
	"plucky",
	"pointless",
	"poised",
	"polite",
	"political",
	"poor",
	"possessive",
	"previous",
	"protective",
	"proud",
	"psychedelic",
	"psychotic",
	"public",
	"puffy",
	"pumped",
	"puny",
	"purple",
	"purring",
	"pushy",
	"puzzled",
	"puzzling",
	"quack",
	"quaint",
	"questionable",
	"quick",
	"quickest",
	"quiet",
	"quirky",
	"quixotic",
	"quizzical",
	"rabid",
	"racial",
	"ragged",
	"rainy",
	"rambunctious",
	"rampant",
	"rapid",
	"rare",
	"raspy",
	"ratty",
	"ready",
	"real",
	"rebel",
	"receptive",
	"recondite",
	"red",
	"redundant",
	"reflective",
	"regular",
	"relieved",
	"romantic",
	"roomy",
	"rough",
	"rural",
	"rustic",
	"ruthless",
	"sable",
	"sad",
	"safe",
	"salty",
	"same",
	"sassy",
	"satisfying",
	"savory",
	"scandalous",
	"scarce",
	"scared",
	"scary",
	"scrawny",
	"screeching",
	"second",
	"second-hand",
	"secret",
	"secretive",
	"sedate",
	"seemly",
	"selective",
	"selfish",
	"separate",
	"serious",
	"shaggy",
	"shivering",
	"shocking",
	"short",
	"shrill",
	"shut",
	"shy",
	"silly",
	"simple",
	"simplistic",
	"small",
	"smart",
	"smelly",
	"smiling",
	"smoggy",
	"smooth",
	"sneaky",
	"snobbish",
	"snotty",
	"soft",
	"soggy",
	"sparkling",
	"special",
	"spectacular",
	"spicy",
	"spiffy",
	"spiky",
	"spiritual",
	"spiteful",
	"statuesque",
	"steadfast",
	"strong",
	"stupendous",
	"stupid",
	"sudden",
	"sulky",
	"super",
	"superb",
	"superficial",
	"supreme",
	"swanky",
	"sweet",
	"sweltering",
	"swift",
	"symptomatic",
	"synonymous",
	"taboo",
	"tacit",
	"tacky",
	"talented",
	"tangy",
	"tart",
	"tasteful",
	"tasteless",
	"tasty",
	"tawdry",
	"tearful",
	"tedious",
	"teeny",
	"teeny-tiny",
	"telling",
	"temporary",
	"ten",
	"tender tense",
	"tense",
	"tenuous",
	"terrible",
	"terrific",
	"tested",
	"testy",
	"thankful",
	"therapeutic",
	"thick",
	"thin",
	"thinkable",
	"third",
	"thirsty",
	"thoughtful",
	"thoughtless",
	"threatening",
	"three",
	"thundering",
	"tidy",
	"tight",
	"tightfisted",
	"tiny",
	"tired",
	"tiresome",
	"toothsome",
	"torpid",
	"trashy",
	"tremendous",
	"tricky",
	"ugliest",
	"ugly",
	"ultra",
	"unable",
	"unaccountable",
	"unadvised",
	"unarmed",
	"unbecoming",
	"unbiased",
	"uncovered",
	"understood",
	"uptight",
	"used",
	"useful",
	"useless",
	"utopian",
	"utter",
	"uttermost",
	"vacuous",
	"vagabond",
	"vague",
	"venomous",
	"violent",
	"violet",
	"vivacious",
	"voiceless",
	"volatile",
	"voracious",
	"vulgar",
	"wanting",
	"warlike",
	"warm",
	"wary",
	"wasteful",
	"watery",
	"weak",
	"willing",
	"windy",
	"wiry",
	"wise",
	"wistful",
	"witty",
	"woebegone",
	"womanly",
	"wonderful",
	"wooden",
	"woozy",
	"workable",
	"worried",
	"yielding",
	"young",
	"youthful",
	"yummy",
	"zany",
	"zealous",
	"zesty",
	"zippy",
	"zonked",
];

export default dictionary;
