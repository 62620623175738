import { useEffect } from "react";

import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { atomFamily, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../shared/utils/dataUtils";
import { UserComment } from "../../../types/db";
import { WithId } from "../../../types/db/utils";

export const commentsState = atomFamily<WithId<UserComment>[], string>({
	key: "commentsState",
	default: [],
});

// @ts-expect-error TS(7006) FIXME: Parameter 'nodeId' implicitly has an 'any' type.
export const useCommentsListener = (nodeId, workspaceId) => {
	const [, setNodeComments] = useRecoilState(commentsState(nodeId));

	useEffect(() => {
		if (nodeId) {
			const commentQuery = query(
				db.comments,
				where("workspace", "==", workspaceId),
				where("node", "==", nodeId),
				orderBy("createdAt"),
			);

			return onSnapshot(
				commentQuery,
				(snapshot) => {
					setNodeComments(extractFromCollectionWithId(snapshot));
				},
				onSnapshotError("useCommentsListener"),
			);
		}
	}, [nodeId, setNodeComments, workspaceId]);
};
