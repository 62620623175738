import { FunctionComponent, useLayoutEffect } from "react";

import Mousetrap from "mousetrap";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useToast } from "@/modules/toast/hooks/useToast";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { NodeLayout } from "@/types/db";

import Background from "../assets/background.svg?react";
import Icon from "../assets/icon.svg?react";
import Stacked from "../assets/stacked.svg?react";

export type LayoutOption = {
	icon: FunctionComponent;
	label: string;
	value: NodeLayout;
	shortcut: string;
};

export const nodeLayoutOptions: LayoutOption[] = [
	{ icon: Stacked, label: "Default", value: "stacked", shortcut: "c d" },
	{ icon: Background, label: "Cover", value: "background", shortcut: "c c" },
	{ icon: Icon, label: "Icon", value: "icon", shortcut: "c i" },
];

type UseShortcutsNodeLayout = (node: NodeModel) => void;

export const useShortcutsNodeEdit: UseShortcutsNodeLayout = (node) => {
	const nodesService = useNodesService();

	const pushToast = useToast();

	const handleCollapseNode = (event: Mousetrap.ExtendedKeyboardEvent) => {
		event.preventDefault();
		node.children.length > 0 && nodesService.collapseNode(node.id);
	};

	useBindShortcut("h", handleCollapseNode);

	const handleRenameNode = (event: Mousetrap.ExtendedKeyboardEvent) => {
		event.preventDefault();
		nodesService.localRename(node.id);
	};

	useBindShortcut("r", handleRenameNode);

	useLayoutEffect(() => {
		const handleLayoutUpdate = (layout: NodeLayout) => (e: Mousetrap.ExtendedKeyboardEvent) => {
			e.preventDefault();
			nodesService.update(node.id, { layout });
		};

		nodeLayoutOptions.forEach((layout) => {
			Mousetrap.bind(layout.shortcut, handleLayoutUpdate(layout.value));
		});

		return () => {
			nodeLayoutOptions.forEach((layout) => {
				Mousetrap.unbind(layout.shortcut);
			});
		};
	}, [node, nodesService, pushToast]);
};
