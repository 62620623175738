import { useRef } from "react";

import _ from "lodash";
import { FiGrid } from "react-icons/fi";
import styled from "styled-components";

import { CreateProject } from "@/domains/accounts/components/createProject/CreateProject";
import { SidebarNavigationContextMenu } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationContextMenu";
import { SidebarNavigationFolder } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationFolder";
import { SidebarNavigationFooter } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationFooter";
import { SidebarNavigationItem } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItem";
import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { Inbox } from "@/modules/inbox/Inbox";
import { PlanUpgrade } from "@/modules/plans/PlanUpgrade";
import routes from "@/shared/core/routes";
import { SystemFolderName } from "@/types/db";

export const SidebarNavigation = () => {
	const navigationEl = useRef<HTMLDivElement>(null);
	const { tree } = useNavigationService();

	if (_.isEmpty(tree)) {
		return null;
	}

	const treeFavourite = tree[SystemFolderName.favorite];
	const treeWorkspace = tree[SystemFolderName.workspace];
	const treeShared = tree[SystemFolderName.shared];
	const treePrivate = tree[SystemFolderName.private];

	return (
		<>
			<Root ref={navigationEl}>
				<SidebarNavigationContextMenu rootEl={navigationEl} />
				<Pages>
					<SidebarNavigationItem to={routes.home}>
						<FiGrid /> Dashboard
					</SidebarNavigationItem>
					<Inbox />
				</Pages>
				<SidebarNavigationFolder tree={treeFavourite} hideIfEmpty />
				<SidebarNavigationFolder tree={treeWorkspace} canAdd />
				<SidebarNavigationFolder tree={treeShared} hideIfEmpty />
				<SidebarNavigationFolder tree={treePrivate} canAdd />
				<SidebarNavigationFooter />
			</Root>
			<PlanUpgrade />
			<CreateProject />
		</>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	flex-grow: 1;
	gap: 14px;
	margin-left: -6px;
	margin-right: -6px;
`;

const Pages = styled.div`
	margin-bottom: 6px;
`;
