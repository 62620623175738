import { useCallback } from "react";

import { format, fromUnixTime } from "date-fns";
import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { KeyResultsSetScreen } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { Divider, Link } from "@/shared/system";
import { ModalHeader, ModalSubHeader } from "@/shared/system/Modal/Modal";
import { KeyResultModel } from "@/types/db";

import { formatTarget, isNoTarget } from "../../utils/keyResultsUtils";
import { KeyResultsActivity } from "../activity/KeyResultsActivity";
import { KeyResultsChart } from "../charts/KeyResultsChart";
import { KeyResultsNoTarget } from "../charts/KeyResultsNoTarget";

const buildTargetData = (target: string, { startDate, endDate, startingValue }: KeyResultModel) => {
	return [
		{ x: fromUnixTime(startDate.seconds), y: parseInt(startingValue, 10) },
		{ x: fromUnixTime(endDate.seconds), y: parseInt(target, 10) },
	];
};

const buildUpdateData = ({ startDate, startingValue, updates }: KeyResultModel) => {
	const updatePoints = updates.map(({ value, date }) => ({
		x: new Date(date),
		y: parseInt(value, 10),
	}));

	updatePoints.splice(0, 0, {
		x: fromUnixTime(startDate.seconds),
		y: parseInt(startingValue, 10),
	});

	return updatePoints;
};

const keyResultTypes: Record<KeyResultModel["goalType"], string> = {
	"get-to": "Get to",
	"stay-above": "Stay above",
	"stay-below": "Stay below",
	"no-target": "No target",
};

const dateFormat = "MMMM do yyyy";

type Props = {
	result: KeyResultModel;
	setActiveScreen: KeyResultsSetScreen;
};

export const KeyResultsDetails: React.FC<Props> = ({ result, setActiveScreen }) => {
	const handleAddUpdate = useCallback(() => {
		setActiveScreen("activity");
	}, [setActiveScreen]);

	const { goalType, startDate, endDate, startingValue, targetValue } = result;

	const { target } = formatTarget(targetValue);

	const targetData = buildTargetData(target, result);
	const updateData = buildUpdateData(result);

	return (
		<>
			<ModalHeader>{result.title}</ModalHeader>
			<ModalSubHeader>
				<span>{keyResultTypes[result.goalType]}</span> <Goal>{result.targetValue}</Goal>{" "}
				<span>by {format(fromUnixTime(endDate.seconds), dateFormat)},</span>{" "}
				<span>starting {format(fromUnixTime(startDate.seconds), dateFormat)}.</span>
			</ModalSubHeader>
			<Root>
				<Chart>
					{isNoTarget(goalType) ? (
						<KeyResultsNoTarget result={result} />
					) : (
						<KeyResultsChart
							goalType={goalType}
							startingValue={startingValue}
							currentData={updateData}
							targetData={targetData}
						/>
					)}
				</Chart>
			</Root>
			<KeyResultsActivity keyResult={result} />
			<Divider my="16px" />
			<Link.Href onClick={handleAddUpdate}>
				<FiPlus /> Write an update
			</Link.Href>
		</>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
`;

const Goal = styled.span`
	color: var(--color-text-heading);
`;

const Chart = styled.div``;
