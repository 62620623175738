import { useMemo } from "react";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { Result } from "@/shared/utils/result";

export interface IProjectsService {
	getProject: (projectId: ProjectModel["id"]) => Result<ProjectModel>;
	getProjectRoadmapGrouping: (projectId: ProjectModel["id"]) => Result<string>;
	getHasProjectMeta: (projectId: ProjectModel["id"]) => boolean;
}

export const useProjectsService = (): IProjectsService => {
	const projectsStore = useProjectsStore();

	return useMemo(() => {
		const getProject: IProjectsService["getProject"] = (projectId) => {
			const project = projectsStore.projects.get(projectId);

			if (project === undefined) {
				return Result.fail("Unable to get project");
			}

			return Result.ok(project);
		};

		const getProjectRoadmapGrouping: IProjectsService["getProjectRoadmapGrouping"] = (projectId) => {
			const projectOrError = getProject(projectId);

			if (projectOrError.isFailure) {
				return Result.fail(projectOrError.getErrorValue());
			}

			const project = projectOrError.getValue();

			if (project.type === "folder") {
				return Result.fail("Project is not a board");
			}

			const groupId = project.meta?.groupedBy;

			if (!groupId) {
				return Result.fail("Unable to find column group Id");
			}

			return Result.ok(groupId);
		};

		const getHasProjectMeta: IProjectsService["getHasProjectMeta"] = (projectId) => {
			const projectOrError = getProject(projectId);

			if (projectOrError.isFailure) {
				return false;
			}

			const project = projectOrError.getValue();

			return !!(project?.type === "board" && project?.metaId);
		};

		return {
			getProject,
			getProjectRoadmapGrouping,
			getHasProjectMeta,
		};
	}, [projectsStore]);
};
