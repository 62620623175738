import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { atomFamily, selectorFamily, useRecoilState } from "recoil";

import { useNotesRepository } from "@/domains/nodes/hooks/useNotesRepository";
import { useNotesService } from "@/domains/nodes/hooks/useNotesService";
import { NoteModel } from "@/domains/nodes/models/notesModel";
import { useToast } from "@/modules/toast/hooks/useToast";
import { Result } from "@/shared/utils/result";

export const noteState = atomFamily<NoteModel | null, string>({
	key: "noteState",
	default: null,
});

export const getNoteId = selectorFamily<string | undefined, string>({
	key: "getNoteId",
	get:
		(nodeId) =>
		({ get }) => {
			const note = get(noteState(nodeId));
			return note?.id;
		},
});

export const useGetNote = () => {
	const { nodeId } = useParams() as { boardId: string; nodeId: string };
	const setToast = useToast();
	const { createEmptyNote } = useNotesService(nodeId);
	const noteRepository = useNotesRepository(nodeId);

	const [, setNote] = useRecoilState(noteState(nodeId));

	useEffect(() => {
		const save = (noteOrError: Result<NoteModel>) => {
			if (noteOrError.isFailure) {
				createEmptyNote();
				return;
			}
			const note = noteOrError.getValue();

			setNote(note);
		};

		noteRepository.get(save);

		// clear on unmount
	}, [createEmptyNote, nodeId, noteRepository, setNote, setToast]);
};
