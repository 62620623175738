import { useParams } from "react-router";
import styled from "styled-components";

import { BreadcrumbsBoards } from "../../../page/components/BreadcrumbsBoards";

export const TopBarProjectPath = () => {
	const { boardId } = useParams() as { boardId: string };

	if (!boardId) {
		return null;
	}

	return (
		<Name>
			<BreadcrumbsBoards />
		</Name>
	);
};

export const Name = styled.div`
	display: flex;
	align-items: center;
	color: var(--color-text-heading);
`;
