import styled from "styled-components";

import { ActionHistory } from "@/domains/projects/components/treeView/components/Controls/components/ActionHistory";
import { Zoom } from "@/domains/projects/components/treeView/components/Controls/components/Zoom";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

export const Controls: React.FC = () => {
	const { canEdit } = usePermissions("project");

	const isEditable = canEdit;

	return (
		<Root className="hide-from-export">
			{isEditable && <ActionHistory />}
			<Zoom isEmbedded={!isEditable} />
		</Root>
	);
};

const Root = styled.div`
	position: absolute;
	right: 24px;
	bottom: 24px;
	display: flex;
	gap: 16px;
`;
