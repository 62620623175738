import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import styled from "styled-components";

import { NodeDetails } from "@/domains/nodes/components/details/NodeDetails";

import routes from "../../shared/core/routes";
import { BoardContainer } from "./components/BoardContainer";
import { BoardListeners } from "./components/BoardListeners";
import { TopBar } from "./topBar/TopBar";

export const BoardAuthWrapper: React.FC = ({ children }) => {
	const location = useLocation();
	const routeKey = location.pathname?.split("/")[3] ?? "";

	return (
		<ReactFlowProvider>
			<TopBar />
			<Background>
				<BoardListeners />
				<BoardContainer>{children}</BoardContainer>
			</Background>
			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={routeKey}>
					<Route path={routes.node} element={<NodeDetails />} />
				</Routes>
			</AnimatePresence>
		</ReactFlowProvider>
	);
};

const Background = styled.div`
	min-height: 0;
	height: 100%;
	position: relative;
`;
