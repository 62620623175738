import pluralize from "pluralize";
import { Helmet } from "react-helmet-async";
import { FiCheck } from "react-icons/fi";
import styled from "styled-components";

import { AvatarStatic } from "../../../shared/system/Avatar/AvatarStatic";
import { Workspace } from "../../../types/db";
import { AvailablePlans } from "../../plans/planUtils";

const planText = (plan?: keyof typeof AvailablePlans) => {
	switch (plan) {
		case undefined: {
			return "Free Plan";
		}
		case "appSumo": {
			return "AppSumo LTD";
		}
		default: {
			return `${AvailablePlans[plan]} Plan`;
		}
	}
};

type Props = {
	workspace: Workspace;
	showPlan?: boolean;
	selected?: boolean;
};

export function WorkspaceItem({ workspace, showPlan = false, selected = false }: Props) {
	const memberCount = workspace.users.allIds.length;
	const logo = workspace.logoUrl;

	return (
		<WorkspaceWrapper selected={selected}>
			{logo && (
				<Helmet>
					<link rel="preload" as="image" href={logo} />
				</Helmet>
			)}
			<Logo>
				<AvatarStatic name={workspace.title} imageUrl={logo} shape="square" size={36} />
			</Logo>
			<Content>
				<Title>{workspace.title}</Title>
				{showPlan && (
					<SubTitle>
						{planText(workspace.plan)} • {memberCount} {pluralize("Member", memberCount)}
					</SubTitle>
				)}
			</Content>
			{selected && <Check />}
		</WorkspaceWrapper>
	);
}

export const WorkspaceWrapper = styled.div<{ selected: boolean }>`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const Logo = styled.div``;

export const Content = styled.div``;

export const Title = styled.div`
	width: 151px;
	color: var(--color-text-heading);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.2;
`;

export const SubTitle = styled.div`
	font-size: 12px;
	line-height: 1;
	margin-top: 2px;
	color: var(--color-text);
`;

const Check = styled(FiCheck)`
	font-size: 18px;
`;
