import { isEqual } from "lodash";

const isRecord = (val: unknown): val is Record<string, unknown> => {
	return typeof val === "object" && !Array.isArray(val) && val !== null;
};

const difference = <T extends Record<string, unknown>>(obj1: T, obj2: T): Partial<T> => {
	if (!obj1 || !obj2) {
		return {};
	}

	const diff = Object.keys(obj1).reduce(
		(result, key) => {
			const val1 = obj1[key];
			const val2 = obj2[key];

			if (isEqual(val1, val2)) {
				delete result[key];
			} else if (isRecord(val1) && isRecord(val2)) {
				/**
				 * Special case for meta so that the entire object is returned rather than just
				 * the changed keys as meta has been de-normalised
				 */
				if (key === "meta") {
					// @ts-ignore
					result[key] = differentKeys(val1, val2).reduce((carry, metaId) => {
						// @ts-ignore
						carry[metaId] = val2[metaId] || { ...val1[metaId], value: null };
						return carry;
					}, {});
				} else {
					// @ts-ignore
					result[key] = difference(val1, val2);
				}
			}

			return result;
		},
		{ ...obj2 },
	);

	return diff;
};

const differentKeys = (obj1: Record<string, unknown>, obj2: Record<string, unknown>): string[] => {
	if (!obj1 || !obj2) {
		return [];
	}

	const diff = Object.keys(obj1).reduce((result, key) => {
		// eslint-disable-next-line
		if (!obj2.hasOwnProperty(key)) {
			result.push(key);
		} else if (isEqual(obj1[key], obj2[key])) {
			const resultKeyIndex = result.indexOf(key);
			result.splice(resultKeyIndex, 1);
		}
		return result;
	}, Object.keys(obj2));

	return diff;
};

export default difference;
