import React from "react";

import styled from "styled-components";

import KeyResultsIcon from "@/domains/nodes/components/details/assets/keyResultsIcon.svg?react";
import { Button, Link } from "@/shared/system";

type Props = {
	onAdd: VoidFunction;
};

export const KeyResultsDetailsListEmpty: React.FC<Props> = ({ onAdd }) => {
	return (
		<Root>
			<TagLine>
				<KeyResultsIcon />
				<span>
					Inspire your team by <Link.Href onClick={onAdd}>setting and tracking goals</Link.Href>
				</span>
			</TagLine>
			<Button.Secondary onClick={onAdd}>Set a goal</Button.Secondary>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: var(--border);
	border-radius: 8px;
	padding: 12px;
	margin-top: 16px;
`;

const TagLine = styled.div`
	display: flex;
	align-items: center;

	svg {
		color: var(--color-accent);
		display: block;
		margin-right: 12px;
		font-size: 20px;
	}
`;
