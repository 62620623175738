import { FiWifi } from "react-icons/fi";
import styled from "styled-components";

import { ProjectIntegrationSlack } from "@/domains/projects/dtos/projectsDto";
import { ProjectBoard, WithId } from "@/types/db";

import { DropdownMenuButton } from "../../../../shared/system/Dropdown/components/DropdownMenuItem";
import SlackIcon from "../../../account/slack/assets/slack-logo.svg?react";
import { popupWindow } from "../../../account/slack/popupWindow";
import { deleteSlackWebhookAuth, getSlackWebhookAuth } from "../../../account/slack/slackData";

// TODO: Probably should be moved to ProjectUtils
const getSlackIntegration = (project: WithId<ProjectBoard>) => {
	const integrations = project?.integrations;

	if (integrations) {
		return Object.values(integrations).find((_): _ is ProjectIntegrationSlack => _.type === "slack");
	}
};

type Props = {
	project: WithId<ProjectBoard>;
};

export const MoreSlack = ({ project }: Props) => {
	const workspaceId = project.workspaceId;
	const projectId = project.id;

	const integrationDetails = getSlackIntegration(project);

	const isActive = !!integrationDetails;

	const handleToggleWebhook = async () => {
		if (!isActive) {
			const url = await getSlackWebhookAuth({ workspaceId: workspaceId, boardId: projectId });
			popupWindow({ url, w: 600, h: 700 });
		} else {
			await deleteSlackWebhookAuth({ workspaceId: workspaceId, boardId: projectId });
		}
	};

	let body = (
		<>
			<ConnectIcon /> Connect to Slack
		</>
	);

	if (isActive) {
		body = (
			<ActiveRoot>
				<SlackIcon />{" "}
				<div>
					<div>{integrationDetails.data.webhook?.channel}</div>
					<SecondaryText>Connected to Slack</SecondaryText>
				</div>
			</ActiveRoot>
		);
	}

	return <DropdownMenuButton onClick={handleToggleWebhook}>{body}</DropdownMenuButton>;
};

const ActiveRoot = styled.div`
	display: flex;

	> * {
		line-height: 1;
	}
`;

const ConnectIcon = styled(FiWifi)`
	transform: rotate(90deg);
`;

const SecondaryText = styled.div`
	font-size: 12px;
	margin-top: 4px;
	color: var(--color-text-secondary);
`;
