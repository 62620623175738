import { hierarchy, HierarchyNode } from "d3";
import _ from "lodash";

import { ProjectModel, ProjectModelFolder } from "@/domains/projects/models/projectsModel";
import { IProjectsStore } from "@/domains/projects/zustand/projectsStore";
import { Members, ProjectDto, SystemFolderName } from "@/types/db";

export type NavigationTreeNode = HierarchyNode<ProjectModel>;
export type NavigationTree = Record<SystemFolderName, NavigationTreeNode>;

export class NavigationUtils {
	private static hasActiveMembers(access: Members) {
		const members = Object.values(access).filter(({ status }) => {
			return status === "active";
		});

		return members.length > 1;
	}

	private static projectIsShared(project: ProjectDto, activeWorkspaceId?: string) {
		const sharedPrivateBoard =
			project.visibility === "private" && project.access !== null && NavigationUtils.hasActiveMembers(project.access);

		const sharedWithGuest = project.workspaceId !== activeWorkspaceId;

		return sharedPrivateBoard || sharedWithGuest;
	}

	private static buildSharedFolder(projects: ProjectModel[], activeWorkspaceId?: string) {
		const rootNode = {
			id: "guestBoards",
			type: "folder",
			data: {
				name: "Shared",
			},
		} as any;

		const children = Object.values(projects).filter((project) => {
			return project.type === "board" && NavigationUtils.projectIsShared(project, activeWorkspaceId);
		});

		const treeShared: any = hierarchy({
			...rootNode,
			children,
		});

		return treeShared;
	}

	public static buildTree(workspaceId: string | undefined, projects: IProjectsStore["projects"]): NavigationTree {
		const projectsIterable = [...projects.values()];

		const rootNodes = projectsIterable.filter((project: ProjectModel): project is ProjectModelFolder => {
			// createdBy.id can be O (number) bad legacy types...
			// eslint-disable-next-line eqeqeq
			return project.type === "folder" && project.createdBy.id == "0";
		});

		const mapChildren = (projectId: string): any => {
			const project = projects.get(projectId);

			if (!project) {
				return;
			}

			if (project.type === "board") {
				return project;
			}

			return {
				...project,
				children: project.childIds.map(mapChildren),
			};
		};

		const hierarchyNodes = rootNodes.map((project) => {
			return hierarchy({
				...project,
				children: project.childIds.map(mapChildren).filter(Boolean),
			});
		});

		const folders = _.keyBy(hierarchyNodes, "data.data.name");
		folders[SystemFolderName.shared] = NavigationUtils.buildSharedFolder(projectsIterable, workspaceId);

		// 🚨 TODO: This is too strongly typed folders can be undefined...
		// @ts-expect-error
		return folders;
	}
}
