import React, { useCallback, useEffect, useRef } from "react";

import { useCombobox, useMultipleSelection } from "downshift";
import { useFormContext } from "react-hook-form";
import { FiMail } from "react-icons/fi";
import styled from "styled-components";

import { ProjectInvite, ShareFormValues } from "@/modules/board/share/shareUsers/shareTypes";
import { validateEmailAddress } from "@/modules/members/components/MemberInviteForm";
import { UserDetails } from "@/modules/members/primitives/UserDetails";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Avatar } from "@/shared/system";

type Props = {
	items: ProjectInvite[];
};

export const Combobox = ({ items }: Props) => {
	const inputEl = useRef<HTMLInputElement>();
	const [inputValue, setInputValue] = React.useState("");
	const [inputItems, setInputItems] = React.useState(items);
	const [isCreating, setIsCreating] = React.useState(false);

	const { canEdit } = usePermissions("project");

	const { watch, setValue } = useFormContext<ShareFormValues>();

	const selectedMembers = watch("members");
	const setSelectedMembers = (value: any[]) => setValue("members", value);

	const getFilteredMembers = useCallback(
		(selectedItems: ProjectInvite[], inputValue: string) => {
			const lowerCasedInputValue = inputValue.toLowerCase();

			return inputItems.filter((item) => {
				return (
					!selectedItems.includes(item) &&
					(item.data.username.toLowerCase().includes(lowerCasedInputValue) ||
						item.data.email.toLowerCase().includes(lowerCasedInputValue))
				);
			});
		},
		[inputItems],
	);

	const resizeInput = () => {
		if (inputEl.current) {
			inputEl.current.style.width = (inputEl.current.value.length + 1) * 10 + "px";
		}
	};

	const handleCreateItem = (item: any) => {
		setInputItems([...selectedMembers, item]);
		setSelectedMembers([...selectedMembers, item]);
	};

	const { getSelectedItemProps, getDropdownProps, removeSelectedItem, activeIndex } = useMultipleSelection({
		selectedItems: selectedMembers,
		onStateChange({ selectedItems: newSelectedItems, type }) {
			switch (type) {
				case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
				case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
				case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
				case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
					if (newSelectedItems && newSelectedItems.length > 0) {
						setSelectedMembers(newSelectedItems);
						const newItems = items.filter((i) => {
							return newSelectedItems.findIndex((n) => n.data.email !== i.data.email) !== -1;
						});
						setInputItems(newItems);
					} else {
						newSelectedItems && setSelectedMembers(newSelectedItems);
						setInputItems(items);
					}
					break;
				default:
					break;
			}
		},
	});

	const filteredItems = getFilteredMembers(selectedMembers, inputValue).filter((i) => {
		return selectedMembers.findIndex((f) => f.data.email === i.data.email) === -1;
	});

	const { isOpen, getMenuProps, getInputProps, getComboboxProps, getItemProps, closeMenu, setHighlightedIndex } =
		useCombobox({
			items: inputItems,
			itemToString(item) {
				return item ? item.data.username : "";
			},
			onInputValueChange: () => {
				if (isCreating && filteredItems.length > 0) {
					setIsCreating(false);
				}
			},
			defaultHighlightedIndex: 0, // after selection, highlight the first item.
			selectedItem: null,
			stateReducer(state, actionAndChanges) {
				const { changes, type } = actionAndChanges;

				switch (type) {
					case useCombobox.stateChangeTypes.InputBlur:
						return {
							...changes,
							highlightedIndex: state.highlightedIndex,
							inputValue: "",
						};
					case useCombobox.stateChangeTypes.InputKeyDownEnter:
					case useCombobox.stateChangeTypes.ItemClick:
						if (changes && hasItems) {
							return {
								...changes,
								highlightedIndex: state.highlightedIndex,
								isOpen: true,
								inputValue: "",
							};
						}
						return state;
					default:
						return changes;
				}
			},
			onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
				switch (type) {
					case useCombobox.stateChangeTypes.InputKeyDownEscape:
						setInputValue("");
						handleInput();
						break;
					case useCombobox.stateChangeTypes.InputKeyDownEnter:
					case useCombobox.stateChangeTypes.ItemClick:
						if (newSelectedItem && hasItems) {
							if (isCreating) {
								handleCreateItem(newSelectedItem);
								setIsCreating(false);
								setInputValue("");
							} else {
								setSelectedMembers([...selectedMembers, newSelectedItem]);
							}

							const newOptions = inputItems.filter((i) => {
								return i.data.email !== newSelectedItem.data.email;
							});

							setInputItems(newOptions);

							closeMenu();
							resizeInput();
						}
						break;

					case useCombobox.stateChangeTypes.InputChange:
						setInputValue(newInputValue || "");
						if (!newInputValue) {
							closeMenu();
							resizeInput();
						}
						break;
					default:
						break;
				}
			},
		});

	useEffect(() => {
		if (canEdit && filteredItems.length === 0 && activeIndex === -1 && validateEmailAddress(inputValue)) {
			setIsCreating(true);
			setInputItems((items) => {
				const newItems = items.slice();
				const index = newItems.findIndex((item) => item.scope === "guest");

				const invite = {
					scope: "guest" as const,
					data: { email: inputValue, username: inputValue, icon: FiMail },
				};

				if (index !== -1) {
					newItems[index] = invite;
				} else {
					newItems.unshift(invite);
				}
				return newItems;
			});
			setHighlightedIndex(0);
		}
	}, [filteredItems, setIsCreating, setHighlightedIndex, inputValue, activeIndex, inputItems, canEdit]);

	const placeHolder = selectedMembers.length === 0 ? "Add names or emails" : "";
	const hasItems = filteredItems.length > 0;

	const inputProps = getInputProps(getDropdownProps({ preventKeyAction: isOpen }));

	const inputRef = (node: HTMLInputElement) => {
		inputProps.ref(node);
		inputEl.current = node;
	};

	const handleFocusInput = () => {
		if (inputEl.current !== document.activeElement) {
			inputEl.current?.focus();
		}
	};

	const handleInput = () => {
		if (inputEl.current && inputEl.current.value.length > 0) {
			resizeInput();
		} else if (inputEl.current && selectedMembers.length === 0) {
			inputEl.current.style.width = "auto";
		}
	};

	useEffect(() => {
		if (inputEl.current && selectedMembers.length === 0 && inputEl.current.value.length === 0) {
			inputEl.current.style.width = "auto";
		}
	}, [inputValue.length, selectedMembers.length]);

	return (
		<InputGroup>
			<Input>
				{selectedMembers.map(function renderSelectedItem(selectedItemForRender, index) {
					// @ts-expect-error TS(2339) FIXME: Property 'icon' does not exist on type 'User | { u... Remove this comment to see the full error message
					const { icon, ...user } = selectedItemForRender.data;

					return (
						<SelectedValues
							key={`selected-item-${index}`}
							{...getSelectedItemProps({
								selectedItem: selectedItemForRender,
								index,
							})}>
							<Avatar user={user} icon={icon} size={20} />
							{selectedItemForRender.data.username}
							<Remove
								onClick={(e) => {
									e.stopPropagation();
									removeSelectedItem(selectedItemForRender);
								}}>
								&#10005;
							</Remove>
						</SelectedValues>
					);
				})}
				<SearchWrapper {...getComboboxProps()} onClick={handleFocusInput}>
					<Search placeholder={placeHolder} onInput={handleInput} {...inputProps} ref={inputRef} />
				</SearchWrapper>
			</Input>
			<div {...getMenuProps()}>
				{isOpen && hasItems && (
					<OptionsList>
						{filteredItems.map((item, index) => (
							<li key={`${item.data.email}${index}`} {...getItemProps({ item, index })}>
								<UserDetails
									name={item.data.username}
									email={item.data.email}
									// @ts-expect-error TS(2322) FIXME: Type 'User | { username: string; email: string; ic... Remove this comment to see the full error message
									user={item.data}
									// @ts-expect-error TS(2339) FIXME: Property 'icon' does not exist on type 'User | { u... Remove this comment to see the full error message
									icon={item.data.icon}
								/>
							</li>
						))}
					</OptionsList>
				)}
			</div>
		</InputGroup>
	);
};

const InputGroup = styled.div`
	flex: 1;
	position: relative;
`;

const Input = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: 4px;
	padding: 2px;
	border: var(--border);
`;

const SearchWrapper = styled.div`
	flex-grow: 1;
`;

const Search = styled.input`
	display: flex;
	border: 0;
	width: 100%;
	min-width: 0;
	background: transparent;
	margin: 8px 6px;

	&:focus {
		outline: 0;
	}

	::placeholder {
		color: var(--color-text);
		opacity: 0.5;
	}
`;

const SelectedValues = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 8px 4px 4px;
	border-radius: 24px;
	margin: 2px;
	border: var(--border);
	background: var(--color-body);
	box-shadow: var(--drop-shadow);
	line-height: 1;
	font-size: 14px;
	gap: 8px;

	& span {
		font-size: 11px;
	}
`;

const Remove = styled.span`
	cursor: pointer;
`;

const OptionsList = styled.ul`
	position: absolute;
	z-index: 2;
	background: var(--color-body);
	width: 100%;
	border: var(--border);
	border-top: 0;
	padding: 0;
	list-style: none;
	padding: 8px;
	border-radius: 0 0 4px 4px;
	box-shadow: var(--drop-shadow-l2);
	margin: 0;

	li {
		padding: 2px 8px;
		border-radius: 4px;
		cursor: pointer;
	}

	li[aria-selected="true"] {
		background: var(--color-hover);
	}
`;
