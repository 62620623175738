import { NodeMetaDate } from "@/domains/nodes/components/details/meta/types/date/NodeMetaDate";
import { NodeMetaEmail } from "@/domains/nodes/components/details/meta/types/email/NodeMetaEmail";
import { NodeMetaLink } from "@/domains/nodes/components/details/meta/types/link/NodeMetaLink";
import { NodeMetaPerson } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPerson";
import { NodeMetaSelect } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelect";
import { NodeMetaText } from "@/domains/nodes/components/details/meta/types/text/NodeMetaText";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { NodeMetaAllTypes } from "@/types/db";

interface Props {
	node: NodeModel;
	meta: NodeMetaAllTypes;
}

export const NodeMetaItem = ({ node, meta }: Props) => {
	const { permissions } = usePermissions("project");

	switch (meta.type) {
		case "select":
		case "list": {
			return <NodeMetaSelect node={node} meta={meta} />;
		}

		case "person": {
			return permissions.includes("users") ? <NodeMetaPerson node={node} meta={meta} /> : null;
		}
		case "date":
		case "dateRange": {
			return <NodeMetaDate node={node} meta={meta} />;
		}
		case "link": {
			return <NodeMetaLink node={node} meta={meta} />;
		}
		case "text": {
			return <NodeMetaText node={node} meta={meta} />;
		}
		case "email": {
			return <NodeMetaEmail node={node} meta={meta} />;
		}
		default: {
			// Returning the exhaustive check here causes and error when deleting a newly created node type
			// due to a race condition of caused by the dropdown.

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const _exhaustiveCheck: never = meta;
			return null;
		}
	}
};
