import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const style = css`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 4px 12px;
	border-radius: 4px;
	border: 0;
	background: transparent;
	cursor: pointer;
	font-size: 14px;
	min-height: 38px;
	margin-bottom: 1px;
	color: var(--color-text-heading);
	min-width: 0;
	flex: 1;

	&.selected,
	&:focus {
		background-color: var(--color-hover);
		outline: none;
	}

	&:hover:not(:disabled) {
		background-color: var(--color-hover);
	}

	> svg {
		margin-right: 0.666em;
	}
`;

export const SidebarNavigationItem = styled(NavLink)`
	${style}
`;

export const SidebarNavigationLink = styled.a`
	${style};
	margin-top: -4px;
`;
