import * as Accordion from "@radix-ui/react-accordion";
import { FiChevronDown } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { importState } from "../hooks/useImportListener";
import { MoreImportFile } from "./MoreImportFile";

type Props = {
	boardId: string;
};

export const MoreImportFiles = ({ boardId }: Props) => {
	const files = useRecoilValue(importState(boardId));

	if (files.length === 0) {
		return null;
	}

	return (
		<Accordion.Root type="single" defaultValue="item-1" collapsible>
			<Accordion.Item value="item-1">
				<Trigger>
					<FiChevronDown className="caret" />
					Last import
				</Trigger>
				<Content>
					{" "}
					{files.map((file) => (
						<MoreImportFile file={file} key={file.id} />
					))}{" "}
				</Content>
			</Accordion.Item>
		</Accordion.Root>
	);
};

const Trigger = styled(Accordion.Trigger)`
	margin-top: 24px;
	font-size: 14px;
	background: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;
	color: var(--color-text-secondary);

	.caret {
		font-size: 16px;
		vertical-align: middle;
		margin-right: 6px;
		transform-origin: center;
		transform: rotate(-90deg);
		transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	&[data-state="open"] > .caret {
		transform: rotate(0deg);
	}

	&:focus {
		outline: 0;
	}
`;

const Content = styled(Accordion.Content)`
	margin-top: 16px;
`;
