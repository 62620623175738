import { z } from "zod";

import {
	nodeMetaSelectValue,
	nodeMetaTypeDate,
	nodeMetaTypeDateRange,
	nodeMetaTypeList,
	nodeMetaTypePerson,
	nodeMetaTypes,
	nodeMetaTypeSelect,
} from "@/domains/nodes/dtos/nodeMetaDto";
import {
	nodeMeta,
	nodeMetaDate,
	nodeMetaDateRange,
	nodeMetaRecordTypes,
	nodeMetaRecordTypesDates,
} from "@/domains/nodes/dtos/nodesDto";

export enum NodeMetaSystemLabels {
	nodeType = "Node Type",
	status = "Status",
}

export type NodeMetaTypeUnion = "person" | "select" | "list" | "date" | "dateRange" | "link" | "email" | "text";

export enum NodeMetaTypes {
	person = "person",
	select = "select",
	multi = "list",
	date = "date",
	dateRange = "dateRange",
}

export type NodeMetaValueSelect = z.infer<typeof nodeMetaSelectValue>;

export type NodeMetaSelect = z.infer<typeof nodeMetaTypeSelect>;
export type NodeMetaMulti = z.infer<typeof nodeMetaTypeList>;
export type NodeMetaPerson = z.infer<typeof nodeMetaTypePerson>;
export type NodeMetaDate = z.infer<typeof nodeMetaTypeDate>;
export type NodeMetaDateRange = z.infer<typeof nodeMetaTypeDateRange>;

export type NodeMetaAllTypes = z.infer<typeof nodeMetaTypes>;

export type NodeMeta = { meta: Record<string, NodeMetaAllTypes>; order: string[]; version: "1" };

// Type alias for data stored on the node document

export type NodeMetaDataDate = z.infer<typeof nodeMetaDate>;
export type NodeMetaDataDateRange = z.infer<typeof nodeMetaDateRange>;

export type NodeMetaDataTypes = z.infer<typeof nodeMetaRecordTypes>;
export type NodeMetaDataDateTypes = z.infer<typeof nodeMetaRecordTypesDates>;

export type NodeMetaData = z.infer<typeof nodeMeta>;
