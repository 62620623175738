import { selector } from "recoil";

import { profileState } from "./profileState";

export const getPublicProfile = selector({
	key: "getPublicProfile",
	get: ({ get }) => {
		const profile = get(profileState);

		if (profile) {
			const { username, photoURL = null, id } = profile;
			return {
				username,
				photoURL,
				id,
			};
		}
	},
});
