import styled from "styled-components";

export const InputWithUnitsWrapper = styled.div`
	display: flex;
	align-items: center;
	color: var(--color-text-secondary);

	> :not(:last-child) {
		margin-right: 8px;
	}
`;
