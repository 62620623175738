import { FiMoreHorizontal, FiTrash } from "react-icons/fi";
import styled from "styled-components";

import { useCommentActions } from "@/modules/comments/hooks/useCommentActions";
import { DropdownMenuButton } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover } from "@/shared/system/Dropdown/Popover";
import { UserComment, WithId } from "@/types/db";

type Props = {
	comment: WithId<UserComment>;
};

export const MessageCommentMore: React.FC<Props> = ({ comment }) => {
	const { remove: deleteComment } = useCommentActions();

	const handleDelete = () => deleteComment(comment.id, comment);

	const render = () => {
		return (
			<PopoverContent>
				<DropdownMenuButton onClick={handleDelete}>
					<FiTrash /> Delete comment
				</DropdownMenuButton>
			</PopoverContent>
		);
	};

	return (
		<Popover render={render} placement="bottom-start">
			<Icon data-testid="messageComment--more">
				<FiMoreHorizontal />
			</Icon>
		</Popover>
	);
};

const Icon = styled.div`
	padding: 4px;
	line-height: 1;
	font-size: 15px;
	margin: 0 -4px;
	border-radius: 4px;

	&:hover {
		background-color: var(--color-hover);
	}
`;
