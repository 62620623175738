import styled from "styled-components";

import { DetailsCard } from "@/domains/nodes/components/details/components/DetailsCard";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { NoteDetails } from "./NoteDetails";

type Props = {
	node: NodeModel;
};

export type ActiveContentKeys = "note" | "results" | "delete";

export const NoteContainer = ({ node }: Props) => {
	return (
		<DetailsCard node={node}>
			<Content>
				<NoteDetails node={node} />
			</Content>
		</DetailsCard>
	);
};

const Content = styled.div`
	padding: 0 48px 48px;
`;
