import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { Result } from "@/shared/utils/result";
import { WithId } from "@/types/db";

export const useProject = (): Result<WithId<ProjectDtoBoard>> => {
	const project = useProjectsStore((state) => state.projectSingle);

	if (!project) {
		return Result.fail("Unable to find project");
	}

	if (project.data?.type !== "board") {
		return Result.fail("Found project is not of type board");
	}

	return Result.ok(project.data);
};
