import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { PopoverContent } from "../../../../shared/system/Dropdown/components/DropdownContent";
import { Popover } from "../../../../shared/system/Dropdown/Popover";
import { useRepositionCoverImage } from "../hooks/useRepositionCoverImage";
import { CoverImageSelectModal } from "./CoverImageSelectModal";

type Props = {
	node: NodeModel;
};

export const CoverImageChange = ({ node }: Props) => {
	const { start } = useRepositionCoverImage(node);

	const handleReposition = start;

	const items = () => (
		<PopoverContent>
			<CoverImageSelectModal node={node} />
		</PopoverContent>
	);

	return (
		<Root>
			<Popover render={items}>
				<Trigger>Change Cover</Trigger>
			</Popover>
			<Divider />
			<Trigger onClick={handleReposition}>Reposition</Trigger>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	border: var(--border);
	border-radius: 4px;
`;

const Divider = styled.div`
	width: 1px;
	background: var(--color-border);
`;

export const Trigger = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 4px 8px;
	color: var(--color-text-secondary);
	cursor: pointer;
	box-shadow: var(--drop-shadow);

	:hover {
		background: var(--color-hover);
	}
`;
