import styled from "styled-components";

import { useNodeMetaValues } from "@/domains/nodes/components/cards/hooks/useNodeMetaValues";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { CardMetaValue } from "@/modules/nodeMeta/components/CardMetaValue";

type Props = {
	node: NodeModel;
	variant?: "stacked" | "inline";
};

export const NodeMeta: React.FC<Props> = ({ node, variant = "stacked" }) => {
	const metaValues = useNodeMetaValues(node);

	if (!metaValues) {
		return null;
	}

	const direction = variant === "stacked" ? "column" : "row";
	const showLabels = variant === "stacked";

	return (
		<Root direction={direction}>
			{metaValues.map((mappedMeta) => (
				<CardMetaValue meta={mappedMeta} key={mappedMeta.definition.id} showLabel={showLabels} />
			))}
		</Root>
	);
};

const Root = styled.div<{ direction: "row" | "column" }>`
	display: flex;
	flex-direction: ${({ direction }) => direction};
	min-width: 0;
	margin-top: 10px;

	&:empty {
		display: none;
	}
`;
