import styled from "styled-components";

import {
	CreateProjectAction,
	CreateProjectProject,
} from "@/domains/accounts/components/createProject/components/CreateProjectProject";
import { CreateProjectProjectTypeShortcuts } from "@/domains/accounts/components/createProject/components/CreateProjectProjectTypeShortcuts";
import { Label } from "@/shared/system";

type Props = {
	folderId: string;
	items: CreateProjectAction[];
};

export const CreateProjectProjectTypes = ({ folderId, items }: Props) => {
	return (
		<>
			<CreateProjectProjectTypeShortcuts folderId={folderId} actions={items} />
			<SectionHeader>Blank</SectionHeader>
			{items.map((item) => {
				return <CreateProjectProject {...item} key={item.name} />;
			})}
		</>
	);
};

const SectionHeader = styled(Label)`
	color: var(--color-text);
`;
