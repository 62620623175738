import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { Button, Tooltip } from "../../../../shared/system";
import { usePermissions } from "../../../workspace/hooks/usePermissions";

type Props = {
	text?: string;
	tooltip?: string;
	onClick: () => void;
};

export const ChildNodeAdd = ({ text, tooltip, onClick }: Props) => {
	const { canEdit } = usePermissions("project");

	if (!canEdit) {
		return null;
	}

	return (
		<Tooltip label={tooltip}>
			<AddButton onClick={onClick} data-testid="child-node-add">
				<FiPlus /> {text}
			</AddButton>
		</Tooltip>
	);
};

const AddButton = styled(Button.Text)`
	color: var(--color-text);
	padding: 0 0 8px;
`;
