import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle, FiCheck, FiXCircle } from "react-icons/fi";
import { IoWarningOutline } from "react-icons/io5";
import styled from "styled-components";

import { ToastMessageState } from "../Toasts";

const IconMap = {
	success: { color: "green", svg: <FiCheck /> },
	error: { color: "red", svg: <FiXCircle /> },
	warning: { color: "yellow", svg: <IoWarningOutline /> },
	info: { color: "blue", svg: <FiAlertCircle /> },
};

type Props = {
	message: ToastMessageState | null;
};

export default function ToastMessage({ message }: Props) {
	const icon = message && IconMap[message.status];

	return (
		<AnimatePresence>
			{message && (
				<Root
					initial={{ y: -100, x: "-50%" }}
					animate={{ y: 0, x: "-50%", transition: { type: "tween", duration: 0.2 } }}
					exit={{ y: -150, x: "-50%", transition: { type: "tween", duration: 0.3 } }}>
					{icon && <Icon color={icon.color}>{icon.svg}</Icon>}
					{message.message}
				</Root>
			)}
		</AnimatePresence>
	);
}

const Icon = styled.div<{ color: string }>`
	display: flex;
	align-items: center;
	margin-right: 8px;
	font-size: 1.3rem;
	color: ${({ theme, color }) => theme.colors[color]};
`;

const Root = styled(motion.div)`
	position: fixed;
	top: 60px;
	left: 50%;
	right: auto;
	z-index: 100;
	display: flex;
	align-items: center;
	transform: translateX(-50%);
	padding: 12px 24px;
	box-shadow: var(--drop-shadow-l2);
	border: var(--border);
	background-color: var(--color-body);
	border-radius: 4px;
`;
