import { useEffect } from "react";

import { onSnapshot, query, where } from "firebase/firestore";
import { useRecoilValue } from "recoil";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { getCurrentWorkspace } from "@/modules/workspace/selectors/getCurrentWorkspace";
import { Dictionary } from "@/types/db";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { useProfileLegacy } from "../../profile/hooks/useProfileLegacy";

export const useGuestBoardsListener = () => {
	const user = useProfileLegacy();
	const workspaceId = useRecoilValue(getCurrentWorkspace);
	const setProjects = useProjectsStore((state) => state.setProjects);

	const userId = user?.id;
	const guestBoardIds = user?.access || {};

	const hasGuestBoards = Object.values(guestBoardIds).filter((_) => _.scope === "guest").length > 0;

	useEffect(() => {
		if (userId && hasGuestBoards) {
			const guestBoardQuery = query(
				db.projects,
				where(`access.${userId}.status`, "==", "active"),
				where(`access.${userId}.scope`, "==", "guest"),
			);

			return onSnapshot(
				guestBoardQuery,
				(snapshot) => {
					const data = snapshot.docs.reduce<Dictionary<ProjectModel>>((carry, doc) => {
						return {
							...carry,
							[doc.id]: {
								...doc.data(),
								group: "guest",
								id: doc.id,
							},
						};
					}, {});

					setProjects(data);
				},
				onSnapshotError("useGuestBoardsListener"),
			);
		}
	}, [hasGuestBoards, workspaceId, setProjects, userId]);
};
