import { memo } from "react";

import styled from "styled-components";

import { NodeCardBase } from "@/domains/nodes/components/cards/components/NodeCardBase";
import { NodeCardDetails } from "@/domains/nodes/components/cards/components/NodeCardDetails";
import { INodeCard } from "@/domains/nodes/components/cards/nodeCardTypes";
import { NodeCoverImage } from "@/domains/nodes/dtos/nodesDto";

export const NodeLayoutStacked: React.FC<INodeCard> = memo(({ node }) => {
	const cover = node.cover as NodeCoverImage;

	return (
		<Root data-testid="node--layoutStacked">
			{cover?.src && (
				<ImageFull>
					<img src={cover.src} alt="stacked cover" />
				</ImageFull>
			)}
			<NodeCardDetails node={node} />
		</Root>
	);
});

NodeLayoutStacked.displayName = "NodeLayoutStacked";

const Root = styled(NodeCardBase)`
	border-left: 0;
`;

const ImageFull = styled.div`
	width: calc(100% + 36px);
	margin: -18px -18px 18px;
	text-align: center;
	overflow: hidden;
	border-radius: 7px 7px 0 0;

	img {
		max-width: 100%;
		margin: auto;
		display: block;
	}
`;
