import { SyntheticEvent } from "react";

import { useRecoilValue } from "recoil";

import { SidebarNavigationItemFolderName } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemFolderName";
import { getSidebarNavigationItemEditing } from "@/modules/page/selectors/getSidebarNavigationItemEditing";
import { Actions, Toggle } from "@/shared/system/Files/FileFolder";
import { FileFolderToggle } from "@/shared/system/Files/FileFolderToggle";
import { ProjectFolder, WithId } from "@/types/db";

interface Props {
	project: WithId<ProjectFolder>;
	hasChildren?: boolean;
	onClick?: (e: SyntheticEvent) => void;
	onCollapse?: (e: SyntheticEvent) => void;
	collapsed?: boolean;
	actions?: any;
}

export const SidebarNavigationItemFolder = ({ project, onClick, onCollapse, collapsed, actions }: Props) => {
	const {
		id,
		data: { name },
	} = project;

	const isEditing = useRecoilValue(getSidebarNavigationItemEditing(project.id));

	const hasChildren = project.childIds.length > 0;
	const variant = isEditing ? "editing" : "";

	const handleClick = (e: SyntheticEvent) => {
		e.stopPropagation();
		!isEditing && onClick && onClick(e);
	};

	const handleCollapse = (e: SyntheticEvent) => {
		e.stopPropagation();
		!isEditing && onCollapse && onCollapse(e);
	};
	return (
		<Toggle className={variant} onClick={handleClick} data-project-id={id}>
			{onCollapse && (
				<div onClick={handleCollapse} style={{ marginRight: "4px" }}>
					<FileFolderToggle isExpanded={!collapsed} canExpand={hasChildren} />
				</div>
			)}
			<SidebarNavigationItemFolderName name={name} projectId={id} isEditing={isEditing} />
			{actions && <Actions>{actions}</Actions>}
		</Toggle>
	);
};
