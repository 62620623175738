import { FiLink } from "react-icons/fi";

import { DropdownMenuButton } from "../../../shared/system";
import { buildUriPublicBoard } from "../../board/buildUriPublicBoard";
import { useToast } from "../../toast/hooks/useToast";

type Props = {
	boardId: string;
	nodeId: string;
	close: VoidFunction;
};

export const NoteMoreCopyLink = ({ boardId, nodeId, close }: Props) => {
	const setToast = useToast();

	const uriPublicBoard = buildUriPublicBoard(boardId, nodeId);

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(uriPublicBoard);
			setToast({ status: "success", message: "Copied link to clipboard" });
			close();
		} catch {
			// TODO: Error handling
		}
	};

	return (
		<DropdownMenuButton onClick={handleCopyToClipboard}>
			<FiLink /> Copy link
		</DropdownMenuButton>
	);
};
