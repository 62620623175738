import { useCallback } from "react";

import { FiTrash } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { MessageTimestamp } from "@/domains/nodes/components/details/activity/components/MessageTimestamp";
import { KeyResultsResultChange } from "@/domains/nodes/components/keyResults/components/KeyResultsResultChange";
import { useKeyResultsService } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsService";
import { Avatar, ButtonIcon, utility } from "@/shared/system";
import { KeyResultModel, KeyResultModelUpdate } from "@/types/db";

import { getMembersById } from "../../../../../../../modules/members/selectors/getMembersById";

type Props = {
	keyResult: KeyResultModel;
	index: number;
	update: KeyResultModelUpdate;
};

export const KeyResultsActivityItem: React.FC<Props> = ({ keyResult, index, update }) => {
	const { removeResult } = useKeyResultsService();

	const { id: keyResultId, startingValue, updates } = keyResult;
	const { id: updateId, value: nextValue, authorId, comment } = update;

	const [member] = useRecoilValue(getMembersById([authorId]));

	const handleRemoveResult = useCallback(() => {
		removeResult(keyResultId, updateId, keyResult);
	}, [keyResult, keyResultId, removeResult, updateId]);

	const previousValue = updates[index + 1]?.value || startingValue;

	return (
		<Root data-testid={`keyResultsActivityItem`}>
			<Header>
				<Avatar user={member} size={16} />
				<KeyResultsResultChange keyResult={keyResult} nextValue={nextValue} previousValue={previousValue} />{" "}
				<Username>by {member?.username}</Username>
				<MessageTimestamp timestamp={keyResult.createdAt} />
				<ActivityDelete src={<FiTrash />} onClick={handleRemoveResult} aria-label="delete activity" />
			</Header>
			{comment && <Comment>{comment}</Comment>}
		</Root>
	);
};

const Root = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;

const ActivityDelete = styled(ButtonIcon)`
	visibility: hidden;
	padding: 0;
	margin-left: 12px;
	color: var(--color-text-secondary);

	:hover {
		background: transparent;
		color: ${utility.color("red")};
	}
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	:hover ${ActivityDelete} {
		visibility: visible;
	}
`;

const Username = styled.div`
	margin-left: 8px;
`;

const Comment = styled.div`
	margin-top: 4px;
	color: var(--color-text-heading);
`;
