import styled from "styled-components";

import NodeCard from "@/landing/home/components/StaticNode";

import { KeyResultsCards } from "../../../domains/nodes/components/keyResults/KeyResultsCards";
import { statusColors } from "../../../modules/workspace/workspaceTemplates";
import { breakpoints, TextWithEmoji } from "../../../shared/system";
import { KeyResultModel } from "../../../types/db";
import { StaticNodeMeta } from "./StaticNodeMeta";

export default function NodeGroupTwo({ refs }: any) {
	const mockPartialResult = {
		title: "Increase user activation rate by 20%",
		updates: [{ status: "onTrack" }],
	} as KeyResultModel;

	return (
		<NodeRoot ref={refs}>
			<NodeWrapper>
				<NodeCard
					borderColor="blueBorder"
					nodeId={"1"}
					handleReveal={() => {}}
					members={[]}
					meta={null}
					showColor={""}
					nodeType={<TextWithEmoji text="🎱️ Experiment" />}
					staticShadow
					title={"Weekly project check in and results update email"}
					type={<StaticNodeMeta color={statusColors.blue} status="Now" showStatus={true} />}
					keyResults={
						<Results className="ballon-show">
							<KeyResultsCards results={[mockPartialResult]} />
						</Results>
					}
					isActive={true}
					isCreating={false}
					isMetaActive={false}
				/>
			</NodeWrapper>
		</NodeRoot>
	);
}

const NodeRoot = styled.div`
	${breakpoints.md`
	padding-bottom: 44px;
`}
`;

const NodeWrapper = styled.div`
	width: 260px;

	* {
		opacity: 1;
	}
`;

const Results = styled.div`
	margin-top: 12px;
`;
