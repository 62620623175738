import styled from "styled-components";

type Props = {
	selected: boolean;
	onClick: () => Promise<void>;
	size?: "small" | "medium" | "large";
	testId?: string;
	disabled?: boolean;
};

export const Toggle = ({ testId, onClick, selected, disabled, size }: Props) => {
	return (
		<ToggleWrapper onClick={onClick} size={size} disabled={disabled} selected={selected} data-testid={testId}>
			<ToggleControl size={size} disabled={disabled} selected={selected} />
		</ToggleWrapper>
	);
};

type ToggleWrapperProps = {
	selected?: boolean;
	size?: "small" | "medium" | "large";
	disabled?: boolean;
};

const calcSize =
	(sizes: [string, string, string]) =>
	({ size }: any) => {
		switch (size) {
			case "small": {
				return sizes[0];
			}
			case "medium":
			default: {
				return sizes[1];
			}
			case "large": {
				return sizes[2];
			}
		}
	};

const ToggleWrapper = styled.div<ToggleWrapperProps>`
	background: ${({ selected }) => (selected ? "var(--color-accent)" : "var(--color-border)")};
	height: ${calcSize(["16px", "24px", "46px"])};
	width: ${calcSize(["28px", "40px", "76px"])};
	opacity: ${({ disabled }) => (disabled ? ".4" : "1")};
	border-radius: 50px;
	display: block;
	position: relative;
	padding: 2px;
	transition: background-color 0.15s ease-out;
	cursor: pointer;

	&:hover {
		background: ${({ selected }) => (selected ? "var(--color-accent)" : "var(--color-border)")};
	}
`;

const ToggleControl = styled.div<ToggleWrapperProps>`
	background: var(--color-body);
	height: ${calcSize(["12px", "20px", "40px"])};
	width: ${calcSize(["12px", "20px", "40px"])};
	border-radius: 100%;
	position: absolute;
	top: 2px;
	left: ${({ selected }) => (!selected ? "2" : "auto")}px;
	right: ${({ selected }) => (selected ? "2" : "auto")}px;
	transition: left 0.15s ease-out;
	line-height: 100%;
	text-align: center;
	vertical-align: middle;
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	path {
		transition: fill 0.15s ease-out;
	}
`;
export default Toggle;
