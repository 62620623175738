import { RefObject } from "react";

import produce from "immer";

import { LocalNodeState, NodeModel, NodeStore } from "@/domains/nodes/models/nodesModel";
import { Result } from "@/shared/utils/result";
import { createStore } from "@/shared/zustand/createStore";

export const mergeLocalNodeData = (nodes: NodeStore, local: LocalNodeState): NodeStore => {
	switch (local?.type) {
		case "create": {
			const parent = local.data.parent;

			return {
				...nodes,
				[local.data.node.id]: local.data.node,
				[parent.id]: { ...nodes[parent.id], ...parent },
			};
		}
		case "update": {
			const node = local.data.node;
			return produce<NodeStore>((state) => {
				state[node.id] = { ...node, creating: true };
			})(nodes);
		}
		case "reorder": {
			const outgoing = local.data.outgoing;
			const incoming = local.data.incoming;

			return produce<NodeStore>((state) => {
				state[outgoing.id] = outgoing;
				state[incoming.id] = incoming;
			})(nodes);
		}
		default: {
			return nodes;
		}
	}
};

export interface INodesStore {
	nodes: NodeStore;
	collapsedNodeIds: string[];
	focusedId: NodeModel["id"] | null;
	focusedEl: RefObject<HTMLTextAreaElement> | null;
	local: LocalNodeState;
	getNode(nodeId: string): Result<NodeModel>;
	getFocusedNode(): Result<NodeModel>;
	getNodes(): NodeStore;
	setNodes(nodes: NodeStore): void;
	getIsEditing(): boolean;
	getHighlightedNodeIds(): string[];
	getIsFocused(nodeId: NodeModel["id"]): boolean;
	getIsCollapsed(nodeId: NodeModel["id"]): boolean;
	setFocused(nodeId: INodesStore["focusedId"]): void;
	setFocusedEl(nodeId: INodesStore["focusedEl"]): void;
	setCollapsed(collapsedNodeIds: INodesStore["collapsedNodeIds"]): void;
	setChildren(nodeId: NodeModel["id"], children: NodeModel["children"]): void;
	setLocal(local: INodesStore["local"]): void;
	setNodeMeta(nodeId: NodeModel["id"], metaId: string, value: any): void;
	setMergeLocal(): void;
}

export const nodesStore = createStore<INodesStore>((set, get) => ({
	nodes: {},
	collapsedNodeIds: [],
	focusedId: null,
	focusedEl: null,
	local: null,
	setLocal(local) {
		set({ local });
	},
	setFocused(nodeId) {
		set({ focusedId: nodeId });
	},
	setFocusedEl(element) {
		set({ focusedEl: element });
	},
	setNodeMeta(nodeId, metaId, value) {
		const node = get().nodes[nodeId];
		if (node) {
			set((state) => ({
				nodes: {
					...state.nodes,
					[nodeId]: {
						...node,
						meta: {
							...node.meta,
							[metaId]: value,
						},
					},
				},
			}));
		}
	},
	setChildren(nodeId, children) {
		const node = get().nodes[nodeId];
		if (node) {
			set((state) => ({
				nodes: {
					...state.nodes,
					[nodeId]: {
						...node,
						children,
					},
				},
			}));
		}
	},
	setCollapsed(collapsedNodeIds) {
		set({ collapsedNodeIds });
	},
	setMergeLocal() {
		const merged = get().getNodes();
		return set({ nodes: merged, local: null });
	},
	getNode(nodeId) {
		const node = get().nodes?.[nodeId];

		if (!node) {
			return Result.fail("Unable to find node");
		}

		return Result.ok(node);
	},
	// DON'T USE Dangerous functions causes layout loop
	getNodes() {
		const nodes = get().nodes;
		const local = get().local;

		return mergeLocalNodeData(nodes, local);
	},
	getHighlightedNodeIds() {
		const nodes = get().nodes;

		if (!nodes) {
			return [];
		}

		return Object.values(nodes)
			.filter((node) => node.highlighted)
			.map((node) => node.id);
	},
	setNodes(nodes) {
		set({ nodes });
	},
	getFocusedNode() {
		const focusedId = get().focusedId;

		if (!focusedId) {
			return Result.fail("Unable to find focused node ID");
		}

		return get().getNode(focusedId);
	},
	getIsFocused(nodeId) {
		return get().focusedId === nodeId;
	},
	getIsCollapsed(nodeId) {
		return get().collapsedNodeIds.includes(nodeId);
	},
	getIsEditing() {
		return get().local !== null;
	},
}));
