import { FiStar } from "react-icons/fi";
import styled from "styled-components";

import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { ProjectModelFolder } from "@/domains/projects/models/projectsModel";
import { useBoardActions } from "@/modules/board/hooks/useBoardActions";
import { SystemFolderName } from "@/types/db";

type Props = {
	boardId: string;
};

export const TopBarStar = ({ boardId }: Props) => {
	const { removeProjectFromFolder, addProjectToFolder } = useBoardActions();
	const { tree } = useNavigationService();

	const favouriteFolder = tree[SystemFolderName.favorite]?.data as ProjectModelFolder | undefined;

	if (!favouriteFolder) {
		return null;
	}

	const isProjectStarred = favouriteFolder.childIds.includes(boardId);

	const handleStarProject = () => {
		if (favouriteFolder) {
			const toggleStarred = isProjectStarred ? removeProjectFromFolder : addProjectToFolder;
			toggleStarred(favouriteFolder.id, boardId);
		}
	};

	return (
		<ActionStar onClick={handleStarProject} active={isProjectStarred}>
			<FiStar />
		</ActionStar>
	);
};

const ActionStar = styled.button<{ active: boolean }>`
	background: transparent;
	border: 0;
	padding: 2px 4px;

	svg {
		display: block;
	}

	${({ active }) =>
		active &&
		`
		color: #FFCA5C;

		svg {
			fill: #FFCA5C;
		}
	`}
`;
