import { useCallback } from "react";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { ImageUpload } from "@/modules/note/coverImage/components/ImageUpload";

type Props = {
	node: NodeModel;
};

export const CoverImageUpload: React.FC<Props> = ({ node }) => {
	const nodesService = useNodesService();

	const nodeId = node.id;
	const workspaceId = node.workspace;

	const buildPath = (extension: string) => `/workspaces/${workspaceId}/covers/${nodeId}.${extension}`;

	const handleUploadComplete = useCallback(
		(src: string) => {
			const cover = { type: "image" as const, src, offsetY: 0, local: true };
			nodesService.update(nodeId, { cover });
		},
		[nodeId, nodesService],
	);

	return <ImageUpload buildPath={buildPath} onUploadComplete={handleUploadComplete} />;
};
