import { FileFolderToggle } from "@/shared/system/Files/FileFolderToggle";

import { FolderToggle } from "./FileFolder";

interface Props {
	hasChildren: boolean;
}

export function FileFolderCaret({ hasChildren }: Props) {
	return (
		<FolderToggle>
			<FileFolderToggle canExpand={hasChildren} />
		</FolderToggle>
	);
}
