import { FiGitPullRequest, FiX } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";
import { WithId } from "@/types/db";

import { getNodeMetaWithValuesHydrated } from "../../../nodeMeta/selectors/getNodeMetaWithValuesHydrated";
import { getNodeMetaTypeIcon } from "../../../nodeMeta/utils/getNodeMetaTypeIcon";
import { Filter, FilterItem, FilterTypes } from "../filterModel";
import { FilterGroupComparator } from "./FilterGroupComparator";
import { FilterGroupValue } from "./FilterGroupValue";

const checkIsNoValueComparator = (filter: Filter) =>
	filter.comparator === "isEmpty" || filter.comparator === "isNotEmpty" || filter.comparator === "isOverdue";

const buildFilter = (filter: Filter, allMeta: any): FilterItem | null => {
	const showOptions = !checkIsNoValueComparator(filter);

	switch (filter.type) {
		case FilterTypes.rootNode: {
			return {
				icon: FiGitPullRequest,
				label: "Project Root",
				type: FilterTypes.rootNode,
				showOptions: true,
				options: {
					type: filter.type,
					value: filter.value,
				},
			};
		}
		default: {
			const meta = allMeta?.[filter.metaId];

			if (!meta) {
				return null;
			}

			return {
				icon: getNodeMetaTypeIcon(meta.type),
				label: meta.label,
				type: meta.type,
				showOptions,
				options: meta,
			};
		}
	}
};

type Props = {
	board: WithId<ProjectDtoBoard>;
	filter: Filter;
};

export default function FilterGroup({ board, filter }: Props) {
	const { removeFilterByKey } = useFilterService();
	const nodeMeta = useNodeMetaStore(({ meta }) => meta);

	const allMeta = useRecoilValue(
		getNodeMetaWithValuesHydrated({ boardId: board.id, workspaceId: board.workspaceId, nodeMeta }),
	);

	const filterData = buildFilter(filter, allMeta);

	if (!filterData) {
		return null;
	}

	const handleRemove = () => {
		removeFilterByKey(filter.key);
	};

	const Icon = filterData.icon;

	return (
		<Root data-testid={`filter-group[${filter.key}]`}>
			<FilterSegment data-testid="filter-group-tag">
				<FilterProperty>
					<Icon /> {filterData.label}
				</FilterProperty>
			</FilterSegment>
			<FilterSegment hover>
				<FilterGroupComparator filter={filter} metaType={filterData.type} />
			</FilterSegment>
			{filterData.showOptions && (
				<FilterSegment data-testid="filter-group-values" hover>
					<FilterGroupValue options={filterData.options} filter={filter} />
				</FilterSegment>
			)}
			<FilterSegment hover data-testid="filter-group-remove" onClick={handleRemove}>
				<FiX />
			</FilterSegment>
		</Root>
	);
}

const Root = styled.div`
	display: inline-flex;
	align-items: stretch;
	border: var(--border);
	border-radius: 4px;
`;

const FilterProperty = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-right: 6px;
	}
`;

const FilterSegment = styled.div<{ hover?: boolean }>`
	display: flex;
	align-items: center;
	border-right: var(--border);
	cursor: ${({ hover }) => (hover ? ` pointer` : `default`)};

	> *:not(svg) {
		padding: 2px 8px;
	}

	> svg {
		margin: 2px 8px;
	}

	:hover {
		${({ hover }) => hover && `background: var(--color-hover);`}
	}

	&:last-of-type {
		border-right: 0;
	}
`;
