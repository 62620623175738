import styled from "styled-components";

export const HeaderWithAction = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;

	> p {
		max-width: 60%;
		margin-right: 24px;
	}
`;
