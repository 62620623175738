import styled from "styled-components";

import { NodeCardBase } from "@/domains/nodes/components/cards/components/NodeCardBase";
import { NodeCardDetails } from "@/domains/nodes/components/cards/components/NodeCardDetails";
import { INodeCard } from "@/domains/nodes/components/cards/nodeCardTypes";
import { NodeCoverImage } from "@/domains/nodes/dtos/nodesDto";

export const NodeLayoutBackground: React.FC<INodeCard> = ({ node }) => {
	const cover = node.cover as NodeCoverImage;

	return (
		<Root $image={cover?.src} data-testid="node--layoutBackground">
			<NodeCardDetails node={node} />
		</Root>
	);
};

const Root = styled(NodeCardBase)`
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding-top: 33%;
	border: 0;
	background-image: ${({ $isDragging, $image }) => {
		return $isDragging ? "none" : `var(--gradient-overlay), url(${$image})`;
	}};
`;
