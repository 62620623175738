import { parseISO, sub } from "date-fns";
import { Timestamp } from "firebase/firestore";

import {
	getScheduledTask,
	removeScheduledTask,
	upsertScheduledTask,
} from "../../../modules/scheduledTasks/scheduledTaskFunctions";
import { NodeMetaDataDateTypes, NodeMetaDataTypes, NodeMetaTypes, ScheduledTaskType, TaskNodeDate } from "../../../types/db";

const timestampFromDate = (date: Date) => Timestamp.fromDate(new Date(date));

/**
 * TODO: This file should its own domain rather than utils, -> convert to scheduleService
 */

function isDateType(nodeMeta: NodeMetaDataTypes | null): nodeMeta is NodeMetaDataDateTypes {
	return (
		(nodeMeta as NodeMetaDataDateTypes)?.type === NodeMetaTypes.date ||
		(nodeMeta as NodeMetaDataDateTypes)?.type === NodeMetaTypes.dateRange
	);
}

const getStartDate = (nodeMeta: NodeMetaDataDateTypes): string => {
	return nodeMeta.type === NodeMetaTypes.date ? nodeMeta.data.date : nodeMeta.data.from;
};

const getEndDate = (nodeMeta: NodeMetaDataDateTypes): string => {
	return nodeMeta.type === NodeMetaTypes.date ? nodeMeta.data.date : nodeMeta.data.to;
};

const getNotifyDate = (nodeMeta: NodeMetaDataDateTypes): Timestamp => {
	const endDate = parseISO(getEndDate(nodeMeta));
	const reminderOffset = nodeMeta.data.reminderOffset || undefined;

	return timestampFromDate(sub(endDate, { days: reminderOffset }));
};

type AddReminderToScheduledTasks = (args: {
	nodeId: string;
	metaId: string;
	boardId: string;
	workspaceId: string;
	newValue: NodeMetaDataTypes;
}) => Promise<void>;

export const addReminderToScheduledTasks: AddReminderToScheduledTasks = async ({
	nodeId,
	metaId,
	boardId,
	workspaceId,
	newValue,
}) => {
	if (!isDateType(newValue)) {
		return;
	}

	const taskId = `${nodeId}-${metaId}`;

	if (newValue.data.reminderOffset === null) {
		const task = await getScheduledTask(taskId);
		if (task.exists()) {
			removeScheduledTask(taskId);
		}
		return;
	}

	const reminder: TaskNodeDate = {
		workspaceId,
		boardId,
		metaId,
		nodeId,
		user: {
			id: "",
			username: "Scheduled",
			photoURL: null,
		},
		version: "1",
		notifyAt: getNotifyDate(newValue),
		processedAt: null,
		type: ScheduledTaskType.nodeDate,
		data: {
			label: newValue.data.label,
			format: newValue.data.format,
			reminderOffset: newValue.data.reminderOffset,
			startAt: getStartDate(newValue),
			endAt: getEndDate(newValue),
		},
	};

	upsertScheduledTask(taskId, reminder);
};

type RemoveReminderFromScheduledTasks = (args: {
	nodeId: string;
	metaId: string;
	boardId: string;
	workspaceId: string;
	newValue: NodeMetaDataTypes;
	oldValue: NodeMetaDataTypes | null;
}) => void;

export const removeReminderFromScheduledTasks: RemoveReminderFromScheduledTasks = ({
	nodeId,
	metaId,
	boardId,
	workspaceId,
	newValue,
	oldValue,
}) => {
	if (!isDateType(newValue) || !isDateType(oldValue) || newValue.data.reminderOffset === oldValue.data.reminderOffset) {
		return;
	}

	if (oldValue.data.reminderOffset === null) {
		removeScheduledTask(`${nodeId}-${metaId}`);
	}

	addReminderToScheduledTasks({ nodeId, metaId, boardId, workspaceId, newValue: oldValue });
};
