import { FiMoreHorizontal } from "react-icons/fi";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

import { MenuNodeLayout } from "../../../domains/nodes/components/menu/components/MenuNodeLayout";
import { DropdownHr } from "../../../shared/system";
import { PopoverContent } from "../../../shared/system/Dropdown/components/DropdownContent";
import { Popover, PopoverRender } from "../../../shared/system/Dropdown/Popover";
import BoardHeaderAction from "../../board/components/BoardHeaderAction";
import { MoreMetaTags } from "../../board/settings/components/MoreMetaTags";
import { NoteMoreCopyLink } from "./NoteMoreCopyLink";
import { NoteMoreDelete } from "./NoteMoreDelete";
import { NoteMoreDuplicate } from "./NoteMoreDuplicate";
import { NoteMoreLayoutOptions } from "./NoteMoreLayoutOptions";

type Props = {
	node: NodeModel;
};

export const NoteMore = ({ node }: Props) => {
	const { canEdit } = usePermissions("project");

	if (!canEdit) {
		return null;
	}

	const nodeId = node.id;
	const nodeLayout = node.layout;

	const boardId = node.board;

	const render: PopoverRender = ({ close }) => (
		<PopoverContent>
			<MenuNodeLayout nodeId={nodeId} layout={nodeLayout} />
			<MoreMetaTags docId={nodeId} boardId={boardId} scope="node" />
			<DropdownHr />
			<NoteMoreCopyLink boardId={boardId} nodeId={nodeId} close={close} />
			<NoteMoreDuplicate node={node} close={close} />
			<DropdownHr />
			<NoteMoreLayoutOptions boardId={boardId} />
			<DropdownHr />
			<NoteMoreDelete nodeId={nodeId} close={close} />
		</PopoverContent>
	);

	return (
		<Popover render={render} placement="bottom-start">
			<div>
				<BoardHeaderAction>
					<FiMoreHorizontal />
				</BoardHeaderAction>
			</div>
		</Popover>
	);
};
