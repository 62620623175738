import { FiMoreHorizontal, FiTrash } from "react-icons/fi";
import styled from "styled-components";
import { z } from "zod";

import { useNodeMetaActions } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaActions";
import { NodeMetaSelectOptionsItemEditLabelInput } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectOptionsItemEditLabelInput";
import { nodeMetaTypeList, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";
import { ContextItem } from "@/modules/nodeContextMenu/components/ContextItem";
import { DropdownHeader, DropdownHr, DropdownMenuButton } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover } from "@/shared/system/Dropdown/Popover";
import { hexToHsl } from "@/shared/utils/hexToHsl";
import { NodeMetaValueSelect, UserTheme } from "@/types/db";

const colors = [
	{
		value: "#8584a7",
		label: "Grey",
	},
	{
		value: "#887b6c",
		label: "Brown",
	},
	{
		value: "#ffa26c",
		label: "Orange",
	},
	{
		value: "#ebd664",
		label: "Yellow",
	},
	{
		value: "#53dd71",
		label: "Green",
	},
	{
		value: "#67c9e7",
		label: "Blue",
	},
	{
		value: "#8d57d3",
		label: "Purple",
	},
	{
		value: "#c85fb0",
		label: "Pink",
	},
	{
		value: "#d46868",
		label: "Red",
	},
];

const updateValueInArray = (newValue: NodeMetaValueSelect, oldValues: NodeMetaValueSelect[]): NodeMetaValueSelect[] => {
	const incomingValueIndex = oldValues.findIndex((value) => value.id === newValue.id);

	if (incomingValueIndex !== -1) {
		const newArray = oldValues.slice(0);
		newArray.splice(incomingValueIndex, 1);
		newArray.splice(incomingValueIndex, 0, newValue);

		return newArray;
	}
	return oldValues;
};

const buildNewValue = (property: keyof NodeMetaValueSelect, value: string, existingValue: NodeMetaValueSelect) => {
	return {
		...existingValue,
		[property]: value,
	};
};

const deleteProperty = {
	id: "delete",
	label: "Delete",
	icon: FiTrash,
};

interface Props {
	meta: z.infer<typeof nodeMetaTypeSelect> | z.infer<typeof nodeMetaTypeList>;
	value: NodeMetaValueSelect;
	onToggle?: (isExpanded: boolean) => void;
}

export const NodeMetaSelectOptionsItemEdit = ({ value, meta }: Props) => {
	const { updateNodeMetaValue } = useNodeMetaActions();

	if (!meta) {
		return null;
	}

	const canEditColor = value.color !== null;

	const voidFn = () => {};

	const render = ({ close }: any) => {
		const handleUpdateTitle = ({ label, isDirty }: { label: string; isDirty: boolean }) => {
			if (isDirty) {
				const newMetaValues = updateValueInArray(buildNewValue("title", label, value), meta.value);
				updateNodeMetaValue(meta.id, newMetaValues);
				close();
			}
		};

		const handleUpdateColor = (color: string) => {
			const newMetaValues = updateValueInArray(buildNewValue("color", color, value), meta.value);
			updateNodeMetaValue(meta.id, newMetaValues);
			close();
		};

		const handleDeleteValue = () => {
			const filteredValues = meta.value.filter((metaValue) => metaValue.id !== value.id);
			updateNodeMetaValue(meta.id, filteredValues);
			close();
		};

		return (
			<PopoverContent data-testid="node-meta-select-options-item-edit--menu">
				<div>
					<SectionHeader>Label</SectionHeader>
					<NodeMetaSelectOptionsItemEditLabelInput value={value.title} onUpdate={handleUpdateTitle} close={voidFn} />
				</div>
				{canEditColor && (
					<div>
						<SectionHeader>Color</SectionHeader>
						{colors.map((item) => {
							const pallet = hexToHsl(item.value);

							return (
								<ColorItem onClick={() => handleUpdateColor(item.value)} key={item.value}>
									<Swatch pallet={pallet} />
									<Item>{item.label}</Item>
								</ColorItem>
							);
						})}
					</div>
				)}
				<DropdownHr />
				<ContextItem item={deleteProperty} onClick={handleDeleteValue} />
			</PopoverContent>
		);
	};

	return (
		<Popover render={render}>
			<div>
				<FiMoreHorizontal data-testid="node-meta-select-options-item-edit--trigger" />
			</div>
		</Popover>
	);
};

const SectionHeader = styled(DropdownHeader)`
	margin-bottom: 4px;
	font-size: 12px;
	padding: 0;
`;

const ColorItem = styled(DropdownMenuButton)`
	display: flex;
	flex: 1;
	align-items: center;
	border: 0;
	background: transparent;
	width: 100%;
	border-radius: 4px;
	margin: 1px 0;
	padding: 4px 6px;
	cursor: pointer;

	:hover {
		background: var(--color-hover);
	}
`;

const Swatch = styled.div<{ pallet: any }>`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	flex-shrink: 0;

	${({ pallet, theme }) => {
		const dark = `
				border: 1px solid var(--color-body);
				background-color: color-mix(in srgb, ${pallet.original} 70%, var(--color-body));
			`;
		const light = `
			border: 1px solid ${pallet.original};
			background-color: color-mix(in srgb, ${pallet.original} 15%, var(--color-body));
			`;

		switch (theme) {
			case UserTheme.dark: {
				return dark;
			}
			case UserTheme.light: {
				return light;
			}
			default: {
				return `
					@media (prefers-color-scheme: dark) {
						${dark}
					}

					@media (prefers-color-scheme: light) {
						${light}
					}
				`;
			}
		}
	}};
`;

const Item = styled.div`
	padding-left: 8px;
`;
