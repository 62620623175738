import { ReactElement } from "react";

import * as RadixTooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";

type Props = {
	label?: string | ReactElement;
	combo?: ReactElement;
	children: ReactElement;
};

export default function Tooltip({ label, combo, children }: Props) {
	return (
		<RadixTooltip.Provider delayDuration={333}>
			<RadixTooltip.Root>
				<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
				<RadixTooltip.Portal>
					<Content sideOffset={6} collisionPadding={6}>
						{label}
						{combo && <span>{combo}</span>}
					</Content>
				</RadixTooltip.Portal>
			</RadixTooltip.Root>
		</RadixTooltip.Provider>
	);
}

const Content = styled(RadixTooltip.Content)`
	display: flex;
	align-items: center;
	min-height: 36px;
	z-index: 10;
	border: 0;
	border-radius: 4px;
	padding: 6px 8px;
	color: var(--color-text-secondary);
	background: var(--color-body);
	border: var(--border);
	line-height: 1;
	font-size: 12px;
	font-weight: 500;
	gap: 8px;

	kbd {
		color: var(--color-text-heading);
		background-color: var(--color-border);
	}
`;
