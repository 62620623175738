import { atom, selectorFamily } from "recoil";

import { KeyResultsDto } from "@/domains/nodes/components/keyResults/dtos/keyResultsDto";

import { KeyResultModel, WithId } from "../../../../../types/db";

export const keyResultsState = atom<WithId<KeyResultsDto>[]>({
	key: "KeyResults",
	default: [],
});

const sortUpdates = ({ updates, ...result }: WithId<KeyResultsDto>): KeyResultModel => {
	const updatesByDate = updates
		.slice(0)
		.sort((a, b) => {
			if (a.createdAt.seconds > b.createdAt.seconds) {
				return 1;
			}
			if (a.createdAt.seconds < b.createdAt.seconds) {
				return -1;
			}
			return 0;
		})
		.map((update, index) => ({
			// Id is required here so that individual updates can be removed
			...update,
			id: index,
		}));

	return {
		...result,
		updates: updatesByDate,
	};
};

export const keyResultsNode = selectorFamily({
	key: "KeyResultsNode",
	get:
		(nodeId: string) =>
		({ get }) => {
			const allKeyResults = get(keyResultsState);
			const keyResultsForNode = allKeyResults.filter((result) => result.nodeId === nodeId);

			return keyResultsForNode.map(sortUpdates);
		},
});
