import { captureException } from "@sentry/browser";
import { FiCopy } from "react-icons/fi";
import { useRecoilValue } from "recoil";

import { ProjectBoard, WithId } from "@/types/db";

import { DropdownMenuButton } from "../../../../shared/system";
import { useCloneBoard } from "../../../nodeContextMenu/hooks/useCloneBoard";
import { getPlanDetails } from "../../../plans/selectors/getPlanDetails";
import { useToast } from "../../../toast/hooks/useToast";
import { usePermissions } from "../../../workspace/hooks/usePermissions";

type Props = {
	board: WithId<ProjectBoard>;
	onClick: VoidFunction;
};

export const MoreDuplicate = ({ board, onClick }: Props) => {
	const { canEdit } = usePermissions("project");
	const planDetails = useRecoilValue(getPlanDetails);
	const setToast = useToast();

	const cloneBoard = useCloneBoard();

	const canClone = !planDetails?.usage.nodes.isLocked && canEdit;
	const handleClone = async () => {
		if (canClone) {
			onClick();
			setToast({ status: "info", message: "Cloning project" });
			try {
				await cloneBoard(board);
				setToast({ status: "success", message: "Project successfully coned" });
			} catch (error) {
				setToast({ status: "error", message: "Failed to cone project" });
				console.log(error);
				captureException(error);
			}
		}
	};

	return (
		<DropdownMenuButton onClick={handleClone} disabled={!canClone}>
			<FiCopy /> Clone project
		</DropdownMenuButton>
	);
};
