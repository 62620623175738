import styled from "styled-components";

import { Tooltip } from "../../../shared/system";

export default function BoardHeaderAction(props: any) {
	const { tooltip, combo, children, ...rest } = props;
	return (
		<Tooltip label={tooltip} combo={combo}>
			<Action {...rest}>{children}</Action>
		</Tooltip>
	);
}

const Action = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 20px;
	height: 100%;
	margin: 0 6px;
	color: var(--color-text-secondary);
	cursor: pointer;

	:hover {
		color: var(--color-accent);
	}

	> svg {
		display: block;
	}
`;
