import { Box } from "rebass";
import styled from "styled-components";
import {
	borderBottom,
	borderColor,
	borderTop,
	color,
	display,
	maxWidth,
	overflow,
	position,
	textAlign,
	zIndex,
} from "styled-system";

export const BoxStyled = styled(Box)`
	${borderTop}
	${borderColor}
  ${borderBottom}
  ${color}
  ${display}
  ${position}
  ${textAlign}
  ${zIndex}
  ${overflow}
  ${maxWidth}
`;

export default BoxStyled;
