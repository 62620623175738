import { captureException } from "@sentry/browser";
import { signOut } from "firebase/auth";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { useProfileLegacy } from "@/modules/profile/hooks/useProfileLegacy";
import * as analyticsService from "@/shared/core/analytics/hooks/useAnalyticsService";

import { DropdownMenuItem } from "..";
import { setUserOffline } from "../../../modules/presence/presenceFunctions";
import routes from "../../core/routes";
import { fb } from "../../infra/init";

const SignOutButton = () => {
	const profile = useProfileLegacy();
	const navigate = useNavigate();

	const handleSignOut = async () => {
		const userId = profile.id;

		analyticsService.signOut({ profile });

		try {
			userId && (await setUserOffline(userId));
		} catch (e) {
			captureException(e);
		}

		navigate(routes.home);
		await signOut(fb.auth);
	};

	return (
		<DropdownMenuItem onSelect={handleSignOut}>
			<FiLogOut />
			Sign Out
		</DropdownMenuItem>
	);
};

export default SignOutButton;
