import { memo, PropsWithChildren, Suspense } from "react";

import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import AppLoading from "../../shared/system/InApp/AppLoading";

type Props = PropsWithChildren<{
	sidebar?: JSX.Element | null;
}>;

export const Layout = memo(({ sidebar = null, children }: Props) => {
	return (
		<Root>
			<Helmet>
				<meta name="viewport" content="width=1280,  shrink-to-fit=yes"></meta>
			</Helmet>
			{sidebar}
			<Main>
				<Suspense fallback={<AppLoading />}>{children}</Suspense>
			</Main>
		</Root>
	);
});

Layout.displayName = "Layout";

const Root = styled.div`
	display: flex;
	height: 100%;
`;

const Main = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-width: 0;
	overflow-x: hidden;
`;
