import * as Accordion from "@radix-ui/react-accordion";
import styled from "styled-components";

import { SidebarNavigationItemFolderName } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemFolderName";
import { FileFolderCaret } from "@/shared/system/Files/FileFolderCaret";
import { FileTree, FileTreeFileMap } from "@/shared/system/Files/FileTree";
import { ProjectFolder, WithId } from "@/types/db";

type Props = {
	project: WithId<ProjectFolder>;
	fileTypeMap: FileTreeFileMap;
	onClick: (projectId?: string) => void;
	isActive?: boolean;
	isEditing?: boolean;
	type?: "board" | "folder";
};

export const FileFolder = ({ project, onClick, fileTypeMap, isActive, isEditing, type }: Props) => {
	const {
		data: { name },
		childIds,
	} = project;

	const hasChildren = childIds.length > 0;

	const handleClick = (e: any) => {
		e.stopPropagation();
		onClick(project.id);
	};

	const variant = isActive ? "active" : "";

	return (
		<div className="Folder">
			<Accordion.Root className="folder" type="single" defaultValue="item-2" disabled={!hasChildren} collapsible>
				<Accordion.Item value="item-2">
					<Accordion.Header>
						<Toggle className={variant}>
							<FileFolderCaret hasChildren={hasChildren} />
							<FolderName onClick={handleClick}>
								<SidebarNavigationItemFolderName name={name} projectId={project.id} isEditing={isEditing} />
							</FolderName>
						</Toggle>
					</Accordion.Header>
					{hasChildren && (
						<Panel>
							{childIds.map((projectId) => {
								return (
									<FileTree projectId={projectId} key={projectId} fileTypeMap={fileTypeMap} onClick={onClick} type={type} />
								);
							})}
						</Panel>
					)}
				</Accordion.Item>
			</Accordion.Root>
		</div>
	);
};

export const Actions = styled.div`
	display: flex;
	visibility: hidden;
	margin-left: auto;
`;

export const Toggle = styled.div`
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 6px 8px;
	min-width: 0;
	flex: 1;

	:hover,
	&.active {
		background: var(--color-hover);

		${Actions} {
			visibility: visible;
		}
	}

	&.editing {
		padding: 5px 7px;
		border: var(--border);
		border-color: var(--color-accent);
		background: var(--color-body);
	}
`;

const FolderName = styled.div`
	flex: 1;
`;

export const FolderToggle = styled(Accordion.Trigger)`
	background: transparent;
	border: 0;
`;

const Panel = styled(Accordion.Content)`
	margin-left: 20px;
	margin-top: 1px;
`;
