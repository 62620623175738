import { formatDistance, fromUnixTime } from "date-fns";
import styled from "styled-components";

import { Timestamp } from "@/shared/infra/firebase/firestore";

type Props = {
	timestamp: Timestamp;
	className?: string;
};

export const MessageTimestamp: React.FC<Props> = ({ timestamp, className }) => {
	const date = fromUnixTime(timestamp.seconds);
	const relativeDate = formatDistance(date, new Date());

	return <Root className={className}>{relativeDate} ago</Root>;
};

const Root = styled.span`
	display: inline-block;
	margin-left: 8px;
	color: var(--color-text);
`;
