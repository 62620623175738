import { selectorFamily } from "recoil";

import { boardMembersWorkspace } from "../../board/hooks/useBoardMembersWorkspace";
import { boardGuestState } from "../../board/hooks/useGuestListener";

export const getBoardMembers = selectorFamily({
	key: "getBoardMembers",
	get:
		({ boardId, workspaceId }: { boardId: string; workspaceId: string }) =>
		({ get }) => {
			const members = get(boardMembersWorkspace(workspaceId));
			const guests = get(boardGuestState(boardId));

			const mergedMembers = {
				...members,
				...guests,
			};
			return Object.values(mergedMembers);
		},
});
