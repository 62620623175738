import { captureException } from "@sentry/browser";
import { arrayRemove, arrayUnion, doc, writeBatch } from "firebase/firestore";

import { projectSetPublic } from "@/modules/board/api/projectSetPublic";
import { db, fb } from "@/shared/infra/init";
import { ProjectVisibility, PublicProfile } from "@/types/db";

type ProjectUpdateVisibility = (args: {
	projectId?: string;
	currentVisibility: ProjectVisibility;
	visibility: ProjectVisibility | "public";
	folders: { old?: string; new?: string };
	user: PublicProfile;
}) => void;

export const projectUpdateVisibility: ProjectUpdateVisibility = ({
	projectId,
	currentVisibility,
	visibility,
	folders,
	user,
}) => {
	if (!projectId) {
		// TODO: Error handling all domain events should return result...
		return;
	}

	if (!folders.old || !folders.new) {
		// TODO: Error handling all domain events should return result...
		return;
	}

	const projectDocument = doc(db.projects, projectId);
	const visibilityHasChanged = currentVisibility !== visibility && visibility !== "public";

	/**
	 * TODO: This case doesn't map very well as public isn't a value of the visibility property
	 * meaning that both private and workspace boards can be made public...
	 *
	 * This leads to some sub optimal UX where the visibility is shown as public, but is actually
	 * set to the last value and the meta is set to public
	 */
	if (visibility === "public") {
		projectSetPublic(projectId, true, user);
		return;
	}

	const batch = writeBatch(fb.firestore);

	const oldFolderDocument = doc(db.projects, folders.old);
	const newFolderDocument = doc(db.projects, folders.new);

	batch.update(projectDocument, {
		visibility,
		"meta.public": false,
		updatedAt: fb.timestamp(),
		updatedBy: user,
	});

	if (visibilityHasChanged) {
		batch.update(oldFolderDocument, {
			childIds: arrayRemove(projectId),
		});

		batch.update(newFolderDocument, {
			childIds: arrayUnion(projectId),
		});
	}

	try {
		batch.commit();
	} catch (e) {
		captureException(e);
	}
};
