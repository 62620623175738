import { captureException } from "@sentry/browser";
import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentSnapshot,
	Firestore,
	FirestoreError,
	getDocs,
	onSnapshot,
	PartialWithFieldValue,
	Query,
	QuerySnapshot,
	setDoc,
	Unsubscribe,
	updateDoc,
} from "firebase/firestore";

import { fb } from "@/shared/infra/init";

export abstract class BaseApi<C = DocumentData> {
	private collectionName: string;
	protected firestore: Firestore;

	public collection: CollectionReference<C>;

	constructor(collectionName: string) {
		this.firestore = fb.firestore;
		this.collectionName = collectionName;
		this.collection = this.createCollection(collectionName);
	}

	private getErrorResponseHandler(error: FirestoreError): void {
		captureException(error);
	}

	public static sanitizeId(id: string) {
		// Paths must not be empty, begin with '.', end with '.', or contain '..'
		return id.replace(/\./g, "");
	}

	private createCollection(collectionPath: string) {
		return collection(this.firestore, collectionPath) as CollectionReference<C>;
	}

	public getDocument(docId?: string) {
		if (docId) {
			const sanitizedId = BaseApi.sanitizeId(docId);
			return doc(this.collection, sanitizedId);
		}

		return doc(this.collection);
	}

	protected documentSnapshot(
		documentId: string,
		next: (data: DocumentSnapshot<C>) => void,
		error: (error: FirestoreError) => void = this.getErrorResponseHandler,
	): Unsubscribe {
		const document = this.getDocument(documentId);

		return onSnapshot(
			document,
			(snapshot) => {
				console.log(`[GET] ${this.collectionName}`);
				next(snapshot);
			},
			error,
		);
	}

	protected querySnapshot(
		query: Query<C>,
		next: (data: QuerySnapshot<C>) => void,
		error: (error: FirestoreError) => void = this.getErrorResponseHandler,
	): Unsubscribe {
		return onSnapshot(
			query,
			(snapshot) => {
				console.log(`[GET] ${this.collectionName}`);
				next(snapshot);
			},
			error,
		);
	}

	public queryDocuments(query: Query<C>) {
		return getDocs(query);
	}

	protected post(documentId: string | undefined, data: C) {
		const document = this.getDocument(documentId);
		return setDoc(document, data, { merge: true });
	}

	protected async put(documentId: string, data: Partial<C>) {
		const document = this.getDocument(documentId);
		return setDoc(document, data, { merge: true });
	}

	protected async patch(documentId: string, data: PartialWithFieldValue<C>) {
		const document = this.getDocument(documentId);

		//@ts-expect-error
		return updateDoc(document, data);
	}
	protected async delete() {}
}
