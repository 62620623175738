import { useCallback } from "react";

import { useSetRecoilState } from "recoil";

import { ToastMessageState, toastState } from "../Toasts";

export const useToast = () => {
	const setShowToast = useSetRecoilState(toastState);

	const displayToast = useCallback(
		(message: ToastMessageState) => {
			setShowToast(message);

			setTimeout(() => {
				setShowToast(null);
			}, 2500);
		},
		[setShowToast],
	);

	return displayToast;
};
