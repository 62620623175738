import { useCallback } from "react";

import styled from "styled-components";

import { useNodeFocus } from "@/domains/nodes/components/cards/hooks/useNodeFocus";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { NodeLayoutList } from "../../../../domains/nodes/components/lists/NodeLayoutList";

export const childNodeStorageKey = "details-display-child-nodes";

type Props = {
	node: NodeModel;
};

export const ChildNodeList = ({ node }: Props) => {
	const { setFocused } = useNodeFocus();

	const handleClearFocusedNode = useCallback(() => {
		setFocused(null);
	}, [setFocused]);

	return (
		<ItemRoot onMouseLeave={handleClearFocusedNode}>
			<Header>
				<Heading>Child nodes</Heading>
			</Header>
			<Panel>
				<NodeLayoutList node={node} variant="gripper" />
			</Panel>
		</ItemRoot>
	);
};

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Heading = styled.h4`
	margin: 0;
`;

const ItemRoot = styled.div``;

const Panel = styled.div`
	margin-bottom: 24px;
	padding-top: 16px;
`;
