import styled from "styled-components";

export const HelpKeyboardShortcuts = () => {
	return (
		<div>
			<SubHeader>Adding nodes</SubHeader>
			<List>
				<ListItem>
					<span>Add node to the left</span>
					<span>
						<kbd>⌘</kbd> + <kbd>←</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Add node to the right</span>
					<span>
						<kbd>⌘</kbd> + <kbd>→</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Add node as child</span>
					<span>
						<kbd>⌘</kbd> + <kbd>↓</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span> Finalize creating a node</span>
					<span>
						<kbd>Enter</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Removes node currently being added</span>
					<span>
						<kbd>Esc</kbd>
					</span>
				</ListItem>
			</List>
			<SubHeader>Editing nodes</SubHeader>
			<List>
				<ListItem>
					<span> Rename node</span>
					<span>
						<kbd>r</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Collapse node</span>
					<span>
						<kbd>⇧</kbd> + <kbd>c</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Promote to project</span>
					<span>
						<kbd>⇧</kbd> + <kbd>p</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Card layout: Default</span>
					<span>
						<kbd>c</kbd>
						then
						<kbd>d</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Card layout: Cover</span>
					<span>
						<kbd>c</kbd>
						then
						<kbd>c</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Card layout: Icon</span>
					<span>
						<kbd>c</kbd>
						then
						<kbd>i</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Collapse node</span>
					<span>
						<kbd>c</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>View node details</span>
					<span>
						<kbd>Enter</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Delete node</span>
					<span>
						<kbd>⌘</kbd> + <kbd>Backspace</kbd>
					</span>
				</ListItem>
			</List>
			<SubHeader>Navigating the board</SubHeader>
			<List>
				<ListItem>
					<span> Collapses all nodes at depth</span>
					<span>
						<kbd>⌥</kbd> + <kbd>[1-9]</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Go to dashboard</span>
					<span>
						<kbd>g</kbd> then <kbd>d</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Switch to roadmap view</span>
					<span>
						<kbd>g</kbd> then <kbd>r</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Switch to tree view</span>
					<span>
						<kbd>g</kbd> then <kbd>t</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Clear filters</span>
					<span>
						<kbd>c</kbd> then <kbd>f</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Zoom in out</span>
					<span>
						<kbd>⌘</kbd> + <kbd>Scroll</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span> Navigate between nodes</span>
					<span>
						<kbd> ↑ </kbd> <kbd> → </kbd> <kbd> ↓ </kbd> <kbd> ←</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Collapses the sidebar</span>
					<span>
						<kbd>[</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Open filter modal</span>
					<span>
						<kbd>f</kbd>
					</span>
				</ListItem>
				<ListItem>
					<span>Open share modal</span>
					<span>
						<kbd>p</kbd>
					</span>
				</ListItem>
			</List>
		</div>
	);
};

const SubHeader = styled.h2`
	font-weight: 500;
	font-size: 16px;
	line-height: 1.55;
	margin-top: 24px;
	margin-bottom: 4px;

	:first-child {
		margin-top: 0;
	}
`;

const List = styled.ul`
	list-style: none;
	padding-left: 0;
	margin: 0;
	color: var(--color-text-secondary);

	kbd {
		color: var(--color-text-heading);
	}
`;

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
`;
