import React, { useCallback } from "react";

import { FiClock } from "react-icons/fi";
import styled from "styled-components";

import { Avatar } from "../../../shared/system";
import { Notification, WithId } from "../../../types/db";
import { InboxMessageContent } from "./InboxMessageContent";

type Props = {
	notification: WithId<Notification>;
	handleClose: () => void;
	handleMarkAsRead: (read?: boolean) => void;
};

export function InboxMessageComment({ notification, handleClose, handleMarkAsRead }: Props) {
	const { from } = notification;

	const icon = notification.type === "reminder" ? FiClock : undefined;

	const handleLinkClick = useCallback(() => {
		handleClose();
		handleMarkAsRead();
	}, [handleClose, handleMarkAsRead]);

	return (
		<Root>
			<AvatarWrapper>
				<Avatar user={from} icon={icon} size={20} />
			</AvatarWrapper>
			<Message>
				<InboxMessageContent notification={notification} handleLinkClick={handleLinkClick} />
			</Message>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	flex: 1;
	min-width: 0;
`;

const AvatarWrapper = styled.div`
	margin-right: 12px;
`;

const Message = styled.div`
	flex-grow: 1;
`;
