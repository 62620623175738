import { doc, updateDoc } from "firebase/firestore";

import { db } from "@/shared/infra/init";

export type ProjectUpdateGrouping = (projectId: string, metaId: string) => Promise<void>;

export const projectUpdateGrouping: ProjectUpdateGrouping = async (projectId, metaId) => {
	const projectDoc = doc(db.projects, projectId);

	updateDoc(projectDoc, {
		"meta.groupedBy": metaId,
	});
};
