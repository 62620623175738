import { SyntheticEvent, useCallback } from "react";

import styled from "styled-components";

import { LayoutOption, nodeLayoutOptions } from "@/domains/nodes/components/menu/hooks/useShortcutsNodeEdit";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { DropdownHeader } from "@/shared/system";

type OptionProps = {
	option: LayoutOption;
	currentLayout: string;
	nodeId: string;
};

const LayoutButton = ({ option, nodeId, currentLayout }: OptionProps) => {
	const nodesService = useNodesService();

	const { icon, label, value } = option;
	const Icon = icon;
	const isSelected = value === currentLayout;
	const variant = isSelected ? "selected" : "";

	const handleClick = useCallback(
		(event: SyntheticEvent) => {
			event.preventDefault();
			nodesService.update(nodeId, { layout: value });
		},
		[nodeId, nodesService, value],
	);

	return (
		<Group onClick={handleClick} className={variant} key={value}>
			<Icon /> <span>{label}</span>
		</Group>
	);
};

type Props = {
	nodeId: NodeModel["id"];
	layout: NodeModel["layout"];
};

export const MenuNodeLayout = ({ nodeId, layout }: Props) => {
	const currentLayout = layout || "stacked";

	return (
		<div>
			<DropdownHeader>Card Layout</DropdownHeader>
			<Root>
				{nodeLayoutOptions.map((option) => {
					return <LayoutButton nodeId={nodeId} option={option} currentLayout={currentLayout} key={option.value} />;
				})}
			</Root>
		</div>
	);
};

const Root = styled.div`
	display: flex;
	justify-content: center;
`;

const Group = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 12px;
	padding: 6px 2px;
	border-radius: 4px;
	margin-right: 0;
	color: var(--color-text-heading);
	cursor: pointer;

	> svg {
		font-size: 28px;
		margin-bottom: 8px;
		margin-right: 0;
		color: var(--color-text-secondary);
	}

	span {
		line-height: 1;
	}

	:hover {
		background: var(--color-hover);
	}

	&.selected {
		color: var(--color-accent);

		svg {
			color: var(--color-accent);
		}
	}
`;
