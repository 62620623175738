import styled from "styled-components";

import { SidebarNavigationItem } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItem";
import { getThemeForProject } from "@/modules/board/settings/components/theme/utils/getThemeForProject";
import { Truncate } from "@/shared/system";
import { generateColorHsl } from "@/shared/system/Avatar/AvatarStatic";
import { ProjectBoard, WithId } from "@/types/db";

type GetPathToProject = (args: WithId<ProjectBoard>) => string;

const getPathToProject: GetPathToProject = ({ id, meta }) => {
	switch (meta.view) {
		case "board": {
			return `/r/${id}`;
		}
		default:
		case "tree": {
			return `/b/${id}`;
		}
	}
};

type Props = {
	project: WithId<ProjectBoard>;
};

export const SidebarNavigationItemBoard = ({ project }: Props) => {
	const projectColor = getThemeForProject(project)?.accent || generateColorHsl(project.id, 0.75);
	const projectBorder = generateColorHsl(project.id);

	const path = getPathToProject(project);

	return (
		<Project to={path} data-project-id={project.id}>
			<ProjectColor color={projectColor} border={projectBorder} />
			<Truncate>{project.data.name}</Truncate>
		</Project>
	);
};

const Project = styled(SidebarNavigationItem)`
	&.active {
		background: var(--color-hover);
	}
`;

const ProjectColor = styled.div<any>`
	display: inline-block;
	width: 14px;
	height: 14px;
	border-radius: 4px;
	margin-right: 8px;
	background: ${(props) => props.color};
	border: 1px solid;
	border-color: ${(props) => `color-mix(in srgb, ${props.color}, var(--color-contrast) 12%)`};
	position: relative;
	flex-shrink: 0;
`;
