import React from "react";

import iconMore from "../../assets/images/logo-icon.svg";
import routes from "../../shared/core/routes";
import { Box, Divider, Flex, Link, Text } from "../../shared/system";
import Container from "../components/Container";

export const Footer = () => {
	return (
		<>
			<Divider />
			<Box py={4}>
				<Container>
					<Flex alignItems="center" px={[8, 32]}>
						<img src={iconMore} width="24" height="24" style={{ display: "block" }} alt="Poda: Product Planning" />
						<Box flex="1 1 auto">
							<Text mb={0} ml={2}></Text>
						</Box>
						<Link.Href as="a" target="_blank" href="https://twitter.com/poda_app" rel="noopener noreferrer nofollow" mr={3}>
							Twitter
						</Link.Href>
						<Link.Plain to={routes.privacy} mr={3}>
							Privacy
						</Link.Plain>
						<Link.Plain to={routes.terms}>Terms</Link.Plain>
					</Flex>
				</Container>
			</Box>
		</>
	);
};
