import { useCallback } from "react";

import { useParams } from "react-router-dom";

import { fb } from "../../../../shared/infra/init";
import { guid } from "../../../../shared/utils/guid";
import { useActiveWorkspace } from "../../../workspace/hooks/useActiveWorkspace";

const storageRef = fb.storage;

const hashFileName = (file: File) => {
	const fileName = guid();
	const extension = file.name.split(".").pop();

	return `${fileName}.${extension}`;
};

export const useFileUpload = () => {
	const activeWorkspace = useActiveWorkspace();
	const { boardId, nodeId } = useParams() as { boardId: string; nodeId: string };

	const workspaceId = activeWorkspace?.id;

	return useCallback(
		async (file: File): Promise<string | undefined> => {
			if (!workspaceId) {
				alert("Something went wrong and we were unable to upload the image");
				return;
			}

			const metadata = {
				fileName: file.name,
				contentType: "image/jpeg",
			};

			const hashedFileName = hashFileName(file);

			try {
				const uploadTask = await storageRef
					.child(`/workspaces/${workspaceId}/boards/${boardId}/nodes/${nodeId}/${hashedFileName}`)
					.put(file, metadata);

				return uploadTask.ref.getDownloadURL();
			} catch (e) {
				alert("Something went wrong and we were unable to upload the image");
			}
		},
		[workspaceId, boardId, nodeId],
	);
};
