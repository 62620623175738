import { forwardRef, SyntheticEvent } from "react";

import styled from "styled-components";

import { DropdownHr, DropdownMenuButton } from "@/shared/system/Dropdown";
import { Truncate } from "@/shared/system/Text/Text";

interface Props {
	icon?: any;
	kbd?: any;
	label: string;
	disabled?: boolean;
	onClick?: (e: any) => void;
	handleClick?: (e: any) => void;
	isEndOfSection?: boolean;
}

export const MenuItem = forwardRef<HTMLButtonElement, Props>(
	({ icon, kbd, label, disabled, isEndOfSection, handleClick, onClick, ...props }, ref) => {
		const Icon = icon;
		const Kbd = kbd;

		const whenClicked = (e: SyntheticEvent) => {
			e.preventDefault();

			handleClick && handleClick(e);
			onClick && onClick(e);
		};

		return (
			<>
				<DropdownMenuButton {...props} ref={ref} role="menuitem" onClick={whenClicked} disabled={disabled}>
					{icon && <Icon />}
					<Truncate>{label}</Truncate>
					{kbd && (
						<Shortcut>
							<Kbd />
						</Shortcut>
					)}
				</DropdownMenuButton>
				{isEndOfSection && <DropdownHr />}
			</>
		);
	},
);

MenuItem.displayName = "MenuItem";

const Shortcut = styled.span`
	display: flex;
	gap: 2px;
	margin-left: auto;
	padding-left: 12px;
	align-self: flex-end;
	color: var(--color-text-secondary);
`;
