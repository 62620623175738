import { DocumentSnapshot, Unsubscribe } from "firebase/firestore";
import { z } from "zod";

import { projectDto } from "@/domains/projects/dtos/projectsDto";
import { ProjectsUtil } from "@/domains/projects/utils/projectUtils";
import { IProjectsStore } from "@/domains/projects/zustand/projectsStore";
import { ProjectDto } from "@/types/db";

import { BaseApi } from "../../../shared/infra/services/BaseApi";

export default class ProjectService extends BaseApi<z.infer<typeof projectDto>> {
	constructor() {
		super("projects");
	}

	public listen(projectId: string, save: IProjectsStore["setProjectSingle"], error?: () => void): Unsubscribe {
		const setState = (snapshot: DocumentSnapshot<ProjectDto>) => {
			const viewModel = ProjectsUtil.toViewModel(snapshot);
			save(viewModel);
		};

		return this.documentSnapshot(projectId, setState, error);
	}
}
