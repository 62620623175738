import { FiBook, FiChevronRight, FiExternalLink, FiSettings } from "react-icons/fi";
import { MdOutlineKeyboardAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { sidebarState } from "../../../../../modules/page/selectors/sidebarState";
import routes from "../../../../../shared/core/routes";
import { Button } from "../../../../../shared/system";

const helpCentreLink = "https://www.notion.so/podaio/Poda-s-help-centre-9058af967316457b98630fa107492ada";

type Props = {
	setPane: (name: "shortcuts" | "home") => void;
};

export const HelpActionsList = ({ setPane }: Props) => {
	const navigate = useNavigate();
	const [, setSidebar] = useRecoilState(sidebarState);

	const handleGuideClick = () => {
		window.open(helpCentreLink, "_blank")?.focus();
		setSidebar(null);
	};

	const handleSettingsClick = () => {
		navigate(routes.account);
		setSidebar(null);
	};

	const handleShortcutsClick = () => setPane("shortcuts");

	return (
		<div>
			<Card onClick={handleGuideClick}>
				<CardIcon>
					<FiBook />
				</CardIcon>
				<CardContent>
					<strong>Documentation</strong>
					Poda how to guide
				</CardContent>
				<ActionRight>
					<FiExternalLink />
				</ActionRight>
			</Card>
			<Card onClick={handleSettingsClick}>
				<CardIcon>
					<FiSettings />
				</CardIcon>
				<CardContent>
					<strong>Settings</strong>
					Access your workspace settings
				</CardContent>
				<ActionRight>
					<FiChevronRight />
				</ActionRight>
			</Card>
			<Card onClick={handleShortcutsClick}>
				<CardIcon>
					<MdOutlineKeyboardAlt />
				</CardIcon>
				<CardContent>
					<strong>Keyboard Shortcuts</strong>
					Learn how to work faster
				</CardContent>
			</Card>
		</div>
	);
};

const Card = styled(Button.Outline)`
	display: flex;
	border-radius: 4px;
	padding: 20px 16px;
	margin-bottom: 16px;
	width: 100%;
	text-align: left;
	border: var(--border);
`;

const CardIcon = styled.div`
	width: 12px;
	margin-right: 16px;
	margin-top: 1px;
	color: var(--color-accent);
`;

const ActionRight = styled.div`
	margin-left: auto;
	align-self: center;
	color: var(--color-text);
`;

const CardContent = styled.div`
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: var(--color-text);

	strong {
		color: var(--color-text-heading);
		display: block;
		font-weight: 500;
		margin-bottom: 4px;
	}
`;
