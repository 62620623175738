import { FiEye } from "react-icons/fi";
import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { Button } from "@/shared/system";

type Props = {
	childCount: number;
	onClick: () => void;
	layout?: NodeModel["layout"];
};

export const NodeActionsCollapsed: React.FC<Props> = ({ childCount = 0, layout, onClick }) => {
	const showFauxCards = layout !== "icon";

	return (
		<>
			{showFauxCards && <EmptyCard />}
			{showFauxCards && <SmallEmptyCard />}
			<ButtonReveal onClick={onClick} className="no-hover">
				<FiEye />
				Reveal {childCount} hidden nodes
			</ButtonReveal>
		</>
	);
};

const EmptyCard = styled.div`
	position: relative;
	bottom: -1px;
	height: 6px;
	margin: 0 20px;
	background: var(--color-body);
	border-radius: 0 0 4px 4px;
	transition: all 0.15s ease-in-out;
	transition-delay: 125ms;
	opacity: 0.75;
`;

const SmallEmptyCard = styled(EmptyCard)`
	margin: 0 36px;
	bottom: -3px;
	opacity: 0.5;
`;

const ButtonReveal = styled(Button.Link)`
	width: 100%;
	padding-top: 16px;
	padding-bottom: 4px;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
`;
