import { FirestoreError } from "firebase/firestore";

import { ErrorLogger } from "@/shared/infra/sentry/sentry";

import { Firebase } from "./firebase/firebase";
import { firestore } from "./firebase/firestore";

ErrorLogger.init();

export const fb = new Firebase();
export const db = firestore(fb.firestore);

export const onSnapshotError = (listener: string) => (error: FirestoreError) =>
	console.error(listener, error.code, error.message);
