import { RefObject, useRef, useState } from "react";

import { motion } from "framer-motion";
import { atomFamily } from "recoil";
import styled from "styled-components";

import { DropdownOption } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectOptionsItem";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover } from "@/shared/system/Dropdown/Popover";
import { SelectDropdownSearch } from "@/shared/system/SelectDropdown/components/SelectDropdownSearch";

export const dropdownState = atomFamily<boolean, string>({
	key: "dropdownState",
	default: false,
});

export type SelectDropdownOptionsFn = (args: {
	inputEl: RefObject<HTMLInputElement>;
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	close: VoidFunction;
}) => JSX.Element;

export type SelectDropdownProps = {
	optionsDescription?: string;
	placeholderText?: string;
	values: JSX.Element;
	options: SelectDropdownOptionsFn | DropdownOption[];
	renderOption?: any;
	shortcutCode?: string;
	disabled?: boolean;
};

export const SelectDropdown = ({
	values,
	options,
	placeholderText,
	shortcutCode = "",
	disabled = false,
}: SelectDropdownProps) => {
	const inputEl = useRef<HTMLInputElement>(null);
	const [search, setSearch] = useState("");

	const render = ({ close }: any) => {
		return (
			<Menu
				initial={{ y: -4, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				exit={{ y: 4, opacity: 0 }}
				transition={{ duration: 0.1 }}
				data-testid="select-dropdown--menu">
				<Container>
					<SelectDropdownSearch
						placeholder={placeholderText}
						value={search}
						onChange={setSearch}
						onEscape={close}
						el={inputEl}
					/>
					{typeof options === "function" && options({ close, inputEl, search, setSearch })}
				</Container>
			</Menu>
		);
	};

	return (
		<Popover render={render} offset={-32} shortcutCode={shortcutCode} isTriggerWidth>
			<Trigger isInteractive={!disabled}>{values}</Trigger>
		</Popover>
	);
};

const Container = styled(PopoverContent)`
	padding: 0;
	gap: 0;
`;

const Trigger = styled.div<{ isInteractive: boolean }>`
	display: flex;
	flex-grow: 1;
	padding: 6px;
	width: 100%;
	max-height: 600px;
	overflow-y: auto;

	> svg {
		margin-left: 6px;
	}

	&:hover {
		border-radius: 4px;
		background: ${({ isInteractive }) => (isInteractive ? "var(--color-hover)" : "transparent")};
	}
`;

const Menu = styled(motion.div)``;
