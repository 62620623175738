import { Fragment } from "react";

import styled from "styled-components";

import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";

export function BreadcrumbsBoards() {
	const project = useGetProject();
	const { getNodePath } = useNavigationService();

	if (!project) {
		return null;
	}

	const path = getNodePath(project.id);

	if (path.length === 0) {
		return null;
	}

	return (
		<Root>
			{path.map(({ id, data }, index) => {
				const first = index === 0;

				return (
					<Fragment key={id}>
						{!first && <Slash>/</Slash>}
						<Segment>{data.name}</Segment>
					</Fragment>
				);
			})}
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	align-items: center;
`;

const Slash = styled.div`
	margin: 0 8px;
	color: var(--color-text);
`;

const Segment = styled.div``;
