import { doc, getDoc, setDoc } from "firebase/firestore";

import { db } from "../../../shared/infra/init";

const DEFAULT_META = {
	boards: {},
};

export const getBoardMeta = async (userId: string) => {
	const metaDocument = doc(db.meta, userId);
	const meta = await getDoc(metaDocument);

	if (meta.exists()) {
		return {
			fetched: true,
			...meta.data(),
		};
	}

	return DEFAULT_META;
};

export const upsertBoardMeta = (userId: string, meta: any) => {
	const metaDocument = doc(db.meta, userId);
	setDoc(metaDocument, meta, { merge: true });
};
