import { IconType } from "react-icons";
import styled from "styled-components";

import { User } from "@/types/db";

import { Avatar, Truncate } from "../../../shared/system";

type Props = {
	email?: string;
	name: string;
	user?: User;
	size?: number;
	width?: number;
	icon?: IconType;
	isSelf?: boolean;
};

export function UserDetails({ name, email, user, width, size, icon, isSelf }: Props) {
	return (
		<Root>
			<Avatar user={user} size={size} icon={icon} />
			<Details $width={width}>
				<Username>
					{name}
					{isSelf && " – you"}
				</Username>
				<Email>{email}</Email>
			</Details>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`;

const Details = styled.div<{ $width?: number }>`
	display: flex;
	flex-direction: column;
	padding: 4px 0;
	${({ $width }) => $width && `}max-width: ${$width}px`};
`;

export const Username = styled(Truncate)`
	font-weight: 500;
	font-size: 14px;
	line-height: 1.1;
	margin-bottom: 4px;
	font-weight: 500;
	color: var(--color-text-heading);
`;
const Email = styled(Truncate)`
	font-weight: 500;
	font-size: 14px;
	line-height: 1.1;
	color: var(--color-text);
`;
