import { useCallback } from "react";

import { EmojiClickEvent } from "emoji-picker-element/shared";
import styled from "styled-components";

import { IconImageLink } from "@/domains/nodes/components/iconPicker/components/IconImageLink";
import { IconImageUpload } from "@/domains/nodes/components/iconPicker/components/IconImageUpload";
import { IconPickerEmoji } from "@/domains/nodes/components/iconPicker/components/IconPickerEmoji";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { modalRoot } from "@/modules/inbox/components/InboxModal";
import { useStorageService } from "@/shared/core/hooks/useStorageService";
import { Box, Tabs } from "@/shared/system";

const removeButtonStyle = {
	marginLeft: "auto",
	marginRight: "0",
	color: "var(--color-text)",
};

type Props = {
	node: NodeModel;
	close: VoidFunction;
};

export const IconPickerModal: React.FC<Props> = ({ node, close }) => {
	const nodesService = useNodesService();
	const storageService = useStorageService();

	const nodeId = node.id;

	const icon = node.icon;

	const handleRemoveIcon = useCallback(() => {
		nodesService.update(nodeId, { icon: null });

		if (icon?.type === "image" && icon.local) {
			storageService.delete(icon.src);
		}
	}, [icon, nodeId, nodesService, storageService]);

	const handleSetIcon = useCallback(
		(event: EmojiClickEvent) => {
			const icon = { type: "emoji" as const, emoji: event.detail.unicode || "" };
			nodesService.update(nodeId, { icon });
		},
		[nodeId, nodesService],
	);

	return (
		<Root>
			<Tabs defaultTab={1}>
				<Box label="Emoji">
					<EmojiWrapper>
						<IconPickerEmoji onClick={handleSetIcon} />
					</EmojiWrapper>
				</Box>
				<Box label="Upload">
					<TabContent>
						<IconImageUpload node={node} onComplete={close} />
					</TabContent>
				</Box>
				<Box label="Link">
					<TabContent>
						<IconImageLink node={node} onComplete={close} />
					</TabContent>
				</Box>
				<Box label="Remove" onClick={handleRemoveIcon} style={removeButtonStyle} />
			</Tabs>
		</Root>
	);
};

const Root = styled.div`
	${modalRoot}
	border: 0;
	box-shadow: 0;
	border: var(--border);
	border-radius: 4px;
	background: var(--color-body);
	overflow-x: hidden;
	box-shadow: var(--drop-shadow);
	width: 404px;
`;

const TabContent = styled.div`
	padding: 20px;
`;

const EmojiWrapper = styled.div`
	padding-top: 8px;
`;
