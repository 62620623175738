import { BoardAuthWrapper } from "@/modules/board/BoardAuthWrapper";
import Nodes from "@/modules/board/components/Nodes";

const TreeViewPage = () => {
	return (
		<BoardAuthWrapper>
			<Nodes />
		</BoardAuthWrapper>
	);
};

export default TreeViewPage;
