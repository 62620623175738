import { SyntheticEvent } from "react";

import { FiMoreHorizontal } from "react-icons/fi";

import { MenuNodeLayout } from "@/domains/nodes/components/menu/components/MenuNodeLayout";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { MoreMetaTags } from "@/modules/board/settings/components/MoreMetaTags";
import { useNodeContextMenu } from "@/modules/nodeContextMenu/hooks/useNodeContextMenu";
import { DropdownHr } from "@/shared/system";
import { Menu } from "@/shared/system/Menu/Menu";
import { MenuItem } from "@/shared/system/Menu/MenuItem";

type Props = {
	node: NodeModel;
	variant?: "context-menu" | "menu-button";
	rootEl?: React.RefObject<HTMLDivElement>;
};

export const MenuNodeOptions = ({ variant, node, rootEl }: Props) => {
	const nodeId = node.id;
	const nodeLayout = node.layout;

	const boardId = node.board;

	const menuItems = useNodeContextMenu(nodeId);
	// const isFocusedNodeCreating = useRecoilValue(getFocusedNodeCreating);

	const handleOpen = (event: MouseEvent) => {
		const contextEl = document.elementFromPoint(event.pageX, event.pageY);
		const nodeCard = !!contextEl && contextEl.closest("[data-node-id]");

		if (nodeCard) {
			// && !isFocusedNodeCreating
			return true;
		}

		return false;
	};

	return (
		<Menu variant={variant} onContextOpen={handleOpen} label={<FiMoreHorizontal />} triggerEl={rootEl}>
			<MenuNodeLayout nodeId={nodeId} layout={nodeLayout} />
			<MoreMetaTags docId={nodeId} boardId={boardId} scope="node" />
			<DropdownHr />

			{menuItems.map((itemGroup, index) => {
				const isLastGroup = menuItems.length - 1 === index;

				return itemGroup.map((item, index) => {
					const isLastItem = itemGroup.length - 1 === index;
					const hasNestedMenu = item.items !== undefined;

					const handleClick = () => {
						item.onClick(nodeId, item);
					};

					const lastInSection = isLastItem && !isLastGroup;

					if (hasNestedMenu) {
						return (
							<Menu icon={item.icon} label={item.text} key={item.key}>
								{item.items?.map((childItem) => {
									const handleClick = (e: SyntheticEvent) => {
										e.preventDefault();
										childItem.onClick(nodeId, childItem);
									};

									return (
										<MenuItem
											icon={childItem.icon}
											label={childItem.text}
											kbd={childItem.kbd}
											key={childItem.key}
											disabled={childItem.disabled}
											handleClick={handleClick}
										/>
									);
								})}
							</Menu>
						);
					}

					return (
						<MenuItem
							icon={item.icon}
							label={item.text}
							kbd={item.kbd}
							key={item.key}
							disabled={item.disabled}
							handleClick={handleClick}
							isEndOfSection={lastInSection}
						/>
					);
				});
			})}
		</Menu>
	);
};
