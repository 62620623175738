import { IconType } from "react-icons";
import { FiAlignLeft, FiArrowDownCircle, FiAtSign, FiCalendar, FiLink, FiList, FiUsers } from "react-icons/fi";

import { NodeMetaTypeUnion } from "../../../types/db";

export const getNodeMetaTypeIcon = (type: NodeMetaTypeUnion): IconType => {
	switch (type) {
		case "select": {
			return FiArrowDownCircle;
		}
		case "list": {
			return FiList;
		}
		case "person": {
			return FiUsers;
		}
		case "link": {
			return FiLink;
		}
		case "text": {
			return FiAlignLeft;
		}
		case "email": {
			return FiAtSign;
		}
		case "date":
		case "dateRange": {
			return FiCalendar;
		}
		default: {
			const _exhaustiveCheck: never = type;
			return _exhaustiveCheck;
		}
	}
};
