import styled from "styled-components";

import { breakpoints } from "../../../shared/system";
import theme from "../../../shared/system/theme";

export const Hamburger = styled.a`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 18px;
	width: 25px;
	right: 30px;
	margin-top: -3px;
	z-index: 9999;

	${breakpoints.md`
		display: none;
	`}

	span,
	:before,
	:after {
		background: ${({
			// @ts-expect-error TS(2339) FIXME: Property 'isOpen' does not exist on type 'Pick<Det... Remove this comment to see the full error message
			isOpen,
		}) => (isOpen ? "#fff" : theme.colors.brand)};
		border-radius: 4px;
		position: absolute;
		top: 9px;
		left: 0;
		right: 0;
		height: 3px;
		margin-top: -2px;
		transition: all 0.2s linear;
	}

	:before,
	:after {
		content: "";
		top: 2px;
	}

	:after {
		top: 16px;
	}

	.nav-open & {
		:before,
		:after {
			transform: rotate(45deg);
			top: 50%;
			left: 0;
			right: 0;
		}

		:after {
			transform: rotate(-45deg);
		}

		span {
			opacity: 0;
		}
	}
`;
