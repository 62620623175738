import { parseISO } from "date-fns";
import differenceInDays from "date-fns/differenceInDays";
import dayjs from "dayjs";
import { FiCalendar } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getDescription } from "@/domains/nodes/components/details/activity/components/MessageAction";
import { formatTimestamp } from "@/domains/nodes/components/details/meta/types/date/DatePickerSelectedValue";

import { Notification, NotificationType, WithId } from "../../../types/db";

const HighlightedLink = (props: any) => (
	<Highlight>
		<LinkedText {...props} />
	</Highlight>
);

const pluralDays = (count: number): string => {
	const countRoundedUp = Math.ceil(count);

	if (countRoundedUp === 0) {
		return "Today";
	} else if (countRoundedUp === 1) {
		return "Tomorrow";
	}
	return `in ${countRoundedUp} days`;
};

const buildMessage = (notification: WithId<Notification>) => {
	const type = notification.type;

	switch (type) {
		case NotificationType.activity: {
			// @ts-expect-error Activity model is a discriminated union which doesn't play nicely with this (and I didn't want to redefine the type)
			const message = getDescription(notification.activity);
			return {
				actionType: "edited",
				body: message,
			};
		}
		case NotificationType.comment: {
			return {
				actionType: "commented on",
				body: notification.body,
			};
		}
		case NotificationType.reminder: {
			const { label, endAt, format } = notification.data;
			const daysTo = differenceInDays(parseISO(endAt), new Date());
			return {
				actionType: "reminder for",
				body: (
					<>
						<FiCalendar /> {label} {pluralDays(daysTo)} ({formatTimestamp(endAt, format)})
					</>
				),
			};
		}
		default: {
			const _exhaustiveCheck: never = type;
			return _exhaustiveCheck;
		}
	}
};

type Props = {
	notification: WithId<Notification>;
	handleLinkClick: () => void;
};

export function InboxMessageContent({ notification, handleLinkClick }: Props) {
	// @ts-expect-error TS(2339) FIXME: Property 'boardTitle' does not exist on type 'With... Remove this comment to see the full error message
	const { nodeTitle, boardId, boardTitle, nodeId, createdAt, from } = notification;
	const createdAtRelative = dayjs.unix(createdAt?.seconds).fromNow();

	const { actionType, body } = buildMessage(notification); // TODO: this should happen on the server
	const isUnread = notification.seenAt === null;

	return (
		<div>
			<BodyTitle>
				{from?.username} <em>{actionType}</em>
				<HighlightedLink to={`/b/${boardId}/${nodeId}`} onClick={handleLinkClick}>
					{nodeTitle}
				</HighlightedLink>
			</BodyTitle>
			<BodyMeta>
				<span>{createdAtRelative} • </span>
				<HighlightedLink to={`/b/${boardId}/`} onClick={handleLinkClick}>
					{boardTitle}
				</HighlightedLink>
			</BodyMeta>
			<Message>
				<UnreadIndicator isUnread={isUnread} />
				{/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
				<BlockLink to={`/b/${boardId}/${nodeId}`} isUnread={isUnread} onClick={handleLinkClick}>
					{body}
				</BlockLink>
			</Message>
		</div>
	);
}

const BodyTitle = styled.div`
	margin-bottom: 4px;
	color: var(--color-text-heading);

	> em {
		font-style: normal;
		color: var(--color-text);
	}
`;

const BodyMeta = styled.div`
	font-size: 12px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;

	> * {
		flex-shrink: 0;
	}
`;

const Highlight = styled.div`
	display: inline;
	padding: 3px;
	color: var(--color-text-heading);

	> a {
		white-space: nowrap;
	}
`;

const LinkedText = styled(Link)`
	color: inherit;
	text-decoration: none;
	background: var(--color-body);
	transition: background 0.2 ease-in-out;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 3px;
	padding: 1px;

	&:hover,
	&:focus {
		color: var(--color-accent-hover);
		border-radius: 4px;
		background-color: var(--color-hover);
		outline: none;
	}
`;

const Message = styled.div`
	display: flex;
	align-items: center;
	margin-left: -28px;
`;

const BlockLink = styled.div<{ isUnread: boolean }>`
	flex-grow: 1;
	padding: 8px 12px;
	margin-left: -8px;
	border-radius: 4px;
	border: 1px solid var(--color-accent);
	border: 1px solid ${({ isUnread }) => (isUnread ? "var(--color-accent)" : "var(--color-border)")};

	svg {
		margin-right: 8px;
	}

	strong {
		color: var(--color-text-secondary);
	}
`;

const UnreadIndicator = styled.div<{ isUnread: boolean }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ isUnread }) => (isUnread ? "var(--color-accent)" : "transparent")};
	margin-right: 20px;
`;
