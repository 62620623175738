import { useEffect, useLayoutEffect, useRef } from "react";

import "emoji-picker-element";
import styled from "styled-components";

import { EmojiClickEvent } from "./IconPickerEmojiTypes";

type Props = {
	onClick: (event: EmojiClickEvent) => void;
};

export const IconPickerEmoji = ({ onClick }: Props) => {
	const ref = useRef<any>({});

	useEffect(() => {
		const emojiPicker = ref.current;
		emojiPicker.addEventListener("emoji-click", onClick);
		emojiPicker.skinToneEmoji = "👍";
		return () => {
			emojiPicker.removeEventListener("emoji-click", onClick);
		};
	}, [onClick]);

	useLayoutEffect(() => {
		const style = document.createElement("style");
		style.innerHTML = styleOverrides;
		ref.current.shadowRoot && ref.current.shadowRoot.appendChild(style);
	}, []);

	const Picker = "emoji-picker" as any;

	return (
		<Root>
			<Picker ref={ref} />
		</Root>
	);
};

const Root = styled.div`
	emoji-picker {
		width: 100%;

		--num-columns: 12;
		--background: var(--color-body);
		--button-active-background: var(--color-hover);
		--button-hover-background: var(--color-hover);
		--indicator-color: var(--color-accent);
		--input-border-color: var(--color-border);
		--input-border-radius: 4px;
		--input-font-color: var(--color-text-heading);
		--input-padding: 8px 12px;
		--input-placeholder-color: var(--color-text);
		--outline-size: 1px;
		--outline-color: var(--color-accent);
		--emoji-padding: 6px;
	}
`;

const styleOverrides = `
	.picker  {border: 0;}
    .favorites {display: none;}
	.indicator-wrapper {border-bottom: 0;}
    .nav {border-bottom: 1px solid var(--input-border-color)}
    .skintone-button-wrapper  {margin: 0 8px;}
    .search-row {margin: 0 0 8px 8px;}

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: var(--input-border-color);
    }

    ::-webkit-scrollbar-track {
        padding: 2px;
        background-color: transparent;
    }
`;
