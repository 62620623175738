import { useCallback } from "react";

import { isEmpty } from "lodash";

import { EventsDto, EventsInterface } from "@/domains/nodes/dtos/eventsDto";
import { useEventsRepository } from "@/domains/nodes/hooks/useEventsRepository";
import { IEventsStore, useEventsStore } from "@/domains/nodes/hooks/useEventsStore";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { EventUtils } from "@/domains/nodes/utils/eventUtils";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { useBoardMembers } from "@/modules/members/hooks/useBoardMembers";
import { getNodeMetaValue } from "@/modules/nodeMeta/utils/getNodeMetaValue";
import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { fb } from "@/shared/infra/init";
import difference from "@/shared/utils/difference";
import { Dictionary, ProjectBoard, User } from "@/types/db";

const getIntegrationsForType = (type: EventsInterface["type"], integrations: ProjectBoard["integrations"]) => {
	if (!integrations) {
		return [];
	}

	return integrations.filter((integration) => {
		return !!integration.data.on.includes(type);
	});
};

const withReadableMeta = (node: NodeModel, nodeMeta: NodeMetaModel, members: Dictionary<User>) => {
	const metaKeys = Object.keys(node?.meta || {});

	const meta: any = {};

	metaKeys.forEach((id) => {
		const metaProperty = nodeMeta.get(id);

		if (!metaProperty) {
			return;
		}

		meta[id] = {
			label: metaProperty.label,
			value: getNodeMetaValue(node, metaProperty, members) || null,
		};
	});

	return { ...node, meta };
};

export interface IEventService {
	addEvent(
		args: {
			newData: any;
			oldData?: any;
			refId: string;
			url: string;
		} & Pick<EventsInterface, "action" | "type">,
	): Promise<void>;
}

export const useEventsService = () => {
	const members = useBoardMembers();
	const project = useGetProject();
	const publicProfile = usePublicProfile();

	const { update, create } = useEventsRepository();

	const meta = useNodeMetaStore((state) => state.meta);
	const getEvents = useEventsStore((state) => state.getEvents);

	const integrations = project?.integrations;

	const upsert = useCallback(
		(events: IEventsStore["events"], nextEvent: EventsDto) => {
			const isNodeEvent = EventUtils.eventIsType(nextEvent, "nodes");
			const isDetailsEvent = EventUtils.eventIsType(nextEvent, "node-details");

			if (!isNodeEvent && !isDetailsEvent) {
				return create(nextEvent);
			}

			const nodeActivity = EventUtils.toViewModel("", nextEvent);

			const lastActivity = nodeActivity && EventUtils.getLastOfType(events, nodeActivity);
			const isRecent = lastActivity && EventUtils.checkWithinThreshold(lastActivity);

			if (isRecent) {
				return update(lastActivity.id, nextEvent);
			}

			return create(nextEvent);
		},
		[create, update],
	);

	const addEvent: IEventService["addEvent"] = useCallback(
		async (eventData) => {
			const { refId, action, type, url, newData, oldData } = eventData;

			if (!publicProfile) {
				return;
			}

			const events = getEvents();

			let event;
			let next = newData;
			let previous = oldData;

			const eventDefaults = {
				refId,
				type,
				action,
				createdAt: fb.timestamp(),
				createdBy: publicProfile,
				groupId: EventUtils.getGroupId(events),
				integrations: getIntegrationsForType(type, integrations),
				url,
			};

			if (type === "nodes") {
				next = withReadableMeta(newData, meta, members);
				previous = oldData && withReadableMeta(oldData, meta, members);
			}

			switch (action) {
				case "update": {
					const changedKeys = previous && difference(next, previous);

					if (isEmpty(changedKeys)) {
						return;
					}

					event = {
						...eventDefaults,
						changed: changedKeys,
						data: next,
					};

					break;
				}
				case "create":
				case "remove": {
					event = {
						...eventDefaults,
						data: next,
					};
					break;
				}
				default: {
					const _exhaustiveCheck: never = action;
					return _exhaustiveCheck;
				}
			}

			upsert(events, event);
		},
		[getEvents, integrations, members, meta, publicProfile, upsert],
	);

	return {
		addEvent,
	};
};
