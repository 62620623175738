import { useEffect } from "react";

import { onSnapshot, orderBy, query } from "firebase/firestore";
import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { authenticatedIdState } from "@/modules/authentication/selectors/authenticatedIdState";
import { BaseApi } from "@/shared/infra/services/BaseApi";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractDataFromCollection } from "../../../shared/utils/dataUtils";
import { User } from "../../../types/db";

export const boardGuestState = atomFamily<Record<string, User>, string>({
	key: "boardGuestState",
	default: {},
});

export const useGuestListener = (boardId: string) => {
	const [, setGuests] = useRecoilState(boardGuestState(boardId));
	const authId = useRecoilValue(authenticatedIdState);

	useEffect(() => {
		if (authId && boardId) {
			const id = BaseApi.sanitizeId(boardId);
			const guestBoardQuery = query(db.users, orderBy(`access.${id}`));
			return onSnapshot(
				guestBoardQuery,
				(snapshot) => {
					const { byId } = extractDataFromCollection(snapshot);

					setGuests(byId);
				},
				onSnapshotError("useGuestListener"),
			);
		}
	}, [authId, boardId, setGuests]);
};
