import { FiFolder, FiMap } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { CreateProjectAction } from "@/domains/accounts/components/createProject/components/CreateProjectProject";
import { createProjectModalState } from "@/domains/accounts/components/createProject/selectors/createProjectModalState";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { useAddBoard } from "@/modules/board/hooks/useAddBoard";
import { useAddFolder } from "@/modules/board/hooks/useAddFolder";
import Tree from "@/modules/page/assets/treeview.svg?react";

export const useCreateProjectActions = (projectId: string | undefined): CreateProjectAction[] => {
	const navigate = useNavigate();
	const getProject = useProjectsStore((state) => state.getProject);
	const handleAddBoard = useAddBoard({ redirect: undefined });
	const handleAddFolder = useAddFolder();
	const setOpen = useSetRecoilState(createProjectModalState);

	const resultProject = getProject(projectId || "");

	const project = resultProject.isSuccess ? resultProject.getValue() : null;

	const handleCreateBoardAsRoadmap = async () => {
		if (!projectId || !project) {
			return;
		}

		const id = await handleAddBoard({
			folderId: projectId,
			visibility: project?.visibility,
			meta: {
				view: "board",
			},
		});
		navigate(`/r/${id}`);
		setOpen(undefined);
	};

	const handleCreateBoardAsTree = async () => {
		if (!projectId || !project) {
			return;
		}

		const id = await handleAddBoard({
			folderId: projectId,
			visibility: project?.visibility,
			meta: {
				view: "tree",
			},
		});
		navigate(`/b/${id}`);
		setOpen(undefined);
	};

	const handleCreateFolder = () => {
		if (!projectId || !project) {
			return;
		}

		handleAddFolder(projectId, project?.visibility);
		setOpen(undefined);
	};

	return [
		{
			name: "Tree",
			color: "var(--color-accent)",
			shortcut: "t",
			icon: Tree,
			onClick: handleCreateBoardAsTree,
		},
		{
			name: "Roadmap",
			color: "#3cffa0",
			shortcut: "r",
			icon: FiMap,
			onClick: handleCreateBoardAsRoadmap,
		},
		{
			name: "Folder",
			color: "#2e90fb",
			shortcut: "f",
			icon: FiFolder,
			onClick: handleCreateFolder,
		},
	];
};
