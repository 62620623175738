import { useRef } from "react";

import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { NodeActionsAddContainer } from "@/domains/nodes/components/actions/components/NodeActionsAddContainer";
import { IconType } from "@/domains/nodes/components/iconPicker/components/IconType";
import { IconPicker } from "@/domains/nodes/components/iconPicker/IconPicker";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { IconPlaceholder } from "@/modules/board/components/IconPlaceholder";

import { usePermissions } from "../../workspace/hooks/usePermissions";
import { BoardHeaderDescription } from "./BoardHeaderDescription";
import { BoardHeaderTitle } from "./BoardHeaderTitle";

interface Props {
	node: NodeModel;
	variant: "tree" | "kanban";
}

export const BoardHeader = ({ node, variant }: Props) => {
	const boardHeaderEl = useRef(null);
	const { canEdit } = usePermissions("project");

	const nodeId = node.id;
	const nodeTitle = node.title;
	const nodeIcon = node.icon;
	const nodeDescription = node.summary || "";

	const boardId = node.board;

	const isTreeNode = variant === "tree";

	const showDescription = canEdit || nodeDescription;
	const showAdd = canEdit && isTreeNode;

	return (
		<Root ref={boardHeaderEl}>
			<NodeIcon>
				<IconPicker node={node}>{nodeIcon ? <IconType icon={nodeIcon} /> : <IconPlaceholder />}</IconPicker>
			</NodeIcon>
			<BoardHeaderTitle boardTitle={nodeTitle} projectId={boardId} rootId={nodeId} />
			{showDescription && <BoardHeaderDescription boardDescription={nodeDescription} projectId={boardId} rootId={nodeId} />}
			{showAdd && (
				<NodeAddButton className="hide-from-export">
					<NodeActionsAddContainer node={node}>
						<ActionIcon>
							<FiPlus />
						</ActionIcon>
					</NodeActionsAddContainer>
				</NodeAddButton>
			)}
		</Root>
	);
};

const Root = styled.div`
	width: 600px;
	margin: auto;
	text-align: center;
`;

const NodeAddButton = styled.div`
	height: 32px;
	width: 32px;
	margin: auto;
	margin-top: 8px;
	padding: 4px;
	font-size: 16px;
	border: 2px solid var(--color-border);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const ActionIcon = styled.div`
	display: flex;
	align-items: center;
	padding: 7px;
	border-radius: 4px;
	color: var(--color-text-secondary);
	cursor: pointer;

	:hover {
		background: var(--color-hover);
		border-radius: 50%;
	}
`;

const NodeIcon = styled.div`
	font-size: 80px;
	text-align: center;
	line-height: 80px;
	min-width: 80px;
	max-width: 240px;
	height: 72px;
	margin: auto;
	margin-bottom: 32px;
	display: flex;
	justify-content: center;
`;
