import { useRecoilValue } from "recoil";

import { getCurrentWorkspace } from "@/modules/workspace/selectors/getCurrentWorkspace";
import { Result } from "@/shared/utils/result";
import { WithId, Workspace } from "@/types/db";

export const useWorkspace = (): Result<WithId<Workspace>> => {
	const workspace = useRecoilValue(getCurrentWorkspace);

	if (!workspace) {
		return Result.fail("Unable to get workspace");
	}

	return Result.ok(workspace);
};
