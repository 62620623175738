import { useMemo, useState } from "react";

import { z } from "zod";

import { ListHandle } from "@/domains/nodes/components/details/meta/components/ListHandle";
import { ListItem } from "@/domains/nodes/components/details/meta/components/ListItem";
import { NodeMetaAdd } from "@/domains/nodes/components/details/meta/components/NodeMetaAdd";
import { NodeMetaShowHide } from "@/domains/nodes/components/details/meta/components/NodeMetaShowHide";
import { useNodeMetaActions } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaActions";
import { nodeMetaTypes } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { SortableListContainer } from "@/shared/system/SortableList/SortableListContainer";
import { SortableListContext } from "@/shared/system/SortableList/SortableListContext";
import { SortableItemRender } from "@/shared/system/SortableList/SortableListItem";

type Props = {
	node: NodeModel;
};

export const NodeMeta = ({ node }: Props) => {
	const project = useGetProject();
	const { id, meta } = useNodeMetaStore();
	const [showAll, setShowAll] = useState(false);
	const { updateNodeMetaOrder } = useNodeMetaActions();

	const hiddenMetaIds = NodeMetaUtil.getHiddenMetaIds(node, project);
	const hiddenCount = hiddenMetaIds.length;

	// Deleting a hidden property should remove it from the hidden meta id list
	const items = useMemo(
		() => [...meta.values()].filter((_) => !hiddenMetaIds.includes(_.id) || showAll),
		[hiddenMetaIds, meta, showAll],
	);

	const showMetaToggle = hiddenMetaIds.length > 0;
	const showMetaAdd = (showMetaToggle && showAll) || !showMetaToggle;

	const handleReorder = (newItems: typeof items) => {
		const order = newItems.map((item) => item.id);
		updateNodeMetaOrder(order);
	};

	const renderItem: SortableItemRender<z.infer<typeof nodeMetaTypes>> = (meta, listeners) => {
		return (
			<ListHandle listeners={listeners}>
				<ListItem node={node} meta={meta} />
			</ListHandle>
		);
	};

	return (
		<div>
			<SortableListContext>
				<SortableListContainer items={items} renderItem={renderItem} onDrop={handleReorder} handle animate />
			</SortableListContext>
			<div>
				{showMetaAdd && <NodeMetaAdd metaId={id} />}
				{showMetaToggle && <NodeMetaShowHide onClick={setShowAll} open={showAll} hiddenCount={hiddenCount} />}
			</div>
		</div>
	);
};
