import { TbPoint } from "react-icons/tb";
import { VscTriangleDown } from "react-icons/vsc";
import styled from "styled-components";

type Props = {
	canExpand?: boolean;
	isExpanded?: boolean;
};

export const FileFolderToggle = ({ canExpand, isExpanded }: Props) => {
	const variant = isExpanded ? "open" : "closed";

	if (!canExpand) {
		return (
			<Empty>
				<TbPoint />
			</Empty>
		);
	}

	return (
		<Root className={`toggle ${variant}`} data-accordion-button>
			<VscTriangleDown />
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	padding: 3px;
	font-size: 10px;
	transform: rotate(-90deg);
	transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

	:hover {
		background: var(--color-hover);
		border-radius: 4px;
	}

	svg {
		display: block;
		fill: var(--color-text);
	}

	&.open,
	.folder[data-state="open"] & {
		transform: rotate(0deg);
	}
`;

const Empty = styled.div`
	color: var(--color-text);

	svg {
		display: block;
	}
`;
