import { useCallback, useMemo } from "react";

import * as RadixMenu from "@radix-ui/react-dropdown-menu";
import { FiEyeOff } from "react-icons/fi";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useHiddenMetaState } from "@/modules/board/settings/components/MoreMetaTags";
import { ContextItem } from "@/modules/nodeContextMenu/components/ContextItem";
import { DropdownSubContent } from "@/shared/system";

const menuId = "visibility";

type Props = {
	metaId: string;
};

export const DetailsMetaItemVisibility = ({ metaId }: Props) => {
	const { boardId } = useParams() as { boardId: string };
	const [hiddenMeta, setHiddenMeta] = useHiddenMetaState(boardId, "project");

	const visibility = !hiddenMeta.includes(metaId) ? "display" : "hide";

	const handleShow = useCallback(() => setHiddenMeta(metaId), [metaId, setHiddenMeta]);

	const handleHide = useCallback(() => setHiddenMeta(metaId), [metaId, setHiddenMeta]);

	const hideOption = useMemo(
		() => ({
			id: menuId,
			label: "Hide property",
			icon: FiEyeOff,
			children: [
				{ id: "display", label: "Always show", onClick: handleShow },
				{ id: "hide", label: "Always hide", onClick: handleHide },
			],
		}),
		[handleHide, handleShow],
	);

	return (
		<RadixMenu.Sub>
			<RadixMenu.SubTrigger>
				<Item item={hideOption} key={menuId} hasChildren testId="node-meta-visibility__menu" />
			</RadixMenu.SubTrigger>
			<RadixMenu.Portal>
				<DropdownSubContent sideOffset={2} alignOffset={-5}>
					{hideOption.children.map((child) => (
						<RadixMenu.Item onSelect={child.onClick} key={child.id} data-testid={`node-meta-visibility__menu--${child.id}`}>
							<Item item={child} isSelected={child.id === visibility} />
						</RadixMenu.Item>
					))}
				</DropdownSubContent>
			</RadixMenu.Portal>
		</RadixMenu.Sub>
	);
};

const Item = styled(ContextItem)`
	padding-left: 12px;
`;
