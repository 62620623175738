import { useAuthenticationStatus } from "../../modules/authentication/hooks/useAuthenticationStatus";
import { RoutesApplication } from "./RoutesApplication";
import { RoutesPublic } from "./RoutesPublic";

export const RoutesAll = () => {
	const isAuthenticated = useAuthenticationStatus();

	if (isAuthenticated) {
		return <RoutesApplication />;
	}

	return <RoutesPublic />;
};
