import { Timestamp } from "firebase/firestore";
import { groupBy } from "lodash";
import { createStore } from "zustand";

import { EventGroup, EventModel } from "@/domains/nodes/models/eventsModel";

export interface IEventsStore {
	events: Record<string, EventModel>;
	getEvents(): IEventsStore["events"];
	getGroupedEvents(): EventGroup[];
	setEvents(events: IEventsStore["events"]): void;
}

export const eventsStore = createStore<IEventsStore>((set, get) => ({
	events: {},
	setEvents: (events) => set({ events }),
	getEvents: () => get().events,
	getGroupedEvents: () => {
		const events = get().events;

		const groupedActivity = groupBy(events, "groupId");
		const uniqueGroups = Object.keys(groupedActivity);

		return uniqueGroups.map((groupId) => {
			const activity = groupedActivity[groupId] as EventModel[];
			const createdAt = activity?.[0]?.createdAt as Timestamp;

			return {
				id: `g_${groupId}`,
				items: activity,
				type: "group" as const,
				createdAt,
			};
		});
	},
}));
