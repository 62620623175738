import { memo } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useNodeMetaListener } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaListener";
import { useNodesListener } from "@/domains/nodes/hooks/useNodesListener";
import { useBuildTree } from "@/domains/projects/hooks/useBuildTree";
import { useGetProjectListener } from "@/domains/projects/hooks/useGetProjectListener";
import { useBoardMembersWorkspace } from "@/modules/board/hooks/useBoardMembersWorkspace";
import { BaseApi } from "@/shared/infra/services/BaseApi";

import { useKeyResultsListener } from "../../../domains/nodes/components/keyResults/hooks/useKeyResultsListener";
import { useGuestInvitesListener } from "../hooks/useGuestInvitesListener";
import { useGuestListener } from "../hooks/useGuestListener";
import { useGetBoardMeta } from "../meta/hooks/useBoardMeta";

export const BoardListeners = memo(() => {
	const { boardId } = useParams() as { boardId: string };
	const navigate = useNavigate();

	const sanitizedId = BaseApi.sanitizeId(boardId);

	useGetProjectListener(boardId);

	// TODO: Synchronize nodeId

	useKeyResultsListener(); // gets all for workspace
	useNodeMetaListener();

	useGetBoardMeta(boardId);
	useNodesListener(boardId);
	useGuestListener(boardId);
	useGuestInvitesListener(boardId);

	useBuildTree();

	//REQUIRED: Temp to get access to members for guest users, scope needs to be limited to guest boards only
	useBoardMembersWorkspace();

	// useTransformNodes();
	// useActiveBoardMembers(); TODO: this isn't memomized correctly as it sends a request on load

	if (sanitizedId !== boardId) {
		navigate(`/b/${sanitizedId}`);
	}

	return null;
});

BoardListeners.displayName = "BoardListeners";
