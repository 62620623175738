import React from "react";

import { FiInbox } from "react-icons/fi";
import styled from "styled-components";

import { Button } from "../../../shared/system";
import { Notification, WithId } from "../../../types/db";
import { archiveNotifications } from "../../notifications/notificationFunctions";
import { InboxEmpty } from "./InboxEmpty";
import { InboxMessage } from "./InboxMessage";

type Props = {
	notifications: WithId<Notification>[];
	handleClose: () => void;
};

export function InboxMessages({ notifications, handleClose }: Props) {
	const handleArchiveNotifications = () => {
		const notificationIds = notifications.map(({ id }) => id);
		archiveNotifications(notificationIds);
	};

	return (
		<div>
			{notifications.length > 0 ? (
				notifications.map((notification) => (
					<InboxMessage notification={notification} handleClose={handleClose} key={notification.id} />
				))
			) : (
				<InboxEmpty />
			)}
			<Footer>
				<Button.Text onClick={handleArchiveNotifications}>
					<FiInbox />
					Clear notifications
				</Button.Text>
			</Footer>
		</div>
	);
}

const Footer = styled.footer`
	padding: 8px 12px;
	border-top: var(--border);

	> button {
		padding: 4px 12px;
	}
`;
