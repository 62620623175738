import { deleteDoc, doc, setDoc } from "firebase/firestore";

import { useEventsService } from "@/domains/nodes/hooks/useEventsService";
import { db, fb } from "@/shared/infra/init";

export const useCommentActions = () => {
	const { addEvent } = useEventsService();
	return {
		create: async ({ user, node, body }: any) => {
			const { board: boardId, id: nodeId } = node;
			if (body) {
				const commentDoc = doc(db.comments);

				const comment = {
					body,
					workspace: node.workspace,
					board: boardId,
					node: nodeId,
					user: user.id,
					author: user,
					type: "comment" as const,
					createdAt: fb.timestamp(),
				};

				setDoc(commentDoc, comment);

				addEvent({
					type: "comments",
					action: "create",
					url: `https://poda.io/b/${boardId}/${nodeId}`,
					refId: commentDoc.id,
					newData: comment,
				});
			}
		},
		remove: (commentId: string, comment: any) => {
			const commentDoc = doc(db.comments, commentId);
			deleteDoc(commentDoc);

			addEvent({
				type: "comments",
				action: "remove",
				url: `https://poda.io/b/${comment.board}/${comment.node}`,
				refId: commentDoc.id,
				newData: comment,
			});
		},
	};
};
