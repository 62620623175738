import styled from "styled-components";

import { NodeContainer } from "@/domains/nodes/components/cards/components/NodeContainer";
import { useNodeHighlight } from "@/domains/nodes/components/cards/hooks/useNodeHighlight";
import { NodeListItem } from "@/domains/nodes/components/lists/components/NodeListItem";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

type Props = {
	node: NodeModel;
};

export const NodeLayoutListItem: React.FC<Props> = ({ node }) => {
	const color = useNodeHighlight(node);

	return (
		<Root node={node} variant="plain">
			<Dot color={color} />
			<NodeListItem node={node} />
		</Root>
	);
};

const Root = styled(NodeContainer)`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 8px 16px;
	border-radius: 0;
	box-shadow: none;

	&:not(:last-child) {
		border-bottom: var(--border);
	}

	&:hover {
		background-color: var(--color-hover);
	}
`;

const Dot = styled.div<{ color?: string }>`
	width: 8px;
	height: 8px;
	background-color: ${({ color }) => color};
	border-radius: 8px;
	margin-right: 12px;
`;
