import { css, ThemedCssFunction } from "styled-components";

export const breakpoints = {
	xs: "480px",
	sm: "768px",
	md: "992px",
	lg: "1200px",
	xl: "1920px",
};

const buildBreakpoint =
	(property: "min-width" | "max-width") =>
	(accumulator: { [key in keyof typeof breakpoints]: ThemedCssFunction<any> }, label: keyof typeof breakpoints) => {
		accumulator[label] = (first: any, ...interpolations: any[]) => {
			const breakpoint = `@media (${property}: ${breakpoints[label]}) {
			${css(first, ...interpolations)};
		}`;

			return css`
				${breakpoint}
			`;
		};

		return accumulator;
	};

export const matchesMaxSize = (size: keyof typeof breakpoints) =>
	window.matchMedia(`(max-width: ${breakpoints[size]} )`).matches;

export const breakpointsMax = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
	buildBreakpoint("max-width"),
	{} as { [key in keyof typeof breakpoints]: ThemedCssFunction<any> },
);

export default (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
	buildBreakpoint("min-width"),
	{} as { [key in keyof typeof breakpoints]: ThemedCssFunction<any> },
);
