import { IUserProps } from "@/modules/authentication/utils/user";
import { WithId } from "@/types/db";

import { AvailablePlans } from "../../modules/plans/planUtils";
import { WorkspaceMember } from "./workspace";

export type Members = Record<string, WorkspaceMember>;

export type User = WithId<IUserProps>;

export enum UserTheme {
	light = "light",
	dark = "dark",
	system = "system",
}

export type UserSubscription = {
	role: keyof typeof AvailablePlans;
	status: string;
	billingId: string;
	subscriptionId: string;
};
