import React from "react";

import { ValidationError } from "./Input";

export default function Validation({ label, error, messages = {} }: any) {
	const errorMessage = messages[error?.type] || null;

	return error ? (
		<ValidationError showError={true}>
			{error?.type === "required" && `${label} is required`}
			{errorMessage}
		</ValidationError>
	) : null;
}
