import React from "react";

import { usePermissions } from "../../modules/workspace/hooks/usePermissions";

interface Props {
	children: any;
}
/**
 *
 * @deprecated
 * @use usePermission()
 */
export const AuthorizationContainer = ({ children }: Props) => {
	const { canView } = usePermissions("project");

	return <>{typeof children === "function" ? <>{children({ success: canView })}</> : <>{canView ? children : null}</>}</>;
};
