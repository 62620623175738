import React from "react";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { keyResultsNode } from "@/domains/nodes/components/keyResults/recoil/dataKeyResults";
import { KeyResultModel } from "@/types/db";

export type Props = {
	render: (keyResult: KeyResultModel) => JSX.Element;
	renderEmpty: () => JSX.Element;
};

export const KeyResultsList: React.FC<Props> = ({ render, renderEmpty }) => {
	const { nodeId } = useParams() as { nodeId: string };
	const keyResults = useRecoilValue(keyResultsNode(nodeId));

	if (keyResults.length === 0) {
		return renderEmpty();
	}

	return <>{keyResults.map(render)}</>;
};
