import { memo } from "react";

import styled from "styled-components";

import { NodeCardBase } from "@/domains/nodes/components/cards/components/NodeCardBase";
import { NodeCardDetails } from "@/domains/nodes/components/cards/components/NodeCardDetails";
import { INodeCard } from "@/domains/nodes/components/cards/nodeCardTypes";
import { IconType } from "@/domains/nodes/components/iconPicker/components/IconType";

export const NodeLayoutIcon: React.FC<INodeCard> = memo(({ node }) => {
	const icon = node.icon;

	return (
		<Root data-testid="node--layoutIcon">
			{icon && (
				<Icon>
					<IconType icon={icon} />
				</Icon>
			)}
			<NodeCardDetails node={node} />
		</Root>
	);
});

NodeLayoutIcon.displayName = "NodeLayoutIcon";

const Root = styled(NodeCardBase)`
	background: transparent;
	text-align: center;
`;

const Icon = styled.div`
	font-size: 72px;
	text-align: center;
	line-height: 1;
	min-width: 72px;
	max-width: 220px;
	height: 72px;
	margin: auto;
	margin-bottom: 8px;
`;
