import { FiMail, FiZap } from "react-icons/fi";
import styled from "styled-components";

import { SidebarNavigationLink } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItem";

import Canny from "../assets/canny.svg?react";

const roadMapUrl = "https://poda.canny.io";

export const HelpFooter = () => {
	const handleOpenRoadmap = () => window.open(roadMapUrl, "_blank")?.focus();
	return (
		<Root>
			<Roadmap onClick={handleOpenRoadmap}>
				<Icon>
					<Canny />
				</Icon>
				<Content>
					<strong>Contribute to our roadmap</strong>
					Share feedback, request or ask questions
				</Content>
			</Roadmap>
			<Actions>
				<ChangelogButton data-canny-changelog>
					<FiZap />
					Whats New
				</ChangelogButton>
				<SidebarNavigationLink href="mailto:support@poda.io">
					<FiMail />
					Contact Support
				</SidebarNavigationLink>
			</Actions>
		</Root>
	);
};

const Root = styled.div``;

const Roadmap = styled.div`
	display: flex;
	border-radius: 8px;
	padding: 20px 16px;
	margin: 0 -8px 24px;
	background: var(--color-hover);
	border: var(--color-text-secondary);
	cursor: pointer;
`;

export const ChangelogButton = styled(SidebarNavigationLink)`
	.Canny_BadgeContainer .Canny_Badge {
		position: absolute;
		top: 10px;
		left: 22px;
		right: auto;
		border: 0;
		border-radius: 10px;
		background-color: var(--color-accent);
		padding: 3px;
	}
`;

const Icon = styled.div`
	width: 12px;
	margin-right: 16px;
	margin-top: 1px;
	color: var(--color-accent);
`;

const Content = styled.div`
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;

	strong {
		color: var(--color-text-heading);
		display: block;
		font-weight: 500;
		margin-bottom: 4px;
	}
`;

const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	margin: -12px -8px -12px;
`;
