import styled from "styled-components";

import routes from "../../../shared/core/routes";
import breakpoints, { breakpointsMax } from "../../../shared/system/breakpoint";
import { NavAction } from "./NavAction";
import { NavLink } from "./NavLink";

export default function Header() {
	return (
		<Root>
			<ul>
				<li>
					<NavLink to={routes.login}>Log In</NavLink>
				</li>
				<li>
					<NavAction to={routes.signUp}>Sign up free</NavAction>
				</li>
			</ul>
		</Root>
	);
}

const Root = styled.div`
	display: table-footer-group;

	${breakpoints.md`
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
	`}

	ul {
		display: flex;
		align-items: center;

		${breakpointsMax.md`
			flex-wrap: wrap;
			margin: -7px -7px 40px;
			padding-top: 40px;
			flex-direction: row-reverse;
		`}
	}

	li {
		${breakpointsMax.md`
			width: 50%;
			padding: 7px;
		`}
	}

	a {
		:hover {
			color: var(--color-accent);
			background-color: #fff;
		}

		${breakpointsMax.md`
			transition: background .1s ease,color .1s ease;
			display: block;
			color: #fff;
			padding: 12px 16px;
			border: 2px solid #fff;
			border-radius: 4px;
			text-align: center;
			line-height: 1;
			font-size: 14px;
			font-weight: 600;
		`}
	}
`;
