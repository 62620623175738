import { FiMessageSquare, FiSmile, FiZap } from "react-icons/fi";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { ChangelogButton } from "@/domains/accounts/components/help/components/HelpFooter";
import { SidebarNavigationLink } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItem";
import { sidebarState } from "@/modules/page/selectors/sidebarState";

export function SidebarNavigationFooter() {
	const [, setSidebar] = useRecoilState(sidebarState);

	const handleOpenSupport = () => setSidebar("help");

	return (
		<>
			<BottomNav>
				<ChangelogButton data-canny-changelog>
					<FiZap />
					Whats New
				</ChangelogButton>
				<SidebarNavigationLink target="_blank" href="https://poda.canny.io/">
					<FiMessageSquare />
					Request a feature
				</SidebarNavigationLink>
				<SidebarNavigationLink onClick={handleOpenSupport}>
					<FiSmile />
					Help & Support
				</SidebarNavigationLink>
			</BottomNav>
		</>
	);
}

export const BottomNav = styled.div`
	margin-top: auto;
	display: flex;
	flex-direction: column;
	padding-top: 24px;

	> button {
		min-width: 100%;
		text-align: left;
	}
`;
