import { unix } from "dayjs";
import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { Truncate } from "@/shared/system";
import { WithId } from "@/types/db";

type Props = {
	board: WithId<ProjectDtoBoard>;
};

const relativeDate = (date: number) => {
	const d = unix(date);
	return d.fromNow && d.fromNow();
};

export const MoreMeta = ({ board }: Props) => {
	const { createdBy, updatedAt, status } = board;

	const dateStamp = updatedAt?.seconds || Math.round(new Date().getTime() / 1000);
	const lastUpdatedRelative = relativeDate(dateStamp);

	const statusText = status === "archived" ? "Archived" : "Edited";

	return (
		<LastEdited>
			{createdBy && (
				<>
					Created by {createdBy.username}
					<br />
				</>
			)}
			{statusText} {lastUpdatedRelative}
		</LastEdited>
	);
};

const LastEdited = styled(Truncate)`
	font-size: 12px;
	padding: 0 6px;
`;
