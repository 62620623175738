import { atom, useRecoilValue } from "recoil";

import { ToastMessage } from "../../shared/system";

export type ToastMessageState = {
	status: "success" | "error" | "warning" | "info";
	message: string;
};

export const toastState = atom<ToastMessageState | null>({
	key: "toastState",
	default: null,
});

export const Toasts = () => {
	const message = useRecoilValue(toastState);

	return <ToastMessage message={message} />;
};
