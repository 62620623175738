import { useEffect } from "react";

import { onSnapshot, query, where } from "firebase/firestore";
import { atomFamily, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../shared/utils/dataUtils";
import { WorkspaceInviteV2 } from "../../../types/db";
import { WithId } from "../../../types/db/utils";

export const guestInviteState = atomFamily<WithId<WorkspaceInviteV2>[], string>({
	key: "boardInvites",
	default: [],
});

export const useGuestInvitesListener = (boardId: string) => {
	const [, setBoardInvites] = useRecoilState(guestInviteState(boardId));

	useEffect(() => {
		if (boardId) {
			const inviteQuery = query(
				db.workspaceInvites,
				where("boardId", "==", boardId),
				where("acceptedAt", "==", null),
				where("rejectedAt", "==", null),
			);
			return onSnapshot(
				inviteQuery,
				(snapshot) => {
					const guestInvites = extractFromCollectionWithId(snapshot);

					setBoardInvites(guestInvites);
				},
				onSnapshotError("useBoardGuestInvites"),
			);
		}
	}, [setBoardInvites, boardId]);
};
