import { doc, getDocs, writeBatch } from "firebase/firestore";

import { db, fb } from "../../shared/infra/init";

export const NOTE_SCHEMA_VERSION = "4";
export const defaultNote = JSON.stringify({ type: "doc", content: [{ type: "paragraph" }] });

export const hasNote = (body: string): boolean => {
	return body !== defaultNote;
};

export const getNote = async (nodeId: string) => {
	return getDocs(db.nodeNotes(nodeId));
};

export const updateNote = async ({ nodeId, noteId, body }: any) => {
	const batch = writeBatch(fb.firestore);

	const nodeDocument = doc(db.nodes, nodeId);
	const noteDocument = doc(db.nodeNotes(nodeId), noteId);

	try {
		batch.update(nodeDocument, { hasNote: hasNote(body) });
		batch.update(noteDocument, { body, bodySchemaVersion: "4" });
		await batch.commit();
	} catch {
		// TODO: Better error handling here
		alert("The document failed to save");
	}
};
