import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import Treeview from "@/modules/page/assets/treeview.svg?react";

type Props = {
	node: NodeModel;
};

export const NodeListChildCount: React.FC<Props> = ({ node }) => {
	const treeService = useTreeService();

	if (node.children.length === 0) {
		return null;
	}

	const descendantCount = treeService.getDescendantCount(node.id);

	return (
		<Root>
			<Treeview /> {descendantCount}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	margin-left: auto;
	color: var(--color-text);

	svg {
		margin-right: 8px;
	}
`;
