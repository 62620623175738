import { useNavigate } from "react-router-dom";

import { projectCreateBoard } from "@/modules/board/api/projectCreateBoard";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { ErrorCodes } from "@/shared/core/errorCodes";
import { useErrorService } from "@/shared/core/hooks/useErrorService";
import { ProjectData, ProjectMeta, ProjectVisibility } from "@/types/db";

import { usePublicProfile } from "../../profile/hooks/usePublicProfile";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

type AddBoard = (args: {
	folderId?: string;
	visibility?: ProjectVisibility;
	data?: ProjectData;
	meta?: Partial<ProjectMeta>;
}) => Promise<string | void>;

export const useAddBoard = ({ redirect = true } = {}): AddBoard => {
	const navigate = useNavigate();
	const analyticsService = useAnalyticsService();
	const publicProfile = usePublicProfile();
	const workspace = useActiveWorkspace();
	const errorService = useErrorService();

	return async ({ folderId, visibility, data, meta }) => {
		const workspaceId = workspace?.id;

		if (!workspaceId) {
			errorService.logError(ErrorCodes.ERR001, { workspaceId });
			return;
		}

		if (!folderId) {
			errorService.logError(ErrorCodes.ERR002, { folderId });
			return;
		}

		if (publicProfile) {
			const boardId = await projectCreateBoard({
				workspaceId,
				folderId,
				visibility,
				profile: publicProfile,
				data,
				meta,
			});
			analyticsService.projectCreated({ boardId, workspaceId });
			redirect && navigate(`/b/${boardId}`);
			return boardId;
		}
	};
};
