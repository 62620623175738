import { CSSProperties, SyntheticEvent } from "react";

import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";

import { SidebarNavigationAddProject } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationAddProject";
import { SidebarNavigationItemBoard } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemBoard";
import { SidebarNavigationItemFolder } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemFolder";
import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { ProjectDto, WithId } from "@/types/db";

export interface Props {
	handleProps: any;
	item: ProjectModel;
	collapsed?: boolean;
	draggableRef: any;
	transform: any;
	transition: string | undefined;
	onCollapse?: (item: WithId<ProjectDto>) => void;
	clone?: boolean;
}

export const SidebarNavigationFolderContainerSortableItem = ({
	clone,
	draggableRef,
	handleProps,
	collapsed, //Could this be merged into variant prop
	onCollapse,
	transform,
	transition,
	item,
}: Props) => {
	const style: CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	const childCount = item.type === "folder" ? item.childIds?.length : 0;

	const folderActions = clone ? null : (
		<>
			<SidebarNavigationAddProject projectId={item.id} />
		</>
	);

	const handleNameClick = (e: SyntheticEvent) => {
		e.preventDefault();
		onCollapse && onCollapse(item);
	};

	return (
		<Item ref={draggableRef} style={style} className="TreeItem" {...handleProps}>
			{item.type === "board" ? (
				<SidebarNavigationItemBoard project={item} />
			) : (
				<SidebarNavigationItemFolder
					project={item}
					onClick={handleNameClick}
					onCollapse={handleNameClick}
					collapsed={collapsed}
					actions={folderActions}
				/>
			)}

			{clone && childCount > 0 ? <ChildCount>{childCount}</ChildCount> : null}
		</Item>
	);
};

const Item = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

const ChildCount = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -6px;
	right: -6px;
	background: var(--color-accent);
	color: #fff;
	font-weight: 800;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	font-size: 10px;
`;
