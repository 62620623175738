import React from "react";

import Header from "./components/Header";
import NavPrimary from "./components/NavPrimary";
import NavSecondary from "./components/NavSecondary";

export default function SiteNavigation() {
	return (
		<Header>
			<NavPrimary />
			<NavSecondary />
		</Header>
	);
}
