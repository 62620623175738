import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Hover = styled.div<SpaceProps>`
	cursor: pointer;
	padding: ${({ theme }) => theme.space[2]}px;
	margin: 0 -${({ theme }) => theme.space[2]}px;
	border-radius: ${({ theme }) => theme.radii[2]}px;
	${space}

	&:hover {
		background-color: var(--color-hover);
	}
`;

export default Hover;
