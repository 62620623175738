import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { db } from "../../shared/infra/init";
import { ScheduledTasks } from "../../types/db";

export const getScheduledTask = (taskId: string) => {
	const taskDocument = doc(db.scheduledTasks, taskId);
	return getDoc(taskDocument);
};

export const upsertScheduledTask = async (taskId: string, task: ScheduledTasks) => {
	const taskDocument = doc(db.scheduledTasks, taskId);
	const scheduledTask = await getDoc(taskDocument);
	if (scheduledTask.exists()) {
		return updateDoc(taskDocument, task);
	}

	return setDoc(taskDocument, task);
};

export const removeScheduledTask = (taskId: string) => {
	const taskDocument = doc(db.scheduledTasks, taskId);
	return deleteDoc(taskDocument);
};
