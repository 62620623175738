import React from "react";

import styled from "styled-components";

import { UserDetails } from "@/modules/members/primitives/UserDetails";

import { User, WorkspaceMemberRole } from "../../../types/db";
import { WithId } from "../../../types/db/utils";

type Props = {
	email?: string;
	actions: React.ReactNode;
	user?: WithId<User>;
	name: string;
	variant?: "standard" | "nested" | "suspended";
	isSelf?: boolean;
	permissionLevel?: WorkspaceMemberRole;
	isActive?: boolean;
};

export const MemberRow = ({ user, name, email, actions, isSelf = false, permissionLevel, variant = "standard" }: Props) => {
	const avatarSize = 36;

	return (
		<Root className={variant} data-testid={`member:${email}`}>
			<Member type={permissionLevel}>
				<UserDetails name={name} email={email} user={user} isSelf={isSelf} size={avatarSize} />
			</Member>
			<Actions>{actions}</Actions>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;
	border-bottom: var(--border);

	&.nested {
		padding-top: 6px;
		padding-bottom: 6px;
		border: 0;

		:first-child {
			padding-top: 0;
		}

		:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	&.suspended {
		opacity: 0.5;
	}
`;

const Username = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	width: 218px;
`;

const Email = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	width: 248px;
`;

const Member = styled.div<{ type?: WorkspaceMemberRole }>`
	display: flex;
	align-items: center;

	${Username},
	${Email} {
		${({ type }) => type === "suspended" && `text-decoration:  line-through;`};
	}
`;

const Actions = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-end;
	max-width: 290px;
`;
