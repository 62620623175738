import { collection, CollectionReference, DocumentData, Firestore } from "firebase/firestore";
import { z } from "zod";

import { KeyResultsDto } from "@/domains/nodes/components/keyResults/dtos/keyResultsDto";
import { IUserProps } from "@/modules/authentication/utils/user";
import { IWorkspaceProps } from "@/modules/authentication/utils/workspace";

import {
	Billing,
	BillingCheckoutSession,
	BillingSubscription,
	Meta,
	Node,
	NodeMeta,
	NodeTypes,
	Note,
	Notification,
	Product,
	ProductPrices,
	ProjectDto,
	ProjectImport,
	ScheduledTasks,
	Status,
	UserComment,
	UserSubscription,
	WorkspaceInviteV2,
	WorkspaceUsage,
} from "../../../types/db";

export const timestamp = z.object({
	seconds: z.number(),
	nanoseconds: z.number(),
});

export type Timestamp = z.infer<typeof timestamp>;

export const firestore = (firestore: Firestore) => {
	const createCollection = <T = DocumentData>(collectionPath: string) =>
		collection(firestore, collectionPath) as CollectionReference<T>;

	return {
		createId: createCollection<void>("_"),
		projects: createCollection<ProjectDto>("projects"),
		projectsImport: (projectId: string) => createCollection<ProjectImport>(`projects/${projectId}/imports`),

		appSumo: createCollection<boolean>("appSumo"),
		billing: createCollection<Billing>("billing"),
		billingSubscriptions: (billingId: string) => createCollection<BillingSubscription>(`billing/${billingId}/subscriptions`),
		billingCheckoutSession: (userId: string) =>
			createCollection<BillingCheckoutSession>(`billing/${userId}/checkout_sessions`),

		comments: createCollection<UserComment>("comments"),
		keyResults: createCollection<KeyResultsDto>("keyResults"),
		meta: createCollection<Meta>("meta"),
		nodeNotes: (nodeId: string) => createCollection<Note>(`nodes/${nodeId}/notes`),
		nodeTypes: createCollection<NodeTypes>("nodeTypes"),
		nodeMeta: createCollection<NodeMeta>("nodeMeta"),
		nodes: createCollection<Node>("nodes"),
		notifications: createCollection<Notification>("notifications"),
		products: createCollection<Product>("products"),
		productPricing: (productId: string) => createCollection<ProductPrices>(`products/${productId}/prices`),
		scheduledTasks: createCollection<ScheduledTasks>("scheduledTasks"),
		status: createCollection<Status>("status"),
		users: createCollection<IUserProps>("users"),
		userSubscriptions: (userId: string) => createCollection<UserSubscription>(`users/${userId}/subscriptions`),
		workspaceInvites: createCollection<WorkspaceInviteV2>("workspaceInvites"),
		workspaces: createCollection<IWorkspaceProps>("workspaces"),
		workspaceUsage: (workspaceId: string) => createCollection<WorkspaceUsage>(`workspaces/${workspaceId}/usage`),
	};
};
