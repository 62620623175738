import { Suspense } from "react";

import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { Analytics } from "@/shared/core/analytics/Analytics";

import { ErrorBoundary } from "./modules/errors/ErrorBoundary";
import { ThemeGlobalVariables } from "./modules/profile/theme/ThemeGlobalVariables";
import { AuthenticationContainer } from "./shared/core/Authentication";
import { RoutesAll } from "./shared/core/RoutesAll";
import { ScrollToTop } from "./shared/core/ScrollToTop";
import { breakpoints } from "./shared/system";
import { AppLoading } from "./shared/system/InApp/AppLoading";
import theme from "./shared/system/theme";

export const GlobalStyle = createGlobalStyle`
		:focus-visible {
			outline: 0;
		}

		::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			background-color: transparent;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 12px;
			background: var(--color-border);
		}

		::-webkit-scrollbar-track {
			padding: 2px;
			background-color: transparent;
		}

		::placeholder {
			opacity: .5;
			color: var(--color-text);
		}

		body, html {
			background-color: var(--color-body);
		}

		body, html, button, input, textarea, a {
			color: var(--color-text);
			font-family: ${({ theme }: any) => theme.fonts.sans};
		}



		#poda {
			.rdp {
				--rdp-cell-size: 32px;
				--rdp-accent-color: var(--color-accent);
				--rdp-background-color: var(--color-accent);
				--rdp-outline: 2px solid var(--color-accent);
				--rdp-outline-selected: 2px solid var(--color-accent);

				margin: 0;
			}

			.rdp-day {
				border-radius: 4px;

				:hover  {
					color: #fff;
				}
			}


			.rdp-day_today {
				font-weight: 700;

				:not(.rdp-day_selected:not(.rdp-day_range_middle)) {
					color: var(--color-accent);
				}

				:hover  {
					color: var(--color-text) !important;
				}
			}

			.rdp-caption_label {
				font-size: 14px;
				font-weight: 500;
				line-height: 24px;
			}
			
			.rdp-nav_button {
				color: var(--color-text-secondary);
				border-radius: 4px;
				width: 28px;
				height: 28px;

				:focus,
				:active,
				:hover:not([aria-disabled='true']) {
					background-color: var(--color-hover);
					border: 0;
					outline: none;
				}
			}

			.rdp-nav svg {
				width: 10px;
				height: 10px;
			}

			.rdp-head_cell {
				font-size: 12px;
				font-weight: 500;
				color: var(--color-text-secondary);
				text-transform: unset;
			}

			.rdp-day_range_middle {
				border-radius: 0 ;
				color: var(--color-text);
				background-color: var(--color-hover);

				:hover {
					font-weight: 700;
					color: var(--color-accent);
				}
			}
		}

		input[type="radio"],
		input[type="checkbox"] {
			accent-color: var(--color-accent);
		}
		

		.firebase-emulator-warning {display:none;}

		kbd {
			font-family: ${({ theme }) => theme.fonts.sans};
			line-height: 110%;
			vertical-align: baseline;
			text-align: center;
			text-transform: capitalize;
			display: inline-block;
			border-radius: 4px;
			border: var(--border);
			min-width: 22px;
			padding: 4px ;
			font-size: 11px;
			font-weight: 500;
		}

		/* Landing page navigation */
		.nav-active {
			overflow: hidden;
			${breakpoints.md`
				overflow:auto;
			`}
		}
`;

const helmetContext = {};

const App = () => {
	return (
		<Suspense fallback={<AppLoading />}>
			<RecoilRoot>
				<HelmetProvider context={helmetContext}>
					<ThemeProvider theme={theme}>
						<ErrorBoundary>
							<AuthenticationContainer>
								<Router>
									<Analytics />
									<ThemeGlobalVariables />
									<GlobalStyle />
									<ScrollToTop />
									<RoutesAll />
								</Router>
							</AuthenticationContainer>
						</ErrorBoundary>
					</ThemeProvider>
				</HelmetProvider>
			</RecoilRoot>
		</Suspense>
	);
};

export default App;
