import { useRecoilValue } from "recoil";

import { profileState } from "@/modules/profile/selectors/profileState";
import { Result } from "@/shared/utils/result";
import { User, WithId } from "@/types/db";

export const useProfile = (): Result<WithId<User>> => {
	const profile = useRecoilValue(profileState);

	if (!profile) {
		return Result.fail("Unable to get user profile");
	}

	return Result.ok(profile);
};
