import { useCallback, useRef, useState } from "react";

import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { Node, WithId } from "@/types/db";

import { useToast } from "../../../../../modules/toast/hooks/useToast";
import { useBindShortcut } from "../../../../../shared/core/hooks/useBindShortcut";
import { Button, ResizableTextarea } from "../../../../../shared/system";

const HOTKEY_SUBMIT = "command+enter";
const HOTKEY_SUBMIT_WINDOWS = "ctrl+enter";
const HOTKEY_CANCEL = "escape";

type Props = {
	parent: WithId<Node>;
	onCancel: () => void;
};

export const NodeListAdd = ({ parent, onCancel }: Props) => {
	const parentId = parent.id;

	const nodeService = useNodesService();
	const nodeTitleRef = useRef<HTMLTextAreaElement>(null);

	const [title, setTitle] = useState("");
	const pushToast = useToast();

	const handleAddNode = useCallback(async () => {
		if (title) {
			const data = { title };
			const options = { commit: true };

			nodeService.localAdd({ parentId, data, options });

			setTitle("");
			nodeTitleRef.current?.select();
		} else {
			pushToast({ status: "warning", message: `Title Required: Please enter a value` });
		}
	}, [nodeService, parentId, pushToast, title]);

	useBindShortcut(HOTKEY_SUBMIT, handleAddNode, nodeTitleRef);
	useBindShortcut(HOTKEY_SUBMIT_WINDOWS, handleAddNode, nodeTitleRef);
	useBindShortcut(HOTKEY_CANCEL, onCancel, nodeTitleRef);

	const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setTitle(e.currentTarget.value);
	};

	return (
		<Root>
			<ResizableTextarea
				autoFocus
				value={title}
				onChange={handleTitleChange}
				placeholder="Add a title"
				data-testid="nodeLayoutAddTitle"
				styledProps={textAreaStyle}
				el={nodeTitleRef}
			/>
			<Actions>
				<ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
				<Button onClick={handleAddNode}>Save</Button>
			</Actions>
		</Root>
	);
};

const textAreaStyle = {
	background: "transparent",
	isPlain: true,
	noHover: true,
	mb: 0,
	p: 0,
};

const Root = styled.div`
	padding: 12px;
	border: var(--border);
	margin-bottom: 24px;
	border-radius: 4px;
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;

	button {
		padding: 4px 12px;
		font-size: 13px;
	}

	button:first-of-type {
		margin-right: 12px;
	}
`;

const ButtonSecondary = styled(Button.Outline)`
	border: var(--border);
`;
