import { Item } from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";

const style = css`
	display: flex;
	align-items: center;
	padding: 4px 6px;
	border-radius: 4px;
	line-height: 22px;
	font-size: 14px;
	cursor: pointer;
	width: 100%;
	color: var(--color-text-heading);

	svg:not(.caret) {
		margin-right: 6px;
	}

	:hover {
		background: var(--color-hover);
	}

	:disabled,
	&[data-disabled] {
		opacity: 0.5;
	}

	:disabled,
	&[data-disabled]:hover {
		color: inherit;
		background: transparent;
		cursor: not-allowed;
	}
`;

export const DropdownMenuItem = styled(Item)`
	${style}
`;

export const DropdownMenuButton = styled.button`
	flex: 1;
	background: transparent;
	text-align: left;
	border: 0;
	${style}

	.caret {
		font-size: 0.8em;
		margin-left: auto;
	}
`;
