// https://www.dofactory.com/javascript/design-patterns/command
import { atom, useRecoilState } from "recoil";

type CommandArgs<T> = {
	action: (args: T) => any;
	undo: (args: T) => any;
	redo?: (args: T) => any;
	value: T;
	name: string;
};

export class Command<T = any> {
	#value;
	#action;
	#undoAction;
	#redoAction;

	name: string;

	constructor({ name, action, undo, redo, value }: CommandArgs<T>) {
		this.#action = action;
		this.#undoAction = undo;
		this.#redoAction = redo;
		this.#value = value;
		this.name = name;
	}

	async execute() {
		return this.#action(this.#value);
	}

	async undo() {
		return this.#undoAction(this.#value);
	}

	async redo() {
		if (this.#redoAction) {
			return this.#redoAction(this.#value);
		}

		return this.execute();
	}
}

const actionHistoryState = atom<any>({
	key: "actionHistory",
	default: {
		past: [],
		future: [],
	},
});

export interface IHistoryService {
	execute: any;
	canUndo: any;
	canRedo: any;
	undo: any;
	redo: any;
}

export const useActionHistory = (): IHistoryService => {
	const [actionHistory, setActionHistory] = useRecoilState(actionHistoryState);
	const { past, future } = actionHistory;

	return {
		execute(command: Command) {
			setActionHistory({
				past: [...past, command],
				future: [],
			});

			return command.execute();
		},
		canUndo() {
			return past.length > 0;
		},
		canRedo() {
			return future.length > 0;
		},
		undo() {
			const previous = past[past.length - 1];
			const newPast = past.slice(0, past.length - 1);

			if (previous) {
				try {
					previous.undo();

					setActionHistory({
						past: newPast,
						future: [previous, ...future],
					});
					return `Undo: ${previous.name}`;
				} catch {
					console.error("Failed to undo", previous.name);
				}
			}
		},
		redo() {
			const next = future[0];
			const newFuture = future.slice(1);

			if (next) {
				setActionHistory({
					past: [...past, next],
					future: newFuture,
				});

				next.redo();
			}
		},
	};
};
