import React from "react";

import styled from "styled-components";

import { FixedTextarea, Label } from "@/shared/system";

type Props = {
	register: any;
	errors: any;
};

export const InputComment: React.FC<Props> = ({ register, errors }) => {
	const key = "comment";

	return (
		<Root>
			<Label htmlFor={key}>Comment</Label>
			<FixedTextarea
				id={key}
				rows={3}
				placeholder="Identify any key events that influence the outcome of this update"
				noHover
				isError={errors[key]}
				register={register(key)}
			/>
		</Root>
	);
};

const Root = styled.div`
	margin-bottom: 32px;
`;
