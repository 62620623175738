import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { Button } from "@/shared/system";

type Props = {
	onAdd: VoidFunction;
};

export const KeyResultsDetailsHeader: React.FC<Props> = ({ onAdd }) => {
	return (
		<Header>
			<Heading>Key results</Heading>
			<AddKeyResult onClick={onAdd} aria-label="add key result">
				<FiPlus />
			</AddKeyResult>
		</Header>
	);
};

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
`;

const Heading = styled.h4`
	margin: 0;
`;

const AddKeyResult = styled(Button.Text)`
	padding: 0;
`;
