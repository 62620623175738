import { FiDroplet } from "react-icons/fi";

import { EmbedActionGroup } from "@/modules/board/share/shareEmbed/components/EmbedActionGroup";
import { Link } from "@/shared/system";

type Props = {
	projectId: string;
};

export const EmbedProjectTheme: React.FC<Props> = ({ projectId }) => {
	const icon = <FiDroplet />;
	const content = (
		<>
			<div>Project Theme</div>
			Customize the look and feel
		</>
	);
	const action = <Link.Plain to={`/s/${projectId}`}>Go to settings</Link.Plain>;

	return <EmbedActionGroup icon={icon} content={content} action={action} />;
};
