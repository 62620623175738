import { IAnalyticsAdapter } from "@/shared/core/analytics/analyticsInterface";

declare global {
	interface Window {
		analytics: any;
	}
}

const segment = window.analytics;

const adapter: IAnalyticsAdapter = {
	track(event: string, properties?: any): void {
		segment.track(event, properties);
	},
	page(): void {
		segment.page();
	},
	identify(userId: string, properties?: any): void {
		segment.identify(userId, properties);
	},
	group(groupId: string, properties?: any): void {
		segment.group(groupId, properties);
	},
	revenue(amount: number, properties?: any): void {
		segment.revenue(amount, properties);
	},
	reset(): void {
		segment.reset();
	},
};

export default adapter;
