import { useCallback } from "react";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { ImageUpload } from "@/modules/note/coverImage/components/ImageUpload";
import { useStorageService } from "@/shared/core/hooks/useStorageService";

type Props = {
	node: NodeModel;
	onComplete: VoidFunction;
};

export const IconImageUpload: React.FC<Props> = ({ node, onComplete }) => {
	const nodesService = useNodesService();
	const storageService = useStorageService();

	const nodeId = node.id;
	const workspaceId = node.workspace;

	const currentIconSrc = node?.icon?.type === "image" && node.icon.src;

	const buildPath = (extension: string) => `/workspaces/${workspaceId}/icons/${nodeId}.${extension}`;

	const handleUploadComplete = useCallback(
		async (src: string) => {
			const icon = {
				type: "image" as const,
				local: true,
				src,
			};

			if (currentIconSrc) {
				storageService.delete(currentIconSrc);
			}

			await nodesService.update(nodeId, { icon });
			onComplete();
		},
		[currentIconSrc, nodeId, nodesService, onComplete, storageService],
	);

	return <ImageUpload buildPath={buildPath} onUploadComplete={handleUploadComplete} />;
};
