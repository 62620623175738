const dateValue = (value: string) => <span>{value}</span>;

export const dateValues = [
	{
		id: "1",
		label: dateValue("1 day from now"),
	},
	{
		id: "2",
		label: dateValue("2 days from now"),
	},
	{
		id: "3",
		label: dateValue("3 days from now"),
	},
	{
		id: "7",
		label: dateValue("1 week from now"),
	},
	{
		id: "14",
		label: dateValue("2 weeks from now"),
	},
	{
		id: "21",
		label: dateValue("3 weeks from now"),
	},
	{
		id: "30",
		label: dateValue("1 month from now"),
	},
	{
		id: "60",
		label: dateValue("2 months from now"),
	},
	{
		id: "90",
		label: dateValue("3 months from now"),
	},
	{
		id: "180",
		label: dateValue("6 month from now"),
	},
];
