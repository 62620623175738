import mapValues from "lodash/mapValues";
import { selectorFamily } from "recoil";
import { z } from "zod";

import { nodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";

import { NodeMetaAllTypes, NodeMetaTypes } from "../../../types/db";
import { getBoardMembers } from "../../members/selectors/getBoardMembers";

// TODO: Referenced in component (Added for filter updates can be improve for better types)

/**
 * Collaborators meta doesn't have values stored so needs to be hydrated. Requiring hydration here is probably suboptimal and adds
 * unneeded complexity to the codebase.
 *
 * The preferred method would probably be to store the values in the document and then have a
 * function that adds/removes values when users are modified.
 */

export const getNodeMetaWithValuesHydrated = selectorFamily({
	key: "getNodeMetaWithValuesHydrated",
	get:
		({
			boardId,
			workspaceId,
			nodeMeta,
		}: {
			boardId: string;
			workspaceId: string;
			nodeMeta: z.infer<typeof nodeMetaModel>;
		}) =>
		({ get }) => {
			const members = get(getBoardMembers({ boardId, workspaceId }));

			const memberObject = Object.fromEntries(nodeMeta);
			const memberIds = Object.values(members).map((member) => member.id);

			if (nodeMeta.size === 0) {
				return {};
			}

			return mapValues(memberObject, (meta: NodeMetaAllTypes) => {
				if (meta.type === NodeMetaTypes.person) {
					return {
						...meta,
						value: memberIds,
					};
				}
				return meta;
			});
		},
});
