import { ChangeEventHandler, useLayoutEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import styled from "styled-components";

import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";

type Props = {
	placeholder?: string;
	value: any;
	close: () => void;
	onComplete: (value: string) => void;
};

export const DropdownTextValue = ({ value, placeholder, close, onComplete }: Props) => {
	const inputEl = useRef<HTMLInputElement>(null);
	const [localValue, setValue] = useState(value);

	useLayoutEffect(() => {
		inputEl.current?.select();
	}, []);

	const handleClose = () => {
		if (value !== localValue) {
			onComplete(localValue);
		}
		close();
	};

	useBindShortcut("enter", handleClose, inputEl);

	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setValue(e.currentTarget.value);
	};

	return (
		<Menu data-testid="dropdown-text--menu">
			<PopoverContent p={0}>
				<Input
					type="text"
					placeholder={placeholder}
					onChange={handleChange}
					value={localValue}
					ref={inputEl}
					data-testid="dropdown-test--input"
				/>
			</PopoverContent>
		</Menu>
	);
};

const Menu = styled(motion.div)``;

const Input = styled.input`
	border: 0;
	margin-left: 4px;
	font-size: 14px;
	line-height: 22px;
	padding: 6px 12px;
	background: transparent;
	flex-grow: 1;
	width: 100%;

	+ * {
		border-top: var(--border);
	}

	:first-child {
		margin-left: 0;
	}

	:focus,
	:active {
		outline: none;
	}
`;
