import { useMemo } from "react";

import { limit, query, QuerySnapshot, Unsubscribe } from "firebase/firestore";

import { NotesDto } from "@/domains/nodes/dtos/notesDto";
import { NoteModel } from "@/domains/nodes/models/notesModel";
import { NoteUtils } from "@/domains/nodes/utils/noteUtils";
import { IErrorService, useErrorService } from "@/shared/core/hooks/useErrorService";
import { BaseApi } from "@/shared/infra/services/BaseApi";
import { Result } from "@/shared/utils/result";

type ConstructorArgs = {
	nodeId: string;
	errorService: IErrorService;
};

export class NotesRepository extends BaseApi<NotesDto> {
	private errorService;

	constructor({ nodeId, errorService }: ConstructorArgs) {
		super(`nodes/${nodeId}/notes`);

		this.errorService = errorService;
	}

	public listen(save: (note: Result<NoteModel>) => void): Unsubscribe {
		const nodeQuery = query(this.collection, limit(1));

		const setState = (document: QuerySnapshot<NotesDto>) => {
			const note = NoteUtils.toViewModel(document);
			save(note);
		};

		return this.querySnapshot(nodeQuery, setState);
	}

	public async get(save: (note: Result<NoteModel>) => void) {
		const nodeQuery = query(this.collection, limit(1));

		const snapshot = await this.queryDocuments(nodeQuery);
		const note = NoteUtils.toViewModel(snapshot);

		save(note);
	}

	public async createNote(note: NoteModel): Promise<Result<void>> {
		const { id, ...noteDto } = note;
		try {
			await this.post(id, noteDto);
			return Result.ok();
		} catch (e) {
			this.errorService.logError("Unable to create note", { e });
			return Result.fail("Unable to create note");
		}
	}
}

export const useNotesRepository = (nodeId: string) => {
	const errorService = useErrorService();

	const nodesRepository = useMemo(() => new NotesRepository({ nodeId, errorService }), [nodeId, errorService]);

	return nodesRepository;
};
