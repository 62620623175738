import { captureException } from "@sentry/browser";
import { doc, setDoc } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";
import { ProjectFolder, ProjectVisibility, PublicProfile } from "@/types/db";

export const projectCreateFolder = async (
	workspaceId: string,
	publicProfile: PublicProfile,
	visibility: ProjectVisibility = "workspace",
) => {
	const data: ProjectFolder = {
		workspaceId,
		type: "folder" as const,
		status: "active" as const,
		visibility,
		access: null,
		createdAt: fb.timestamp(),
		updatedAt: fb.timestamp(),
		createdBy: publicProfile,
		updatedBy: publicProfile,
		childIds: [],
		version: "1",
		data: {
			name: "New folder",
			description: null,
		},
	};

	if (visibility === "private") {
		data.access = {
			[publicProfile.id]: {
				status: "active",
				role: "editor",
				scope: "workspace",
			},
		};
	}

	try {
		const newFolderReference = doc(db.projects);
		setDoc(newFolderReference, data);

		return newFolderReference.id;
	} catch (e) {
		captureException(e);
	}
};
