import styled from "styled-components";

import { ViewSettings } from "@/domains/projects/components/layout/ViewSettings";
import { BoardView } from "@/modules/board/components/BoardView";
import { FilterAdd } from "@/modules/board/filter/components/FilterAdd";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";
import { Share } from "@/modules/board/share/Share";
import { TopBarStar } from "@/modules/board/topBar/components/TopBarStar";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { ProjectBoard, WithId } from "@/types/db";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { FilterForm } from "../filter/FilterForm";
import { TopBarProjectPath } from "./components/TopBarProjectPath";

export const TopBar = () => {
	const board = useGetProject() as WithId<ProjectBoard>;
	const { hasActiveFilter } = useFilterService();
	const { canEdit } = usePermissions("project");

	return (
		<>
			<Root>
				{board && (
					<>
						<Left>
							<TopBarProjectPath />
							<TopBarStar boardId={board.id} />
							<FilterAdd board={board} />
						</Left>
						<Right canEdit={canEdit}>
							<BoardView board={board} />
							<ViewSettings board={board} />
							<Share />
						</Right>
					</>
				)}
			</Root>
			{hasActiveFilter && (
				<FilterBar>
					<FilterForm />
					{/* <FilterSave>
						<FiLayers /> Save
					</FilterSave> */}
				</FilterBar>
			)}
		</>
	);
};

export const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px 8px;
`;

const FilterBar = styled(Root)`
	top: 56px;
	padding: 0 24px;
	z-index: 5;
`;

// const FilterSave = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-self: flex-end;
// 	padding: 2px 8px;
// 	border-radius: 4px;
// 	background: var(--color-hover);
// 	border: var(--border);

// 	> svg {
// 		margin-right: 8px;
// 	}
// `;

export const Left = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

export const Right = styled.div<{ canEdit: boolean }>`
	display: flex;
	align-items: center;
	gap: 10px;
	${({ canEdit }) => !canEdit && { padding: 0, border: 0 }}
`;
