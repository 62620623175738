import { useCallback } from "react";

import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { buildUriPublicBoard } from "@/modules/board/buildUriPublicBoard";
import { GuestInviteForm } from "@/modules/board/components/GuestInvite";
import { addGuests } from "@/modules/board/share/shareUsers/api/addGuests";
import { ShareMembers } from "@/modules/board/share/shareUsers/components/ShareMembers";
import { ShareVisibility } from "@/modules/board/share/shareUsers/components/ShareVisibility";
import { ShareFormValues } from "@/modules/board/share/shareUsers/shareTypes";
import { useProfileLegacy } from "@/modules/profile/hooks/useProfileLegacy";
import { useToast } from "@/modules/toast/hooks/useToast";
import { Button, CopyToClipboard } from "@/shared/system";
import { WithId } from "@/types/db";

const defaultValues: ShareFormValues = {
	members: [],
	notify: true,
	role: "editor",
	message: "",
};

type Props = {
	project: WithId<ProjectDtoBoard>;
	modalClose: VoidFunction;
};

export const ShareUsers: React.FC<Props> = ({ project, modalClose }) => {
	const pushToast = useToast();
	const profile = useProfileLegacy();

	const methods = useForm<ShareFormValues>({ defaultValues });

	const { watch, handleSubmit, reset } = methods;
	const membersSelectedForInvite = watch("members");

	//TODO: Use Effect to run on mount
	const handleResetSelected = useCallback(() => {
		reset(defaultValues);
	}, [reset]);

	const handleInviteMembers = useCallback(
		async (data: ShareFormValues) => {
			modalClose();
			await addGuests({
				project,
				invitedBy: profile,
				...data,
			});
			pushToast({ status: "success", message: `Access updated` });
		},
		[modalClose, profile, project, pushToast],
	);

	if (!project) {
		return null;
	}

	const uriPublicBoard = buildUriPublicBoard(project.id);

	const isProjectOwner = project.createdBy.id === profile.id;
	const isWorkspaceProjectAndMember =
		Object.keys(profile.access).includes(project.workspaceId) && project.visibility === "workspace";

	const showMembers = membersSelectedForInvite.length === 0;
	const canUpdatePermissions = isProjectOwner;
	const canInviteMembers = isProjectOwner || isWorkspaceProjectAndMember;

	return (
		<FormProvider {...methods}>
			{canInviteMembers && <GuestInviteForm />}
			{showMembers && (
				<>
					<div>
						<SectionHeader>People with access</SectionHeader>
						<ShareMembers project={project} />
					</div>
					<div>
						<SectionHeader>General Access</SectionHeader>
						<ShareVisibility project={project} disabled={!canUpdatePermissions} />
					</div>
				</>
			)}
			<Actions>
				<CopyToClipboard textType="link" textToCopy={uriPublicBoard} />
				{showMembers ? (
					<Button onClick={modalClose} rounded>
						Done
					</Button>
				) : (
					<ActionGroup>
						<Button.Plain onClick={handleResetSelected} rounded>
							Cancel
						</Button.Plain>
						<Button onClick={handleSubmit(handleInviteMembers)} rounded>
							Send
						</Button>
					</ActionGroup>
				)}
			</Actions>
		</FormProvider>
	);
};

const SectionHeader = styled.h5`
	margin: 16px 0;
`;

const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
`;

const ActionGroup = styled.div`
	display: flex;
`;
