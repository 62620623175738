import { z } from "zod";

export const nodeMetaSelectValue = z.object({
	id: z.string(),
	title: z.string(),
	color: z.string().nullable(),
});

export const nodeMetaTypeSelect = z.object({
	type: z.literal("select"),
	id: z.string(),
	label: z.string(),
	value: nodeMetaSelectValue.array(),
});

export type NodeMetaTypeSelect = z.infer<typeof nodeMetaTypeSelect>;

export const nodeMetaTypeList = z.object({
	type: z.literal("list"),
	id: z.string(),
	label: z.string(),
	value: nodeMetaSelectValue.array(),
});

export const nodeMetaTypePerson = z.object({
	type: z.literal("person"),
	id: z.string(),
	label: z.string(),
	value: z.null(),
});

export type NodeMetaTypePerson = z.infer<typeof nodeMetaTypePerson>;

export const nodeMetaDateValue = z.object({
	format: z.string(),
});

export const nodeMetaTypeDate = z.object({
	type: z.literal("date"),
	id: z.string(),
	label: z.string(),
	value: nodeMetaDateValue,
});

export type NodeMetaTypeDate = z.infer<typeof nodeMetaTypeDate>;

export const nodeMetaTypeLink = z.object({
	type: z.literal("link"),
	id: z.string(),
	label: z.string(),
	value: z.null(),
});

export type NodeMetaTypeLink = z.infer<typeof nodeMetaTypeLink>;

export const nodeMetaTypeText = z.object({
	type: z.literal("text"),
	id: z.string(),
	label: z.string(),
	value: z.null(),
});

export type NodeMetaTypeText = z.infer<typeof nodeMetaTypeText>;

export const nodeMetaTypeEmail = z.object({
	type: z.literal("email"),
	id: z.string(),
	label: z.string(),
	value: z.null(),
});

export type NodeMetaTypeEmail = z.infer<typeof nodeMetaTypeEmail>;

export const nodeMetaTypeDateRange = z.object({
	type: z.literal("dateRange"),
	id: z.string(),
	label: z.string(),
	value: nodeMetaDateValue,
});

export const nodeMetaTypes = z.discriminatedUnion("type", [
	nodeMetaTypeSelect,
	nodeMetaTypeList,
	nodeMetaTypePerson,
	nodeMetaTypeDate,
	nodeMetaTypeDateRange,
	nodeMetaTypeLink,
	nodeMetaTypeText,
	nodeMetaTypeEmail,
]);

export const nodeMetaTypeValues = z.union([nodeMetaSelectValue.array(), nodeMetaDateValue]);

export const nodeMetaDTO = z.object({
	order: z.string().array(),
	meta: z.record(nodeMetaTypes),
	version: z.literal("1"),
});
