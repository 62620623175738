import { useMemo } from "react";

import { IAnalyticsAdapter } from "@/shared/core/analytics/analyticsInterface";
import segment from "@/shared/core/analytics/segment/analyticsAdapter";
import { User, WithId, Workspace, WorkspaceInviteV2 } from "@/types/db";

const analytics: IAnalyticsAdapter = segment;

type AnalyticsIdentifyExtras = Partial<{
	persona: string;
}>;

type AnalyticsProjectEventName =
	| "Project Created"
	| "Project Exported"
	| "Project Imported"
	| "Project Deleted"
	| "Node Created"
	| "Node Updated"
	| "Webhook Created"
	| "Key Result Created"
	| "Key Result Updated";

type AnalyticsProjectEventBaseArgs = { boardId: string; workspaceId: string };
type AnalyticsProjectEvent<T = AnalyticsProjectEventBaseArgs> = (args: T) => void;

type AnalyticsUserEvent = (args: { profile: Pick<User, "email" | "activeWorkspaceId"> }) => void;
type AnalyticsUserSignedUpEvent = (args: { user: User; type: "organic" | "invite" }) => void;

export interface IAnalyticsService {
	identify: (profile: Partial<User>, extra?: AnalyticsIdentifyExtras) => void;
	page: () => void;
	buttonClick: (args: { name: string; source?: string }) => void;
	accountCreated: (args: { workspace: WithId<Workspace> }) => void;
	accountDeleted: (args: { workspace: WithId<Workspace> }) => void;
	accountAddedUser: (args: { workspaceId: string; role: string }) => void;
	accountRemovedUser: (args: { workspaceId: string }) => void;
	accountPlanUpdated: (args: { workspaceId: string; workspaceName: string }) => void;
	inviteSent: (args: { invite: WorkspaceInviteV2 }) => void;
	projectCreated: AnalyticsProjectEvent;
	projectExported: AnalyticsProjectEvent<{ format: string } & AnalyticsProjectEventBaseArgs>;
	projectImported: AnalyticsProjectEvent;
	projectDeleted: AnalyticsProjectEvent;
	nodeCreated: AnalyticsProjectEvent;
	nodeUpdated: AnalyticsProjectEvent;
	signUp: AnalyticsUserSignedUpEvent;
	signIn: AnalyticsUserEvent;
	signOut: AnalyticsUserEvent;
	webhookCreated: AnalyticsProjectEvent;
	keyResultCreated: AnalyticsProjectEvent;
	keyResultUpdated: AnalyticsProjectEvent;
}

export const identify: IAnalyticsService["identify"] = (profile, extra = {}) => {
	const identity = {
		...(profile.email && { email: profile.email }),
		...(profile.photoURL && { avatar: profile.photoURL }),
		...(profile.username && { name: profile.username }),
		...extra,
	};

	if (!profile.id) {
		return;
	}

	analytics.identify(profile.id, identity);
};

export const page: IAnalyticsService["page"] = () => {
	analytics.page();
};

type UserActionEvent = (type: "Button Clicked" | "Modal Viewed") => (args: { name: string; source?: string }) => void;

const userAction: UserActionEvent =
	(type) =>
	({ name, source }) => {
		analytics.track(type, { name, source });
	};

export const buttonClick: IAnalyticsService["buttonClick"] = userAction("Button Clicked");

export const accountCreated: IAnalyticsService["accountCreated"] = ({ workspace }) => {
	const accountCreatedEvent = {
		context: {
			groupId: workspace.id,
		},
	};
	analytics.track("Account Created", accountCreatedEvent);
};

export const accountDeleted: IAnalyticsService["accountDeleted"] = ({ workspace }) => {
	const accountDeletedEvent = {
		context: {
			groupId: workspace.id,
		},
	};

	analytics.track("Account Deleted", accountDeletedEvent);
};

export const inviteSent: IAnalyticsService["inviteSent"] = ({ invite }) => {
	const inventSentEvent = {
		inviteeEmail: invite.email,
		inviteeRole: invite.role,
		inviteeType: invite.type,
		context: {
			groupId: invite.workspace,
		},
	};

	analytics.track("Invite Sent", inventSentEvent);
};

export const accountAddedUser: IAnalyticsService["accountAddedUser"] = ({ workspaceId, role }) => {
	const accountUserAddedEvent = {
		role,
		context: {
			groupId: workspaceId,
		},
	};

	analytics.track("Account Added User", accountUserAddedEvent);
};

export const accountRemovedUser: IAnalyticsService["accountRemovedUser"] = ({ workspaceId }) => {
	const accountUserRemovedEvent = {
		context: {
			groupId: workspaceId,
		},
	};

	analytics.track("Account Removed User", accountUserRemovedEvent);
};

export const accountPlanUpdated: IAnalyticsService["accountPlanUpdated"] = (properties) => {
	analytics.track("Account Plan Updated", properties);
};

export const signUp: IAnalyticsService["signUp"] = ({ user, type }) => {
	const username = user.username || "";
	const signUpEvent = {
		type,
		firstName: username.split(" ")[0],
		lastName: username.split(" ")[1],
		email: user.email,
		context: {
			groupId: user.activeWorkspaceId,
		},
	};

	analytics.track("Sign Up", signUpEvent);
	identify(user);
};

export const signIn: IAnalyticsService["signIn"] = ({ profile }) => {
	const singInEvent = {
		email: profile.email,
		context: {
			groupId: profile.activeWorkspaceId,
		},
	};

	analytics.track("Sign In", singInEvent);
};

export const signOut: IAnalyticsService["signOut"] = ({ profile }) => {
	const signOutEvent = {
		email: profile.email,
	};

	analytics.track("Sign Out", signOutEvent);
	analytics.reset();
};

export const projectEvent =
	(eventName: AnalyticsProjectEventName): AnalyticsProjectEvent =>
	({ boardId, workspaceId, ...rest }) => {
		analytics.track(eventName, {
			boardId,
			...rest,
			context: {
				groupId: workspaceId,
			},
		});
	};

export const useAnalyticsService = (): IAnalyticsService => {
	return useMemo(
		() => ({
			identify,
			page,
			buttonClick,
			accountCreated,
			accountDeleted,
			accountAddedUser,
			accountRemovedUser,
			accountPlanUpdated,
			inviteSent,
			signIn,
			signUp,
			signOut,
			projectCreated: projectEvent("Project Created"),
			projectDeleted: projectEvent("Project Deleted"),
			projectExported: projectEvent("Project Exported"),
			projectImported: projectEvent("Project Imported"),
			nodeCreated: projectEvent("Node Created"),
			nodeUpdated: projectEvent("Node Updated"),
			keyResultCreated: projectEvent("Key Result Created"),
			keyResultUpdated: projectEvent("Key Result Updated"),
			webhookCreated: projectEvent("Webhook Created"),
		}),
		[],
	);
};
