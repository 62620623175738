import React, { MutableRefObject, ReactNode } from "react";

import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

import { breakpoints, utility } from "../../../shared/system";
import { ContentDescription, ContentHeader, ContentType, ImageBlock } from "./StyledLandingComponents";

type Props = {
	type: string;
	header: string;
	description: string;
	imageBlock: ReactNode;
	refs: MutableRefObject<null>;
	flip?: boolean;
} & SpaceProps;

export default function FeatureBlock({ type, header, description, imageBlock, flip = false, refs, ...space }: Props) {
	return (
		<Root flip={flip} ref={refs} {...space}>
			<Content>
				<ContentType>{type}</ContentType>
				<ContentHeader>{header}</ContentHeader>
				<ContentDescription>{description}</ContentDescription>
			</Content>
			<ImageBlock>{imageBlock}</ImageBlock>
		</Root>
	);
}

const Root = styled.section<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 32px;

	@media screen and (min-width: 40em) {
		padding: 120px 90px 132px;
		flex-direction: ${({ flip }) => (flip ? "row-reverse" : "row")};
		${space}
	}

	> *:not(:last-child) {
		margin-bottom: 24px;

		${breakpoints.md`
			margin-right: 60px;
		`}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 590px;
	flex-shrink: 0;

	${breakpoints.md`
		width: 45%;
	`}

	> * {
		color: ${utility.color("blackLight")};
	}

	> *:not(:last-child) {
		margin-bottom: 12px;

		${breakpoints.md`
			margin-bottom: 24px;
		`}
	}
`;
