import { arrayUnion, doc, updateDoc } from "firebase/firestore";

import { IUserProps } from "@/modules/authentication/utils/user";
import { resetAllStores } from "@/shared/zustand/createStore";

import { db, fb } from "../../shared/infra/init";

export const updateActiveWorkspace = async (activeWorkspaceId: string) => {
	resetAllStores();

	const userDocument = doc(db.users, fb.auth.currentUser?.uid);
	return updateDoc(userDocument, { activeWorkspaceId });
};

export const updateTipsSeen = async (tipId: string) => {
	const userDocument = doc(db.users, fb.auth.currentUser?.uid);
	return updateDoc(userDocument, { tips: arrayUnion(tipId) });
};

export const updateTheme = (theme: IUserProps["theme"]) => {
	const userDocument = doc(db.users, fb.auth.currentUser?.uid);

	return updateDoc(userDocument, { theme });
};
