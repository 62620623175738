import { useCallback } from "react";

import debounce from "lodash/debounce";

import { User, WorkspaceInviteV2 } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { useProfileLegacy } from "../../profile/hooks/useProfileLegacy";
import { useToast } from "../../toast/hooks/useToast";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";
import { deleteInvite, sendEmail } from "../memberFunctions";
import { RoleSelector } from "../primitives/RoleSelector";
import { MemberRow } from "./MemberRow";

const TEN_SECONDS = 10000;

const debouncedSendEmail = debounce(sendEmail, TEN_SECONDS, {
	leading: true,
	trailing: false,
});

interface Props {
	invite: WithId<WorkspaceInviteV2>;
	variant?: "standard" | "nested";
}

export const MemberInvited = ({ invite, variant }: Props) => {
	const pushToast = useToast();

	const { id: inviteId, email, role, type } = invite;

	const authUser = useProfileLegacy();
	const workspace = useActiveWorkspace();

	const id = type === "guest" ? invite.boardId : invite.workspace;

	const handleResendEmail = useCallback(() => {
		debouncedSendEmail({
			inviteId,
			id,
			email,
			role,
			authUser,
			type: invite.type,
			workspaceName: workspace.title,
		});
		pushToast({ status: "success", message: `Invite resent to: ${email}` });
	}, [authUser, email, id, invite.type, inviteId, pushToast, role, workspace.title]);

	const handleRevokeInvite = useCallback(() => {
		deleteInvite(inviteId);
	}, [inviteId]);

	const options = [
		{
			label: "Revoke Access",
			onClick: handleRevokeInvite,
		},
		{
			label: "Resend Email",
			onClick: handleResendEmail,
		},
	];

	const inviteActions = <RoleSelector currentRole="invited" roles={[]} options={options} />;

	const mockUser = {
		username: email,
		email,
	} as User;

	return <MemberRow name={email} user={mockUser} actions={inviteActions} variant={variant} permissionLevel={"invite"} />;
};
