import { useRecoilValue } from "recoil";

import { getCurrentWorkspace } from "../selectors/getCurrentWorkspace";

/**
 *
 * @deprecated use 'useWorkspace()'
 */

export const useActiveWorkspace = () => useRecoilValue(getCurrentWorkspace);
