import { ReactNode, SyntheticEvent } from "react";

import { Flex } from "rebass";
import styled from "styled-components";

import { Input } from "@/shared/system/Forms/Option";

import { DropdownMenuButton } from "../..";

type Props = {
	checked: boolean;
	onChange: (event: SyntheticEvent<HTMLDivElement>) => void;
	children: ReactNode;
	id: string;
};

export const DropdownList = ({ checked, onChange, children, id }: Props) => {
	return (
		<DropdownMenuButton>
			<Flex alignItems="center">
				<Input checked={checked} onChange={onChange} id={id} type="checkbox" />
				<ListLabel htmlFor={id}>{children}</ListLabel>
			</Flex>
		</DropdownMenuButton>
	);
};

export const ListLabel = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
