import React from "react";

import styled from "styled-components";
import { CubeGrid } from "styled-spinkit";

export const TreeLoading = () => {
	return (
		<Overlay>
			<CubeGrid color={"var(--color-accent)"} />
		</Overlay>
	);
};

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--color-body);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;
`;
