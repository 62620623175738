import { memo } from "react";

import { useRecoilValue } from "recoil";

import { authenticatedIdState } from "@/modules/authentication/selectors/authenticatedIdState";

import { useProjectsListener } from "./modules/board/hooks/useProjectsListener";

export const ListenerProject = memo(() => {
	const userId = useRecoilValue(authenticatedIdState);

	useProjectsListener();
	useProjectsListener({ visibility: "private", userId });

	return null;
});

ListenerProject.displayName = "ListenerProject";
