import { useCallback, useState } from "react";

import { formatDistance, fromUnixTime } from "date-fns";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { KeyResultsDetailsListCardModal } from "@/domains/nodes/components/keyResults/components/details/components/KeyResultsDetailsListCardModal";
import { KeyResultsProgress } from "@/domains/nodes/components/keyResults/components/KeyResultsProgress";
import { KeyResultsScreen, KeyResultsSetScreen } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { getMembersById } from "@/modules/members/selectors/getMembersById";
import { KeyResultModel } from "@/types/db";

import { Avatar } from "../../../../../../../shared/system";
import { keyResultStatusMap } from "../../../utils/keyResultStatusMap";
import { formatTarget, isNoTarget } from "../../../utils/keyResultsUtils";
import { KeyResultsResultChange } from "../../KeyResultsResultChange";

type Props = {
	keyResult: KeyResultModel;
};

export const KeyResultsDetailsListCard: React.FC<Props> = ({ keyResult }) => {
	const [activeScreen, setActiveScreen] = useState<KeyResultsScreen | null>(null);

	const handleToggleModal: KeyResultsSetScreen = useCallback(
		(screen) => {
			const create = activeScreen === screen ? null : screen;
			setActiveScreen(create);
		},
		[activeScreen],
	);

	const handleOpenDetails = useCallback(() => {
		handleToggleModal("details");
	}, [handleToggleModal]);

	const { title, updatedAt, updates, targetValue, startingValue, goalType, endDate } = keyResult;

	const lastUpdate = updates.slice(-1)[0];
	const lastUpdateAuthor = lastUpdate?.authorId || keyResult.createdBy || "";
	const lastUpdateStatus = lastUpdate?.status || "inactive";
	const lastUpdateValue = lastUpdate?.value || "0";

	const [member] = useRecoilValue(getMembersById([lastUpdateAuthor]));

	const relativeEndDate = formatDistance(fromUnixTime(endDate?.seconds), new Date());
	const relativeLastUpdateDate = formatDistance(fromUnixTime(updatedAt?.seconds), new Date());

	const { target, appendUnits } = formatTarget(targetValue);
	const targetWithUnits = appendUnits(target);

	const hasTarget = !isNoTarget(goalType);
	const hasUpdates = updates.length > 0;

	const showResultsChange = hasUpdates && hasTarget;

	const progressText = hasTarget
		? `${lastUpdateValue} of ${targetWithUnits} • Ends in ${relativeEndDate}`
		: `${keyResultStatusMap[lastUpdateStatus].label} • Ends in ${relativeEndDate}`;

	return (
		<>
			<Card onClick={handleOpenDetails}>
				<CardHeader>
					<CardHeaderTitle>{title}</CardHeaderTitle>
					<CardHeaderInfo>
						<Avatar user={member} size={18} />
						<KeyResultsUpdateAt>{relativeLastUpdateDate} ago</KeyResultsUpdateAt>
						{showResultsChange && (
							<KeyResultsResultChange keyResult={keyResult} nextValue={lastUpdateValue} previousValue={startingValue} />
						)}
					</CardHeaderInfo>
				</CardHeader>
				<CardProgress>
					<KeyResultsProgress keyResult={keyResult} />
					<KeyResultsProgressText>{progressText}</KeyResultsProgressText>
				</CardProgress>
			</Card>
			<KeyResultsDetailsListCardModal keyResult={keyResult} activeScreen={activeScreen} setActiveScreen={setActiveScreen} />
		</>
	);
};

const Card = styled.div`
	margin: 4px -12px 0;
	align-items: center;
	padding: 12px;
	cursor: pointer;

	&:hover {
		background: var(--color-hover);
		border-radius: 4px;
	}
`;

const CardHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-content: center;
	flex-shrink: 0;
	margin-bottom: 6px;
`;

const CardHeaderTitle = styled.div`
	font-weight: 500;
	color: var(--color-text-heading);
`;

const CardHeaderInfo = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
`;

const KeyResultsUpdateAt = styled.div`
	margin-left: 8px;
`;

const CardProgress = styled.div`
	font-size: 14px;
`;
const KeyResultsProgressText = styled.div``;
