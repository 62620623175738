import styled from "styled-components";

import { Avatar } from "@/shared/system";

export const NodeMetaPersonValueUnassigned = () => {
	return (
		<Root>
			<Avatar size={18} showStatusIndicator={false} />
			<Username>Unassigned</Username>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
`;

const Username = styled.div`
	margin-left: 4px;
`;
