import { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import styled from "styled-components";

import { useStorageService } from "@/shared/core/hooks/useStorageService";

type Props = {
	buildPath: (extension: string) => string;
	onUploadComplete: (src: string) => void;
};

export const ImageUpload: React.FC<Props> = ({ buildPath, onUploadComplete }) => {
	const storageService = useStorageService();

	const onDrop = useCallback(
		async (files: File[]) => {
			const file = files[0];

			if (!file) {
				return;
			}

			const extension = file.name.split(".").pop() as string;
			const path = buildPath(extension);
			const fileRefOrError = await storageService.upload(file, path);

			if (fileRefOrError.isFailure) {
				return;
			}

			const filePath = await fileRefOrError.getValue().getDownloadURL();

			onUploadComplete(filePath);
		},
		[buildPath, onUploadComplete, storageService],
	);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop,
	});

	const imageUploadProps = getRootProps();
	const inputProps = getInputProps();

	return (
		<ImageDropzone {...imageUploadProps}>
			<input {...inputProps} data-testid="imageUpload--fileInput" />
			<IconWrapper>
				<FiUploadCloud />
			</IconWrapper>
			<div>
				<strong>Click to upload</strong> or drag and drop <br />
				JPG,PNG or GIF
			</div>
		</ImageDropzone>
	);
};

const ImageDropzone = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 16px 24px;
	border: var(--border);
	text-align: center;
	border-radius: 4px;
	cursor: pointer;

	strong {
		color: var(--color-accent);
		font-weight: 500;
	}
`;
const IconWrapper = styled.div`
	padding: 12px;
	border-radius: 50%;
	background: var(--color-hover);
	margin-bottom: 12px;

	svg {
		display: block;
		font-size: 24px;
	}
`;
