import React, { useState } from "react";

import { FiCheck, FiLink } from "react-icons/fi";

import { Button } from "..";

type Props = {
	textType: string;
	textToCopy: string;
};
export const CopyToClipboard: React.FC<Props> = ({ textType, textToCopy }) => {
	const textDefault = `Copy ${textType}`;
	const textCopied = `${textType} Copied`;

	const [buttonText, setButtonText] = useState(textDefault);

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy);
			setButtonText(textCopied);

			setTimeout(() => {
				setButtonText(textDefault);
			}, 1000);
		} catch {
			// TODO: Error handling
		}
	};

	const Icon = buttonText === textDefault ? FiLink : FiCheck;

	return (
		<Button.Negative onClick={handleCopyToClipboard} rounded>
			<Icon />
			{textDefault}
		</Button.Negative>
	);
};
