import abstract1 from "../assets/abstract/abstract-1.webp";
import abstract2 from "../assets/abstract/abstract-2.webp";
import abstract3 from "../assets/abstract/abstract-3.webp";
import abstract4 from "../assets/abstract/abstract-4.webp";
import abstract5 from "../assets/abstract/abstract-5.webp";
import abstract6 from "../assets/abstract/abstract-6.webp";
import color1 from "../assets/color/color-1.webp";
import color2 from "../assets/color/color-2.webp";
import color3 from "../assets/color/color-3.webp";
import color4 from "../assets/color/color-4.webp";
import color5 from "../assets/color/color-5.webp";
import color6 from "../assets/color/color-6.webp";
import gradient1 from "../assets/gradient/gradient-1.webp";
import gradient2 from "../assets/gradient/gradient-2.webp";
import gradient3 from "../assets/gradient/gradient-3.webp";
import gradient4 from "../assets/gradient/gradient-4.webp";
import gradient5 from "../assets/gradient/gradient-5.webp";
import gradient6 from "../assets/gradient/gradient-6.webp";
import texture1 from "../assets/texture/texture-1.webp";
import texture2 from "../assets/texture/texture-2.webp";
import texture3 from "../assets/texture/texture-3.webp";
import texture4 from "../assets/texture/texture-4.webp";
import texture5 from "../assets/texture/texture-5.webp";
import texture6 from "../assets/texture/texture-6.webp";

export const abstract = [abstract1, abstract2, abstract3, abstract4, abstract5, abstract6];
export const texture = [texture1, texture2, texture3, texture4, texture5, texture6];
export const color = [color1, color2, color3, color4, color5, color6];
export const gradient = [gradient1, gradient2, gradient3, gradient4, gradient5, gradient6];

// TODO: Thumbnails + Resize large images

export const galleryImages: Record<string, string[]> = {
	color,
	gradient,
	abstract,
	texture,
};
