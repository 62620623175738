import { cloneElement, useState } from "react";

import {
	autoUpdate,
	flip,
	FloatingNode,
	FloatingOverlay,
	FloatingPortal,
	FloatingTree,
	offset,
	Placement,
	shift,
	size,
	useClick,
	useDismiss,
	useFloating,
	useFloatingNodeId,
	useFloatingParentNodeId,
	useInteractions,
	useRole,
} from "@floating-ui/react-dom-interactions";

import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

export type PopoverRender = (args: { close: () => void }) => React.ReactNode;
export type PopoverRenderTrigger = (args: { open: () => void; triggerProps: any }) => React.ReactNode;

interface Props {
	render: PopoverRender;
	onToggle?: (open: boolean) => void;
	offset?: number;
	placement?: Placement;
	isTriggerWidth?: boolean;
	shortcutCode?: string;
	children: PopoverRenderTrigger | JSX.Element;
}

export const Popover = ({ render, onToggle, shortcutCode, children, ...settings }: Props) => {
	const [open, setOpen] = useState(false);
	const parentId = useFloatingParentNodeId();
	const nodeId = useFloatingNodeId();
	// TODO: Permissions should be managed in a generic component as the scope can't really be determined
	const { canEdit } = usePermissions("project");

	const handleShortcutOpen = (event: KeyboardEvent) => {
		event.preventDefault();
		shortcutCode && setOpen(true);
	};

	useBindShortcut(shortcutCode, handleShortcutOpen);

	const handleToggle = (state: boolean) => {
		if (canEdit) {
			setOpen(state);
			onToggle && onToggle(state);
		}
	};

	const { offset: offsetPx = 5, placement = "bottom", isTriggerWidth = false } = settings;

	const { x, y, reference, floating, context } = useFloating({
		open,
		onOpenChange: handleToggle,
		middleware: [
			offset(offsetPx),
			flip(),
			shift(),
			size({
				apply({ elements }) {
					isTriggerWidth &&
						Object.assign(elements.floating.style, {
							width: `${elements.reference.getBoundingClientRect().width}px`,
						});
				},
			}),
		],
		placement,
		nodeId,
		whileElementsMounted: autoUpdate,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useClick(context),
		useRole(context),
		useDismiss(context),
	]);

	const handleClose = () => handleToggle(false);
	const handleOpen = () => handleToggle(true);

	const triggerRenderProps = typeof children === "function";

	const popover = (
		<FloatingNode id={nodeId}>
			{triggerRenderProps
				? children({
						open: handleOpen,
						triggerProps: getReferenceProps({
							ref: reference,
							onClick: (e) => e.preventDefault(),
						}),
					})
				: cloneElement(
						children,
						getReferenceProps({
							ref: reference,
							onClick: (e) => e.preventDefault(),
							...children.props,
						}),
					)}
			<FloatingPortal>
				{open && (
					<>
						<FloatingOverlay lockScroll style={{ zIndex: 11000 }} />
						<div
							ref={floating}
							{...getFloatingProps()}
							style={{
								position: "absolute",
								zIndex: 11000,
								top: y ?? 0,
								left: x ?? 0,
							}}>
							{render({ close: handleClose })}
						</div>
					</>
				)}
			</FloatingPortal>
		</FloatingNode>
	);

	if (parentId == null) {
		return <FloatingTree>{popover}</FloatingTree>;
	}

	return popover;
};
