import React, { useMemo } from "react";

import { ProjectSeenBy } from "@/types/db";

import { AuthorizationContainer } from "../../../shared/core/Authorization";
import { AvatarList } from "../../../shared/system";
import { BoardSeenByAvatar } from "./BoardSeenByAvatar";

const lastSeen = (a: any, b: any) => {
	return a.seenAt.seconds - b.seenAt.seconds;
};

interface Props {
	seenBy: ProjectSeenBy;
	size?: number;
}

export function BoardSeenBy({ seenBy, size }: Props) {
	const seenByList = useMemo(() => {
		if (seenBy) {
			return Object.values(seenBy)
				.map(({ user, active, seenAt }) => ({
					...user,
					active,
					seenAt,
					state: null,
				}))
				.sort(lastSeen);
		}
		return [];
	}, [seenBy]);

	return (
		<AuthorizationContainer>
			<AvatarList users={seenByList} size={size} AvatarComponent={BoardSeenByAvatar} userCap={6} />
		</AuthorizationContainer>
	);
}
