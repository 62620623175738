import { captureMessage } from "@sentry/browser";
import { arrayUnion, doc, getDoc, getDocs, limit, query, writeBatch } from "firebase/firestore";
import { useRecoilValue } from "recoil";

import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { nodeMetaService } from "@/domains/nodes/services";
import { defaultNote, NOTE_SCHEMA_VERSION } from "@/modules/note/noteFunctions";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";

import { db, fb } from "../../../shared/infra/init";
import { Node, ProjectBoard, PublicProfile } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { getPlanDetails } from "../../plans/selectors/getPlanDetails";
import { usePublicProfile } from "../../profile/hooks/usePublicProfile";

const cloneSuffix = "(Clone)";

const prepareBoard = (board: WithId<ProjectBoard>, profile: PublicProfile): ProjectBoard => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { id, data, ...boardData } = board;

	return {
		...boardData,
		access: {
			[profile.id]: {
				role: "owner",
				scope: "workspace",
				status: "active",
			},
		},
		data: {
			name: `${data.name} ${cloneSuffix}`,
			description: data.description || "",
		},
		createdBy: profile,
		updatedAt: fb.timestamp(),
		seenBy: {},
	};
};

export const prepareNote = async (nodeId: string, workspaceId: string) => {
	const noteQuerySnapshot = await getDocs(query(db.nodeNotes(nodeId), limit(1)));

	const nodeDoc = noteQuerySnapshot.docs[0]?.data();

	if (nodeDoc) {
		return nodeDoc;
	} else {
		captureMessage("Found node with no note", {
			extra: {
				nodeId,
			},
		});

		return {
			workspace: workspaceId,
			bodySchemaVersion: NOTE_SCHEMA_VERSION,
			body: defaultNote,
		};
	}
};

export const buildPrepareNode =
	(boardId: string, userId: string) =>
	(node: WithId<Node>, lookup: Record<string, string>): Node => {
		return {
			...node,
			board: boardId,
			createdAt: fb.timestamp(),
			createdBy: userId,
			subscribers: [userId],
			children: node.children.map((id) => lookup[id]),
		};
	};

export const useCloneBoard = () => {
	const nodes = useNodesStore((state) => state.nodes);
	const profile = usePublicProfile();
	const planDetails = useRecoilValue(getPlanDetails);
	const analyticsService = useAnalyticsService();
	const { getNodeById } = useNavigationService();

	const overNodeQuota = planDetails?.usage.nodes.isLocked;

	if (overNodeQuota || !profile) {
		return () => {};
	}

	return async function (project: WithId<ProjectBoard>) {
		const lookup: Record<string, string> = {};
		const batch = writeBatch(fb.firestore);

		const node = getNodeById(project.id, project.visibility);
		const folder = node?.parent?.data;

		if (!folder) {
			captureMessage("Attempted to clone board but unable to find folder");
			return;
		}

		const folderDoc = doc(db.projects, folder.id);
		const profileDoc = doc(db.users, profile.id);

		const newMetaDoc = doc(db.nodeMeta);
		const newProjectDoc = doc(db.projects);

		const prepareNode = buildPrepareNode(newProjectDoc.id, profile.id);

		const traverse = (node: WithId<Node>) => {
			if (!node) {
				return;
			}

			for (const child of node.children) {
				traverse(nodes[child]);
			}

			const nodeData = prepareNode(nodes[node.id], lookup);

			if (node.id === project.rootId) {
				nodeData.title = `${nodeData.title} ${cloneSuffix}`;
			}

			const newNodeDoc = doc(db.nodes);
			batch.set(newNodeDoc, nodeData);

			lookup[node.id] = newNodeDoc.id;
			analyticsService.nodeCreated({ boardId: node.board, workspaceId: node.workspace });
		};

		const rootNode = nodes[project.rootId];
		traverse(rootNode);

		const newBoard = {
			...prepareBoard(project, profile),
			rootId: lookup[project.rootId],
		};

		if (project.metaId) {
			const metaDoc = nodeMetaService.getDocument(project.metaId);
			const currentDoc = (await getDoc(metaDoc)).data();
			batch.set(newMetaDoc, currentDoc);

			newBoard.metaId = newMetaDoc.id;
		}

		batch.set(newProjectDoc, newBoard);
		batch.update(folderDoc, { childIds: arrayUnion(newProjectDoc.id) });

		// @ts-expect-error
		batch.update(profileDoc, {
			[`access.${newProjectDoc.id}`]: {
				role: "owner",
				scope: "workspace",
				status: "active",
			},
		});

		for (const nodeId in lookup) {
			const node = nodes[nodeId];
			const noteData = await prepareNote(nodeId, node.workspace);
			const noteDoc = doc(db.nodeNotes(lookup[nodeId]));
			batch.set(noteDoc, noteData);
		}

		batch.commit();
		analyticsService.buttonClick({ name: "clone-board", source: "board-menu" });

		return;
	};
};
