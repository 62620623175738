import { useEffect } from "react";

import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { atom, useRecoilState } from "recoil";

import { db, fb } from "../../../shared/infra/init";
import { onSnapshotError } from "../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../shared/utils/dataUtils";
import { Notification } from "../../../types/db";
import { WithId } from "../../../types/db/utils";

export const notificationsState = atom<WithId<Notification>[]>({
	key: "notificationsState",
	default: [],
});

export const useNotificationsListener = () => {
	const [, setNotifications] = useRecoilState(notificationsState);

	const userId = fb.auth.currentUser?.uid;

	useEffect(() => {
		if (userId) {
			const notificationQuery = query(
				db.notifications,
				where("userId", "==", userId),
				where("archivedAt", "==", null),
				orderBy("createdAt", "asc"),
			);

			return onSnapshot(
				notificationQuery,
				(snapshot) => {
					setNotifications(extractFromCollectionWithId(snapshot));
				},
				onSnapshotError("useNotificationsListener"),
			);
		}
	}, [setNotifications, userId]);
};
