import styled from "styled-components";

import { Node } from "@/domains/nodes/components/cards/Node";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";

type Props = {
	nodeId: string;
};

export const NodePreview: React.FC<Props> = ({ nodeId }) => {
	const nodeOrError = useNodesStore((state) => state.getNode(nodeId));

	if (nodeOrError.isFailure) {
		return null;
	}

	const node = nodeOrError.getValue();

	return (
		<Root>
			<Node node={node} />
		</Root>
	);
};

const Root = styled.div`
	position: fixed;
	pointer-events: none;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-shadow: var(--drop-shadow-l2);
`;
