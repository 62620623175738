import _ from "lodash";
import { matchSorter } from "match-sorter";
import { FiFolder, FiMoreHorizontal } from "react-icons/fi";
import { RiNodeTree } from "react-icons/ri";
import styled, { css } from "styled-components";

import { CreateProjectLocationFolders } from "@/domains/accounts/components/createProject/components/CreateProjectLocationFolders";
import { CreateProjectLocationSearchResults } from "@/domains/accounts/components/createProject/components/CreateProjectLocationSearchResults";
import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { NavigationTree, NavigationTreeNode } from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { Truncate } from "@/shared/system";
import { SelectDropdown, SelectDropdownOptionsFn } from "@/shared/system/SelectDropdown/SelectDropdown";
import { SystemFolderName } from "@/types/db";

const defaultOptionFilter = (items: any[], inputValue: string) => {
	return matchSorter(items, inputValue, {
		keys: ["data.name"],
		threshold: matchSorter.rankings.WORD_STARTS_WITH,
	});
};

export const projectLocationStateKey = "fileLocation";

const getFolder = (tree: NavigationTree, folderId: string): NavigationTreeNode | null => {
	const editableFolders = _.pick(tree, [SystemFolderName.workspace, SystemFolderName.private]);
	const iterableFolders = Object.values(editableFolders);

	let needle: NavigationTreeNode | null = null;

	iterableFolders.forEach((folder) => {
		const node = folder.find((node) => node.data.id === folderId);
		if (node) {
			needle = node;
		}
	});

	return needle;
};

interface Props {
	folderId: string;
}

export const CreateProjectLocation = ({ folderId }: Props) => {
	const { tree } = useNavigationService();
	const { projects } = useProjectsStore();

	const treeNode = getFolder(tree, folderId);

	if (treeNode === null) {
		return null;
	}

	const isFolder = treeNode.depth > 0;
	const isNestedFolder = treeNode.depth > 1;
	const [rootFolder] = treeNode.ancestors();

	const folderName = treeNode.data.data.name;
	const folderNameRoot = rootFolder ? rootFolder.data.data.name : folderName;

	const navigationTree = _.pick(tree, [SystemFolderName.workspace, SystemFolderName.private]);
	const navigationTreeArray = Object.values(navigationTree);

	const iterableProjects = [...projects.values()];

	const values = (
		<Path>
			<FolderSystem>
				<RiNodeTree />
				{folderNameRoot}
			</FolderSystem>
			{isFolder && (
				<>
					<Slash>/</Slash>
					{isNestedFolder && (
						<>
							<FolderPrevious>
								<FiMoreHorizontal />
							</FolderPrevious>
							<Slash>/</Slash>
						</>
					)}
					<FolderProject>
						<FiFolder /> <Truncate>{folderName}</Truncate>
					</FolderProject>
				</>
			)}
			<Kbd>l</Kbd>
		</Path>
	);

	const options: SelectDropdownOptionsFn = ({ close, inputEl, search }) => {
		const sortedOptions = search ? defaultOptionFilter(iterableProjects, search) : null;

		return (
			<OptionsRoot>
				{sortedOptions ? (
					<CreateProjectLocationSearchResults items={sortedOptions} search={search} inputEl={inputEl} close={close} />
				) : (
					<CreateProjectLocationFolders items={navigationTreeArray} inputEl={inputEl} close={close} />
				)}
			</OptionsRoot>
		);
	};

	return <SelectDropdown values={values} options={options} placeholderText="Select Location" shortcutCode="l" />;
};

const Path = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding: 0 10px;
	margin: -2px 0;
`;

const Slash = styled.div`
	margin: 0 8px;
	color: var(--secondary-color);
`;

const flex = css`
	display: flex;
	align-items: center;
`;

const FolderSystem = styled.div`
	${flex}
	text-transform: uppercase;
	letter-spacing: 0.04em;
	font-weight: 500;
	font-size: 12px;
	flex-shrink: 0;
	color: var(--color-text);

	svg {
		margin-right: 6px;
		font-size: 16px;
	}
`;

const FolderProject = styled.div`
	${flex}
	color: var(--color-text-heading);
	min-width: 0;
	padding-right: 24px;

	svg {
		margin-right: 6px;
	}
`;
const FolderPrevious = styled.div`
	${flex}
`;

const Kbd = styled.kbd`
	margin-left: auto;
	margin-right: 8px;
	color: var(--color-text-heading);
`;

const OptionsRoot = styled.div`
	padding: 12px 8px;
`;
