import { flextree } from "d3-flextree";

import { NodeModel, NodeStore } from "@/domains/nodes/models/nodesModel";
import { INodesStore } from "@/domains/nodes/zustand/nodesStore";
import { getDefaultNodeDimensions } from "@/domains/projects/components/treeView/config/reactFlow";
import { TreeModel, TreeNode, TreeNodeHeightMap, TreeNodeTypes } from "@/domains/projects/models/treeModel";
import { ITreeStore } from "@/domains/projects/zustand/treeStore";
import { Result } from "@/shared/utils/result";
import { ProjectBoard } from "@/types/db/boards";

type BuildTreeOptions = { withList: boolean };

interface IHierarchyUtils {
	buildTreeNode(args: {
		nodes: NodeStore;
		node: NodeModel;
		treeInfo: TreeNodeHeightMap;
		type?: TreeNodeTypes;
		options?: BuildTreeOptions;
	}): TreeNode;

	buildTree(args: {
		rootId: string;
		nodes: NodeStore;
		treeInfo: TreeNodeHeightMap;
		options?: BuildTreeOptions;
	}): Result<TreeModel>;

	buildFilteredTree(args: {
		tree: ITreeStore["tree"];
		nodes: INodesStore["nodes"];
		rootId: ProjectBoard["rootId"];
		collapsedNodeIds: INodesStore["collapsedNodeIds"];
		treeInfo: ITreeStore["treeInfo"];
	}): Result<TreeModel>;
}

const buildTreeNode: IHierarchyUtils["buildTreeNode"] = ({
	nodes,
	node,
	type = "source",
	treeInfo,
	options = { withList: false },
}) => {
	const children: TreeNode[] = [];

	const buildListItems = (node: any, children: string[]) => {
		const list: any = [];

		const [nextChildId, ...siblingIds] = children;

		if (!nextChildId) {
			list.push({
				id: `add-${node.id}`,
				type: "list",
				size: getDefaultNodeDimensions("listItem"),
				children: [],
				document: node,
			});
		}

		const child = nextChildId && nodes[nextChildId];

		if (child) {
			list.push({
				id: nextChildId,
				type: "listItem",
				size: getDefaultNodeDimensions("listItem"),
				children: buildListItems(node, siblingIds || []),
				document: child,
			});
		}

		return list;
	};

	if (options.withList && node.variant === "list") {
		const hasChildren = node.children.length > 0;
		hasChildren && children.push(...buildListItems(node, node.children));
	} else {
		node?.children.forEach((nodeId) => {
			const child = nodes[nodeId];

			if (!child || !child.children) {
				return;
			}
			children.push(buildTreeNode({ nodes, node: child, type: "card", options, treeInfo }));
		});
	}

	const tempHeight = treeInfo.get(node.id);

	return {
		id: node.id,
		type: node.variant === "list" ? "card" : type,
		size: getDefaultNodeDimensions(type, tempHeight),
		children,
		document: node,
	};
};

const buildTree: IHierarchyUtils["buildTree"] = ({ rootId, nodes, treeInfo, options }) => {
	const rootNode = nodes[rootId];

	if (!rootNode) {
		return Result.fail("Unable to find root node");
	}

	const nodesWithMappedChildren = buildTreeNode({
		nodes,
		node: rootNode,
		options,
		treeInfo,
	});

	const tree = flextree<TreeNode>({
		spacing: () => 48,
	});

	const nodeModel = tree.hierarchy(nodesWithMappedChildren);

	return Result.ok(tree(nodeModel));
};

const buildFilteredTree: IHierarchyUtils["buildFilteredTree"] = ({ tree, nodes, rootId, collapsedNodeIds, treeInfo }) => {
	const clonedNodes = { ...nodes };

	if (tree === null) {
		return Result.fail("Tree is not defined");
	}

	collapsedNodeIds.forEach((nodeId) => {
		const treeNode = tree.descendants().find((node) => {
			return node.data.id === nodeId;
		});

		const node = nodes[nodeId];

		if (!treeNode || !node) {
			return;
		}

		clonedNodes[nodeId] = {
			...node,
			children: [],
			variant: "tree",
		};

		treeNode.children?.forEach((child) => {
			delete clonedNodes[child.data.id];
		});
	});

	const filteredTree = buildTree({
		rootId,
		nodes: clonedNodes,
		treeInfo,
		options: { withList: true },
	});

	if (filteredTree.isFailure) {
		return Result.fail(filteredTree.getErrorValue());
	}

	return Result.ok(filteredTree.getValue());
};

export const HierarchyUtils: IHierarchyUtils = {
	buildTree,
	buildFilteredTree,
	buildTreeNode,
};
