import styled from "styled-components";

import { NodeCardBase } from "@/domains/nodes/components/cards/components/NodeCardBase";
import { NodeCardKeyResults } from "@/domains/nodes/components/cards/components/NodeCardKeyResults";
import { NodeActionsCollapsed } from "@/domains/nodes/components/cards/components/NodeRevealCollapsed";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

// @deprecated
export default function NodeCard({
	nodeId,
	cover,
	layout,
	icon,
	handleReveal,
	title,
	type,
	keyResults,
	nodeType,
	staticShadow = false,
	isActive,
	isFocused,
	isDragging,
	isHoverLeft,
	isHoverRight,
	isHoverCenter,
	isCollapsed,
}: any) {
	const results = keyResults || <NodeCardKeyResults node={{ id: nodeId } as NodeModel} />;

	const isIconLayout = layout === "icon" && icon;
	const isImageFullLayout = layout === "stacked" && cover?.src;

	const CardType = NodeCardBase;

	return (
		<Root isIconLayout={isIconLayout} isFocused={isFocused} staticShadow={staticShadow} className="nodeCard">
			<CardType
				$isDragging={isDragging}
				$isActive={isActive}
				$image={cover?.src}
				hoveringLeft={isHoverLeft}
				hoveringRight={isHoverRight}>
				{isHoverCenter && <NodeHover />}
				<CardTitle>
					{isIconLayout && <Icon>{icon.emoji}</Icon>}
					{isImageFullLayout && cover?.src && (
						<ImageFull>
							<div>
								<img src={cover.src} alt="cover" />
							</div>
						</ImageFull>
					)}
					<NodeType>{nodeType}</NodeType>
					{title}
					{results}
				</CardTitle>
				<NodeMeta>{type}</NodeMeta>
				{/* Displays 'has description' icon */}
				{/* <MetaIcons hasMeta={isMetaActive} className="hide-from-export">
					{meta}
				</MetaIcons> */}
			</CardType>
			{isCollapsed && (
				<>
					{!isIconLayout && <EmptyCard />}
					{!isIconLayout && <SmallEmptyCard />}
					<NodeActionsCollapsed childCount={4} onClick={handleReveal} />
				</>
			)}
		</Root>
	);
}

const CardTitle = styled.div`
	color: var(--color-text-heading);
`;

const Root = styled.div<{ isFocused: boolean; staticShadow: boolean; isIconLayout: boolean }>`
	position: relative;
	cursor: pointer;
	box-shadow: ${({ isIconLayout }) => (isIconLayout ? "none" : "var(--drop-shadow)")};

	> div {
		${({ staticShadow }) =>
			staticShadow &&
			`	box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.0197),
			0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.0283),
			0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.035),
			0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.0417),
			0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.0503),
			0px 100px 80px 0px rgba(0, 0, 0, 0.07);`};
	}

	& > :nth-child(2) {
		transform: ${({ isFocused }) => isFocused && "scale(0.83) translateY(2px)"};
	}

	& > :nth-child(3) {
		transform: ${({ isFocused }) => isFocused && "scale(0.78) translateY(4px)"};
	}
`;

const Icon = styled.div`
	font-size: 72px;
	line-height: 1;
	margin-bottom: 8px;
`;

const ImageFull = styled.div`
	width: calc(100% + 44px);
	margin: -20px -20px 20px;
	text-align: center;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	overflow: hidden;
	div {
		backdrop-filter: blur(8px);
	}
	img {
		max-width: 100%;
		margin: auto;
		display: block;
	}
`;

const NodeType = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 8px;
	letter-spacing: 0.025em;

	&:empty {
		display: none;
	}
`;

const NodeMeta = styled.div`
	display: flex;
	min-width: 0;
	margin-top: 10px;

	&:empty {
		display: none;
	}
`;

const EmptyCard = styled.div`
	position: absolute;
	bottom: -8px;
	width: 100%;
	height: 16px;
	background: var(--color-body);
	border-radius: 4px;
	border: 1px solid var(--color-overlay);
	transform: scale(0.83);
	transition: all 0.15s ease-in-out;
	transition-delay: 125ms;
	z-index: -2;
`;

const SmallEmptyCard = styled(EmptyCard)`
	bottom: -16px;
	transform: scale(0.78);
	z-index: -3;
`;

export const NodeHover = styled.div`
	position: absolute;
	top: -2px;
	left: 0;
	bottom: 0;
	right: 0;
	content: "";
	border: 2px solid var(--color-accent);
	border-radius: ${({ theme }) => theme.radii[2]}px;
	opacity: 0.66;
`;
