import { useEffect } from "react";

import { onSnapshot, orderBy, query } from "firebase/firestore";
import { atomFamily, useRecoilState } from "recoil";

import { db } from "../../../shared/infra/init";
import { onSnapshotError } from "../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../shared/utils/dataUtils";
import { Status } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export const userPresenceState = atomFamily<WithId<Status>[], string | undefined>({
	key: "userPresenceState",
	default: [],
});

export const useUserPresenceListener = () => {
	const workspace = useActiveWorkspace();
	const [, setUserPresence] = useRecoilState(userPresenceState(workspace?.id));

	const workspaceId = workspace?.id;

	useEffect(() => {
		if (workspaceId) {
			const statusQuery = query(db.status, orderBy(`access.${workspaceId}`));

			return onSnapshot(
				statusQuery,
				(snapshot) => {
					setUserPresence(extractFromCollectionWithId<Status>(snapshot));
				},
				onSnapshotError("useUserPresenceListener"),
			);
		}
	}, [setUserPresence, workspaceId]);
};
