import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { EmbedProjectTheme } from "@/modules/board/share/shareEmbed/components/EmbedProjectTheme";
import { EmbedPublicAccess } from "@/modules/board/share/shareEmbed/components/EmbedPublicAccess";
import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { Button, CopyToClipboard } from "@/shared/system";
import { WithId } from "@/types/db";

type Props = {
	project: WithId<ProjectDtoBoard>;
	modalClose: VoidFunction;
};

export const ShareEmbed: React.FC<Props> = ({ project, modalClose }) => {
	const profile = usePublicProfile();
	const embedCode = `<iframe src="${window.location.href}" width="100%" height="100%" frameBorder="0" />`;
	return (
		<Root>
			<div>Enable public access to embed a copy of your project anywhere online.</div>
			<Settings>
				<EmbedPublicAccess project={project} profile={profile} />
				<EmbedProjectTheme projectId={project.id} />
			</Settings>
			<EmbedCode>
				<pre>{embedCode}</pre>
			</EmbedCode>

			<Actions>
				<CopyToClipboard textType="embed code" textToCopy={embedCode} />
				<Button onClick={modalClose} rounded>
					Done
				</Button>
			</Actions>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Settings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0 0 12px;
`;

const EmbedCode = styled.div`
	padding: 16px;
	background: var(--color-overlay);
	border-radius: 8px;

	> pre {
		margin: 0;
		white-space: pre-wrap;
		word-wrap: break-word;
	}
`;

const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
`;
