import styled from "styled-components";

import { AuthorizationContainer } from "@/shared/core/Authorization";

type Props = {
	icon: React.ReactNode;
	lastChild?: boolean;
};

export const ActivityItemWrapper: React.FC<Props> = ({ icon, lastChild, children }) => {
	return (
		<AuthorizationContainer>
			<Root>
				<Icon>
					{icon}
					{!lastChild && <TimeLine />}
				</Icon>
				<Activity>{children}</Activity>
			</Root>
		</AuthorizationContainer>
	);
};

const TimeLine = styled.div`
	width: 1px;
	background-color: var(--color-border);
	flex-grow: 1;
	margin-top: 8px;
`;

const Root = styled.div`
	display: flex;

	.grouped &:not(:last-child) {
		div {
			margin-bottom: 0;
		}

		${TimeLine} {
			display: none;
		}
	}
`;

const Icon = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 24px;
	margin-right: 16px;
	align-items: center;

	/* Removes margin from avatar */
	> div {
		margin-right: 0;
	}
`;

const Activity = styled.div`
	flex-grow: 1;
	margin-left: 12px;
`;
