import React from "react";

import styled from "styled-components";

import { NodeMetaPersonValueUnassigned } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPersonValueUnassigned";

import { useBoardMembers } from "../../../../../../../modules/members/hooks/useBoardMembers";
import { Avatar } from "../../../../../../../shared/system";

interface Props {
	userId: string;
}

export const NodeMetaPersonValue = ({ userId }: Props) => {
	const members = useBoardMembers();

	const user = members[userId];

	if (!user) {
		return <NodeMetaPersonValueUnassigned />;
	}

	return (
		<Root>
			<Avatar size={18} user={{ ...user, state: null }} showStatusIndicator={false} />
			{user.username && <Username>{user.username}</Username>}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
`;

const Username = styled.div`
	margin-left: 4px;
`;
