import { RefObject, useCallback, useState } from "react";

import { atom, useRecoilState } from "recoil";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { getScaledHeight } from "../utils/getScaledHeight";

const coverImageState = atom<boolean>({
	key: "coverImageState",
	default: false,
});

export const useRepositionCoverImage = (node: NodeModel) => {
	const { id: nodeId, cover } = node;
	const [isRepositioning, setRepositioning] = useRecoilState(coverImageState);

	const initialOffset = cover?.type === "image" ? cover.offsetY : 0;
	const [movementDelta, setDelta] = useState(initialOffset);

	const nodesService = useNodesService();

	const calculatePosition = useCallback(
		(imageHeight: number) => (event: MouseEvent) => {
			event.preventDefault();

			if (event.buttons === 1) {
				const min = 0; //image height
				const max = imageHeight;

				const positiveMovement = event.movementY * 1.75 * -1;

				setDelta((current) => {
					const currentPx = (current / 100) * imageHeight;
					const newPx = Math.min(Math.max(currentPx + positiveMovement, min), max);
					const newPercent = (newPx / imageHeight) * 100;
					return newPercent;
				});
			}
		},
		[],
	);

	const bindRepositionEvents = useCallback(
		(el: RefObject<HTMLImageElement>): VoidFunction => {
			if (el.current !== null) {
				const scaledHeight = getScaledHeight(el.current);
				const repositionEvent = calculatePosition(scaledHeight);

				document.addEventListener("mousemove", repositionEvent);
				return () => document.removeEventListener("mousemove", repositionEvent);
			}

			return () => {};
		},
		[calculatePosition],
	);

	return {
		isRepositioning,
		bindRepositionEvents,
		backgroundPositionY: movementDelta,
		start: () => setRepositioning(true),
		save: () => {
			if (cover?.type === "image") {
				nodesService.update(nodeId, {
					cover: {
						...cover,
						offsetY: movementDelta,
					},
				});
			}
			setRepositioning(false);
		},
		cancel: () => {
			setDelta(initialOffset);
			setRepositioning(false);
		},
	};
};
