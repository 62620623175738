import { RefObject, useCallback } from "react";

import styled from "styled-components";

import { NodeMetaPersonValue } from "@/domains/nodes/components/details/meta/types/person/NodeMetaPersonValue";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useBoardMembers } from "@/modules/members/hooks/useBoardMembers";
import { SelectOptions } from "@/shared/system/SelectDropdown/SelectOptions";

type Props = {
	nodeId: string;
	metaId: string;
	selected: string[];
	search: string;
	inputEl: RefObject<HTMLInputElement>;
	close: VoidFunction;
};

export const NodeMetaPersonOptions = ({ nodeId, metaId, selected, search, inputEl, close }: Props) => {
	const members = useBoardMembers();
	const nodesService = useNodesService();

	const membersArray = Object.values(members);

	const handleAddCollaborator = useCallback(
		(collaboratorIds: string[]) => {
			nodesService.updateMeta(nodeId, metaId, collaboratorIds);
			close();
		},
		[close, metaId, nodeId, nodesService],
	);

	const handleRemoveCollaborator = useCallback(() => {
		nodesService.updateMeta(nodeId, metaId, []);
		close();
	}, [close, metaId, nodeId, nodesService]);

	const options = membersArray.map(({ id: userId, username }) => {
		const isSelected = selected.includes(userId);

		return {
			metaId: metaId,
			valueId: userId,
			match: username,
			editable: false,
			type: "single",
			selected: isSelected,
			onClick: () => handleAddCollaborator([userId]),
			element: <NodeMetaPersonValue userId={userId} />,
		};
	});

	options.unshift({
		metaId: "unassigned",
		valueId: "unassigned",
		match: "unassigned",
		editable: false,
		type: "single",
		selected: selected.length === 0,
		onClick: handleRemoveCollaborator,
		element: <NodeMetaPersonValue userId="" />,
	});

	return (
		<SelectOptions options={options} search={search} inputEl={inputEl}>
			{(option) => {
				return (
					<Option onClick={option.onClick}>
						<NodeMetaPersonValue userId={option.valueId} />
					</Option>
				);
			}}
		</SelectOptions>
	);
};

const Option = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	min-width: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 4px 6px;
`;
