import produce, { enableMapSet } from "immer";
import { create } from "zustand";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { TreeModel, TreeNodeHeightMap } from "@/domains/projects/models/treeModel";

enableMapSet();

export interface ITreeStore {
	id: string | null;
	tree: TreeModel | null;
	filteredTree: TreeModel | null;
	treeInfo: TreeNodeHeightMap;
	setTree(id: string, tree: TreeModel): void;
	setFilteredTree(tree: TreeModel): void;
	setNodeHeight(nodeId: NodeModel["id"], height: number): void;
}

export const useTreeStore = create<ITreeStore>((set) => ({
	id: null,
	tree: null,
	filteredTree: null,
	treeInfo: new Map(),
	setTree: (id, tree) => set({ id, tree }),
	setFilteredTree: (filteredTree) => set({ filteredTree }),
	setNodeHeight(nodeId, height) {
		set((state) => ({
			treeInfo: produce(state.treeInfo, (draft) => {
				draft.set(nodeId, height);
			}),
		}));
	},
}));
