import { z } from "zod";

import { nodeMetaTypes } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";

export const useNodeMetaValues = (node: NodeModel) => {
	const project = useGetProject();
	const { meta } = node;
	/**
	 * This should reference a 'projectStore' rather than calling recoil directly
	 * logic from the selector could then be moved into the component possibly use
	 * uniq from lodash
	 
		const projectStore = useProjectStore()

		const projectOrError = projectStore.getProject(node.board);

		if(projectOrError.isFailure) {
			return null;
		}

		const hiddenMetaProject = projectOrError.getValue().meta.hiddenNodeMetaIds;
		const hiddenMetaNode = node.metaHidden || []

		const hiddenMeta = uniq([...hiddenMetaProject, ...hiddenMetaNode]);
		
	 *
	 */
	const hiddenMeta = NodeMetaUtil.getHiddenMetaIds(node, project);
	const nodeMetaDefinition = useNodeMetaStore(({ meta }) => meta);

	if (!meta) {
		return null;
	}

	const metaValues = [...nodeMetaDefinition.values()]
		.map(({ id }) => {
			return {
				value: meta[id] || "",
				definition: nodeMetaDefinition.get(id) as z.infer<typeof nodeMetaTypes>,
			};
		})
		.filter(({ definition }) => !hiddenMeta.includes(definition.id));

	return metaValues;
};
