import { useMemo, useState } from "react";

import {
	closestCenter,
	defaultDropAnimation,
	DndContext,
	DragEndEvent,
	DragMoveEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	DropAnimation,
	MeasuringStrategy,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import _ from "lodash";
import { createPortal } from "react-dom";

import { SidebarNavigationFolderContainerSortable } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationFolderContainerSortable";
import { getNextParent } from "@/domains/accounts/components/sidebarNavigation/utils/getProjectedDepth";
import { NavigationTreeNode } from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";
import { ProjectModelFolder } from "@/domains/projects/models/projectsModel";
import { useCollapsedState } from "@/modules/page/hooks/useCollapseState";

export const SIDEBAR_NAVIGATION_INDENTATION_WIDTH = 16;

const measuring = {
	droppable: {
		strategy: MeasuringStrategy.Always,
	},
};

const dropAnimationConfig: DropAnimation = {
	keyframes({ transform }) {
		return [
			{ opacity: 1, transform: CSS.Transform.toString(transform.initial) },
			{
				opacity: 0,
				transform: CSS.Transform.toString({
					...transform.final,
					x: transform.final.x + 5,
					y: transform.final.y + 5,
				}),
			},
		];
	},
	easing: "ease-out",
	sideEffects({ active }) {
		active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
			duration: defaultDropAnimation.duration,
			easing: defaultDropAnimation.easing,
		});
	},
};

const flattenTreePreOrder = (tree: NavigationTreeNode): NavigationTreeNode[] => {
	const flattenedTree: NavigationTreeNode[] = [];
	tree.eachBefore((node) => flattenedTree.push(node));
	flattenedTree.shift();

	return flattenedTree;
};

const getIndexAndElement = (
	id: UniqueIdentifier | undefined,
	flattenedTree: NavigationTreeNode[],
): [number, NavigationTreeNode] | [] => {
	const index = flattenedTree.findIndex((node) => node.data.id === id);
	const node = flattenedTree[index];

	if (node) {
		return [index, node];
	}

	return [];
};

export interface Props {
	tree: NavigationTreeNode;
	onDrop: (args: {
		previous: ProjectModelFolder;
		next: ProjectModelFolder;
		active: NavigationTreeNode;
		all: NavigationTreeNode[];
	}) => void;
}

export const SidebarNavigationFolderContainer = ({ tree, onDrop }: Props) => {
	const [collapsedNodeIds, toggleCollapsed] = useCollapsedState(tree.data.id);
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
	const [offsetLeft, setOffsetLeft] = useState(0);
	const [activeDepth, setActiveDepth] = useState(0);

	const flattenedTree = flattenTreePreOrder(tree);

	const estimatedPosition = getNextParent(
		flattenedTree,
		tree,
		activeId,
		overId,
		offsetLeft,
		SIDEBAR_NAVIGATION_INDENTATION_WIDTH,
	);

	const filteredNodes = useMemo(() => {
		return flattenedTree.filter((node) => {
			const [, ...ancestorIds] = node.ancestors().map((n) => n.data.id);
			const collapsedAncestors = _.intersection(ancestorIds, collapsedNodeIds);

			const isCollapsed = collapsedAncestors.length !== 0;
			const isActiveChild = ancestorIds.some((id) => id === activeId);

			return !isCollapsed && !isActiveChild;
		});
	}, [activeId, collapsedNodeIds, flattenedTree]);

	const handleDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
		setActiveId(activeId);
		setOverId(activeId);

		document.body.style.setProperty("cursor", "grabbing");
	};

	const handleDragMove = ({ delta }: DragMoveEvent) => {
		setOffsetLeft(delta.x);
	};

	const handleDragOver = ({ over }: DragOverEvent) => {
		setOverId(over?.id ?? null);
	};

	const handleDragEnd = ({ active, over }: DragEndEvent) => {
		resetState();

		if (!over || !estimatedPosition) {
			return;
		}

		const clonedItems: NavigationTreeNode[] = flattenedTree.slice();

		const [overIndex] = getIndexAndElement(over.id, clonedItems);
		const [activeIndex, activeNode] = getIndexAndElement(active.id, clonedItems);
		const [, parentNew] = getIndexAndElement(estimatedPosition.parentId, clonedItems);

		const parentOld = activeNode?.parent;
		const parentNext = parentNew || tree;

		if (activeIndex === undefined || overIndex === undefined || !parentOld) {
			return;
		}

		const oldParent = parentOld.data as ProjectModelFolder;
		const nextParent = parentNext.data as ProjectModelFolder;

		//@ts-expect-error
		clonedItems[activeIndex] = {
			...activeNode,
			depth: parentNext.depth + 1,
			parent: parentNext,
		};

		const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

		onDrop({ previous: oldParent, next: nextParent, active: activeNode, all: sortedItems });
	};

	const handleDragCancel = () => {
		resetState();
	};

	const resetState = () => {
		setOverId(null);
		setActiveId(null);
		setOffsetLeft(0);
		setActiveDepth(0);

		document.body.style.setProperty("cursor", "");
	};

	const pointerSensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 2,
		},
	});

	const sensors = useSensors(pointerSensor);

	const activeItem = filteredNodes.find((node) => node.data.id === activeId);
	const getIsCollapsed = (id: string) => collapsedNodeIds.includes(id);
	const getDepth = (id: string, depth: number) => (id === activeId ? estimatedPosition?.depth || 0 : depth - 1);
	const sortedIds = useMemo(() => filteredNodes.map((node) => node.data.id), [filteredNodes]);

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			measuring={measuring}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}>
			<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
				{filteredNodes.map(({ depth, data }) => {
					const id = data.id;
					const sidebarDepth = getDepth(id, depth);
					const collapsed = getIsCollapsed(id);

					return (
						<SidebarNavigationFolderContainerSortable
							key={id}
							id={id}
							item={data}
							depth={sidebarDepth}
							collapsed={collapsed}
							onCollapse={toggleCollapsed}
						/>
					);
				})}
				{createPortal(
					<DragOverlay dropAnimation={dropAnimationConfig}>
						{activeItem ? (
							<SidebarNavigationFolderContainerSortable
								id={activeItem.data.id}
								depth={activeDepth}
								item={activeItem.data}
								clone
							/>
						) : null}
					</DragOverlay>,
					document.body,
				)}
			</SortableContext>
		</DndContext>
	);
};
