import { useMemo } from "react";

import { TreeDepthMap, TreeModel } from "@/domains/projects/models/treeModel";
import { useTreeStore } from "@/domains/projects/zustand/treeStore";
import { Result } from "@/shared/utils/result";

export const useTreeService = () => {
	const tree = useTreeStore((state) => state.tree);
	const filteredTree = useTreeStore((state) => state.filteredTree);

	return useMemo(
		() => ({
			getTree({ filtered } = { filtered: false }): Result<TreeModel> {
				if (!tree || !filteredTree) {
					return Result.fail("Unable to get tree");
				}

				if (filtered) {
					return Result.ok(filteredTree);
				}

				return Result.ok(tree);
			},

			getFilteredTree(): Result<TreeModel> {
				return this.getTree({ filtered: true });
			},

			getNode(nodeId: string, { filtered } = { filtered: false }): Result<TreeModel> {
				const treeOrError = this.getTree({ filtered });

				if (treeOrError.isFailure) {
					return Result.fail(treeOrError.getErrorValue());
				}

				const tree = treeOrError.getValue();

				const node = tree.descendants().find((node) => node.data.id === nodeId);

				if (!node) {
					return Result.fail("Unable to find node");
				}

				return Result.ok(node);
			},

			getNodes({ filtered } = { filtered: false }): Result<TreeModel[]> {
				const treeOrError = this.getTree({ filtered });

				if (treeOrError.isFailure) {
					return Result.fail(treeOrError.getErrorValue());
				}

				const tree = treeOrError.getValue();

				return Result.ok(tree.descendants());
			},

			getAncestors(nodeId: string): Result<TreeModel[]> {
				const nodeOrError = this.getNode(nodeId);

				if (nodeOrError.isFailure) {
					return Result.fail(nodeOrError.getErrorValue());
				}

				const node = nodeOrError.getValue();

				return Result.ok(node.ancestors());
			},

			getIsAncestor(haystackId: string, needleId: string): boolean {
				const ancestorsOrError = this.getAncestors(haystackId);

				if (ancestorsOrError.isFailure) {
					return false;
				}

				const ancestors = ancestorsOrError.getValue();

				return !!ancestors.find((TreeModel) => TreeModel.data.id === needleId);
			},

			getHighlightedPaths(nodeIds: string[]) {
				return nodeIds.reduce((acc, nodeId) => {
					const ancestorsOrError = this.getAncestors(nodeId);

					if (ancestorsOrError.isFailure) {
						return acc;
					}

					const ancestors = ancestorsOrError.getValue();

					ancestors.forEach((ancestor: any) => acc.add(ancestor.data.id));

					return acc;
				}, new Set<string>());
			},

			getRootNode(): Result<TreeModel> {
				const treeOrError = this.getTree();

				if (treeOrError.isFailure) {
					return Result.fail(treeOrError.getErrorValue());
				}

				const tree = treeOrError.getValue();
				const root = tree.descendants()[0];

				if (!root) {
					return Result.fail("Unable to get root node");
				}

				return Result.ok(root);
			},

			getParent(nodeId: string, { filtered } = { filtered: false }): Result<TreeModel> {
				const nodeOrError = this.getNode(nodeId, { filtered });

				if (nodeOrError.isFailure) {
					return Result.fail(nodeOrError.getErrorValue());
				}

				const node = nodeOrError.getValue();
				const parent = node.parent;

				if (parent === null) {
					return Result.fail("Unable to get parent for root node");
				}

				return Result.ok(parent);
			},

			getChildren(nodeId: string): Result<TreeModel[]> {
				const nodeOrError = this.getNode(nodeId);

				if (nodeOrError.isFailure) {
					return Result.fail(nodeOrError.getErrorValue());
				}

				const children = nodeOrError.getValue().children;

				if (!children) {
					return Result.fail("Unable to get children for node");
				}

				return Result.ok(children);
			},

			getHasChildren(nodeId: string): boolean {
				const childrenOrError = this.getChildren(nodeId);

				if (childrenOrError.isFailure) {
					return false;
				}

				const children = childrenOrError.getValue();

				return children.length > 0;
			},

			getDescendantCount(nodeId: string): number {
				const nodeOrError = this.getNode(nodeId);

				if (nodeOrError.isFailure) {
					return 0;
				}

				const node = nodeOrError.getValue();
				return node.descendants().length - 1;
			},

			getDescendants(nodeId: string): TreeModel[] {
				const nodeOrError = this.getNode(nodeId);

				if (nodeOrError.isFailure) {
					return [];
				}

				const node = nodeOrError.getValue();
				const descendants = node.descendants();
				descendants.shift();

				return descendants;
			},

			getSiblings(nodeId: string): Result<TreeModel[]> {
				const parentOrError = this.getParent(nodeId);

				if (parentOrError.isFailure) {
					return Result.fail(parentOrError.getErrorValue());
				}

				const parent = parentOrError.getValue();
				const siblings = parent.children;

				if (!siblings) {
					return Result.fail("Unable to get siblings for node");
				}

				return Result.ok(siblings);
			},

			getDepthMap(): Result<TreeDepthMap> {
				const treeOrError = this.getTree();

				if (treeOrError.isFailure) {
					return Result.fail(treeOrError.getErrorValue());
				}

				const tree = treeOrError.getValue();
				const nodes = tree.descendants();

				const depthMap = nodes.reduce<TreeDepthMap>((carry, node) => {
					return {
						...carry,
						[node.data.id]: { depth: node.depth, hasChildren: node.data.children.length > 0 },
					};
				}, {});

				return Result.ok(depthMap);
			},

			getHeight(): Result<TreeModel["height"]> {
				const treeOrError = this.getTree();

				if (treeOrError.isFailure) {
					return Result.fail(treeOrError.getErrorValue());
				}

				const tree = treeOrError.getValue();

				return Result.ok(tree.height);
			},
		}),
		[filteredTree, tree],
	);
};
