import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import styled from "styled-components";

import { InputDate } from "@/domains/nodes/components/keyResults/components/forms/components/InputDate";
import { InputGroupGoal } from "@/domains/nodes/components/keyResults/components/forms/components/InputGroupGoal";
import { InputTitle } from "@/domains/nodes/components/keyResults/components/forms/components/InputTitle";
import { KeyResultsGoalType } from "@/domains/nodes/components/keyResults/dtos/keyResultsDto";
import { Button, utility } from "@/shared/system";
import { ModalActionGroup } from "@/shared/system/Modal/Modal";

export const formValues = {
	title: "title",
	goalType: "goalType",
	targetValue: "targetValue",
	startingValue: "startingValue",
	startDate: "startDate",
	endDate: "endDate",
};

export type KeyResultsFormFields = {
	title: string;
	goalType: KeyResultsGoalType;
	targetValue: string;
	startingValue: string;
	startDate: string;
	endDate: string;
};

export interface IKeyResultsInput {
	register: UseFormRegister<KeyResultsFormFields>;
	errors: FieldErrors;
}

type Props = {
	defaultValues: any;
	cta?: string;
	onSubmit: (data: KeyResultsFormFields) => void;
	onCancel?: VoidFunction;
};

export const KeyResultForm: React.FC<Props> = ({ cta = "Add Result", defaultValues = {}, onSubmit, onCancel }) => {
	const { register, handleSubmit, watch, formState } = useForm<KeyResultsFormFields>({
		defaultValues,
	});
	const { errors } = formState;

	const goalType = watch("goalType");
	const startDate = watch("startDate");
	const targetValue = watch("targetValue");

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputTitle register={register} errors={errors} />
			<InputGroupGoal register={register} errors={errors} goalType={goalType} targetValue={targetValue} />
			<InlineInputs>
				<InputDate label="Starting Date" name="startDate" register={register} errors={errors} required />
				<InputDate label="End Date" name="endDate" min={startDate} register={register} errors={errors} required />
			</InlineInputs>
			<ModalActionGroup>
				<Button.Secondary onClick={onCancel}>Cancel</Button.Secondary>
				<Button type="submit" disabled={formState.isSubmitting}>
					{cta}
				</Button>
			</ModalActionGroup>
		</form>
	);
};

const InlineInputs = styled.div`
	display: flex;
	margin-bottom: 8px;

	> :not(:last-child) {
		margin-right: ${utility.space(3)}px;
	}
`;
