import { Item } from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";

export const dropdownLinkStyle = css`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 4px 6px;
	cursor: pointer;
	border-radius: 4px;
	width: 100%;
	color: var(--color-text-secondary);

	svg {
		margin-right: ${({ theme }) => theme.space[2]}px;
	}

	&:hover {
		background: var(--color-hover);
	}
`;

export const DropdownMenuLink = styled(Item)`
	${dropdownLinkStyle};
`;

export const PopoverMenuLink = styled.div`
	${dropdownLinkStyle};
`;
