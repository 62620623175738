import { FieldValue } from "firebase/firestore";

import { addProjectToFolder } from "@/modules/board/commands/addProjectToFolder";
import { removeProjectFromFolder } from "@/modules/board/commands/removeProjectFromFolder";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { useActionHistory } from "../../../shared/core/hooks/useActionHistory";
import { updateMetaVisibilityBoard } from "../commands/updateMetaVisibilityBoard";
import { updateNoteLayoutOptions } from "../commands/updateNoteLayoutOptions";

export const useBoardActions = () => {
	const { execute } = useActionHistory();
	const board = useGetProject();

	return {
		addProjectToFolder: (folderId: string, projectId: string) => {
			execute(addProjectToFolder({ folderId, projectId }));
		},
		removeProjectFromFolder: (folderId: string, projectId: string) => {
			execute(removeProjectFromFolder({ folderId, projectId }));
		},
		updateMetaVisibilityBoard: (boardId: string, metaId: FieldValue) => {
			const oldValue = board?.meta?.hiddenNodeMetaIds || [];
			execute(updateMetaVisibilityBoard({ boardId, newValue: metaId, oldValue }));
		},
		updateDetailsSectionVisibility: (boardId: string, sectionKey: FieldValue) => {
			const oldValue = board?.meta?.hiddenDetailSectionKeys || [];
			execute(updateNoteLayoutOptions({ boardId, newValue: sectionKey, oldValue }));
		},
	};
};
