import { useCallback, useRef } from "react";

import {
	DndContext,
	DragEndEvent,
	DragMoveEvent,
	DragOverlay,
	MouseSensor,
	rectIntersection,
	TouchSensor,
	useDndContext,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { NodePreview } from "@/modules/roadmap/components/NodePreview";
import { Result } from "@/shared/utils/result";

const sensorConfig = {
	activationConstraint: {
		distance: 5,
	},
};

const DraggableOverlay = () => {
	const { active } = useDndContext();

	const draggingType = active?.data.current?.type;

	if (!active || draggingType === "listItem") {
		return null;
	}

	const nodeId: string = active?.id as any;

	return <DragOverlay>{active && <NodePreview nodeId={nodeId} />}</DragOverlay>;
};

const modifiers = [restrictToFirstScrollableAncestor];

export const BoardDnDContext: React.FC = ({ children }) => {
	const treeService = useTreeService();
	const nodesService = useNodesService();

	const mouseSensor = useSensor(MouseSensor, sensorConfig);
	const touchSensor = useSensor(TouchSensor, sensorConfig);
	const sensors = useSensors(mouseSensor, touchSensor);

	const handleDragEnd = useCallback(
		({ active, over }: DragEndEvent) => {
			const activeId: string = active.id as any;
			const overId: string = over?.id as any;

			if (activeId === overId) {
				return;
			}

			const overParentOrError = treeService.getParent(overId);
			const activeParentOrError = treeService.getParent(activeId);
			const isAncestor = treeService.getIsAncestor(overId, activeId);

			const result = Result.combine([overParentOrError, activeParentOrError]);

			if (result.isFailure) {
				return;
			}

			if (isAncestor) {
				return;
			}

			const newParent = overParentOrError.getValue().data.document;
			const oldParent = activeParentOrError.getValue().data.document;

			const overIndex = newParent.children.findIndex((id) => id === overId);

			const newPosition = overIndex >= 0 ? overIndex : 0;

			nodesService.reorder(activeId, oldParent, newParent, newPosition);
		},
		[nodesService, treeService],
	);

	const timer = useRef<any>();

	const handleDragOver = useCallback(
		(event: DragMoveEvent) => {
			if (timer.current) {
				clearTimeout(timer.current);
				timer.current = undefined;
			}

			const activeId: string = event.active.id as any;
			const overId: string = event.over?.id as any;

			if (activeId === overId) {
				return;
			}

			const overParentOrError = treeService.getParent(overId);
			const activeParentOrError = treeService.getParent(activeId);
			const isAncestor = treeService.getIsAncestor(overId, activeId);
			const result = Result.combine([overParentOrError, activeParentOrError]);

			if (result.isFailure) {
				return;
			}

			if (isAncestor) {
				return;
			}

			const newParent = overParentOrError.getValue().data.document;
			const oldParent = activeParentOrError.getValue().data.document;
			const overIndex = newParent.children.findIndex((id) => id === overId);

			const newPosition = Math.max(overIndex, 0);

			timer.current = setTimeout(() => {
				nodesService.localReorder(activeId, oldParent, newParent, newPosition);
				timer.current = null;
			}, 300);
		},
		[nodesService, treeService],
	);

	return (
		<DndContext
			sensors={sensors}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			collisionDetection={rectIntersection}
			modifiers={modifiers}>
			{children}
			<DraggableOverlay />
		</DndContext>
	);
};
