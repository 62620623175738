import { Timestamp } from "firebase/firestore";

import { PublicProfile } from "./boards";

export enum ScheduledTaskType {
	nodeDate = "nodeDate",
}

type ScheduledTask<T, D> = {
	workspaceId: string;
	boardId: string;
	metaId: string;
	nodeId: string;
	version: "1";
	notifyAt: Timestamp | null;
	user: PublicProfile;
	processedAt: Timestamp | null;
	type: T;
	data: D;
};

export type TaskNodeDateData = {
	label: string;
	reminderOffset: number;
	format: string;
	startAt: string;
	endAt: string;
};

export type TaskNodeDate = ScheduledTask<ScheduledTaskType.nodeDate, TaskNodeDateData>;

export type ScheduledTasks = TaskNodeDate;
