import { useEffect, useRef } from "react";

import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";

import { Button } from "../../../shared/system";
import { CoverImageChange } from "./components/CoverImageChange";
import { useRepositionCoverImage } from "./hooks/useRepositionCoverImage";

type Props = {
	node: NodeModel;
};

export const CoverImage = ({ node }: Props) => {
	const coverImageEl = useRef<HTMLImageElement>(null);
	const { isRepositioning, backgroundPositionY, bindRepositionEvents, save, cancel } = useRepositionCoverImage(node);

	useEffect(() => {
		if (isRepositioning) {
			const removeEventListener = bindRepositionEvents(coverImageEl);
			return removeEventListener;
		}
	}, [bindRepositionEvents, isRepositioning]);

	/**
	 * As we are using global state to manage the repositioning
	 * if the user closes the modal without committing the change
	 * we want to reset the state when the component unmounts
	 */
	useEffect(() => {
		return () => {
			cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { cover } = node;

	if (cover?.type !== "image") {
		return null;
	}

	return (
		<Root>
			<Overlay />
			<img alt="cover" src={cover.src} style={{ objectPosition: `center ${backgroundPositionY}%` }} ref={coverImageEl} />
			{isRepositioning ? (
				<Repositioning>
					<div>Drag Image to reposition</div>
					<div>
						<Button.Text onClick={cancel} small rounded px="16px" py="6px">
							Cancel
						</Button.Text>
						<Button onClick={save} small rounded px="16px" py="6px">
							Save
						</Button>
					</div>
				</Repositioning>
			) : (
				<Actions>
					<ActionGroup>
						<CoverImageChange node={node} />
					</ActionGroup>
				</Actions>
			)}
		</Root>
	);
};

const Overlay = styled.div`
	background: linear-gradient(#00000080 -200%, transparent);
	width: 100%;
	height: 100px;
	position: absolute;
`;

const Actions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: flex-end;
	justify-content: end;
	padding: 0 40px 20px;
	height: 100%;
	margin: auto;
	opacity: 0;
	transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
`;

const Repositioning = styled.div`
	position: absolute;
	margin: auto;
	display: flex;
	align-items: center;
	font-weight: 500;
	justify-content: space-between;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 12px 20px;
	background-color: rgba(0, 0, 0, 0.66);
	color: var(--color-text-heading);
`;

const ActionGroup = styled.div`
	background: var(--color-body);
	border-radius: 4px;
`;

const Root = styled.div`
	position: relative;
	width: 100%;
	height: 33vh;
	margin-top: -52px;
	top: 0;
	left: 0;

	img {
		display: block;
		object-fit: cover;
		border-radius: 0px;
		width: 100%;
		height: 100%;
		opacity: 1;
		object-position: center 12.5%;
		border-radius: 8px;
	}

	:hover {
		${Actions} {
			opacity: 1;
		}
	}
`;
