import { xor } from "lodash";
import { useRecoilState } from "recoil";

import { collapsedFolderState } from "@/modules/page/selectors/collapsedFolderState";
import { ProjectDto, WithId } from "@/types/db";

export const useCollapsedState = (key: string = ""): [string[], (project: WithId<ProjectDto>) => void] => {
	const [collapsedNodes, setCollapsed] = useRecoilState(collapsedFolderState(key));

	return [
		collapsedNodes,
		(project) => {
			if (project.type === "folder") {
				setCollapsed((items) => {
					return xor(items, [project.id]);
				});
			}
		},
	];
};
