import React, {
	Children,
	cloneElement,
	forwardRef,
	isValidElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import {
	Alignment,
	autoUpdate,
	flip,
	FloatingNode,
	FloatingPortal,
	FloatingTree,
	offset,
	Placement,
	safePolygon,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFloatingNodeId,
	useFloatingParentNodeId,
	useFloatingTree,
	useHover,
	useInteractions,
	useListNavigation,
	useRole,
	useTypeahead,
} from "@floating-ui/react-dom-interactions";
import { VscTriangleRight } from "react-icons/vsc";

import { DropdownMenuButton } from "@/shared/system/Dropdown";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";

interface Props {
	icon?: any;
	label?: string | any;
	placement?: Placement;
	alignment?: Alignment;
	onContextOpen?: (e: MouseEvent) => boolean; // Determines whether the context menu will open
	triggerEl?: React.RefObject<HTMLDivElement>; // Required only for context menus to limit the scope of the event listener
	children?: React.ReactNode;
	variant?: "menu-button" | "context-menu";
	testId?: string;
}

export const MenuComponent = forwardRef<any, Props & React.HTMLProps<HTMLButtonElement>>(
	(
		{
			children,
			icon,
			label,
			placement = "bottom-start",
			testId,
			triggerEl: trigger,
			variant = "menu-button",
			onContextOpen: onContextClick,
			...props
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		ref,
	) => {
		const [open, setOpen] = useState(false);
		const [activeIndex, setActiveIndex] = useState<number | null>(null);
		const [allowHover, setAllowHover] = useState(false);

		const tree = useFloatingTree();
		const nodeId = useFloatingNodeId();
		const parentId = useFloatingParentNodeId();

		const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
		const listContentRef = useRef(
			Children.map(children, (child) => (isValidElement(child) ? child.props.label : null)) as Array<string | null>,
		);

		const isNested = parentId != null;
		const isMenuButton = variant === "menu-button";
		const isContextMenu = variant === "context-menu";

		const { x, y, reference, floating, strategy, refs, context } = useFloating<any>({
			open,
			onOpenChange: setOpen,
			placement: isNested ? "right-start" : placement,
			middleware: [offset({ mainAxis: 4, alignmentAxis: isNested ? -5 : 0 }), flip(), shift()],
			nodeId,
			strategy: "fixed",
			whileElementsMounted: autoUpdate,
		});

		const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
			useHover(context, {
				handleClose: safePolygon({ restMs: 200 }),
				enabled: isNested && allowHover,
				delay: { open: 125 },
			}),
			useClick(context, {
				toggle: !isNested,
				event: "mousedown",
				ignoreMouse: isNested,
			}),
			useRole(context, { role: "menu" }),
			useDismiss(context),
			useListNavigation(context, {
				listRef: listItemsRef,
				activeIndex,
				nested: isNested,
				onNavigate: setActiveIndex,
			}),
			useTypeahead(context, {
				listRef: listContentRef,
				onMatch: open ? setActiveIndex : undefined,
				activeIndex,
			}),
		]);

		const onContextMenu = useCallback(
			(e: MouseEvent) => {
				const canOpen = !!onContextClick && onContextClick(e);

				if (!canOpen) {
					return;
				}

				e.preventDefault();

				reference({
					getBoundingClientRect() {
						return {
							x: e.clientX,
							y: e.clientY,
							width: 0,
							height: 0,
							top: e.clientY,
							right: e.clientX,
							bottom: e.clientY,
							left: e.clientX,
						};
					},
				});
				setOpen(true);
			},
			[onContextClick, reference],
		);

		useEffect(() => {
			if (isContextMenu && onContextClick && trigger?.current) {
				const contextRoot = trigger.current;

				contextRoot.addEventListener("contextmenu", onContextMenu);
				return () => {
					contextRoot.removeEventListener("contextmenu", onContextMenu);
				};
			}
		}, [isContextMenu, onContextClick, onContextMenu, trigger]);

		// Event emitter allows you to communicate across tree components.
		// This effect closes all menus when an item gets clicked anywhere
		// in the tree.
		useEffect(() => {
			function onTreeClick() {
				setOpen(false);
			}

			tree?.events.on("click", onTreeClick);

			return () => {
				tree?.events.off("click", onTreeClick);
			};
		}, [parentId, tree, refs]);

		// Determine if "hover" logic can run based on the modality of input. This
		// prevents unwanted focus synchronization as menus open and close with
		// keyboard navigation and the cursor is resting on the menu.
		useEffect(() => {
			function onPointerMove() {
				setAllowHover(true);
			}

			function onKeyDown() {
				setAllowHover(false);
			}

			window.addEventListener("pointermove", onPointerMove, {
				once: true,
				capture: true,
			});
			window.addEventListener("keydown", onKeyDown, true);
			return () => {
				window.removeEventListener("pointermove", onPointerMove, {
					capture: true,
				});
				window.removeEventListener("keydown", onKeyDown, true);
			};
		}, [allowHover]);

		const mergedReferenceRef = useMemo(() => reference, [reference]);

		const Icon = icon;

		return (
			<FloatingNode id={nodeId}>
				{isMenuButton && (
					<DropdownMenuButton
						data-testid={`${testId}--trigger`}
						{...getReferenceProps({
							...props,
							ref: mergedReferenceRef,
							onClick(event) {
								event.preventDefault();
								event.stopPropagation();
								(event.currentTarget as HTMLButtonElement).focus();
							},
							...(isNested
								? {
										className: open ? "open" : "",
										role: "menuitem",
										onKeyDown(event) {
											// Prevent more than one menu from being open.
											if (event.key === "ArrowUp" || event.key === "ArrowDown") {
												setOpen(false);
											}
										},
									}
								: {
										className: open ? "trigger open" : "trigger",
									}),
						})}>
						{icon ? (
							<>
								<Icon className="icon" /> {label}
							</>
						) : (
							label
						)}
						{isNested && <VscTriangleRight className="caret" />}
					</DropdownMenuButton>
				)}
				<FloatingPortal>
					{open && (
						<PopoverContent
							data-testid={`${testId}--menu`}
							{...getFloatingProps({
								className: "Menu",
								ref: floating,
								style: {
									position: strategy,
									top: y ?? 0,
									left: x ?? 0,
								},
								onKeyDown(event) {
									if (event.key === "Tab") {
										setOpen(false);
									}
								},
							})}>
							{Children.map(
								children,
								(child, index) =>
									isValidElement(child) &&
									cloneElement(
										child,
										getItemProps({
											tabIndex: -1,
											role: "menuitem",
											ref(node: HTMLButtonElement) {
												listItemsRef.current[index] = node;
											},
											onClick() {
												tree?.events.emit("click");
											},
											onPointerEnter() {
												if (allowHover) {
													setActiveIndex(index);
												}
											},
										}),
									),
							)}
						</PopoverContent>
					)}
				</FloatingPortal>
			</FloatingNode>
		);
	},
);

MenuComponent.displayName = "MenuComponent";

export const Menu: React.FC<Props> = forwardRef((props, ref) => {
	const parentId = useFloatingParentNodeId();

	if (parentId == null) {
		return (
			<FloatingTree>
				<MenuComponent {...props} ref={ref} />
			</FloatingTree>
		);
	}

	return <MenuComponent {...props} ref={ref} />;
});

Menu.displayName = "Menu";
