import { useCallback } from "react";

import { KeyResultsDetails } from "@/domains/nodes/components/keyResults/components/details/KeyResultsDetails";
import { KeyResultsActivityForm } from "@/domains/nodes/components/keyResults/components/forms/KeyResultsActivityForm";
import { KeyResultEdit } from "@/domains/nodes/components/keyResults/components/forms/KeyResultsEdit";
import { KeyResultsMore } from "@/domains/nodes/components/keyResults/components/KeyResultsMore";
import { KeyResultsScreen, KeyResultsSetScreen } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { Modal } from "@/shared/system";
import { KeyResultModel } from "@/types/db";

type Props = {
	keyResult: KeyResultModel;
	activeScreen: KeyResultsScreen | null;
	setActiveScreen: KeyResultsSetScreen;
};
export const KeyResultsDetailsListCardModal: React.FC<Props> = ({ activeScreen, keyResult, setActiveScreen }) => {
	const handleClose = useCallback(() => {
		setActiveScreen(null);
	}, [setActiveScreen]);

	const isModalOpen = activeScreen !== null;

	const isModalEdit = activeScreen === "edit";
	const isModalDetails = activeScreen === "details";
	const isModalActivity = activeScreen === "activity";

	const actions = <KeyResultsMore keyResult={keyResult} setActiveScreen={setActiveScreen} />;

	return (
		<Modal isOpen={isModalOpen} actions={actions} onClose={handleClose} width="660px">
			{isModalDetails && <KeyResultsDetails result={keyResult} setActiveScreen={setActiveScreen} />}
			{isModalActivity && <KeyResultsActivityForm keyResult={keyResult} onComplete={setActiveScreen} />}
			{isModalEdit && <KeyResultEdit keyResult={keyResult} onComplete={setActiveScreen} />}
		</Modal>
	);
};
