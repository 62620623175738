import styled from "styled-components";

type Props = {
	needle: string;
	haystack: string;
};

export const SearchResultsMatchedText = ({ needle, haystack }: Props) => {
	const matchString = new RegExp(needle, "gi");

	const text = haystack.replace(matchString, function (str) {
		return "<span>" + str + "</span>";
	});
	// TODO: This should be run through an XSS filter
	return <MatchRoot dangerouslySetInnerHTML={{ __html: text }} />;
};
const MatchRoot = styled.div`
	span {
		background-image: linear-gradient(to bottom, var(--color-accent), var(--color-accent));
		background-repeat: repeat-x;
		background-size: 1px 2px;
		background-position: 0px 100%;
		padding-bottom: 4px;
	}
`;
