import Mousetrap from "mousetrap";

export class MenuUtils {
	public static unbindShortcut(shortcut: string | string[]) {
		try {
			Mousetrap.unbind(shortcut);
		} catch (e) {
			console.log("unable to unbind", shortcut);
		}
	}
}
