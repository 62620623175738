import { Suspense, SyntheticEvent } from "react";

import { motion } from "framer-motion";
import { ExtendedKeyboardEvent } from "mousetrap";
import { FiChevronLeft } from "react-icons/fi";
import styled from "styled-components";

import { SidebarNavigation } from "@/domains/accounts/components/sidebarNavigation/SidebarNavigation";
import UserDropdown from "@/modules/dashboard/components/UserDropdown";
import { WorkspaceDropdown } from "@/modules/dashboard/components/WorkspaceDropdown";
import { useWorkspacesListener } from "@/modules/workspace/hooks/useWorkspacesListener";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Tooltip } from "@/shared/system";
import AppLoading from "@/shared/system/InApp/AppLoading";

const variants = {
	visible: () => ({
		marginLeft: "0px",
		transition: { type: "tween", duration: 0.25 },
	}),
	hidden: () => ({
		marginLeft: "-266px",
		transition: { type: "tween", duration: 0.25 },
	}),
};

interface Props {
	toggleSidebar: (e: SyntheticEvent | ExtendedKeyboardEvent) => void;
	isExpanded: boolean;
}

export function Sidebar({ toggleSidebar, isExpanded }: Props) {
	useWorkspacesListener();
	useBindShortcut("[", toggleSidebar);

	const animationVariant = isExpanded ? "visible" : "hidden";
	const tooltipText = isExpanded ? "Collapse" : "Expand";

	return (
		<Root animate={animationVariant} variants={variants}>
			{isExpanded && (
				<>
					<Suspense fallback={<AppLoading />}>
						<WorkspaceDropdown />
						<SidebarNavigation />
					</Suspense>
					<UserDropdown />
				</>
			)}
			<Tooltip label={tooltipText} combo={<kbd>[</kbd>}>
				<Collapse onClick={toggleSidebar} isExpanded={isExpanded}>
					<FiChevronLeft />
				</Collapse>
			</Tooltip>
		</Root>
	);
}

const Collapse = styled.button<{ isExpanded: boolean }>`
	position: absolute;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 50%;
	right: -16px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: var(--color-body);
	border: 0;
	border: var(--border);
	z-index: 9;
	visibility: hidden;
	opacity: 0;
	transition: opacity 175ms ease-in-out;
	cursor: pointer;

	&:focus,
	&:hover {
		outline: 0;
		filter: lighten(10%);
	}

	svg {
		transform: ${({ isExpanded }) => (isExpanded ? "rotate(0deg)" : "rotate(180deg)")};
	}
`;

const Root = styled(motion.div)`
	display: flex;
	gap: 10px;
	padding: 16px 24px;
	flex-direction: column;
	position: relative;
	width: 280px;
	flex-shrink: 0;
	background: var(--color-body);
	border-right: var(--border);

	&:hover ${Collapse} {
		visibility: visible;
		opacity: 1;
	}
`;
