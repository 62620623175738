import { FiTrash } from "react-icons/fi";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";

import { DropdownMenuButton } from "../../../shared/system";

type Props = {
	nodeId: string;
	close: VoidFunction;
};

export const NoteMoreDelete = ({ nodeId, close }: Props) => {
	const nodesService = useNodesService();

	const handleDelete = () => {
		nodesService.archive(nodeId);
		close();
	};

	return (
		<DropdownMenuButton onClick={handleDelete}>
			<FiTrash /> Delete Node
		</DropdownMenuButton>
	);
};
