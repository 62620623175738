import Mousetrap from "mousetrap";
import { useNavigate, useParams } from "react-router-dom";

import routes from "../../../shared/core/routes";

export const useNavigationShortcuts = () => {
	const navigate = useNavigate();
	const { nodeId, boardId } = useParams<{ nodeId: string; boardId: string }>();

	const handleGoToDashboard = () => {
		navigate(routes.home);
	};

	const handleGoToRoadmap = () => {
		if (nodeId) {
			navigate(`/r/${boardId}/${nodeId}`);
		} else if (boardId) {
			navigate(`/r/${boardId}`);
		}
	};

	const handleGoToTreeView = () => {
		if (nodeId) {
			navigate(`/b/${boardId}/${nodeId}`);
		} else if (boardId) {
			navigate(`/b/${boardId}`);
		}
	};

	Mousetrap.bind("g d", handleGoToDashboard);
	Mousetrap.bind("g r", handleGoToRoadmap);
	Mousetrap.bind("g t", handleGoToTreeView);
};
