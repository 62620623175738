import { removeGuestAccess } from "@/modules/board/share/shareUsers/api/removeGuestAccess";
import { updateGuestRole } from "@/modules/board/share/shareUsers/api/updateGuestRole";
import { useProfileLegacy } from "@/modules/profile/hooks/useProfileLegacy";

import { ProjectDto, User, WithId, WorkspaceMemberRole } from "../../../../../types/db";
import { MemberRow } from "../../../../members/components/MemberRow";
import { RoleSelector } from "../../../../members/primitives/RoleSelector";

export type UserWithRole = {
	role: WorkspaceMemberRole;
} & User;

type Props = {
	member: UserWithRole;
	ownerId: string;
	project: WithId<ProjectDto>;
};

export const ShareMembersMember = ({ member, project }: Props) => {
	const profile = useProfileLegacy();

	const handleUpdateRole = (role: WorkspaceMemberRole) => {
		updateGuestRole({ projectId: project.id, userId: member.id, role });
	};

	const isWorkspaceProjectAndMember =
		Object.keys(profile.access).includes(project.workspaceId) && project.visibility === "workspace";
	const isProjectOwner = project.createdBy.id === profile.id;

	const roles = [
		{
			label: "Editor",
			onClick: () => handleUpdateRole("editor"),
		},
		{
			label: "Commenter",
			onClick: () => handleUpdateRole("commenter"),
		},
		{
			label: "Viewer",
			onClick: () => handleUpdateRole("viewer"),
		},
	];

	const actions = [
		{
			label: "Revoke access",
			onClick: () => removeGuestAccess(member.id, project.id),
		},
	];

	const disableRoleEdit = member.role === "owner" || (!isProjectOwner && !isWorkspaceProjectAndMember);

	const memberActions = (
		<RoleSelector currentRole={member.role} roles={roles} options={actions} disabled={disableRoleEdit} />
	);

	return (
		<MemberRow
			key={member.id}
			user={member}
			name={member.username}
			email={member.email}
			actions={memberActions}
			variant="nested"
			// @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'UserWith... Remove this comment to see the full error message
			permissionLevel={member.status}
			isSelf={false}
		/>
	);
};
