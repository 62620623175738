import { captureException } from "@sentry/browser";
import { FiLink } from "react-icons/fi";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { WithId } from "@/types/db";

import { DropdownMenuButton } from "../../../../shared/system";
import { useToast } from "../../../toast/hooks/useToast";
import { buildUriPublicBoard } from "../../buildUriPublicBoard";

type Props = {
	board: WithId<ProjectDtoBoard>;
	onClick: VoidFunction;
};

export const MoreCopyLink = ({ board, onClick }: Props) => {
	const setToast = useToast();

	const uriPublicBoard = buildUriPublicBoard(board?.id);

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(uriPublicBoard);
			onClick();
			setToast({ status: "success", message: "Copied link to clipboard" });
		} catch (error) {
			captureException(error);
		}
	};

	return (
		<DropdownMenuButton onClick={handleCopyToClipboard}>
			<FiLink /> Copy link
		</DropdownMenuButton>
	);
};
