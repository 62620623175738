import styled from "styled-components";

import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { PublicProfile, UserComment, WithId } from "@/types/db";

import { MessageCommentMore } from "./MessageCommentMore";
import { MessageTimestamp } from "./MessageTimestamp";

type Props = {
	comment: WithId<UserComment>;
	user: PublicProfile;
};

export const MessageComment: React.FC<Props> = ({ comment, user }) => {
	const profile = usePublicProfile();
	const isCommentCreator = comment.author.id === profile.id;

	return (
		<Root data-testid="messageComment">
			<CommentHeader>
				<div>
					{user?.username}
					<MessageTimestamp timestamp={comment.createdAt} />
				</div>
				<div>{isCommentCreator && <MessageCommentMore comment={comment} />}</div>
			</CommentHeader>

			<div>{comment.body}</div>
		</Root>
	);
};

const Root = styled.div`
	border-radius: 4px;
	padding: 12px 16px;
	margin: -12px 0px 32px;
	background: var(--color-overlay);
`;

const CommentHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 13px;
	margin-bottom: 8px;
	color: var(--color-text-heading);
`;
