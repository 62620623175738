import { arrayRemove, arrayUnion, deleteField, Unsubscribe } from "firebase/firestore";
import { keyBy } from "lodash";
import { compose } from "lodash/fp";
import { z } from "zod";

import { nodeMetaDTO, nodeMetaTypes, nodeMetaTypeValues } from "@/domains/nodes/dtos/nodeMetaDto";
import { nodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";
import { NodeMetaUtil } from "@/domains/nodes/utils/nodeMetaUtils";
import { NodeMetaTypeUnion } from "@/types/db";

import { BaseApi } from "../../../shared/infra/services/BaseApi";

export default class NodeMetaService extends BaseApi<z.infer<typeof nodeMetaDTO>> {
	constructor() {
		super("nodeMeta");
	}

	public listen(metaId: string, next: (data: { id: string; meta: z.infer<typeof nodeMetaModel> }) => void): Unsubscribe {
		const setState = compose(next, NodeMetaUtil.toViewModel);

		return this.documentSnapshot(metaId, setState);
	}

	public createNodeMeta(documentId: string, metaTypes: z.infer<typeof nodeMetaTypes>[]) {
		const byId = (_: z.infer<typeof nodeMetaTypes>) => _.id;
		return this.post(documentId, {
			meta: keyBy(metaTypes, byId),
			order: metaTypes.map(byId),
			version: "1",
		});
	}

	public addMetaType(documentId: string, meta: z.infer<typeof nodeMetaTypes>) {
		return this.patch(documentId, {
			[`meta.${meta.id}`]: meta,
			order: arrayUnion(meta.id),
		});
	}

	public addMetaValue(documentId: string, metaId: string, value: z.infer<typeof nodeMetaTypeValues>) {
		return this.patch(documentId, {
			[`meta.${metaId}.value`]: value,
		});
	}

	public deleteMetaType(documentId: string, metaId: string) {
		return this.patch(documentId, {
			[`meta.${metaId}`]: deleteField(),
			order: arrayRemove(metaId),
		});
	}

	public updateNodeMetaLabel(documentId: string, metaId: string, label: string) {
		return this.patch(documentId, {
			[`meta.${metaId}.label`]: label,
		});
	}

	public updateNodeMetaType(documentId: string, metaId: string, newType: NodeMetaTypeUnion, oldType: NodeMetaTypeUnion) {
		// unit test this function
		const resetValue = !NodeMetaUtil.compatibleTypeValues[oldType].includes(newType);

		const newMeta = {
			[`meta.${metaId}.type`]: newType,
		};

		if (resetValue) {
			newMeta[`meta.${metaId}.value`] = NodeMetaUtil.defaultValues[newType];
		}

		return this.patch(documentId, newMeta);
	}

	public updateNodeMetaValue<TValue>(documentId: string, metaId: string, value: TValue) {
		return this.patch(documentId, {
			[`meta.${metaId}.value`]: value,
		});
	}

	public updateNodeMetaOrder(documentId: string, order: string[]) {
		return this.patch(documentId, {
			order,
		});
	}
}
