import { doc, QuerySnapshot } from "firebase/firestore";

import { NodeDto } from "@/domains/nodes/dtos/nodesDto";
import { LocalNodeState, NodeModel, NodeStore } from "@/domains/nodes/models/nodesModel";
import { getParentPath } from "@/modules/note/utils/nodeNoteUtils";
import { db, fb } from "@/shared/infra/init";
import { queryToViewModel } from "@/shared/utils/dataUtils";

type PartialNode = Pick<NodeDto, "workspace" | "board" | "createdBy" | "meta"> & {
	overrides?: Partial<NodeDto>;
};

export type PositionChildren = {
	node: NodeModel;
	childId: string;
	position?: "left" | "right" | "start" | "end";
	activeId?: string;
};

export class NodeUtils {
	public static createNodeId(): string {
		return doc(db.nodes).id;
	}

	public static create({ createdBy, overrides = {}, ...rest }: PartialNode): NodeModel {
		const id = NodeUtils.createNodeId();

		return {
			id,
			creating: true,
			title: "",
			version: "2.1",
			icon: null,
			layout: "stacked",
			cover: null,
			hasNote: false,
			createdAt: fb.timestamp(),
			createdBy,
			subscribers: [createdBy],
			archived: false,
			children: [],
			...rest,
			...overrides,
		};
	}

	public static addChild({ node, childId, activeId = "", position = "end" }: PositionChildren): NodeModel {
		const children = node.children.slice(0);
		const activeIndex = children.indexOf(activeId);

		switch (position) {
			case "left": {
				children.splice(activeIndex, 0, childId);
				break;
			}
			case "right": {
				children.splice(activeIndex + 1, 0, childId);
				break;
			}
			case "start": {
				children.splice(0, 0, childId);
				break;
			}
			default: {
				children.push(childId);
				break;
			}
		}

		return Object.assign({}, node, { children });
	}

	public static getParent(nodes: NodeStore, nodeId: string): NodeModel | undefined {
		const nodeList = Object.values(nodes);
		return nodeList.find((node) => node?.children.includes(nodeId));
	}

	public static isUserSubscribed(userId: string, node: NodeDto) {
		return node.subscribers.includes(userId);
	}

	public static mergeLocalData(nodes: NodeStore, local: LocalNodeState): NodeStore {
		switch (local?.type) {
			case "create": {
				const parent = local.data.parent;

				return {
					...nodes,
					[local.data.node.id]: local.data.node,
					[parent.id]: { ...nodes[parent.id], ...parent },
				};
			}
			case "update": {
				return {
					...nodes,
					[local.data.node.id]: { ...local.data.node, creating: true },
				};
			}
			default: {
				return nodes;
			}
		}
	}

	public static toViewModel(snapshot: QuerySnapshot<NodeDto>) {
		if (snapshot.metadata.fromCache) {
			return;
		}

		const toNodeModel = (id: string, node: NodeDto) => {
			return {
				...node,
				id,
				creating: false,
			};
		};

		return queryToViewModel(snapshot, toNodeModel);
	}

	public static buildNodeUrl(node: NodeModel) {
		return `${getParentPath()}/${node.board}/${node.id}`;
	}
}
