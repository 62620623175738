import { FiGlobe, FiLock } from "react-icons/fi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import styled from "styled-components";

import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { projectUpdateVisibility } from "@/modules/board/api/projectUpdateVisibility";
import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { ProjectBoard, SystemFolderName, WithId } from "@/types/db";

import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger } from "../../../../../shared/system";

const iconMap = {
	Restricted: FiLock,
	Workspace: HiOutlineOfficeBuilding,
	"Anyone with link": FiGlobe,
};

type PermissionOption = {
	label: keyof typeof iconMap;
	description: string;
	action: () => void;
	checkSelected: (board: ProjectBoard) => boolean;
};

interface Props {
	project: WithId<ProjectBoard>;
	disabled: boolean;
}

export const ShareVisibility = ({ project, disabled }: Props) => {
	const publicProfile = usePublicProfile();
	const { getSystemFolderByName, getNodeById } = useNavigationService();

	const projectVisibility = project.visibility;

	const navigationNode = getNodeById(project.id, project.visibility);
	const parentId = navigationNode?.parent?.data.id;

	const workspaceFolder = getSystemFolderByName(SystemFolderName.workspace);
	const workspaceFolderId = workspaceFolder?.id;

	const privateFolder = getSystemFolderByName(SystemFolderName.private);
	const privateFolderId = privateFolder?.id;

	const commonArgs = {
		projectId: project.id,
		currentVisibility: projectVisibility,
		folders: {
			old: parentId,
			new: projectVisibility === "private" ? workspaceFolderId : privateFolderId,
		},
		user: publicProfile,
	};

	const permissionOptions: PermissionOption[] = [
		{
			label: "Restricted",
			description: "Only people with access can open",
			action: () => projectUpdateVisibility({ ...commonArgs, visibility: "private" }),
			checkSelected: ({ visibility, meta }) => visibility === "private" && meta.public !== true,
		},
		{
			label: "Workspace",
			description: "All workspace members can open",
			action: () => projectUpdateVisibility({ ...commonArgs, visibility: "workspace" }),
			checkSelected: ({ visibility, meta }) => visibility === "workspace" && meta.public !== true,
		},
		{
			label: "Anyone with link",
			description: "Anyone on the Internet with the link can view",
			action: () => projectUpdateVisibility({ ...commonArgs, visibility: "public" }),
			checkSelected: ({ meta }) => meta.public === true,
		},
	];

	const activeOption = permissionOptions.find(({ checkSelected }) => checkSelected(project)) as PermissionOption;

	const Icon = iconMap[activeOption.label];

	return (
		<PermissionItem>
			<IconAvatar>
				<Icon />
			</IconAvatar>
			<PermissionItemSelect>
				<Dropdown>
					<Trigger disabled={disabled} chevron>
						{activeOption?.label}
					</Trigger>
					<DropdownContent width={150}>
						{permissionOptions.map(({ label, action }) => {
							return <DropdownItems label={label} action={action} key={label} />;
						})}
					</DropdownContent>
				</Dropdown>
				<SelectedDescription>{activeOption.description}</SelectedDescription>
			</PermissionItemSelect>
		</PermissionItem>
	);
};

interface DropdownItemProps {
	label: string;
	action: () => void;
}

const DropdownItems = ({ action, label }: DropdownItemProps) => {
	const handleClick = () => {
		action();
	};
	return <DropdownMenuItem onSelect={handleClick}>{label}</DropdownMenuItem>;
};

const PermissionItem = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 0;
	gap: 16px;
`;

const IconAvatar = styled.div`
	display: flex;
	align-items: center;

	> svg {
		font-size: 24px;
		display: block;
	}
`;

const PermissionItemSelect = styled.div``;

const Trigger = styled(DropdownTrigger)`
	color: var(--color-accent);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.1;
	margin-bottom: 4px;

	svg {
		margin-left: 8px;
	}

	:hover {
		color: var(--color-accent-hover);
	}
`;

const SelectedDescription = styled.div`
	font-size: 14px;
	line-height: 1.1;
`;
