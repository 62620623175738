import { useEffect } from "react";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { projectService } from "@/domains/projects/services";

export const useGetProjectListener = (projectId: string) => {
	const setProjectSingle = useProjectsStore((state) => state.setProjectSingle);

	useEffect(() => {
		if (projectId) {
			const onError = () => {
				setProjectSingle({
					code: 401,
					data: null,
				});
			};

			return projectService.listen(projectId, setProjectSingle, onError);
		}
	}, [projectId, setProjectSingle]);
};
