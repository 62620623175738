import { captureException } from "@sentry/browser";
import { arrayRemove, arrayUnion, runTransaction } from "firebase/firestore";

import {
	projectIntegrationWebhook,
	ProjectIntegrationWebhook,
	ProjectIntegrationWebhookData,
} from "@/domains/projects/dtos/projectsDto";
import { ProjectDto } from "@/types/db";

import { BaseApi } from "../../../shared/infra/services/BaseApi";

export default class ProjectsService extends BaseApi<ProjectDto> {
	constructor() {
		super("projects");
	}

	public async createWebhook(projectId: string, webhook: ProjectIntegrationWebhookData) {
		try {
			const nextProjectWebhook: ProjectIntegrationWebhook = projectIntegrationWebhook.parse({
				type: "webhook",
				data: webhook,
			});

			this.patch(projectId, {
				integrations: arrayUnion(nextProjectWebhook),
			});
		} catch (e) {
			captureException(e, { extra: webhook });
			console.log("[ERROR] Failed to create webhook", e, webhook);
		}
	}

	public async updateWebhook(
		projectId: string,
		newWebhook: ProjectIntegrationWebhook,
		oldWebhook: ProjectIntegrationWebhook,
	) {
		try {
			runTransaction(this.firestore, async (transaction) => {
				const projectDoc = this.getDocument(projectId);
				const document = projectIntegrationWebhook.parse(newWebhook);

				transaction.update(projectDoc, {
					integrations: arrayRemove(oldWebhook),
				});
				transaction.update(projectDoc, {
					integrations: arrayUnion(document),
				});
			});
		} catch (e) {
			console.log("[ERROR] Failed to update webhook", e);
			captureException(e, { extra: { webhook: newWebhook } });
		}
	}

	public async removeWebhook(projectId: string, webhook: ProjectIntegrationWebhook) {
		this.patch(projectId, {
			integrations: arrayRemove(webhook),
		});
	}
}
