import { FiFolder } from "react-icons/fi";
import styled from "styled-components";

import { SearchResultsMatchedText } from "@/domains/accounts/components/createProject/components/SearchResultMatchedText";
import { useNavigationService } from "@/domains/accounts/components/sidebarNavigation/hooks/useNavigationService";
import { ProjectModelFolder } from "@/domains/projects/models/projectsModel";

type Props = {
	item: ProjectModelFolder;
	search: string;
	activeId: string | null;
	onMouseEnter: (id: string) => void;
	onClick: () => void;
};

export const SearchResults = ({ activeId, search, onMouseEnter, onClick, item }: Props) => {
	const { getAllParents } = useNavigationService();
	const variant = activeId === item.id ? "active" : "";

	const [parent] = getAllParents(item.id);

	const isSystemFolder = parent?.createdBy.id === "0";

	const handleMouseEnter = () => {
		onMouseEnter(item.id);
	};

	return (
		<Root className={variant} key={item.id} onClick={onClick} onMouseEnter={handleMouseEnter}>
			<FiFolder />
			<div>
				<SearchResultsMatchedText needle={search} haystack={item.data.name} />
				<Parent>
					{isSystemFolder ? (
						<SystemFolder>{parent?.data.name}</SystemFolder>
					) : (
						<>
							<FiFolder />
							{parent?.data.name}
						</>
					)}
				</Parent>
			</div>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	padding: 4px 12px;
	color: var(--color-text-heading);
	border-radius: 4px;
	margin-bottom: 2px;

	> svg {
		display: block;
		margin-top: 4px;
		margin-right: 11px;
	}

	&.active {
		background: var(--color-hover);
	}
`;

const Parent = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: var(--color-text);
	line-height: 1.9em;
	margin-top: 4px;

	> svg {
		display: block;
		margin-right: 6px;
		margin-top: -1px;
	}
`;

const SystemFolder = styled.div`
	font-size: 12px;
	color: var(--color-text);
	text-transform: uppercase;
	letter-spacing: 0.03em;
`;
