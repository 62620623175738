import { captureException } from "@sentry/browser";
import { doc, runTransaction } from "firebase/firestore";

import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { db, fb } from "@/shared/infra/init";
import { Dictionary } from "@/types/db/utils";

export type ProjectUpdateParent = (changedNodes: Dictionary<ProjectModel>) => void;

export const projectUpdateAll: ProjectUpdateParent = async (changedNodes) => {
	const projects = Object.values(changedNodes);

	try {
		await runTransaction(fb.firestore, async (transaction) => {
			projects.forEach(({ id, ...project }) => {
				const projectDoc = doc(db.projects, id);
				//@ts-expect-error
				transaction.update(projectDoc, project);
			});
		});
	} catch (e) {
		captureException(e);
	}
};
