import { z } from "zod";

import { SelectedValues } from "@/domains/nodes/components/details/meta/components/SelectedValues";
import { NodeMetaSelectOptions } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectOptions";
import { NodeMetaSelectValue } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectValue";
import { nodeMetaTypeList, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";
import { getNodeMetaSelectValue } from "@/modules/nodeMeta/selectors/getNodeMetaSelectValue";
import { TextWithEmoji } from "@/shared/system";
import { SelectDropdown, SelectDropdownOptionsFn } from "@/shared/system/SelectDropdown/SelectDropdown";
import { Node, WithId } from "@/types/db";

type Props = {
	node: WithId<Node>;
	meta: z.infer<typeof nodeMetaTypeSelect> | z.infer<typeof nodeMetaTypeList>;
};

export const NodeMetaSelect = ({ node, meta }: Props) => {
	const currentValues = getNodeMetaSelectValue(node, meta);

	const placeholderText = `Change ${meta.label.toLowerCase()}...`;

	const values = (
		<SelectedValues values={currentValues}>
			{currentValues.map((metaValue) => {
				return (
					<NodeMetaSelectValue color={metaValue.color} key={metaValue.id}>
						<TextWithEmoji text={metaValue.title} />
					</NodeMetaSelectValue>
				);
			})}
		</SelectedValues>
	);

	const options: SelectDropdownOptionsFn = ({ close, inputEl, search, setSearch }) => {
		const clearSearch = () => setSearch("");
		return (
			<NodeMetaSelectOptions
				nodeId={node.id}
				meta={meta}
				selected={currentValues}
				search={search}
				inputEl={inputEl}
				close={close}
				clearSearch={clearSearch}
			/>
		);
	};

	return <SelectDropdown values={values} options={options} placeholderText={placeholderText} />;
};
