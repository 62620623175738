import { forwardRef } from "react";

/**
 * This is a wrapper element so that group items don't emit the tree click event
 * eg: When toggling range
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MenuGroup = forwardRef<HTMLDivElement, any>(({ children }, ref) => {
	return <div>{children}</div>;
});

MenuGroup.displayName = "MenuGroup";
