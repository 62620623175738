import { memo } from "react";

import styled from "styled-components";

import { NodeListChildCount } from "@/domains/nodes/components/lists/components/NodeListChildCount";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { CardMetaList } from "@/modules/nodeMeta/CardMetaList";
import { Link } from "@/shared/system";

interface Props {
	node: NodeModel;
}

export const NodeListItem = memo(({ node }: Props) => {
	return (
		<Layout to={NodeUtils.buildNodeUrl(node)}>
			<Title>
				<span>{node.title}</span>
			</Title>
			<NodeListChildCount node={node} />
			<CardMetaList node={node} showLabel={false} />
		</Layout>
	);
});

NodeListItem.displayName = "NodeListItem";

const Layout = styled(Link.Plain)`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	> div {
		width: auto;
		display: flex;
		align-items: center;
		> div {
			margin: 0;
			flex-shrink: 0;
			> div {
				max-width: 100%;
				min-width: auto;
				margin-left: 8px;
			}
		}
	}
`;

const Title = styled.div`
	text-decoration: none;
	color: var(--color-text-heading);
	font-weight: 500;
	margin-right: 12px;
	cursor: pointer;
	> span {
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 330px;
	}
`;
