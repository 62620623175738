import { useCallback } from "react";

import { useCommentActions } from "@/modules/comments/hooks/useCommentActions";
import { usePublicProfile } from "@/modules/profile/hooks/usePublicProfile";
import { Avatar } from "@/shared/system";
import { Node, WithId } from "@/types/db";

import { ActivityItemWrapper } from "./ActivityItemWrapper";
import { CommentInput } from "./CommentInput";

type Props = {
	node: WithId<Node>;
};

export const CommentForm: React.FC<Props> = ({ node }) => {
	const user = usePublicProfile();
	const { create: addComment } = useCommentActions();

	const handleSubmit = useCallback((body: string) => addComment({ node, user, body }), [addComment, node, user]);

	return (
		<ActivityItemWrapper icon={<Avatar small user={user} />} lastChild>
			<CommentInput onSubmit={handleSubmit} />
		</ActivityItemWrapper>
	);
};
