import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { themes } from "@/modules/board/settings/components/theme/themes";

export const getThemeForProject = (project: ProjectDtoBoard) => {
	if (!project.meta.theme || project.meta.theme.name === "inherit") {
		return;
	}

	const themeName = project.meta.theme.name;

	switch (themeName) {
		case "custom": {
			return project.meta.theme.data;
		}
		default: {
			return themes[themeName];
		}
	}
};
