import React, { useMemo } from "react";

import editorJsHTML from "editorjs-html";
import isEmpty from "lodash/isEmpty";
import { BsFillMarkdownFill } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

import { Editor } from "./editor/Editor";
import { noteState } from "./hooks/useGetNote";

const editorJsParser = editorJsHTML({
	checklist: (block: any) => {
		const listItems: any = block.data.items.map(({ text }: any) => `<li>${text}</li>`).join("");
		return `<ul> ${listItems} </ul>`;
	},
});

const buildLegacyHtml = (body: any) => {
	try {
		const html = editorJsParser.parse(body);
		if (html) {
			return html.join("");
		}
		return "";
	} catch (e) {
		return "";
	}
};

type Props = {
	nodeId: string;
	onSave: any;
};

const Notepad = React.memo(({ nodeId, onSave }: Props) => {
	const { canEdit } = usePermissions("project");
	const note = useRecoilValue(noteState(nodeId));

	const body = useMemo(() => {
		if (!note) {
			return null;
		}

		if (note.bodySchemaVersion === "4" && typeof note.body === "string") {
			return JSON.parse(note.body);
		}

		if (note.bodySchemaVersion === "4" && typeof note.body !== "string") {
			return note.body;
		}

		if (note.bodySchemaVersion !== "4" && !isEmpty(note.body)) {
			return buildLegacyHtml(note.body);
		}

		return "";
	}, [note]);

	const handleSave = (newDoc: any) => onSave(newDoc, body);

	return (
		<Root>
			<Heading>Description</Heading>
			<Editor initialContent={body} onSave={handleSave} editable={canEdit} />
			{canEdit && (
				<Markdown>
					<BsFillMarkdownFill /> Markdown
				</Markdown>
			)}
		</Root>
	);
});

Notepad.displayName = "Notepad";

const Root = styled.div`
	min-height: 32px;
	margin-bottom: 24px;
`;

const Heading = styled.h4`
	margin: 0;
	margin-bottom: 8px;
`;

const Markdown = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;

	svg {
		margin-right: 8px;
	}
`;

export default Notepad;
