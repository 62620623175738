import styled from "styled-components";

import { breakpoints, Heading, utility } from "../../../shared/system";

const glow = `
		background: radial-gradient(40% 40% at 40% 40%, rgba(112, 64, 236, 0.05) 0%, rgba(112, 64, 236, 0) 100%),
		radial-gradient(50% 80% at 50% 80%, rgba(252, 108, 255, 0.02) 0%, rgba(252, 108, 255, 0) 60%),
		radial-gradient(30% 50% at 70% 50%, rgba(49, 45, 255, 0.05) 0%, rgba(49, 45, 255, 0) 80%);
`;

export const ImageBlock = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 24px 0;
	${glow}

	${breakpoints.lg`
		width: 545px;
	`}
`;

export const ContentType = styled.div`
	letter-spacing: 0.04em;
	text-transform: uppercase;

	${breakpoints.md`
		font-size: 18px;
	`}
`;

export const ContentHeader = styled(Heading.H2)`
	font-weight: 300;
	font-size: 24px;
	color: ${utility.color("blackLight")};

	${breakpoints.sm`
		line-height: 116%;
		font-size: 36px;
	`}

	${breakpoints.lg`
		font-size: 48px;
	`}
`;

export const ContentDescription = styled.div`
	${breakpoints.md`
		font-size: 18px;
	`}
`;
