import { useRecoilValue, useSetRecoilState } from "recoil";

import { createProjectModalState } from "@/domains/accounts/components/createProject/selectors/createProjectModalState";
import { listSelectionState } from "@/domains/accounts/components/createProject/selectors/listSelectionState";
import { FileFolder } from "@/shared/system/Files/FileFolder";
import { FileTreeFileMap } from "@/shared/system/Files/FileTree";
import { ProjectFolder, WithId } from "@/types/db";

type Props = {
	project: WithId<ProjectFolder>;
	fileTypeMap: FileTreeFileMap;
	onClick?: (projectId?: string) => void;
};

export const CreateProjectLocationFileFolder = ({ project, fileTypeMap, onClick }: Props) => {
	const setActiveFolderId = useSetRecoilState(createProjectModalState);

	const activeState = useRecoilValue(listSelectionState("locationFolder"));

	const handleClick = (projectId?: string) => {
		/**
		 * This is a dirty hack to allow the close fun to be passed down recursively to nested folders
		 * this function is call N times where N is the depth of the folder the first call with the correct ID
		 * subsequent calls are undefined
		 */
		projectId && setActiveFolderId(projectId);
		onClick && onClick();
	};

	const isActive = activeState === project.id;

	return <FileFolder project={project} onClick={handleClick} isActive={isActive} fileTypeMap={fileTypeMap} type="folder" />;
};
