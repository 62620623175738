import { useEffect } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { atom, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { WorkspaceUsage } from "../../../types/db";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export const workspaceUsageState = atom<WorkspaceUsage>({
	key: "workspaceUsageState",
	default: {
		totalNodes: 0,
		totalGuests: 0,
		totalInvites: 0,
		totalInvitesGuest: 0,
		totalNodesBoards: {},
		totalMembers: 0,
		version: 2,
	},
});

export const usePlanUsageListener = () => {
	const workspace = useActiveWorkspace();
	const [, setUsage] = useRecoilState(workspaceUsageState);

	const workspaceId = workspace?.id;

	useEffect(() => {
		if (workspaceId) {
			const workspaceUsageDocument = doc(db.workspaceUsage(workspaceId), workspaceId);

			return onSnapshot(
				workspaceUsageDocument,
				(snapshot) => {
					const workspaceUsage = snapshot.data();
					workspaceUsage && setUsage(workspaceUsage);
				},
				onSnapshotError("usePlanUsageListener"),
			);
		}
	}, [setUsage, workspaceId]);
};
