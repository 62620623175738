import { useLayoutEffect, useRef } from "react";

import { FiCornerDownLeft } from "react-icons/fi";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { projectTypesStateKey } from "@/domains/accounts/components/createProject/components/CreateProjectProjectTypeShortcuts";
import { listSelectionState } from "@/domains/accounts/components/createProject/selectors/listSelectionState";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

export type CreateProjectAction = {
	icon: any;
	color: string;
	name: string;
	shortcut: string;
	onClick: () => void;
};

export const CreateProjectProject = ({ icon, name, color, shortcut, onClick }: CreateProjectAction) => {
	const buttonEl = useRef<HTMLButtonElement>(null);
	const [activeAction, setActiveAction] = useRecoilState(listSelectionState(projectTypesStateKey));

	const isActive = activeAction === name;
	const variant = isActive ? "active" : "";

	useBindShortcut(shortcut, onClick);

	useLayoutEffect(() => {
		if (isActive) {
			buttonEl.current?.focus();
		}
	}, [isActive]);

	const Icon = icon;
	const key = !isActive ? <>{shortcut}</> : <FiCornerDownLeft />;

	const handleSetActive = () => {
		setActiveAction(name);
	};

	return (
		<Root onClick={onClick} onMouseEnter={handleSetActive} ref={buttonEl} className={variant}>
			<IconWrapper $backgroundColor={color}>
				<Icon />
			</IconWrapper>
			{name}
			<kbd>{key}</kbd>
		</Root>
	);
};

const Root = styled.button`
	display: flex;
	align-items: center;
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 14px;
	color: var(--color-text-heading);
	padding: 6px;
	width: 100%;
	border-radius: 4px;
	cursor: pointer;

	&:focus,
	&.active {
		background: var(--color-hover);

		kbd {
			background: var(--color-border);
		}
	}

	kbd {
		margin-left: auto;
	}
`;

const IconWrapper = styled.div<{ $backgroundColor: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	width: 28px;
	height: 28px;
	border-radius: 4px;
	background: ${({ $backgroundColor }) => $backgroundColor};
	color: var(--color-body);

	svg {
		display: block;
	}
`;
