import { Fragment } from "react";

import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";

import { Label } from "../../../../shared/system";
import { galleryImages } from "../utils/galleryImages";

type Props = {
	nodeId: string;
};

// TODO: Need to load thumbnails in gallery

export const CoverImageGallery = ({ nodeId }: Props) => {
	const nodesService = useNodesService();

	const handleUpdateCoverImage = (src: string) => {
		const cover = {
			type: "image" as const,
			src,
			offsetY: 0,
			local: false,
		};
		nodesService.update(nodeId, { cover });
	};

	const sectionKeys = Object.keys(galleryImages);

	return (
		<Root>
			{sectionKeys.map((key) => {
				const images = galleryImages[key] || [];
				return (
					<Fragment key={key}>
						<SectionHeading mb="12px">{key}</SectionHeading>
						<ImageWrapper>
							{images.map((src) => {
								return <ImageCard onClick={() => handleUpdateCoverImage(src)} src={src} key={src} />;
							})}
						</ImageWrapper>
					</Fragment>
				);
			})}
		</Root>
	);
};

const Root = styled.div`
	overflow-y: auto;
	max-height: 240px;
	padding: 14px;
`;

const SectionHeading = styled(Label)`
	padding: 0 6px;
`;

const ImageWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;

	:not(:last-child) {
		margin-bottom: 12px;
	}
`;

const ImageCard = styled.div<{ src: string }>`
	border-radius: 4px;
	overflow: hidden;
	margin: 6px;
	width: 120px;
	height: 72px;
	background: url(${({ src }) => src});
	background-position: center;
	background-size: cover;
	cursor: pointer;
`;
