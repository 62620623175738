export enum AvailablePlans {
	free = "Free",
	solo = "Solo",
	team = "Team",
	business = "Business",
	appSumo = "AppSumo",
	lifetime = "Lifetime",
	premium = "Premium", //Deprecated only used in staging
}

export enum PlanAttributes {
	nodes = "nodes",
	guests = "guests",
	members = "members",
}
