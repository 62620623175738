import { RefObject, useCallback, useMemo } from "react";

import { z } from "zod";

import { useNodeMetaActions } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaActions";
import { NodeMetaSelectOptionsItem } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectOptionsItem";
import { NodeMetaSelectValue } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectValue";
import { nodeMetaSelectValue, nodeMetaTypeList, nodeMetaTypeSelect } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { getNextTagColor } from "@/modules/nodeMeta/utils/getNextTagColor";
import { SelectOptions } from "@/shared/system/SelectDropdown/SelectOptions";
import { guid } from "@/shared/utils/guid";

type Props = {
	nodeId: string;
	search: string;
	inputEl: RefObject<HTMLInputElement>;
	meta: z.infer<typeof nodeMetaTypeSelect> | z.infer<typeof nodeMetaTypeList>;
	selected: z.infer<typeof nodeMetaSelectValue>[];
	close: VoidFunction;
	clearSearch: VoidFunction;
};

export const NodeMetaSelectOptions = ({ nodeId, meta, selected, search, inputEl, close, clearSearch }: Props) => {
	const nodesService = useNodesService();
	const { updateNodeMetaAtId } = useNodeMetaActions();

	const options = meta.value.map((value) => {
		const isSelected = !!selected.find((v) => v && v.id === value.id);

		return {
			metaId: meta.id,
			valueId: value.id,
			match: value.title,
			value,
			editable: true,
			selected: isSelected,
			type: meta.type,
			onClick: () => {
				!isSelected ? handleAddItem(value) : handleRemoveItem(value);
			},
			element: <NodeMetaSelectValue color={value.color} value={value.title} />,
		};
	});

	/**
	 * Add create option if no exact match found
	 */
	const nextTagColor = getNextTagColor(meta.value.length);
	const isSearching = search.length > 0;
	const isExactMatch = !!options.find((_) => _.match.toLowerCase() === search.toLowerCase());

	if (isSearching && !isExactMatch) {
		options.push({
			type: meta.type,
			metaId: meta.id,
			valueId: "create",
			match: search,
			editable: false,
			selected: false,
			value: {} as any, //FIXME: This looks odd
			onClick: async () => {
				const newItem = {
					id: guid(),
					title: search,
					color: nextTagColor,
				};

				const mergedValues = [...meta.value, newItem];

				updateNodeMetaAtId(meta.id, mergedValues);
				handleAddItem(newItem);
			},
			element: (
				<span>
					Create <NodeMetaSelectValue color={nextTagColor} value={search} />
				</span>
			),
		});
	}

	const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected]);

	/**
	 * Updates db based on selection. Each type currently has its own
	 * method so that the activity message can still be triggered
	 */
	const handleAddItem = useCallback(
		(item: z.infer<typeof nodeMetaSelectValue>) => {
			const newItem = meta.type === "select" ? item.id : [...selectedIds, item.id];
			nodesService.updateMeta(nodeId, meta.id, newItem);
			meta.type === "select" && close();
			clearSearch();
		},
		[clearSearch, close, meta.id, meta.type, nodeId, nodesService, selectedIds],
	);

	const handleRemoveItem = useCallback(
		(item: z.infer<typeof nodeMetaSelectValue>) => {
			const newMeta = selectedIds.filter((id) => id !== item.id);
			nodesService.updateMeta(nodeId, meta.id, newMeta);
			meta.type === "select" && close();
			clearSearch();
		},
		[clearSearch, close, meta.id, meta.type, nodeId, nodesService, selectedIds],
	);

	return (
		<SelectOptions options={options} search={search} inputEl={inputEl}>
			{(option) => {
				return <NodeMetaSelectOptionsItem option={option} meta={meta} />;
			}}
		</SelectOptions>
	);
};
