import React from "react";

import styled from "styled-components";

import routes from "../../../shared/core/routes";
import breakpoints from "../../../shared/system/breakpoint";
import { NavLink, NavLinkExternal } from "./NavLink";

// import { NavDropdown } from "./NavDropdown";

export default function NavPrimary() {
	// const [isDropdownOpen, setDropdownOpen] = useState(false);
	return (
		<Nav>
			<ul>
				{/* <li
					onMouseEnter={() => setDropdownOpen(true)}
					onMouseLeave={() => setDropdownOpen(false)}
					className={isDropdownOpen ? "dropdown-open" : ""}>
					<NavLink>Why Poda</NavLink>
					<NavDropdown />
				</li> */}
				<li>
					<NavLink to={routes.workflows}>Workflows</NavLink>
				</li>
				<li>
					<NavLinkExternal href="https://poda.canny.io/changelog" target="_blank" rel="noopener nofollow">
						<Emoji role="img" aria-label="launch">
							🚀
						</Emoji>{" "}
						Whats new?
					</NavLinkExternal>
				</li>
				<li>
					<NavLink to={routes.pricing}>Pricing</NavLink>
				</li>
			</ul>
		</Nav>
	);
}

const Emoji = styled.span`
	display: none;

	${breakpoints.md`
		display: inline-block;
		padding-right: 6px;
	`}
`;

const Nav = styled.nav`
	${breakpoints.md`
		padding-left: 76px;
	`}

	> ul {
		${breakpoints.md`
			display: flex;
			align-items: center;
			margin: 0 -25px;

		`}

		> li {
			position: relative;

			${breakpoints.md`
				padding: 0 9px;
			`}

			+ li {
				margin-top: 20px;

				${breakpoints.md`
				margin-top: 0;
			`}
			}
		}
	}
`;
