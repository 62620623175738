import { useCallback } from "react";

import { compose } from "lodash/fp";
import { FiEdit2, FiMoreHorizontal, FiTrash } from "react-icons/fi";

import { useKeyResultsService } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsService";
import { KeyResultsSetScreen } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { DropdownMenuButton } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover, PopoverRender } from "@/shared/system/Dropdown/Popover";
import { ModalHeaderAction } from "@/shared/system/Modal/Modal";
import { KeyResultModel } from "@/types/db";

type Props = {
	keyResult: KeyResultModel;
	setActiveScreen: KeyResultsSetScreen;
};

export const KeyResultsMore = ({ keyResult, setActiveScreen }: Props) => {
	const { canEdit } = usePermissions("project");
	const { remove } = useKeyResultsService();

	const handleEdit = useCallback(() => {
		setActiveScreen("edit");
	}, [setActiveScreen]);

	const handleRemove = useCallback(() => {
		remove(keyResult.id, keyResult);
	}, [keyResult, remove]);

	if (!canEdit) {
		return null;
	}

	const render: PopoverRender = ({ close }) => {
		const onEdit = compose(close, handleEdit);
		const onRemove = compose(close, handleRemove);

		return (
			<PopoverContent>
				<DropdownMenuButton onClick={onEdit}>
					<FiEdit2 /> Edit Key Result
				</DropdownMenuButton>
				<DropdownMenuButton onClick={onRemove}>
					<FiTrash /> Delete Key Result
				</DropdownMenuButton>
			</PopoverContent>
		);
	};

	return (
		<Popover render={render} placement="bottom-start">
			<ModalHeaderAction aria-label="more actions">
				<FiMoreHorizontal />
			</ModalHeaderAction>
		</Popover>
	);
};
