import { useMemo } from "react";

import app from "firebase/compat/app";

import { useErrorService } from "@/shared/core/hooks/useErrorService";
import { fb as firebase } from "@/shared/infra/init";
import { Result } from "@/shared/utils/result";

type StorageReference = app.storage.Reference;

export interface IStorageService {
	upload: (file: File, path: string) => Promise<Result<StorageReference>>;
	delete: (path: string) => Promise<Result<void>>;
}

export const useStorageService = (): IStorageService => {
	const errorService = useErrorService();

	return useMemo(() => {
		const uploadFile: IStorageService["upload"] = async (file, path) => {
			try {
				const fileSnapshot = await firebase.storage.child(path).put(file);
				return Result.ok(fileSnapshot.ref);
			} catch (exception) {
				const errorMessage = "Failed to upload file";
				errorService.logError(errorMessage, { exception });
				return Result.fail(errorMessage);
			}
		};

		const deleteFile: IStorageService["delete"] = async (path) => {
			try {
				await firebase.app.storage().refFromURL(path).delete();

				return Result.ok();
			} catch (exception) {
				const errorMessage = "Failed to remove file";
				errorService.logError(errorMessage, { exception });
				return Result.fail(errorMessage);
			}
		};

		return {
			upload: uploadFile,
			delete: deleteFile,
		};
	}, [errorService]);
};
