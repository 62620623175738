import styled from "styled-components";

import { NodeActionsAdd } from "@/domains/nodes/components/actions/components/NodeActionsAdd";
import { NodeActionsPermissions } from "@/domains/nodes/components/actions/components/NodeActionsPermissions";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";

import { NodeActionsGroup } from "./components/NodeActionsGroup";

interface Props {
	node: NodeModel;
}

export const NodeActions = ({ node }: Props) => {
	const treeService = useTreeService();

	const isNodeCollapsed = useNodesStore((state) => state.getIsCollapsed(node.id));
	const isNodeFocused = useNodesStore((state) => state.getIsFocused(node.id));
	const isNodeLeaf = !treeService.getHasChildren(node.id);

	const showActionGroup = isNodeFocused && !isNodeCollapsed;

	const actions = isNodeLeaf ? <NodeActionsAdd node={node} /> : <NodeActionsGroup node={node} />;

	return (
		<NodeActionsPermissions>
			{showActionGroup && (
				<Root isVisible={isNodeFocused} isNodeLeaf={isNodeLeaf} className="hide-from-export">
					<div>{actions}</div>
				</Root>
			)}
		</NodeActionsPermissions>
	);
};

const Root = styled.div<{ isVisible: boolean; isNodeLeaf: boolean }>`
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 14px;

	> span {
		display: inline-block;
		position: relative;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		content: "";
	}

	> div {
		transition: 200ms ease-in-out;
		${({ isVisible }) => !isVisible && `visibility: hidden;`}
		${({ isVisible }) => !isVisible && `opacity: 0;`}
	}

	:hover {
		background: transparent;
		border: 0;

		> div {
			visibility: visible;
			opacity: 1;
		}
	}
`;
