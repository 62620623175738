import { useEffect } from "react";

import { atom, useRecoilState } from "recoil";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";

export const activeNodeMetaDocumentId = atom<string>({
	key: "ActiveNodeMetaDocumentId",
	default: "",
});

export const useNodeMetaListener = (metaId?: string) => {
	const project = useGetProject();
	const [, setActiveDocumentId] = useRecoilState(activeNodeMetaDocumentId);

	const nodeMetaId = metaId || (project?.type === "board" && project?.metaId) || project?.workspaceId;

	const { connectNodeMeta } = useNodeMetaStore();

	useEffect(() => {
		if (nodeMetaId) {
			setActiveDocumentId(nodeMetaId);
			return connectNodeMeta(nodeMetaId);
		}
	}, [connectNodeMeta, nodeMetaId, setActiveDocumentId]);
};
