import { Filter, FilterMeta, FilterRootNode, FilterTypes } from "@/modules/board/filter/filterModel";
import { useFilterState } from "@/modules/board/filter/hooks/useFilterState";
import { checkIsInFilter } from "@/modules/board/filter/utils/checkIsInFilter";
import { Node, WithId } from "@/types/db";

export const useFilterService = () => {
	const [filters, setFilters] = useFilterState();

	const byType = (type: FilterTypes) => (_: Filter) => _.type === type;

	return {
		filters: filters,
		hasActiveFilter: filters.length > 0,

		getIsNodeFiltered: (node: Node) => checkIsInFilter(filters, node),
		getByType: (type: FilterTypes) => filters.filter(byType(type)),
		getRootNodeId: () => {
			const rootNodeFilter = filters.find(byType(FilterTypes.rootNode)) as FilterRootNode;
			return rootNodeFilter ? rootNodeFilter.value.id : null;
		},

		setMetaFilter: (filter: FilterMeta) => {
			setFilters([...filters, filter]);
			// track.userAction({ type: ActionTypes.buttonClick, name: "filter-add", source: "top-bar" });
		},
		updateMetaFilter: (filter: FilterMeta) => {
			const filterIndex = filters.findIndex(({ key }) => key === filter.key);
			setFilters([...filters.slice(0, filterIndex), filter, ...filters.slice(filterIndex + 1)]);
		},
		setNodeFilter: (node: WithId<Node>) => {
			setFilters([
				{
					key: FilterTypes.rootNode,
					type: FilterTypes.rootNode,
					value: node,
					comparator: "is",
				},
			]);
		},
		removeFilterByKey: (key: string) => {
			const newFilters = filters.filter((filter) => filter.key !== key);
			setFilters(newFilters);
		},
		clearFilters: () => setFilters([]),
	};
};
