import { FiImage, FiSmile } from "react-icons/fi";
import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

import { Button } from "../../../shared/system";
import { useDetailsSectionVisibility } from "../../board/hooks/useDetailsSectionVisibility";
import { getRandomCoverImage } from "../utils/getRandomCoverImage";
import { getRandomEmoji } from "../utils/getRandomEmoji";

type Props = {
	node: NodeModel;
};

export const CoverImageAdd = ({ node }: Props) => {
	const { id, cover, icon } = node;

	const { canEdit } = usePermissions("project");
	const nodesService = useNodesService();
	const { displayCover, displayTitle } = useDetailsSectionVisibility();

	const handleAddCoverImage = () => {
		const cover = {
			type: "image" as const,
			src: getRandomCoverImage(),
			offsetY: 0,
			local: false,
		};
		nodesService.update(id, { cover });
	};

	const handleAddIcon = () => {
		const icon = { type: "emoji" as const, emoji: getRandomEmoji() };
		nodesService.update(id, { icon });
	};

	const hasCoverImage = !!cover;
	const hasIcon = !!icon && icon !== null;

	if ((hasIcon && hasCoverImage) || !canEdit) {
		return null;
	}

	return (
		<Root>
			{!hasIcon && displayTitle && (
				<Button.Link onClick={handleAddIcon} mr="12px">
					<FiSmile />
					Add Icon
				</Button.Link>
			)}
			{!hasCoverImage && displayCover && (
				<Button.Link onClick={handleAddCoverImage}>
					<FiImage />
					Add Cover
				</Button.Link>
			)}
		</Root>
	);
};

const Root = styled.div`
	margin-top: 20px;
`;
