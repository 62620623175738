import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { breakpoints } from "../../../shared/system";

const landingActionStyle = css`
	display: flex;
	align-items: center;
	color: var(--color-accent) !important;
	background-color: #fff;
	margin-left: 0;
	border: 0;
	letter-spacing: 0.01em;
	line-height: 1.3;
	text-decoration: none;
	border-radius: 4px;
	font-weight: 600;

	svg:last-child {
		margin-left: 8px;
	}

	${breakpoints.md`
		background: rgb(58, 78, 106);
		color: #fff !important;
		padding: 14px 24px;
		min-width: 120px;
		border-radius: 60px
		margin-left: 16px;
	`}
	:hover {
		background: var(--color-accent) !important;
		color: #fff !important;

		${breakpoints.md`
			background-color: var(--color-accent) !important;
		`}
	}
`;

export const NavAction = styled(Link)`
	${landingActionStyle}
`;

export const NavButton = styled.button`
	${landingActionStyle}
	padding: 16px 32px !important;
	cursor: pointer;
`;
