import { useEffect } from "react";

import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { db } from "../../../shared/infra/init";
import { onSnapshotError } from "../../../shared/infra/init";
import { extractDataFromCollection } from "../../../shared/utils/dataUtils";
import { authenticatedIdState } from "../../authentication/selectors/authenticatedIdState";
import { workspacesState } from "../selectors/workspacesState";

export const useWorkspacesListener = () => {
	const userId = useRecoilValue(authenticatedIdState);
	const setWorkspaces = useSetRecoilState(workspacesState);

	useEffect(() => {
		if (userId) {
			const activeUserWorkspaces = query(
				db.workspaces,
				where("users.allIds", "array-contains", userId),
				where("archived", "==", false),
				orderBy("createdAt", "asc"),
			);
			return onSnapshot(
				activeUserWorkspaces,
				(workspaces) => {
					const { byId } = extractDataFromCollection(workspaces);
					setWorkspaces(byId);
				},
				onSnapshotError("useWorkspacesListener"),
			);
		}
	}, [setWorkspaces, userId]);
};
