import { format, parseISO } from "date-fns";

import { NodeMetaAllTypes, NodeMetaTypes, NodeMetaValueSelect, User } from "../../../types/db";
import { Node } from "../../../types/db";

/**
 * Return type is string | string[] based on the discriminated union type didn't really want to have to do deeply
 * nested cases as I'm not sure the best way to type it
 */
const getMetaValueOnNode = (node: Node, meta: NodeMetaAllTypes): any => {
	return node?.meta && node.meta[meta.id];
};

export const getNodeMetaObject = (node: Node, meta: NodeMetaAllTypes) => {
	const nodeMetaValue = getMetaValueOnNode(node, meta);
	switch (meta.type) {
		case NodeMetaTypes.select: {
			return meta.value.find(({ id }) => id === nodeMetaValue) as NodeMetaValueSelect;
		}
		case NodeMetaTypes.person: {
			return nodeMetaValue as string[];
		}
		case NodeMetaTypes.multi: {
			return (nodeMetaValue as string[]) || [];
		}
		default: {
			return [];
		}
	}
};
/**
 * TODO: This function isn't type safe, the meta type on nodes isn't updated when the meta type is changed via the label, we should either
 * 1. Update all nodes with the corresponding meta when the meta type changes
 * 2. Create a better data format for node meta stored
 */
export const getNodeMetaValue = (node: Node, meta: NodeMetaAllTypes, members: Record<string, User>): string | undefined => {
	const nodeMetaValue = getMetaValueOnNode(node, meta);

	switch (meta.type) {
		case "select": {
			return meta.value.find(({ id }) => id === nodeMetaValue)?.title;
		}
		case "person": {
			return members[nodeMetaValue]?.username;
		}
		case "link":
		case "email":
		case "text": {
			const metaValue = node.meta?.[meta.id];

			if (metaValue && typeof metaValue === "string") {
				return metaValue.replaceAll(",", "");
			}
			return undefined;
		}
		case "list": {
			if (Array.isArray(nodeMetaValue)) {
				const mappedValues = nodeMetaValue?.map((valueId: string) => {
					return meta.value.find(({ id }) => id === valueId)?.title;
				});

				return mappedValues.join(", ");
			}
			return undefined;
		}
		case "date":
		case "dateRange": {
			if (nodeMetaValue?.data && nodeMetaValue.type === "date") {
				const { date, format: dateFormat } = nodeMetaValue.data;
				return format(parseISO(date), dateFormat);
			} else if (nodeMetaValue?.data && nodeMetaValue.type === "dateRange") {
				const { from, to, format: dateFormat } = nodeMetaValue.data;
				return `${format(parseISO(from), dateFormat)} - ${format(parseISO(to), dateFormat)}`;
			}
			return undefined;
		}

		default: {
			const _exhaustiveCheck: never = meta;
			return _exhaustiveCheck;
		}
	}
};
