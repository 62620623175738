import { arrayUnion, doc, getDoc, getDocs, query, runTransaction, where } from "firebase/firestore";

import { projectCreateSystemFolders } from "@/modules/board/api/projectCreateSystemFolders";
import * as analyticsService from "@/shared/core/analytics/hooks/useAnalyticsService";
import { extractFromCollectionWithId } from "@/shared/utils/dataUtils";
import { Result } from "@/shared/utils/result";
import { WithId, WorkspaceInviteV2 } from "@/types/db";

import { db, fb } from "../../shared/infra/init";
import { v1v2InviteUpdate } from "./hooks/useInviteListener";

/**
 * This should be in a new service
 */

export async function queryInvitesById(inviteId: string) {
	const inviteDocument = doc(db.workspaceInvites, inviteId);
	const inviteDoc = await getDoc(inviteDocument);

	const invite = inviteDoc.data();

	if (!invite) {
		return;
	}

	return v1v2InviteUpdate(invite);
}

export const queryInvitesByEmail = async (email: string): Promise<Result<WithId<WorkspaceInviteV2>[]>> => {
	// <- Domain event || Service call ? Account Aggregate ? 2. Bad return type
	if (!email) {
		Result.fail("Failed to get Invites no email provided");
	}

	const invites = query(
		// DAO <- Repo layer ?
		db.workspaceInvites,
		where("email", "==", email),
		where("acceptedAt", "==", null),
		where("rejectedAt", "==", null),
	);

	try {
		const queryResults = await getDocs(invites);
		return Result.ok(extractFromCollectionWithId(queryResults)); // Transformation into Domain object (Entity + Mapper)
	} catch (e) {
		return Result.fail("Failed to get invites from db");
	}
};

/**
 * TODO:
 * 1. What is the difference between this accept & process invite how should the responsibilities be separated/defined
 * 2. Args should have more consistent typing... should the invite arg be a Entity?
 * 3. Is this a kind of a domain event + a repo function rolled into one... separate concerns
 */
const acceptInvite = async (invite: WithId<WorkspaceInviteV2>, userId: string) => {
	if (!invite) {
		return Result.fail("No invite found");
	}

	if (!userId) {
		return Result.fail("No user found");
	}

	try {
		await runTransaction(fb.firestore, async (transaction) => {
			// 1. This should be a repo method

			transaction.update(doc(db.workspaceInvites, invite.id), {
				acceptedAt: fb.timestamp(),
			});

			const documentUser = doc(db.users, userId);

			if (invite.type === "guest") {
				const projectId = invite.boardId;

				//@ts-expect-error Doesn't like dot notation
				transaction.update(doc(db.projects, projectId), {
					[`access.${userId}`]: {
						status: "active",
						scope: "guest",
						role: invite.role,
					},
				});

				transaction.update(documentUser, {
					[`access.${projectId}`]: {
						status: "active" as const,
						role: invite.role,
						scope: invite.type,
					},
				});
			}

			if (invite.type === "workspace") {
				const workspaceId = invite.workspace;

				transaction.update(doc(db.workspaces, workspaceId), {
					"users.allIds": arrayUnion(userId),
					[`users.byId.${userId}`]: {
						role: invite.role,
					},
				});

				transaction.update(documentUser, {
					[`access.${workspaceId}`]: {
						status: "active" as const,
						role: invite.role,
						scope: invite.type,
					},
				});
			}
		});

		return Result.ok();
	} catch (e) {
		return Result.fail("Failed to accept invite");
	}
};

/**
 * Questions
 * 1. Where should this method live should it be a domain event
 * 2. Currently it also created system folders so can only be used for existing users calling during signup
 * 	  would create duplicate folders for the workspace. Using on signup however could reduce the logic in the create account
 *    function this could be a valid fork and should be considered
 */
export async function processInvite(invite: WithId<WorkspaceInviteV2>, userId: string) {
	if (!invite) {
		return Result.fail("No invite found");
	}

	const resultInvite = await acceptInvite(invite, userId);

	if (resultInvite.isFailure) {
		return resultInvite;
	}

	if (invite.type === "workspace") {
		const { role, workspace } = invite;

		try {
			await projectCreateSystemFolders({
				workspaceId: invite.workspace,
				userId,
				isInvite: true,
			});
		} catch (e) {
			return Result.fail("Failed to create system folders for invite");
		}

		analyticsService.accountAddedUser({ workspaceId: workspace, role });
	}

	return Result.ok();
}
