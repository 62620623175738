import { deleteField, doc, writeBatch } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";

export const removeGuestAccess = (userId: string, projectId: string) => {
	const userDocument = doc(db.users, userId);
	const projectDocument = doc(db.projects, projectId);

	const batch = writeBatch(fb.firestore);
	batch.update(userDocument, { [`access.${projectId}`]: deleteField() });
	batch.update(projectDocument, {
		[`access.${userId}`]: deleteField(),
	});
	batch.commit();
};
