import { doc } from "firebase/firestore";

import { db } from "@/shared/infra/init";

export class Identifier<T> {
	constructor(private value: T) {
		this.value = value;
	}

	equals(id?: Identifier<T>): boolean {
		if (id === null || id === undefined) {
			return false;
		}
		if (!(id instanceof this.constructor)) {
			return false;
		}
		return id.toValue() === this.value;
	}

	toString() {
		return String(this.value);
	}

	/**
	 * Return raw value of identifier
	 */

	toValue(): T {
		return this.value;
	}
}

const uuid = (): string => {
	const docRef = doc(db.createId);
	return docRef.id;
};

export class UniqueEntityId extends Identifier<string | number> {
	constructor(id?: string | number) {
		super(id ? id : uuid());
	}
}

const isEntity = (v: any): v is Entity<any> => {
	return v instanceof Entity;
};

export abstract class Entity<T> {
	protected readonly _id: UniqueEntityId;
	protected props: T;

	get id() {
		return this._id.toString();
	}

	// Take note of this particular nuance here:
	// Why is "id" optional?
	constructor(props: T, id?: UniqueEntityId) {
		this._id = id ? id : new UniqueEntityId();
		this.props = props;
	}

	public save() {
		return this.props;
	}

	// Entities are compared based on their referential
	// equality.
	public equals(object?: Entity<T>): boolean {
		if (object == null || object === undefined) {
			return false;
		}

		if (this === object) {
			return true;
		}

		if (!isEntity(object)) {
			return false;
		}

		return this._id.equals(object._id);
	}
}
