import styled from "styled-components";
import { fontSize, FontSizeProps, space, SpaceProps } from "styled-system";

type Props = {
	disabled?: boolean;
} & SpaceProps &
	FontSizeProps;

const Label = styled.label<Props>`
	display: block;
	font-size: 15px;
	line-height: 24px;
	opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
	color: var(--color-text-secondary);
	margin-bottom: 8px;
	${fontSize};
	${space};

	em {
		color: var(--color-text);
	}
`;

export default Label;
