import { Unsubscribe } from "firebase/firestore";

import { NodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";
import { nodeMetaService } from "@/domains/nodes/services";
import { Result } from "@/shared/utils/result";
import { createStore } from "@/shared/zustand/createStore";
import { NodeMetaAllTypes, NodeMetaTypes } from "@/types/db";

export interface INodeMetaStore {
	id: string;
	meta: NodeMetaModel;
	getById(nodeMetaId: string): Result<NodeMetaAllTypes>;
	getByLabel(label: string): Result<NodeMetaAllTypes>;
	getByType(type: NodeMetaTypes): Result<NodeMetaAllTypes>;
	connectNodeMeta(metaId: string): Unsubscribe;
}

export const nodeMetaStore = createStore<INodeMetaStore>((set, get) => ({
	id: "",
	meta: new Map(),
	getById(nodeMetaId) {
		const meta = get().meta.get(nodeMetaId);

		if (meta === undefined) {
			return Result.fail("Unable to find node meta for id");
		}

		return Result.ok(meta);
	},
	getByType(type) {
		const meta = get().meta;
		const metaArray = [...meta.values()];
		const matchedMeta = metaArray.find((meta) => meta.type === type);

		if (matchedMeta === undefined) {
			return Result.fail("Unable to find meta for type");
		}

		return Result.ok(matchedMeta);
	},
	getByLabel(label) {
		const meta = get().meta;
		const metaArray = [...meta.values()];
		const matchedMeta = metaArray.find((meta) => meta.label === label);

		if (matchedMeta === undefined) {
			return Result.fail("Unable to find meta for label");
		}

		return Result.ok(matchedMeta);
	},
	connectNodeMeta: (documentId) => nodeMetaService.listen(documentId, set),
}));
