import { RefObject, useEffect } from "react";

import { useRecoilState } from "recoil";

import { listSelectionState } from "@/domains/accounts/components/createProject/selectors/listSelectionState";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

type Args = {
	id: string;
	items: any[];
	key?: string;
	defaultValue?: string | null;
	element?: RefObject<HTMLElement>;
};

/**
 * This probably doesn't need to be abstracted or at min needs to be typed better and
 * less/more opinionated about it incoming data
 * @deprecated
 */
export const useListSelection = ({
	id,
	items,
	key = "id",
	element,
	defaultValue = null,
}: Args): [any | null, (id: string | null) => void] => {
	const [activeFolder, setActiveFolder] = useRecoilState(listSelectionState(id));

	useEffect(() => {
		return () => {
			setActiveFolder(defaultValue);
		};
	}, [defaultValue, setActiveFolder]);

	const handleIncrementActive = (increment: number) => {
		if (activeFolder !== null) {
			const activeIndex = items.findIndex((item) => item[key] === activeFolder);

			const nextIndex = Math.max(Math.min(activeIndex + increment, items.length - 1), 0);
			setActiveFolder(items[nextIndex][key]);
		} else {
			setActiveFolder(items[0][key]);
		}
	};

	useBindShortcut("down", () => handleIncrementActive(1), element);
	useBindShortcut("up", () => handleIncrementActive(-1), element);

	return [activeFolder, setActiveFolder];
};
