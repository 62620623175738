import pluralize from "pluralize";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styled from "styled-components";

import { Button } from "../../../../../../shared/system";

type Props = {
	onClick: (open: boolean) => void;
	open: boolean;
	hiddenCount: number;
};

export const NodeMetaShowHide = ({ onClick, open, hiddenCount }: Props) => {
	const handleToggle = () => onClick(!open);

	const openMessage = (
		<>
			<FiChevronDown />
			{hiddenCount} more {pluralize("properties", hiddenCount)}
		</>
	);

	const closeMessage = (
		<>
			<FiChevronUp />
			Hide {hiddenCount} {pluralize("properties", hiddenCount)}
		</>
	);

	const message = !open ? openMessage : closeMessage;

	return <ToggleButton onClick={handleToggle}>{message}</ToggleButton>;
};

const ToggleButton = styled<any>(Button.Text)`
	display: block;
	color: var(--color-text);
	margin-top: 6px;
	padding: 4px;
`;
