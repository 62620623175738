import { SyntheticEvent } from "react";

import styled from "styled-components";

import { INodeMetaStore, useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { projectUpdateGrouping } from "@/modules/board/api/projectUpdateMeta";
import { ProjectBoard, WithId } from "@/types/db";

import { Select } from "../../../../shared/system";

const getCompatibleValues = ({ meta }: INodeMetaStore) => {
	return [...meta.values()]
		.filter((item) => item.type === "select")
		.map((item) => {
			return {
				label: item.label,
				id: item.id,
			};
		});
};

type Props = {
	board: WithId<ProjectBoard>;
};

export const MoreGrouping = ({ board }: Props) => {
	const groupedBy = board.meta?.groupedBy;
	const metaMap = useNodeMetaStore(({ meta }) => meta);
	const options = useNodeMetaStore(getCompatibleValues);
	const currentValue = groupedBy ? metaMap.get(groupedBy)?.id : options[0]?.id;

	const handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
		projectUpdateGrouping(board.id, e.currentTarget.value);
	};

	return (
		<Root>
			<div>Grouping</div>
			<div>
				<Select value={currentValue} onChange={handleChange} variant="thin">
					{options.map((option) => (
						<option key={option.id} value={option.id}>
							{option.label}
						</option>
					))}
				</Select>
			</div>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 6px;
	color: var(--color-text-heading);

	> * {
		flex-grow: 0;
		flex-shrink: 0;
	}
`;
