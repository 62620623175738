import React, { Suspense, useLayoutEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import config from "@/config/firebase";

import videoFile from "../../assets/images/poda-demo-v3.mp4";
import { breakpoints, utility } from "../../shared/system";
import { matchesMaxSize } from "../../shared/system/breakpoint";
import FeatureImage from "../components/FeatureImage";
import { Footer } from "../components/Footer";
import { FooterCta } from "../components/FooterCta";
import SiteNavigation from "../navigation/SiteNavigation";
import { PreFetchPricingData } from "../pricing/components/PreFetchPricingData";
import Callout from "./components/Callout";
import FeatureBlock from "./components/FeatureBlock";
import NodeGroupOne from "./components/NodeGroupOne";
import NodeGroupThree from "./components/NodeGroupThree";
import NodeGroupTwo from "./components/NodeGroupTwo";

const videoGradient = `conic-gradient(
		from -63.64deg at 44.35% 46.23%,
		#936df2 -34.67deg,
		#936df2 26.14deg,
		#54fbdd 133deg,
		#00dbff 194.75deg,
		#00afff 229.8deg,
		#936df2 325.33deg,
		#936df2 386.14deg
	)`;

// @ts-expect-error TS(7006) FIXME: Parameter 'parent' implicitly has an 'any' type.
const getPositionInParent = (parent) => (child) => {
	if (parent && child) {
		const parentRect = parent.getBoundingClientRect();
		const childRect = child.getBoundingClientRect();

		return {
			top: childRect.top - parentRect.top,
			bottom: childRect.bottom - parentRect.top,
			left: childRect.left - parentRect.left + childRect.width / 2,
		};
	}

	return {
		top: 0,
		bottom: 0,
		left: 0,
	};
};

// @ts-expect-error TS(7006) FIXME: Parameter 'node1' implicitly has an 'any' type.
const getGap = (node1, node2) => {
	return node2.top - node1.bottom;
};

// @ts-expect-error TS(7031) FIXME: Binding element 'top' implicitly has an 'any' type... Remove this comment to see the full error message
const getCenterY = ({ top, bottom }) => {
	return (bottom - top) / 2 + top;
};

// @ts-expect-error TS(7031) FIXME: Binding element 'cx' implicitly has an 'any' type.
const Connector = ({ cx, cy }) => {
	if (!cx || !cy) {
		return null;
	}

	return <Circle cx={cx} cy={cy} r="4" />;
};

const Home = () => {
	const [svgPath, setPath] = useState("");
	const node1 = useRef(null);
	const node2 = useRef(null);
	const node3 = useRef(null);
	const node4 = useRef(null);
	const node5 = useRef(null);
	const node6 = useRef(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const feature1 = useRef(null);
	const feature2 = useRef(null);
	const feature3 = useRef(null);
	const feature4 = useRef(null);

	const isMobile = matchesMaxSize("xs");

	const cornerLeft = "a -4 -4 0 0 0 -4 4";
	const cornerRight = "a 4,4 0 0 1 4,4";
	const cornerBottomLeft = "a 4,4 0 0 0 4,4";
	const cornerBottomRight = "a -4 -4 0 0 1 -4 4";

	const getContainerPosition = getPositionInParent(containerRef?.current);
	const container = containerRef?.current?.getBoundingClientRect();

	const n1 = getContainerPosition(node1?.current);
	const n2 = getContainerPosition(node2?.current);
	const n3 = getContainerPosition(node3?.current);
	const n4 = getContainerPosition(node4?.current);
	const n5 = getContainerPosition(node5?.current);
	const n6 = getContainerPosition(node6?.current);

	const f1 = getContainerPosition(feature1?.current);
	const f2 = getContainerPosition(feature2?.current);

	const cornerRadius = 4;
	const lineWidth = 6;
	const finalInsetWidth = 104;

	const n1n2gap = getGap(n1, n2);
	const n6CenterY = getCenterY(n6);
	const containerEdge = container ? container.right - container.left - lineWidth : 0;

	useLayoutEffect(() => {
		let path;

		if (window.matchMedia("(max-width: 768px)").matches) {
			path = `M ${n1.left} ${n1.bottom} v ${n1n2gap / 2}
			M ${n2.left + cornerRadius} ${n2.top - n1n2gap / 2}
			M ${n2.left + cornerRadius} ${n2.top - n1n2gap / 2} ${cornerLeft} V ${f1.bottom}`;
		} else {
			path = `
			M ${n1.left} ${n1.bottom} v ${n1n2gap / 2}
			M ${n2.left + cornerRadius} ${n2.top - n1n2gap / 2}
				H ${n3.left - cornerRadius} ${cornerRight} V ${n3.top}
			M ${n2.left + cornerRadius} ${n2.top - n1n2gap / 2} ${cornerLeft} V ${f1.bottom}
			M ${n4.left + cornerRadius} ${f1.bottom} H ${containerEdge} ${cornerRight}
				V ${n6CenterY - cornerRadius}  ${cornerBottomRight} H ${containerEdge - finalInsetWidth}
			M ${n4.left + cornerRadius} ${f1.bottom} ${cornerLeft} V ${f2.bottom}
			M ${lineWidth} ${f2.bottom} H ${n5.left} ${cornerRight} V ${n5.top}
			M ${lineWidth} ${f2.bottom} ${cornerLeft}
				V ${n6CenterY - cornerRadius} ${cornerBottomLeft} H ${finalInsetWidth}
			M ${containerEdge} ${n6CenterY - cornerRadius}
			`;
		}
		// Check to see if elements have been rendered to DOM
		setPath(path);
	}, [
		containerEdge,
		f1.bottom,
		f2.bottom,
		n1.bottom,
		n1.left,
		n1n2gap,
		n2.left,
		n2.top,
		n3.left,
		n3.top,
		n4.left,
		n5.left,
		n5.top,
		n6CenterY,
	]);

	return (
		<>
			<Helmet>
				<link rel="canonical" href={`https://${config.authDomain}`} />
			</Helmet>
			<SiteNavigation />
			<Callout
				title={<span>Top teams are using outcome based roadmaps, is yours?</span>}
				subtitle="Make the switch to create clarity and alignment between stakeholders and empower your team to solve the problems that matter the most."
				hero={
					<FeatureImage background={videoGradient}>
						<video playsInline autoPlay muted loop width="100%" height="auto">
							<source src={videoFile} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</FeatureImage>
				}
				withGrid
				header
			/>
			<FeatureContainer ref={containerRef}>
				<Svg>
					<Path d={svgPath} />
					<Connector cx={n1.left} cy={n2.top - n1n2gap / 2} />
					<Connector cx={n2.left} cy={f1.bottom} />
					{!isMobile && (
						<>
							<Connector cx={n2.left} cy={f1.bottom} />
							<Connector cx={n4.left} cy={f2.bottom} />
							<Connector cx={finalInsetWidth} cy={n6CenterY} />
							<Connector cx={containerEdge - finalInsetWidth} cy={n6CenterY} />
						</>
					)}
				</Svg>
				<FeatureBlock
					type="Plan"
					header="Stay focused on your products north star."
					description="Keep plans focused on what matters most with tree based visual roadmaps. Poda's visual roadmaps help you quickly see dependencies between items and how each body of work relates back to your goal."
					imageBlock={<NodeGroupOne refs={[node1, node2, node3]} />}
					refs={feature1}
					pt={["24px", "48px"]}
				/>
				<FeatureBlock
					type="Track"
					header="Track the progress towards objectives with key results."
					description="Poda allows you to add track key results for any outcomes and gives you simple visuals showing you the entire journey of where you are, how you got there, and where you should be."
					imageBlock={<NodeGroupTwo refs={node4} />}
					flip
					refs={feature2}
				/>
				<FeatureBlock
					type="Share"
					header="Keep stakeholders and teams aligned with clear roadmaps."
					description="Communicate priorities either as an opportunity solution tree or lean product roadmap. The lean product roadmap allows you to drill down into each initiative to reduce the noise and keep people focused on what is important to them."
					imageBlock={<NodeGroupThree refs={node5} />}
					refs={feature3}
					pb="0"
				/>
				<div ref={node6}>
					<FooterCta calloutRef={feature4} />
				</div>
			</FeatureContainer>
			<Footer />
			<Suspense fallback={<div />}>
				<PreFetchPricingData />
			</Suspense>
		</>
	);
};

const FeatureContainer = styled.div`
	position: relative;
	max-width: 1280px;
	margin: auto;
	margin-top: 32px;
`;

const Svg = styled.svg`
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;

	${breakpoints.lg`
		display: block;
	`}
`;

const Path = styled.path`
	stroke: ${utility.color("strokeLight")};
	stroke-width: 2;
	fill: none;
`;

const Circle = styled.circle`
	stroke: ${utility.color("strokeLight")};
	stroke-width: 2;
	fill: #fff;
`;

export default Home;
