const buildFunctionsUrl = (path: any) => new URL(`${process.env.VITE_FUNCTIONS_URL}${path}`);

const headers = {
	"Content-Type": "application/json",
};

// @ts-expect-error TS(7031) FIXME: Binding element 'workspaceId' implicitly has an 'a... Remove this comment to see the full error message
export const getSlackWebhookAuth = async ({ workspaceId, boardId }) => {
	const url = buildFunctionsUrl("/slack/install");
	const params = { workspaceId, boardId };
	url.search = new URLSearchParams(params).toString();

	const data = await fetch(url, {
		method: "GET",
		headers,
	});
	return data.text();
};

// @ts-expect-error TS(7031) FIXME: Binding element 'workspaceId' implicitly has an 'a... Remove this comment to see the full error message
export const deleteSlackWebhookAuth = async ({ workspaceId, boardId }) => {
	fetch(buildFunctionsUrl("/slack/uninstall"), {
		method: "POST",
		headers,
		body: JSON.stringify({ workspaceId, boardId }),
	});
};
