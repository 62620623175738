import React from "react";

import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { debounce } from "lodash";

import { EditorStyles } from "./EditorStyles";
import { createImageExtension } from "./extensions/Image";
import { Link } from "./extensions/Link";
import { useFileUpload } from "./hooks/useFileUpload";
import { MenuBar } from "./Menu/MenuBar";

interface Props {
	onSave: (body: any) => void;
	editable?: boolean;
	initialContent?: JSONContent;
}

export const Editor = ({ initialContent, onSave, editable = true }: Props) => {
	const uploadImage = useFileUpload();
	const autoSave = debounce(onSave, 25000, { trailing: true, leading: false });

	const editor = useEditor(
		{
			extensions: [
				StarterKit,
				TaskList,
				TaskItem,
				createImageExtension(uploadImage),
				Link.configure({
					openOnClick: false,
				}),
				Placeholder.configure({
					placeholder: "+ Add a description",
				}),
			],
			editable,
			content: initialContent,
			onBlur: ({ editor }) => {
				onSave(editor.getJSON());
				autoSave.cancel();
			},
			onUpdate: ({ editor }) => {
				autoSave(editor.getJSON());
			},
		},
		[initialContent],
	);

	if (!editor) {
		return null;
	}

	return (
		<>
			<EditorStyles />
			<MenuBar editor={editor} />
			<EditorContent editor={editor} />
		</>
	);
};
