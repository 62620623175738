import React, { useMemo } from "react";

import styled from "styled-components";

import { KeyResultModel } from "@/types/db";

import { KeyResultsActivityEmpty } from "./components/KeyResultsActivityEmpty";
import { KeyResultsActivityItem } from "./components/KeyResultsActivityItem";

type Props = {
	keyResult: KeyResultModel;
};

export const KeyResultsActivity: React.FC<Props> = ({ keyResult }) => {
	const keyResultNewestUpdateFirst = useMemo(
		() => ({
			...keyResult,
			updates: keyResult.updates.slice(0).reverse(),
		}),
		[keyResult],
	);

	const hasActivity = keyResult.updates.length > 0;

	return (
		<>
			<Heading>Updates</Heading>
			{hasActivity ? (
				keyResultNewestUpdateFirst.updates.map((update, index) => (
					<KeyResultsActivityItem
						keyResult={keyResultNewestUpdateFirst}
						index={index}
						update={update}
						key={`${update.createdAt}${update.value}`}
					/>
				))
			) : (
				<KeyResultsActivityEmpty />
			)}
		</>
	);
};

const Heading = styled.div`
	color: var(--color-text-heading);
	margin-bottom: 16px;
	font-weight: 500;
`;
