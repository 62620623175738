import styled from "styled-components";

import { useNodeHighlight } from "@/domains/nodes/components/cards/hooks/useNodeHighlight";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

type Props = {
	node: NodeModel;
	variant: "highlight" | "plain";
	className?: string;
};

export const NodeContainer: React.FC<Props> = ({ node, variant, children, className }) => {
	const isFocused = useNodesStore((state) => state.getIsFocused(node.id));
	const filterService = useFilterService();
	const color = useNodeHighlight(node);

	const isHighlighted = variant === "highlight";
	const isActive = filterService.getIsNodeFiltered(node);
	const isCreating = node.creating;

	return (
		<Root
			$isFocused={isFocused}
			$isActive={isActive}
			$isCreating={isCreating}
			$highlight={isHighlighted}
			$highlightColor={color}
			data-node-id={node.id}
			className={className}>
			{children}
		</Root>
	);
};

const Root = styled.div<any>`
	border: var(--border);
	border-radius: 8px;
	display: block;
	position: relative;
	text-decoration: none;
	color: inherit;
	opacity: ${({ $isActive }) => ($isActive ? 1 : 0.33)};
	cursor: pointer;

	${({ $isCreating }) => {
		if ($isCreating) {
			return `border-color: var(--color-accent);`;
		}
	}};

	${({ $highlight, $isFocused }) => {
		if (!$highlight && !$isFocused) {
			return `border-color: transparent`;
		}

		if ($isFocused) {
			return `
				border: 1px solid var(--color-accent);
			`;
		}
	}};

	:focus {
		outline: none;
	}
`;
