import { FiGlobe } from "react-icons/fi";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { projectSetPublic } from "@/modules/board/api/projectSetPublic";
import { EmbedActionGroup } from "@/modules/board/share/shareEmbed/components/EmbedActionGroup";
import { Toggle } from "@/shared/system";
import { PublicProfile, WithId } from "@/types/db";

type Props = {
	project: WithId<ProjectDtoBoard>;
	profile: PublicProfile;
};

export const EmbedPublicAccess: React.FC<Props> = ({ project, profile }) => {
	const isPublic = project.meta.public;

	const handleToggleAccess = async () => {
		projectSetPublic(project.id, !isPublic, profile);
	};

	const icon = <FiGlobe />;
	const content = (
		<>
			<div>Public access</div>
			Anyone with link can view
		</>
	);
	const action = <Toggle onClick={handleToggleAccess} selected={isPublic} testId="publicAccess--toggle" />;

	return <EmbedActionGroup icon={icon} content={content} action={action} />;
};
