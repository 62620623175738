import { selector } from "recoil";

import { workspacesState } from "./workspacesState";

export const getWorkspacesEnumerable = selector({
	key: "getWorkspacesEnumerable",
	get: ({ get }) => {
		const workspaces = get(workspacesState);
		return Object.values(workspaces);
	},
});
