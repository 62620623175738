import React, { useState } from "react";

import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

import Flex from "../Flex/Flex";
import Heading from "../Heading/Heading";

export const Tabs = ({ children, inline, boxed }: any) => {
	const [selectedTab, setSelectedTab] = useState(0);

	// @ts-expect-error TS(7006) FIXME: Parameter 'tabIndex' implicitly has an 'any' type.
	const selectTab = (tabIndex) => {
		setSelectedTab(tabIndex);
	};

	const TabListWrapper = boxed ? TabListBoxed : inline ? TabListInline : TabList;
	const TabButtonWrapper = boxed ? TabButtonBoxed : inline ? TabButtonInline : (TabButton as any);

	return (
		<TabListBoxed>
			<TabListWrapper role="tablist">
				{React.Children.map(children, ({ props: { label, onClick, style } }, index) => {
					const handleClick = onClick || selectTab;
					return (
						<TabButtonWrapper
							role="tab"
							boxed={boxed}
							selected={selectedTab === index}
							style={style}
							aria-selected={selectedTab === index ? "true" : "false"}
							onClick={() => handleClick(index)}>
							{label}
						</TabButtonWrapper>
					);
				})}
			</TabListWrapper>
			<TabContent role="tabcontent">
				{React.Children.map(children, (comp, index) => (selectedTab === index ? comp : undefined))}
			</TabContent>
		</TabListBoxed>
	);
};

const TabListBoxed = styled(Flex)`
	flex-direction: column;
	overflow: auto;
`;

export const TabButtonBoxed = styled.a<{ selected: boolean }>`
	display: inline-block;
	cursor: pointer;
	flex-grow: 1;
	flex-basis: 0;

	&:hover {
		background-color: rgba(0, 0, 0, 0.03);
	}

	${({ theme, selected }) => ({
		padding: theme.space[3] + "px",
		borderRadius: theme.radii[2] + "px",
		backgroundColor: selected ? "rgba(0,0,0,.03)" : "none",
	})}
`;

export const TabButton = styled(Heading.H5)`
	${({ theme, selected }) => ({
		is: "a",
		height: "auto",
		padding: "0",
		paddingBottom: "12px",
		verticalAlign: "middle",
		display: "flex",
		alignItems: "center",
		transform: "translateY(1px)",
		fontWeight: theme.fontWeights.medium,
		cursor: "pointer",
		color: selected ? "var(--color-accent-hover)" : "var(--color-text)",
		transition: "color 200ms ease-in-out",

		"&:hover": {
			color: "var(--color-accent-hover)",
		},
	})}
`;

TabButton.defaultProps = {
	mr: "24px",
	py: 2,
};

export const TabList = styled(Flex)`
	flex: 0 0 auto;
	align-items: stretch;
	margin-bottom: 0;
`;

export const TabListInline = styled(Flex)`
	flex: 0 0 auto;
	display: inline-block;
	border: 1px;
	align-items: stretch;
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
	${space}
`;

TabListInline.defaultProps = {
	px: 3,
	mb: 0,
};

export const TabButtonInline = styled.a<{ selected: boolean }>`
	height: auto;
	color: inherit;
	border-color: transparent;
	${({ selected, theme }) => ({
		verticalAlign: "middle",
		height: "32px",
		lineHeight: "30px",
		display: "inline-block",
		alignItems: "center",
		transform: "translateY(1px)",
		"&:hover": {
			color: theme.colors.blackLight,
		},
		cursor: "pointer",
		color: selected ? theme.colors.blackDark : theme.colors.secondary,
		borderColor: selected ? theme.colors.blackDark : "none",
	})}
`;

const TabContent = styled.div<SpaceProps>`
	flex-grow: 1;
	${space}
`;

export default Tabs;
