import { useMemo } from "react";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

const voidFunction = () => {};

export const useNodeFocus = () => {
	const { canEdit } = usePermissions("project");

	const setFocused = useNodesStore((state) => state.setFocused);
	const isEditing = useNodesStore((state) => state.getIsEditing());

	const canFocus = canEdit && !isEditing;
	const handleFocus = canFocus ? setFocused : voidFunction;

	return useMemo(() => ({ setFocused: handleFocus }), [handleFocus]);
};
