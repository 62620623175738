import { forwardRef } from "react";

import styled from "styled-components";

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
	hover?: boolean;
	variant?: "thin";
}

export const Select = forwardRef<HTMLSelectElement, Props>((props, ref) => {
	return (
		<Root>
			<StyledSelect {...props} ref={ref} />
		</Root>
	);
});

Select.displayName = "Select";

const Root = styled.div`
	position: relative;

	::after {
		--caret: url("data:image/svg+xml;utf8,<svg fill='white' width='9' height='5' viewBox='0 0 9 5'  xmlns='http://www.w3.org/2000/svg'><path d='M1.415.057L.472 1l4 4 4-4-.944-.943-3.056 3.056L1.415.057z'></path></svg>");

		position: absolute;
		top: 0;
		right: 0.6em;
		bottom: 0;
		display: block;
		margin: auto;
		width: 0.8em;
		height: 0.8em;
		background-color: var(--color-text);
		mask-image: var(--caret);
		mask-position: center;
		mask-repeat: no-repeat;
		mask-size: contain;
		content: "";
	}
`;

type StyledProps = {
	hover?: Props["hover"];
	variant?: Props["variant"];
};

export const StyledSelect = styled.select<StyledProps>`
	border: var(--border);
	background-color: transparent;
	font-size: inherit;
	color: inherit;
	box-shadow: none;
	line-height: 20px;
	outline: none;
	width: 100%;
	padding-right: 1.6em;
	border-radius: 4px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	background-position: right 16px center;

	${({ variant }) => {
		switch (variant) {
			case "thin": {
				return {
					padding: "2px 24px 2px 8px",
					fontSize: "12px",
					backgroundPosition: "right 8px center",
				};
			}
			default: {
				return {
					padding: "10px 28px 10px 14px",
					backgroundPosition: "right 16px center",
				};
			}
		}
	}};

	&::-ms-expand {
		display: none;
	}
	&:focus {
		border-color: var(--color-accent);
	}

	&:hover {
		background-color: ${({ hover }) => (hover ? "var(--color-hover)" : "none")};
	}

	option {
		text-transform: capitalize;
	}
`;
