import { FieldErrors, UseFormRegister } from "react-hook-form";

import { InputWithUnitsWrapper } from "@/domains/nodes/components/keyResults/components/forms/components/InputWithUnitsWrapper";
import { Input, InputWrapper, Label, Validation } from "@/shared/system";

export const getDateInputNow = function () {
	const local = new Date();
	local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
	return local.toJSON().slice(0, 10);
};

type Props = {
	register: UseFormRegister<any>;
	errors: FieldErrors;
	label: string;
	name: "startDate" | "endDate" | "date";
	required?: boolean;
	defaultValue?: string;
	min?: string;
	max?: string;
};

export const InputDate: React.FC<Props> = ({ defaultValue, min, max, name, errors, register, label, required }) => {
	const inputProps = register(name, { required });
	const error = errors[name];

	return (
		<InputWrapper>
			<Label htmlFor={name}>{label}</Label>
			<InputWithUnitsWrapper>
				<Input
					type="date"
					id={name}
					mb={0}
					min={min}
					max={max}
					isError={!!error}
					defaultValue={defaultValue}
					{...inputProps}
				/>
			</InputWithUnitsWrapper>
			<Validation label={label} error={error} />
		</InputWrapper>
	);
};
