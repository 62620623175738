import { atom, atomFamily, selectorFamily } from "recoil";

import { authenticatedIdState } from "../../authentication/selectors/authenticatedIdState";
import { upsertBoardMeta } from "./metaFunctions";

const defaultCoords = { x: 0, y: 92 };

const defaultCanvas = {
	scale: 1,
	origin: defaultCoords,
	translate: defaultCoords,
};

export const metaCanvasState = atomFamily<typeof defaultCanvas, string>({
	key: "metaCanvasState",
	default: defaultCanvas,
});

export const metaCollapsedState = atom<string[]>({
	key: "metaCollapsedState",
	default: [],
});

export const metaAsyncState = selectorFamily({
	key: "metaAsyncState",
	get: () => () => {},
	set:
		(boardId: string) =>
		({ get }) => {
			const userId = get(authenticatedIdState);
			const canvas = get(metaCanvasState(boardId));
			const collapsed = get(metaCollapsedState);

			if (userId) {
				upsertBoardMeta(userId, {
					collapsed,
					boards: {
						[boardId]: canvas,
					},
				});
			}
		},
});
