import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import { getPlanDetails, PlanDetails } from "@/modules/plans/selectors/getPlanDetails";
import { Result } from "@/shared/utils/result";

type ConstructorArgs = {
	subscription: PlanDetails | undefined;
};

export class SubscriptionService {
	subscription;

	constructor({ subscription }: ConstructorArgs) {
		this.subscription = subscription;
	}

	getCanAddNodes(): Result<boolean> {
		if (!this.subscription) {
			return Result.fail("Unable to find subscription");
		}
		return Result.ok(!this.subscription.usage.nodes.isLocked);
	}
}

export const useSubscriptionService = () => {
	// TODO: This should access state via an adapter rather than querying recoil directly
	const subscription = useRecoilValue(getPlanDetails);

	const subscriptionService = useMemo(() => new SubscriptionService({ subscription }), [subscription]);

	return subscriptionService;
};
