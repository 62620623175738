import { httpsCallable } from "firebase/functions";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { ProjectBoard, WithId } from "@/types/db";

import { fb, fb as firebase } from "../../../../shared/infra/init";
import { Modal } from "../../../../shared/system";
import { FileUpload } from "../../../../shared/system/FileUpload";
import { ModalHeader, ModalSubHeader } from "../../../../shared/system/Modal/Modal";
import { useProfileLegacy } from "../../../profile/hooks/useProfileLegacy";
import { addImport, updateImportStatus } from "../../boardFunctions";
import { useImportListener } from "../hooks/useImportListener";
import { MoreImportFiles } from "./MoreImportFiles";

type Props = {
	board: WithId<ProjectBoard>;
	isOpen: boolean;
	setOpen: (isOpen: boolean) => void;
};

type RequestProcessCsv = {
	boardId: string;
	rootId: string;
	importId: string;
	workspaceId: string;
	userId: string;
	bucket: string;
	file: string;
};

const processCsv = httpsCallable<RequestProcessCsv, void>(fb.functions, "processCsv");

export const MoreImportModal = ({ board, isOpen, setOpen }: Props) => {
	const profile = useProfileLegacy();
	const analyticsService = useAnalyticsService();

	useImportListener(board.id);

	const workspaceId = board.workspaceId;

	const onDrop = async (acceptedFiles: File[]) => {
		const fileName = acceptedFiles[0].name;

		const importDoc = await addImport(board.id, {
			status: "uploading",
			workspaceId,
			createdBy: profile.id,
			fileName,
		});

		const csvRef = firebase.storage.child(`/workspaces/${workspaceId}/imports/${board.id}/${fileName}`);

		csvRef
			.put(acceptedFiles[0])
			.then((snapshot) => {
				updateImportStatus(board.id, importDoc.id, "processing");

				return processCsv({
					workspaceId,
					importId: importDoc.id,
					boardId: board.id,
					rootId: board.rootId,
					userId: profile.id,
					bucket: snapshot.ref.bucket,
					file: snapshot.ref.fullPath,
				});
			})
			.then(() => {
				analyticsService.projectImported({
					boardId: board.id,
					workspaceId: board.workspaceId,
				});
			})
			.catch((e) => {
				updateImportStatus(board.id, importDoc.id, "error");
				console.log("error", e);
			});
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "text/csv",
		multiple: false,
		onDrop,
	});

	const rootProps = getRootProps();
	const inputProps = getInputProps();

	const uploadText = (
		<>
			<strong>Click to upload</strong> or drag and drop <br />
			CSV <em>(max. 100 rows)</em>
		</>
	);

	return (
		<Modal isOpen={isOpen} onClose={setOpen}>
			<ModalHeader>Import from CSV</ModalHeader>
			<ModalSubHeader>
				To import a project from CSV see{" "}
				<Link
					href="https://docs.google.com/spreadsheets/d/1Eqe-wsLWtyQ2C8RthvLDfGlAMAex8cT0HzLMPCiE3a4"
					target="_blank"
					rel="noreferrer">
					example.csv
				</Link>{" "}
				for details
			</ModalSubHeader>
			<FileUpload rootProps={rootProps} inputProps={inputProps} message={uploadText} />
			<MoreImportFiles boardId={board.id} />
		</Modal>
	);
};

const Link = styled.a`
	color: var(--color-accent);
`;
