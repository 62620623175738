import styled from "styled-components";

import { IconType } from "@/domains/nodes/components/iconPicker/components/IconType";
import { IconPicker } from "@/domains/nodes/components/iconPicker/IconPicker";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

type Props = {
	node: NodeModel;
};

export const DetailsIcon: React.FC<Props> = ({ node }) => {
	const { canEdit } = usePermissions("project");
	const nodeIcon = node.icon;

	if (!nodeIcon) {
		return null;
	}

	return (
		<IconPicker node={node}>
			<IconWrapper canEdit={canEdit}>
				<IconType icon={nodeIcon} />
			</IconWrapper>
		</IconPicker>
	);
};

const IconWrapper = styled.div<{ canEdit?: boolean }>`
	width: 80px;
	height: 80px;
	font-size: 80px;
	margin-bottom: 8px;
	line-height: 1;
	cursor: pointer;

	${({ canEdit }) => {
		return (
			canEdit &&
			`
				:hover {
					background: var(--color-hover);
					border-radius: 8px;
				}
			`
		);
	}}
`;
