import { DocumentSnapshot } from "firebase/firestore";

import { ProjectSingle } from "@/domains/projects/models/projectsModel";
import { ProjectDto } from "@/types/db";

export class ProjectsUtil {
	public static toViewModel(snapshot: DocumentSnapshot<ProjectDto>): ProjectSingle {
		const id = snapshot.id;

		const notFound = {
			code: 404 as const,
			data: null,
		};

		if (!snapshot.exists()) {
			return notFound;
		}

		const dto = snapshot.data();
		const data = dto; //projectDto.parse(dto);

		return {
			code: 200,
			data: { id, ...data },
		};
	}
}
