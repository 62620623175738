import { ReactNode } from "react";

import { format, formatRelative, intervalToDuration, isFuture, parseISO } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import { FiClock } from "react-icons/fi";
import styled from "styled-components";

import { NodeMetaDataDateTypes, NodeMetaTypes } from "@/types/db";

const formatRelativeLocale: Record<string, string> = {
	lastWeek: "'Last' eeee",
	yesterday: "'Yesterday'",
	today: "'Today'",
	tomorrow: "'Tomorrow'",
	nextWeek: "'Next' eeee",
	other: "dd/MM/yyyy",
};

const locale = {
	...enGB,
	formatRelative: (token: string) => formatRelativeLocale[token],
};

export const formatTimestamp = (date: string, dateFormat: string): string => {
	if (dateFormat === "relative") {
		return formatRelative(parseISO(date), new Date(), { locale });
	}

	return format(parseISO(date), dateFormat);
};
type Props = {
	value?: NodeMetaDataDateTypes;
	dateFormat: string;
};

export const DatePickerSelectedValue = ({ value, dateFormat }: Props) => {
	switch (value?.type) {
		case NodeMetaTypes.date: {
			const { date } = value.data;
			return (
				<Value reminder={value.data.reminderOffset} to={date}>
					{formatTimestamp(date, dateFormat)}
				</Value>
			);
		}
		case NodeMetaTypes.dateRange: {
			const { to, from } = value.data;
			return (
				<Value reminder={value.data.reminderOffset} to={value.data.to}>
					{formatTimestamp(from, dateFormat)} → {formatTimestamp(to, dateFormat)}
				</Value>
			);
		}
		default: {
			return <div>Empty</div>;
		}
	}
};

const timeToColor = (to: string) => {
	const endDate = parseISO(to);
	const interval = intervalToDuration({
		start: new Date(),
		end: endDate,
	});

	const futureDate = isFuture(endDate);

	if (!interval.days || interval.days === 0 || !futureDate) {
		return "red";
	} else if (interval.days > 0) {
		return "blue";
	}
};

type ValueProps = {
	reminder: number | null;
	to: string;
	children: ReactNode;
};

const Value = ({ reminder, to, children }: ValueProps) => {
	const hasReminder = reminder !== null;
	const color = timeToColor(to) || "text";

	return (
		<Root hasReminder={hasReminder} alertColor={color}>
			{children}
			{hasReminder && <FiClock />}
		</Root>
	);
};

const Root = styled.div<{ alertColor: string; hasReminder: boolean }>`
	display: flex;
	align-items: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 0;

	${({ hasReminder, alertColor, theme }) => {
		if (hasReminder) {
			return { color: theme.colors[alertColor] };
		}
	}};

	> svg {
		margin-left: 6px;
	}
`;
