import { useCallback } from "react";

import styled from "styled-components";

import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { CoverImageLink } from "@/modules/note/coverImage/components/CoverImageLink";
import { CoverImageUpload } from "@/modules/note/coverImage/components/CoverImageUpload";
import { useStorageService } from "@/shared/core/hooks/useStorageService";

import { Box, Tabs } from "../../../../shared/system";
import { modalRoot } from "../../../inbox/components/InboxModal";
import { CoverImageGallery } from "./CoverImageGallery";

const removeButtonStyle = {
	marginLeft: "auto",
	marginRight: "0",
	color: "var(--color-text)",
};

type Props = {
	node: NodeModel;
};

export const CoverImageSelectModal = ({ node }: Props) => {
	const nodesService = useNodesService();
	const storageService = useStorageService();

	const { id: nodeId, cover } = node;

	const isCoverImage = cover?.type === "image";

	const handleRemove = useCallback(() => {
		nodesService.update(nodeId, { cover: null });

		if (isCoverImage && cover.local) {
			storageService.delete(cover.src);
		}
	}, [cover, isCoverImage, nodeId, nodesService, storageService]);

	if (!isCoverImage) {
		return null;
	}

	return (
		<Root>
			<Tabs defaultTab={1}>
				<Box label="Gallery">
					<CoverImageGallery nodeId={nodeId} />
				</Box>
				<Box label="Upload">
					<TabContent>
						<CoverImageUpload node={node} />
					</TabContent>
				</Box>
				<Box label="Link">
					<TabContent>
						<CoverImageLink node={node} />
					</TabContent>
				</Box>
				<Box label="Remove" onClick={handleRemove} style={removeButtonStyle} />
			</Tabs>
		</Root>
	);
};

const Root = styled.div`
	${modalRoot}
	border: 0;
	box-shadow: 0;
	margin: -6px;
	width: 460px;
`;

const TabContent = styled.div`
	padding: 20px;
`;
