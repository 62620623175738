import { z } from "zod";

import { projectDto } from "@/domains/projects/dtos/projectsDto";
import { Entity, UniqueEntityId } from "@/modules/authentication/utils/entity";
import { Result } from "@/shared/utils/result";

export type IProjectProps = z.infer<typeof projectDto>;

export class Project extends Entity<IProjectProps> {
	public static createProject(props: IProjectProps, id?: UniqueEntityId): Result<Project> {
		try {
			const resultsProjectProps = projectDto.parse(props);
			return Result.ok<Project>(new Project(resultsProjectProps, id));
		} catch (e) {
			return Result.fail<Project>("Failed to create project");
		}
	}
}
