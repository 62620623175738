import { useEffect } from "react";

import { limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { atomFamily, useRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../../shared/infra/init";
import { extractFromCollectionWithId } from "../../../../shared/utils/dataUtils";
import { ProjectImport, WithId } from "../../../../types/db";

export const importState = atomFamily<WithId<ProjectImport>[], string>({
	key: "importState",
	default: [],
});

export const useImportListener = (boardId: string) => {
	const [, setImports] = useRecoilState(importState(boardId));

	useEffect(() => {
		if (boardId) {
			const importQuery = query(db.projectsImport(boardId), orderBy("createdAt", "desc"), limit(1));

			return onSnapshot(
				importQuery,
				(snapshot) => {
					setImports(extractFromCollectionWithId(snapshot));
				},
				onSnapshotError("useNotificationsListener"),
			);
		}
	}, [setImports, boardId]);
};
