import { selector } from "recoil";

import { extractFromCollectionWithId } from "../../shared/utils/dataUtils";
import { Product, ProductPrices } from "../../types/db";
import { WithId } from "../../types/db/utils";
import { getCurrentWorkspace } from "../workspace/selectors/getCurrentWorkspace";
import { getProducts as firebaseGetProducts, getProductPricing, portalLink } from "./planFunctions";
import { MangledProduct } from "./planTypes";

const buildProductWithPricing = async (product: WithId<Product>): Promise<MangledProduct> => {
	const productPricing = await getProductPricing(product.id);
	const pricing = extractFromCollectionWithId<ProductPrices>(productPricing);

	const lineItems = pricing
		.filter((price) => price.active)
		.map((price) => {
			return {
				price: price.id,
				quantity: 1,
			};
		});

	/**
	 * Assumes the last item has the main price. This is used for lifetime subscriptions
	 * where a $0 subscription is added as well as a One off line item
	 */
	const { unit_amount } = pricing[pricing.length - 1];

	return {
		...product,
		pricing,
		line_items: lineItems,
		unit_amount,
	};
};

const productInformation = selector<MangledProduct[]>({
	key: "productInformation",
	get: async () => {
		const productDocs = await firebaseGetProducts();
		const products = extractFromCollectionWithId<Product>(productDocs);
		return Promise.all(products.map(buildProductWithPricing));
	},
});

export const getProducts = selector({
	key: "productsState",
	get: async ({ get }) => {
		const products = await get(productInformation);
		const priceLowToHigh = (a: MangledProduct, b: MangledProduct) => a.unit_amount - b.unit_amount;

		return products.map((_) => _).sort(priceLowToHigh);
	},
});

export const getPortalLink = selector({
	key: "portalLinkState",
	get: async ({ get }) => {
		const { billingId } = get(getCurrentWorkspace);

		if (billingId) {
			const { data } = await portalLink(billingId);
			return data;
		}

		return {};
	},
});
