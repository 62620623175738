import { useCallback } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { getDateInputNow, InputDate } from "@/domains/nodes/components/keyResults/components/forms/components/InputDate";
import { InputMetric } from "@/domains/nodes/components/keyResults/components/forms/components/InputMetric";
import { InputStatus } from "@/domains/nodes/components/keyResults/components/forms/components/InputStatus";
import { useKeyResultsService } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsService";
import {
	KeyResultsActivityFormFields,
	KeyResultsSetScreen,
} from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { getCurrentWorkspace } from "@/modules/workspace/selectors/getCurrentWorkspace";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { Button, utility } from "@/shared/system";
import { ModalActionGroup, ModalHeader } from "@/shared/system/Modal/Modal";
import { KeyResultModel } from "@/types/db";

import { useProfileLegacy } from "../../../../../../modules/profile/hooks/useProfileLegacy";
import { isNoTarget } from "../../utils/keyResultsUtils";
import { InputComment } from "./components/InputComment";

export const formValues = {
	date: "date",
	endDate: "endDate",
	value: "value",
	description: "description",
	status: "onTrack",
};

type Props = {
	keyResult: KeyResultModel;
	onComplete: KeyResultsSetScreen;
};

export const KeyResultsActivityForm: React.FC<Props> = ({ keyResult, onComplete }) => {
	const analyticsService = useAnalyticsService();

	const { goalType, targetValue, updates } = keyResult;

	const [lastUpdate] = updates.slice(-1);

	const defaultValues = {
		status: lastUpdate?.status || "onTrack",
	};

	const handleComplete = useCallback(() => {
		onComplete("details");
	}, [onComplete]);

	const { createResult } = useKeyResultsService();
	const { register, handleSubmit, formState, reset } = useForm<KeyResultsActivityFormFields>({
		defaultValues,
	});
	const { errors } = formState;
	const profile = useProfileLegacy();
	const { boardId } = useParams() as { boardId: string; nodeId: string };
	const workspace = useRecoilValue(getCurrentWorkspace);

	const onSubmit: SubmitHandler<KeyResultsActivityFormFields> = async (data) => {
		if (!workspace) {
			return;
		}

		await createResult(keyResult.id, { ...data, authorId: profile?.id }, keyResult);
		analyticsService.keyResultUpdated({ boardId, workspaceId: workspace.id });
		reset();
		handleComplete();
	};

	return (
		<>
			<ModalHeader>Update Key Result</ModalHeader>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InlineInputs>
					<InputDate
						label="Date"
						name="date"
						defaultValue={getDateInputNow()}
						max={getDateInputNow()}
						register={register}
						errors={errors}
					/>
					{!isNoTarget(goalType) && (
						<InputMetric
							label="Progress"
							name="value"
							template={targetValue}
							placeholder={lastUpdate?.value}
							register={register}
							errors={errors}
							required
						/>
					)}
				</InlineInputs>
				<InputStatus register={register} errors={errors} />
				<InputComment register={register} errors={errors} />
				<ModalActionGroup>
					<Button.Secondary onClick={handleComplete}>Cancel</Button.Secondary>
					<Button type="submit">Update progress</Button>
				</ModalActionGroup>
			</form>
		</>
	);
};

const InlineInputs = styled.div`
	display: flex;

	> * {
		flex: 1;
	}

	> :not(:last-child) {
		margin-right: ${utility.space(3)}px;
	}
`;
