import { FiEdit2 } from "react-icons/fi";
import styled from "styled-components";
import { z } from "zod";

import { nodeMetaTypeLink } from "@/domains/nodes/dtos/nodeMetaDto";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { Button, Tooltip } from "@/shared/system";
import { ActionsFn, DropdownText } from "@/shared/system/DropdownText/DropdownText";

export function stringToUrl(string: string) {
	let url = string.trim();

	if (url.length === 0) {
		return "";
	}

	if (url && !/(http(s?)):\/\//i.test(url)) {
		url = `https://${url}`;
	}

	return new URL(url);
}

interface Props {
	meta: z.infer<typeof nodeMetaTypeLink>;
	node: NodeModel;
}

export const NodeMetaLink = ({ node, meta }: Props) => {
	const currentValue = node?.meta?.[meta.id];
	const placeholderText = `Add ${meta.label.toLowerCase()}...`;
	const nodesService = useNodesService();

	const link = typeof currentValue === "string" ? currentValue : "";

	const hasValue = !!link;

	const handleSave = (link: string) => {
		let url = link.trim();

		if (url && !/(http(s?)):\/\//i.test(url)) {
			url = `https://${link}`;
		}

		nodesService.updateMeta(node.id, meta.id, stringToUrl(url).toString());
	};

	const displayValue = (
		<DisplayValue href={link} target="_blank" rel="nofollow noreferrer" data-testid="dropdown-text--link">
			{link}
		</DisplayValue>
	);

	const actions: ActionsFn = ({ open }) => (
		<Tooltip label="Edit URL">
			<Button.Plain onClick={() => open(true)} py={0} px="6px" data-testid="dropdown-text--edit">
				<FiEdit2 size="16px" />
			</Button.Plain>
		</Tooltip>
	);

	return (
		<DropdownText
			label={meta.label}
			displayValue={displayValue}
			value={link}
			placeholderText={placeholderText}
			actions={actions}
			onComplete={handleSave}
			openOnClick={!hasValue}
		/>
	);
};

const DisplayValue = styled.a`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 290px;
`;
