import styled from "styled-components";

import { ActivitySubscribe } from "@/domains/nodes/components/details/activity/components/ActivitySubscribe";
import { NodeActivity } from "@/domains/nodes/components/details/activity/NodeActivity";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useCommentsListener } from "@/modules/comments/hooks/useComments";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

import { CommentForm } from "../../../domains/nodes/components/details/activity/components/CommentForm";

type Props = {
	node: NodeModel;
};

export const NoteDetailsActivity: React.FC<Props> = ({ node }) => {
	useCommentsListener(node.id, node.workspace);

	const { canComment } = usePermissions("project");

	if (!canComment) {
		return null;
	}

	return (
		<>
			<Header>
				<Heading>Discussion</Heading>
				<ActivitySubscribe node={node} />
			</Header>
			<NodeActivity node={node} canComment={canComment} />
			{canComment && <CommentForm node={node} />}
		</>
	);
};

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;
`;

const Heading = styled.h4`
	margin: 0;
`;
