import { IconType } from "react-icons";

import { NodeMetaTypeUnion } from "../../../types/db";
import { getNodeMetaTypeIcon } from "./getNodeMetaTypeIcon";

export type NodeMetaTypeOption = {
	type: NodeMetaTypeUnion;
	label: string;
	icon: IconType;
};

export const getNodeMetaTypes: NodeMetaTypeOption[] = [
	{
		type: "select",
		label: "Select",
		icon: getNodeMetaTypeIcon("select"),
	},
	{
		type: "list",
		label: "Multi-select",
		icon: getNodeMetaTypeIcon("list"),
	},
	{
		type: "person",
		label: "Person",
		icon: getNodeMetaTypeIcon("person"),
	},
	{
		type: "date",
		label: "Date",
		icon: getNodeMetaTypeIcon("date"),
	},
	{
		type: "link",
		label: "URL",
		icon: getNodeMetaTypeIcon("link"),
	},
	{
		type: "email",
		label: "Email",
		icon: getNodeMetaTypeIcon("email"),
	},
	{
		type: "text",
		label: "Text",
		icon: getNodeMetaTypeIcon("text"),
	},
];
