import { Navigate, Route, Routes } from "react-router-dom";

import TreePage from "@/pages/projects/tree.page";
import AppLoading from "@/shared/system/InApp/AppLoading";

import { LayoutApplication } from "../../modules/page/LayoutApplication";
import { lazyWithRetry } from "../utils/lazyWithRetry";
import routes from "./routes";

const AccountPage = lazyWithRetry(() => import("../../modules/account/WorkspaceSettingsPage"));
const AppSumoPage = lazyWithRetry(() => import("../../modules/authentication/AppSumoPage"));
const DashboardPage = lazyWithRetry(() => import("../../modules/dashboard/DashboardPage"));
const EmailActionsPage = lazyWithRetry(() => import("../../modules/authentication/EmailActionsPage"));
const Error404 = lazyWithRetry(() => import("../../modules/errors/Error404"));
const MembersPage = lazyWithRetry(() => import("../../modules/members/MembersPageWorkspace"));
const OnboardingPage = lazyWithRetry(() => import("../../modules/onboarding/OnboardingPage"));
const PlansPage = lazyWithRetry(() => import("../../modules/plans/PlanPageAccount"));
const ProfilePage = lazyWithRetry(() => import("../../modules/profile/ProfilePage"));
const SettingsPage = lazyWithRetry(() => import("../../modules/board/settings/SettingsPage"));
const PublicBoardPage = lazyWithRetry(() => import("../../modules/board/public/PublicBoard"));
const RoadmapPage = lazyWithRetry(() => import("../../pages/projects/roadmap.page"));

export const RoutesApplication = () => {
	return (
		<Routes>
			<Route path={routes.welcome} element={<OnboardingPage />} />
			<Route path={routes.workspace} element={<Navigate to="/" replace />} />
			<Route path={routes.login} element={<Navigate to={routes.home} replace />} />
			<Route path={routes.signUp} element={<AppLoading />} />
			<Route path={routes.appSumo} element={<AppSumoPage />} />

			<Route element={<LayoutApplication />}>
				<Route index element={<DashboardPage />} />
				<Route path={routes.account} element={<AccountPage />} />
				<Route path={routes.board} element={<TreePage />} />
				<Route path={routes.boardEmbed} element={<PublicBoardPage />} />
				<Route path={routes.boardSettings} element={<SettingsPage />} />
				<Route path={routes.emailActions} element={<EmailActionsPage />} />
				<Route path={routes.members} element={<MembersPage />} />
				<Route path={routes.plans} element={<PlansPage />} />
				<Route path={routes.profile} element={<ProfilePage />} />
				<Route path={routes.kanban} element={<RoadmapPage />} />
				<Route path="*" element={<Error404 />} />
			</Route>
		</Routes>
	);
};
