import React, { RefObject, SyntheticEvent, useEffect, useLayoutEffect } from "react";

import styled from "styled-components";

import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

type Props = {
	placeholder?: string;
	value: string;
	el: RefObject<HTMLInputElement>;
	onChange: React.Dispatch<React.SetStateAction<string>>;
	onEscape: VoidFunction;
};

export const SelectDropdownSearch = ({ placeholder, value, el, onChange, onEscape }: Props) => {
	useBindShortcut("escape", onEscape, el);

	useEffect(() => {
		return () => {
			onChange("");
		};
	}, [onChange]);

	useLayoutEffect(() => {
		el.current?.focus();
	}, [el]);

	const handleSearch = (e: SyntheticEvent<HTMLInputElement>) => {
		onChange(e.currentTarget.value);
	};

	return <Input type="text" placeholder={placeholder} onChange={handleSearch} value={value} ref={el} />;
};

const Input = styled.input`
	border: 0;
	margin-left: 4px;
	font-size: 14px;
	line-height: 22px;
	padding: 6px 12px;
	background: transparent;
	flex-grow: 1;
	width: 100%;

	+ * {
		border-top: var(--border);
	}

	:first-child {
		margin-left: 0;
	}

	:focus,
	:active {
		outline: none;
	}
`;
