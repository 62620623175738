import { createGlobalStyle } from "styled-components";

import { Theme } from "@/modules/board/settings/components/theme/models/themeModel";
import { getContrastTextColor } from "@/modules/board/settings/components/theme/utils/getContrastTextColor";
import { getThemeForProject } from "@/modules/board/settings/components/theme/utils/getThemeForProject";
import { ProjectBoard } from "@/types/db";

type Props = {
	project: ProjectBoard;
};
export const ThemeStyles: React.FC<Props> = ({ project }) => {
	const customTheme = getThemeForProject(project);

	if (!customTheme) {
		return null;
	}

	return <GlobalStyles theme={customTheme} />;
};

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
	:root {
		--color-body: ${({ theme }) => theme.body} !important;
		--color-accent: ${({ theme }) => theme.accent} !important;
		--color-contrast: ${({ theme }) => getContrastTextColor(theme.body)} !important;
	}
`;
