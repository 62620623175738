import { selector } from "recoil";

import { subscriptionState } from "../../profile/hooks/useSubscriptionDetails";
import { getCurrentWorkspace } from "../../workspace/selectors/getCurrentWorkspace";
import { workspaceUsageState } from "../hooks/usePlanUsageListener";
import { AvailablePlans, PlanAttributes } from "../planUtils";

const freeNodeLimit = process.env.VITE_FREE_NODE_COUNT ? parseInt(process.env.VITE_FREE_NODE_COUNT, 10) : 50;

const freeMemberLimit = process.env.VITE_FREE_MEMBER_COUNT ? parseInt(process.env.VITE_FREE_MEMBER_COUNT, 10) : 2;

const freeGuestLimit = process.env.VITE_FREE_MEMBER_COUNT ? parseInt(process.env.VITE_FREE_MEMBER_COUNT, 10) : 2;

const memberLimits: Record<AvailablePlans, number> = {
	Free: freeMemberLimit,
	Solo: 2,
	Team: 10,
	Business: 200,
	AppSumo: Infinity,
	Lifetime: Infinity,
	Premium: Infinity, //Deprecated only used in staging
};

const guestLimits: Record<AvailablePlans, number> = {
	Free: freeGuestLimit,
	Solo: 3,
	Team: 20,
	Business: 200,
	AppSumo: Infinity,
	Lifetime: Infinity,
	Premium: Infinity, //Deprecated only used in staging
};

const nodeLimits: Record<AvailablePlans, number> = {
	Free: freeNodeLimit,
	Solo: Infinity,
	Team: Infinity,
	Business: Infinity,
	AppSumo: Infinity,
	Lifetime: Infinity,
	Premium: Infinity, //Deprecated only used in staging
};

type PlanUsage = {
	limit: number;
	count: number;
	isLocked: boolean;
};

export type PlanDetails = {
	plan: AvailablePlans;
	usage: Record<PlanAttributes, PlanUsage>;
	isPaidPlan: boolean;
};

export const getPlanDetails = selector<PlanDetails | undefined>({
	key: "planDetails",
	get: ({ get }) => {
		const workspace = get(getCurrentWorkspace);

		if (!workspace) {
			return;
		}

		const subscription = get(subscriptionState(workspace?.subscriptionId));

		const activePlan = subscription?.status === "active" ? subscription?.role : "free";
		const plan = AvailablePlans[activePlan];

		const isPaidPlan = activePlan !== "free";

		const { totalNodes, totalMembers, totalGuests, totalInvites, totalInvitesGuest } = get(workspaceUsageState);

		const nodeLimit = nodeLimits[plan];
		const memberLimit = memberLimits[plan];
		const guestLimit = guestLimits[plan];

		return {
			nodeCount: totalNodes,
			memberCount: totalMembers,
			plan: AvailablePlans[activePlan],
			usage: {
				nodes: {
					limit: nodeLimit,
					count: totalNodes,
					isLocked: totalNodes >= nodeLimit,
				},
				guests: {
					limit: guestLimit,
					count: totalGuests,
					isLocked: totalGuests + totalInvitesGuest >= guestLimit,
				},
				members: {
					limit: memberLimit,
					count: totalMembers,
					isLocked: totalMembers + totalInvites >= memberLimit,
				},
			},
			isPaidPlan,
		};
	},
});
