import React, { useState } from "react";

import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";

import { Button, Input, Label, Select, ValidationError } from "../../../shared/system";
import { ModalActionGroup } from "../../../shared/system/Modal/Modal";
import { User, Workspace, WorkspaceMemberRole } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { createInvite } from "../memberFunctions";

export function validateEmailAddress(email: string) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export type InviteScope = "workspace" | "board";
export type InviteRoles = { value: WorkspaceMemberRole; label: string }[];

type Props = {
	board?: WithId<ProjectDtoBoard>;
	existingMembers?: string[];
	onSubmit: () => void;
	roles: InviteRoles;
	scope: InviteScope;
	user: User;
	workspace: WithId<Workspace>;
};

export const MembersInvite = ({ workspace, board, user, roles, scope, existingMembers, onSubmit }: Props) => {
	const [email, setEmail] = useState<string>("");
	const [role, setRole] = useState<WorkspaceMemberRole>("editor");
	const [showEmailValidation, setShowEmailValidation] = useState<string | null>(null);

	const isEmailValid = validateEmailAddress(email);

	const handleUserInvite = () => {
		if (existingMembers?.find((memberEmail) => memberEmail === email)) {
			setShowEmailValidation("A member with this email address already exists");
			return;
		}
		if (scope === "workspace" && workspace) {
			createInvite({
				user: user,
				entityId: workspace.id,
				entityTitle: workspace.title,
				type: "workspace",
				workspaceId: workspace.id,
				email,
				role,
			});
		} else if (scope === "board" && board) {
			createInvite({
				user: user,
				entityId: board.id,
				entityTitle: board.data.name,
				type: "guest",
				workspaceId: board.workspaceId,
				role,
				email,
			});
		}

		setEmail("");
		setRole("editor");
		onSubmit();
	};

	const handleEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget.value);
		if (e.currentTarget.value.length === 0) {
			setShowEmailValidation(null);
		}
	};

	const handleCheckIsValid = () => {
		if (email.length) {
			const invalidEmailMessage = !isEmailValid ? "Please enter a valid email address" : null;
			setShowEmailValidation(invalidEmailMessage);
		}
	};

	const handleRoleChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
		setRole(e.currentTarget.value as WorkspaceMemberRole);
	};

	return (
		<Root>
			<InputWrapper>
				<Label htmlFor="inviteEmail">E-mail</Label>
				<Input
					type="email"
					id="inviteEmail"
					onChange={handleEmailChange}
					onBlur={handleCheckIsValid}
					placeholder="Enter email address"
					name="user[email]"
					value={email}
					isError={!!showEmailValidation}
					mb="0"
				/>
				<ValidationError showError={!!showEmailValidation}>{showEmailValidation}</ValidationError>
			</InputWrapper>
			{roles && (
				<InputWrapper>
					<Label htmlFor="inviteRole">Role</Label>
					<Select value={role} onChange={handleRoleChange} id="inviteRole">
						{roles.map(({ value, label }) => (
							<option value={value} key={value}>
								{label}
							</option>
						))}
					</Select>
				</InputWrapper>
			)}

			<ModalActionGroup>
				<Button.Secondary onClick={onSubmit}>Cancel</Button.Secondary>
				<Button disabled={!isEmailValid} onClick={handleUserInvite}>
					Send Invite
				</Button>
			</ModalActionGroup>
		</Root>
	);
};

const Root = styled.div``;

const InputWrapper = styled.div`
	position: relative;
	margin-bottom: 16px;
`;

export default MembersInvite;
