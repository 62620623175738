import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useKeyResultsService } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsService";
import { getCurrentWorkspace } from "@/modules/workspace/selectors/getCurrentWorkspace";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { ModalHeader } from "@/shared/system/Modal/Modal";

import { formatTarget, isStayAboveBelow } from "../../utils/keyResultsUtils";
import { KeyResultForm, KeyResultsFormFields } from "./components/KeyResultForm";

type Props = {
	onComplete: VoidFunction;
	onCancel: VoidFunction;
};

export const KeyResultAdd: React.FC<Props> = ({ onComplete, onCancel }) => {
	const { boardId, nodeId } = useParams() as { boardId: string; nodeId: string };
	const { create: createKeyResult } = useKeyResultsService();
	const workspace = useRecoilValue(getCurrentWorkspace);
	const analyticsService = useAnalyticsService();

	const onSubmit = useCallback(
		async (data: KeyResultsFormFields) => {
			if (!workspace) {
				return;
			}

			const newData = { ...data };

			if (isStayAboveBelow(data.goalType)) {
				const { target } = formatTarget(data.targetValue);
				newData.startingValue = target;
			}

			await createKeyResult({
				...newData,
				workspaceId: workspace.id,
				boardId,
				nodeId,
			});

			onComplete();

			analyticsService.keyResultCreated({ boardId, workspaceId: workspace.id });
		},
		[analyticsService, boardId, createKeyResult, nodeId, onComplete, workspace],
	);

	return (
		<>
			<ModalHeader>Add key result</ModalHeader>
			<KeyResultForm onSubmit={onSubmit} onCancel={onCancel} defaultValues={{ goalType: "get-to" }} />
		</>
	);
};
