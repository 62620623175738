import { FiPlus } from "react-icons/fi";
import styled from "styled-components";

import { NodeActionsAddContainer } from "@/domains/nodes/components/actions/components/NodeActionsAddContainer";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { Button } from "@/shared/system";

type Props = {
	node: NodeModel;
};

export const NodeActionsAdd: React.FC<Props> = ({ node }) => {
	return (
		<Root>
			<NodeActionsAddContainer node={node}>
				<Button.Link>
					<FiPlus />
					Add child node
				</Button.Link>
			</NodeActionsAddContainer>
		</Root>
	);
};

const Root = styled.div`
	margin-top: -6px;
`;
