import { useLayoutEffect, useRef } from "react";

import { FiFolder } from "react-icons/fi";
import { useResetRecoilState } from "recoil";
import styled from "styled-components";

import { updateFolderName } from "@/modules/board/commands/updateFolderName";
import { sidebarNavigationState } from "@/modules/page/selectors/sidebarNavigationState";
import { useActionHistory } from "@/shared/core/hooks/useActionHistory";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Truncate } from "@/shared/system";

interface Props {
	projectId: string;
	name: string;
	isEditing?: boolean;
}

export function SidebarNavigationItemFolderName({ name, projectId, isEditing }: Props) {
	const inputEl = useRef<HTMLInputElement>(null);
	const resetSidebarState = useResetRecoilState(sidebarNavigationState);
	const { execute } = useActionHistory();

	useLayoutEffect(() => {
		if (inputEl.current && isEditing) {
			inputEl.current.focus();
		}
	}, [isEditing]);

	const handleEdit = (e: any) => {
		e.preventDefault();
		if (e.target.value !== name) {
			execute(updateFolderName({ projectId, newValue: e.target.value, oldValue: name }));
		}
		resetSidebarState();
	};

	useBindShortcut("return", handleEdit, inputEl);
	useBindShortcut("escape", () => resetSidebarState(), inputEl);

	return (
		<Root>
			<Icon>
				<FiFolder />
			</Icon>
			{isEditing ? <input type="text" onBlur={handleEdit} defaultValue={name} ref={inputEl} /> : <Truncate>{name}</Truncate>}
		</Root>
	);
}

export const Root = styled.div`
	display: flex;
	align-items: center;
	background: transparent;
	font-size: 14px;
	border: 0;
	min-width: 0;
	cursor: pointer;

	color: var(--color-text-secondary);

	&:focus {
		outline: 0;
	}

	input {
		background: transparent;
		border: 0;
		padding: 0;
		outline: 0;
		color: inherit;
		font-size: inherit;
		letter-spacing: inherit;
		min-width: 0;
	}
`;

export const Icon = styled.div`
	display: inline-block;
	margin-right: 8px;
	position: relative;
	font-size: 14px;

	svg {
		display: block;
		visibility: visible !important;
	}
`;
