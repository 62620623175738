import { useMemo } from "react";

import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { Combobox } from "@/modules/board/share/shareUsers/components/Combobox";
import { ProjectInvite } from "@/modules/board/share/shareUsers/shareTypes";
import { getWorkspaceMembers } from "@/modules/members/selectors/getWorkspaceMembers";
import { ProjectBoard, WithId, WorkspaceMemberRole } from "@/types/db";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { Dropdown, DropdownContent, DropdownMenuItem, DropdownTrigger } from "../../../shared/system";

export const GuestInviteForm = () => {
	const workspaceMembers = useRecoilValue(getWorkspaceMembers);
	const { register, watch, setValue } = useFormContext();

	const { members, role } = watch();
	const setRole = (value: WorkspaceMemberRole) => setValue("role", value);

	const board = useGetProject() as WithId<ProjectBoard>;
	const workspaceId = board.workspaceId;

	const eligibleMembers: ProjectInvite[] = useMemo(() => {
		const memberIds = board.access !== null ? Object.keys(board.access) : [];

		return workspaceMembers
			.filter((member) => {
				return !memberIds.includes(member.id) && member.access[workspaceId]?.status === "active";
			})
			.map((member) => {
				return {
					scope: "member" as const,
					data: member,
				};
			});
	}, [board.access, workspaceId, workspaceMembers]);

	if (!board) {
		return null;
	}

	const roleOptions: any[] = [
		{
			label: "Editor",
			action: () => setRole("editor"),
			checkSelected: () => role === "editor",
		},
		{
			label: "Commenter",
			action: () => setRole("commenter"),
			checkSelected: () => role === "commenter",
		},
		{
			label: "Viewer",
			action: () => setRole("viewer"),
			checkSelected: () => "viewer",
		},
	];

	const activeOption = roleOptions.find(({ checkSelected }) => checkSelected());

	const hasMembers = members.length > 0;

	return (
		<Root>
			<Form>
				<Combobox items={eligibleMembers} />
				{hasMembers && (
					<Role>
						<Dropdown>
							<Trigger chevron>{activeOption?.label}</Trigger>
							<DropdownContent width={150}>
								{roleOptions.map(({ label, action }) => {
									return (
										<DropdownMenuItem onSelect={action} key={label}>
											{label}
										</DropdownMenuItem>
									);
								})}
							</DropdownContent>
						</Dropdown>
					</Role>
				)}
			</Form>
			{hasMembers && (
				<>
					<Notify>
						<input id="notify" type="checkbox" {...register("notify")} />
						<label htmlFor="notify">Notify people</label>
					</Notify>
					<Message placeholder="Message" {...register("message")} />
				</>
			)}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
`;

const Role = styled.div`
	margin-left: 12px;
`;
const Form = styled.div`
	display: flex;
	flex-grow: 1;
	margin-bottom: 12px;
`;

// TODO Duplicate code
const Trigger = styled(DropdownTrigger)`
	color: var(--color-accent);
	padding: 7px 12px;
	border-radius: 4px;
	border: var(--border);

	svg {
		margin-left: 12px;
	}

	:hover {
		color: var(--color-accent-hover);
	}
`;

const Message = styled.textarea`
	height: 112px;
	width: 100%;
	background: transparent;
	border: var(--border);
	border-radius: 4px;
	padding: 8px 12px;
	resize: none;
	font-size: 15px;

	&::placeholder {
		opacity: 0.5;
		color: var(--color-text);
	}

	&:focus {
		outline: 0;
	}
`;

const Notify = styled.div`
	display: flex;
	margin-bottom: 12px;

	input {
		margin-right: 8px;
	}
`;
