import { Link } from "react-router-dom";
import styled, { StyledComponent } from "styled-components";
import { color, fontSize, space } from "styled-system";

type Test = StyledComponent<any, any>;

export const LinkStyled: Test = styled(Link)`
	${color}
	${space}
  text-decoration: none;
	cursor: pointer;
	border-radius: ${(props) => props.theme.radii[1]};
	&:hover {
		background-color: ${(props) => props.theme.colors.bgGrey};
	}
`;

LinkStyled.Plain = styled(Link)`
	text-decoration: none;
	color: var(--color-accent);
	transition: color 200ms ease-in-out;

	&:hover {
		color: var(--color-accent-hover);
	}
	${fontSize}
	${color}
  ${space}
`;

LinkStyled.Href = styled.a`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: var(--color-accent);
	cursor: pointer;
	transition: color 200ms ease-in-out;

	> svg {
		margin-right: 4px;
	}

	&:hover {
		color: var(--color-accent-hover);
	}

	${fontSize}
	${color}
  ${space}
`;

LinkStyled.defaultProps = {
	color: "body",
	py: 1,
	px: 2,
};

export default LinkStyled;
