import { useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { ErrorFn, User } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { atom, selector, useResetRecoilState, useSetRecoilState } from "recoil";

import { processInvite, queryInvitesByEmail } from "@/modules/members/workspaceInviteFunctions";
import { profileState } from "@/modules/profile/selectors/profileState";
import * as analyticsService from "@/shared/core/analytics/hooks/useAnalyticsService";
import { resetAllStores } from "@/shared/zustand/createStore";

import { fb } from "../../../shared/infra/init";
import { membersState } from "../../members/hooks/useMembersListener";
import { workspaceUsageState } from "../../plans/hooks/usePlanUsageListener";
import { workspacesState } from "../../workspace/selectors/workspacesState";
import { authenticatedIdState } from "../selectors/authenticatedIdState";

export const welcomeWorkspaceState = atom<string | null>({
	key: "workspaceWelcome",
	default: null,
});

export const activeWorkspaceIdState = selector({
	key: "workspaceActiveId",
	get: ({ get }) => {
		const profile = get(profileState);
		const workspaces = get(workspacesState);
		return profile?.activeWorkspaceId || Object.keys(workspaces)[0];
	},
});

export const useAuthenticationListener = (onComplete: VoidFunction) => {
	const setCurrentUser = useSetRecoilState(authenticatedIdState);
	const setWelcomeState = useSetRecoilState(welcomeWorkspaceState);

	const resetAuthenticationState = useResetRecoilState(authenticatedIdState);
	const resetMembersState = useResetRecoilState(membersState);
	const resetProfileState = useResetRecoilState(profileState);
	const resetUsageState = useResetRecoilState(workspaceUsageState);
	const resetWorkspacesState = useResetRecoilState(workspacesState);

	const isEmbedded = window.self !== window.top;

	useEffect(() => {
		const onNext = async (authUser: User | null) => {
			if (isEmbedded) {
				onComplete();
				return;
			}

			if (authUser) {
				const userEmail = authUser.email || "";

				/**
				 * TODO:
				 * This should probably be pulled from this listener and made into a component displaying
				 * invites and allowing users to explicitly opt in/out to them rather than processing on signup
				 */
				const resultInvites = await queryInvitesByEmail(userEmail);

				if (resultInvites.isSuccess) {
					const invites = resultInvites.getValue();

					invites.map((invite) => processInvite(invite, authUser.uid));

					if (invites[0]) {
						setWelcomeState(invites[0].id);
					}
				}
				setCurrentUser(authUser.uid);
				onComplete();

				analyticsService.identify({ id: authUser.uid });
				Sentry.setUser({ email: authUser.email || undefined });
			} else {
				setCurrentUser(null);

				resetAuthenticationState();
				resetMembersState();
				resetProfileState();
				resetUsageState();
				resetWorkspacesState();

				resetAllStores();

				onComplete();

				Sentry.configureScope((scope) => scope.setUser(null));
			}
		};

		const onError: ErrorFn = (error) => {
			setCurrentUser(null);
			resetProfileState();

			Sentry.captureException(error);
		};

		return onAuthStateChanged(fb.auth, onNext, onError);
	}, [
		isEmbedded,
		onComplete,
		resetAuthenticationState,
		resetMembersState,
		resetProfileState,
		resetUsageState,
		resetWorkspacesState,
		setCurrentUser,
		setWelcomeState,
	]);
};
