import { doc, runTransaction } from "firebase/firestore";

import { UniqueEntityId } from "@/modules/authentication/utils/entity";
import { Project } from "@/modules/authentication/utils/project";
import { buildFavouriteFolder, buildPrivateFolder, buildWorkspaceFolder } from "@/modules/board/api/buildProject";
import { db, fb } from "@/shared/infra/init";
import { Result } from "@/shared/utils/result";

type ProjectCreateSystemFolders = (args: {
	workspaceId: string;
	userId: string;
	isInvite: boolean;
}) => Promise<Result<void>>;

export const projectCreateSystemFolders: ProjectCreateSystemFolders = async ({ workspaceId, userId, isInvite }) => {
	const docFolderWorkspace = doc(db.projects);
	const resultCreateWorkspaceFolder = Project.createProject(
		buildWorkspaceFolder(workspaceId, userId),
		new UniqueEntityId(docFolderWorkspace.id),
	);

	const docFolderPrivate = doc(db.projects);
	const resultCreatePrivateFolder = Project.createProject(
		buildPrivateFolder(workspaceId, userId),
		new UniqueEntityId(docFolderPrivate.id),
	);

	const docFolderFavorite = doc(db.projects);
	const resultCreateFavoriteFolder = Project.createProject(
		buildFavouriteFolder(workspaceId, userId),
		new UniqueEntityId(docFolderFavorite.id),
	);

	const entities = Result.combine([resultCreateFavoriteFolder, resultCreatePrivateFolder, resultCreateWorkspaceFolder]);

	if (entities.isFailure) {
		return entities;
	}

	const folderWorkspace = resultCreateWorkspaceFolder.getValue();
	const folderPrivate = resultCreatePrivateFolder.getValue();
	const folderFavorite = resultCreateFavoriteFolder.getValue();

	try {
		await runTransaction(fb.firestore, async (transaction) => {
			transaction.set(docFolderPrivate, folderPrivate.save());
			transaction.set(docFolderFavorite, folderFavorite.save());
			!isInvite && transaction.set(docFolderWorkspace, folderWorkspace.save());
		});
	} catch (e) {
		Result.fail("Failed to create system folder");
	}

	return Result.ok();
};
