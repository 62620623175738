import styled from "styled-components";

import { NodeMetaSelectValue } from "@/domains/nodes/components/details/meta/types/select/NodeMetaSelectValue";

type Props = {
	title?: string;
	status?: string;
	color?: string | null;
	showStatus?: boolean;
};

export const StaticNodeMeta = ({ color, showStatus, status }: Props) => {
	return <Root>{status && showStatus && <NodeMetaSelectValue color={color} value={status} />}</Root>;
};

const Root = styled.div`
	width: 100%;
`;
