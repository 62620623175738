import styled from "styled-components";

import { NodeIcon } from "@/domains/nodes/dtos/nodesDto";

type Props = {
	icon: NodeIcon;
};

export const IconType: React.FC<Props> = ({ icon }) => {
	const iconType = icon.type;

	switch (iconType) {
		case "emoji": {
			return <>{icon.emoji}</>;
		}
		case "image": {
			return <IconTypeImage src={icon.src} alt="icon" />;
		}
		default: {
			const _exhaustiveCheck: never = iconType;
			return _exhaustiveCheck;
		}
	}
};

const IconTypeImage = styled.img`
	height: 100%;
	max-width: 100%;
	border: var(--boarder);
	display: block;
	object-fit: contain;
	margin: auto;
`;
