import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";

const contentStyle = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin: 0px;
	padding: 8px;
	border: var(--border);
	background-color: var(--color-body);
	box-shadow: var(--drop-shadow-l2);
	border-radius: 4px;
	font-size: 14px;
	${space};
`;

export const DropdownPortal = DropdownMenu.Portal;

export const DropdownContent = styled<any>(DropdownMenu.Content)`
	${contentStyle};
	z-index: 10000;
`;

export const PopoverContent = styled.div<SpaceProps>`
	${contentStyle};
	z-index: 10000;
`;

export const DropdownSubContent = styled(DropdownMenu.SubContent)`
	${contentStyle};
	z-index: 10000;
`;
