import { useEffect } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { atomFamily, useSetRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { StripeSubscriptionStatus } from "../../../types/db";
import { AvailablePlans } from "../../plans/planUtils";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export type SubscriptionInfo = {
	role: keyof typeof AvailablePlans;
	status: StripeSubscriptionStatus;
};

export const subscriptionState = atomFamily<SubscriptionInfo | null, string | undefined>({
	key: "subscriptionState",
	default: null,
});

export const useSubscriptionDetailsListener = () => {
	const workspace = useActiveWorkspace();
	const billingId = workspace?.billingId;
	const subscriptionId = workspace?.subscriptionId;
	const setSubscriptionState = useSetRecoilState(subscriptionState(subscriptionId));

	useEffect(() => {
		if (billingId && subscriptionId) {
			const subscriptionDocument = doc(db.billingSubscriptions(billingId), subscriptionId);
			return onSnapshot(
				subscriptionDocument,
				(snapshot) => {
					const subscription = snapshot.data();
					if (subscription) {
						// There seems to be a nested document referenced attached to this document which
						// causes problems when attaching to an atom.
						setSubscriptionState({ role: subscription.role, status: subscription.status });
					}
				},
				onSnapshotError("useSubscriptionDetailsListener"),
			);
		}
	}, [billingId, setSubscriptionState, subscriptionId]);
};
