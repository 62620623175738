import { arrayUnion, doc, updateDoc } from "firebase/firestore";

import { projectCreateFolder } from "@/modules/board/api/projectCreateFolder";
import { db } from "@/shared/infra/init";
import { ProjectVisibility } from "@/types/db";

import { usePublicProfile } from "../../profile/hooks/usePublicProfile";
import { useActiveWorkspace } from "../../workspace/hooks/useActiveWorkspace";

export const useAddFolder = () => {
	const publicProfile = usePublicProfile();
	const workspace = useActiveWorkspace();

	return async (folderId: string, visibility?: ProjectVisibility) => {
		const newFolderId = await projectCreateFolder(workspace.id, publicProfile, visibility);
		const parentFolder = doc(db.projects, folderId);
		updateDoc(parentFolder, { childIds: arrayUnion(newFolderId) });
	};
};
