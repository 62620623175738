import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";

import { db, fb } from "../../shared/infra/init";
import { ProjectImport, ProjectImportStatus } from "../../types/db";

export const setMetaId = (boardId: string, metaId: string) => {
	const boardDocument = doc(db.projects, boardId);

	updateDoc(boardDocument, {
		metaId,
	});
};

export const deleteMetaId = (boardId: string) => {
	const boardDocument = doc(db.projects, boardId);

	updateDoc(boardDocument, {
		metaId: deleteField(),
	});
};

export const updateSummary = (boardId: string, rootId: string, summary: string) => {
	const boardDocument = doc(db.projects, boardId);

	updateDoc(boardDocument, {
		"data.description": summary,
		updatedAt: fb.timestamp(),
	});

	const nodeDocument = doc(db.nodes, rootId);
	updateDoc(nodeDocument, { summary });
};

export const updateTitle = (projectId: string, rootId: string, title: string) => {
	const boardDocument = doc(db.projects, projectId);
	const nodeDocument = doc(db.nodes, rootId);

	updateDoc(boardDocument, {
		"data.name": title,
		updatedAt: fb.timestamp(),
	});

	updateDoc(nodeDocument, { title });
};

type AddImportData = Omit<ProjectImport, "createdAt" | "version" | "successCount" | "failedCount" | "messages">;

export const addImport = (boardId: string, data: AddImportData) => {
	const importDocument = doc(db.projectsImport(boardId));

	setDoc(importDocument, {
		createdAt: fb.timestamp(),
		version: "1",
		successCount: 0,
		failedCount: 0,
		messages: [],
		...data,
	});

	return importDocument;
};

export const updateImportStatus = (boardId: string, importId: string, status: ProjectImportStatus) => {
	const importDocument = doc(db.projectsImport(boardId), importId);

	return updateDoc(importDocument, {
		status,
	});
};

export const updateBoard = (boardId: string, value: any) => {
	const boardDocument = doc(db.projects, boardId);

	updateDoc(boardDocument, value);
};
