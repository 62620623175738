import { ReactElement, ReactNode } from "react";

import { FiCheck } from "react-icons/fi";
import { VscTriangleRight } from "react-icons/vsc";
import styled from "styled-components";

import { DropdownMenuButton, TextWithEmoji } from "../../../shared/system";

type PartialContextItem = {
	label: string | ReactElement;
	kbd?: any;
	icon?: any;
	disabled?: boolean;
};

type Props = {
	item: PartialContextItem;
	onClick?: VoidFunction;
	onMouseEnter?: VoidFunction;
	onMouseLeave?: VoidFunction;
	children?: ReactNode;
	isSelected?: boolean;
	hasChildren?: boolean;
	className?: string;
	testId?: string;
};

const voidFunction = () => {};

export function ContextItem({
	item,
	isSelected = false,
	onClick,
	className,
	onMouseEnter,
	onMouseLeave,
	hasChildren,
	testId,
}: Props) {
	const { kbd, icon, disabled } = item;

	const Icon = icon;
	const Kbd = kbd;

	const handleMouseEnter = disabled ? voidFunction : onMouseEnter;
	const handleMouseLeave = disabled ? voidFunction : onMouseLeave;
	const handleClick = disabled ? voidFunction : onClick;

	const label = typeof item.label === "string" ? <TextWithEmoji text={item.label} /> : item.label;

	return (
		<>
			<DropdownMenuButton
				className={className}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				data-testid={testId}>
				<Item className={disabled ? "disabled" : ""}>
					{Icon && <Icon />} {label}
					{isSelected && (
						<Mark>
							<FiCheck />
						</Mark>
					)}
				</Item>
				{Kbd && <Kbd />}
				{hasChildren && !disabled && <VscTriangleRight className="caret" />}
			</DropdownMenuButton>
		</>
	);
}

const Item = styled.span`
	display: flex;
	position: relative;
	align-items: center;
	margin-left: -2px;
	flex-grow: 1;
	padding-right: 24px;
	width: 100%;

	> svg {
		margin-right: 8px;
	}
`;

const Mark = styled.div`
	position: absolute;
	right: 0;
	width: 20px;
	margin-right: -4px;

	> svg {
		display: block;
		margin: 0;
	}
`;
