import styled from "styled-components";

import { NodeMetaItem } from "@/domains/nodes/components/details/meta/NodeMetaItem";
import { NodeMetaLabel } from "@/domains/nodes/components/details/meta/NodeMetaLabel";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeMetaAllTypes } from "@/types/db";

type Props = {
	node: NodeModel;
	meta: NodeMetaAllTypes;
};

export const ListItem: React.FC<Props> = ({ node, meta }) => {
	return (
		<Root>
			<MetaLabel>
				<NodeMetaLabel meta={meta} />
			</MetaLabel>
			<MetaValue>
				<NodeMetaItem node={node} meta={meta} />
			</MetaValue>
		</Root>
	);
};

export const Root = styled.div`
	display: flex;
	width: 100%;
	margin-left: -4px;
`;

export const MetaLabel = styled.div``;

export const MetaValue = styled.div`
	max-width: 515px;
	flex-grow: 1;
	align-items: center;
	border-radius: 4px;
	display: flex;
`;
