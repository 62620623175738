import groupBy from "lodash/groupBy";
import { selector } from "recoil";

import { notificationsState } from "./hooks/useNotificationsListener";

export const getUnreadMessageCount = selector({
	key: "getUnreadMessageCount",
	get: ({ get }) => {
		const notifications = get(notificationsState);
		const unreadNotifications = notifications.filter(({ seenAt }) => seenAt === null);
		return unreadNotifications.length;
	},
});
export const getNotificationGroupedByType = selector({
	key: "getNotificationGroupedByType",
	get: ({ get }) => {
		const notifications = get(notificationsState);
		return groupBy(notifications, ({ type }) => {
			if (type === "comment") {
				return "comment";
			}
			return "activity";
		});
	},
});
