import { ReactNode } from "react";

import { Data } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import styled from "styled-components";

export interface SortableItem extends Data {
	id: string;
}

export type SortableItemRender<TItem> = (node: TItem, listeners?: SyntheticListenerMap) => ReactNode;

type Props<TItem> = {
	id: string;
	index: number;
	renderItem: SortableItemRender<TItem>;
	item: TItem;
	animate?: boolean;
	handle?: boolean;
};

export function SortableListItem<TItem extends SortableItem>({
	id,
	item,
	index,
	handle,
	animate,
	renderItem,
}: Props<TItem>) {
	const { attributes, isDragging, listeners, items, setNodeRef, transform, transition } = useSortable({
		id,
		disabled: false,
		data: item.data,
	});

	const zIndex = items.length - index;
	const rootListeners = handle ? {} : listeners;

	return (
		<Root
			ref={setNodeRef}
			isDragging={isDragging}
			animate={animate}
			className="draggable"
			style={
				{
					transition,
					zIndex,
					position: "relative",
					"--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
					"--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
					"--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
					"--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
					"--index": index,
				} as React.CSSProperties
			}
			{...attributes}
			{...rootListeners}>
			{renderItem(item, listeners)}
		</Root>
	);
}

const Root = styled.div<{ isDragging: boolean; animate?: boolean }>`
	${({ animate }) =>
		animate &&
		`
			transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
				scaleY(var(--scale-y, 1));
			transform-origin: 0 0;
			touch-action: manipulation;

`};
	opacity: ${({ isDragging }) => (isDragging ? 0.25 : 1)};
	cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "pointer")};

	&.dragOverlay {
		--scale: 1.05;
		--box-shadow: $box-shadow;
		--box-shadow-picked-up: $box-shadow-border, -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
		z-index: 999;
	}
`;
