import { useEffect } from "react";

import { onSnapshot, orderBy, query } from "firebase/firestore";
import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { authenticatedIdState } from "@/modules/authentication/selectors/authenticatedIdState";

import { useGetProject } from "../../../domains/projects/hooks/useGetProject";
import { db, onSnapshotError } from "../../../shared/infra/init";
import { extractDataFromCollection } from "../../../shared/utils/dataUtils";
import { User } from "../../../types/db";

export const boardMembersWorkspace = atomFamily<Record<string, User>, string>({
	key: "boardMembersWorkspace",
	default: {},
});

export const useBoardMembersWorkspace = () => {
	const board = useGetProject();
	const authId = useRecoilValue(authenticatedIdState);

	const workspaceId = board?.workspaceId || "";
	const [, setBoardWorkspaceMembers] = useRecoilState(boardMembersWorkspace(workspaceId));

	useEffect(() => {
		if (authId && workspaceId) {
			const guestBoardQuery = query(db.users, orderBy(`access.${workspaceId}`));

			return onSnapshot(
				guestBoardQuery,
				(snapshot) => {
					const { byId } = extractDataFromCollection(snapshot);
					setBoardWorkspaceMembers(byId);
				},
				onSnapshotError("useBoardWorkspaceMembers"),
			);
		}
	}, [workspaceId, setBoardWorkspaceMembers, authId]);
};
