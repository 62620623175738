import { doc, updateDoc } from "firebase/firestore";

import { db, fb } from "@/shared/infra/init";
import { ProjectMeta, PublicProfile } from "@/types/db";

export function projectUpdateView(projectId: string, view: ProjectMeta["view"], user: PublicProfile) {
	const projectDocument = doc(db.projects, projectId);

	updateDoc(projectDocument, {
		"meta.view": view,
		updatedAt: fb.timestamp(),
		updatedBy: user,
	});
}
