import { Text } from "rebass";
import styled from "styled-components";

export const TextStyled = (props: any) => <Text as="p" mt={0} lineHeight={1.62} mb={2} {...props} />;

export default TextStyled;

export const Truncate = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	* {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;
