import { format, fromUnixTime } from "date-fns";
import pluralize from "pluralize";
import styled from "styled-components";

import { ProjectImport, WithId } from "../../../../types/db";

type Props = {
	file: WithId<ProjectImport>;
};

export const MoreImportFile = ({ file }: Props) => {
	const importDate = format(fromUnixTime(file.createdAt.seconds), "PP");
	const showMessages = file.messages.length !== 0;
	const showFailed = file.failedCount > 0;

	return (
		<Root>
			<FileDate>{importDate}</FileDate>
			<File>
				<FileInfo>{file.fileName}</FileInfo>
				<FileStats>
					{file.successCount} {pluralize("node", file.successCount)} imported
				</FileStats>
				{showFailed && (
					<FileStats>
						{file.failedCount} {pluralize("node", file.failedCount)} failed to import
					</FileStats>
				)}
				{showMessages && (
					<ErrorList>
						{file.messages.map((msg, index) => {
							return <li key={`${index}`}>{msg}</li>;
						})}
					</ErrorList>
				)}
			</File>
			<FileStatus>{file.status}</FileStatus>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	padding: 8px 16px;
	background: var(--color-hover);
	border-radius: 4px;
	margin-top: 8px;
`;

const FileDate = styled.div`
	min-width: 80px;
`;

const File = styled.div`
	margin: 0 16px;
	flex-grow: 0;

	ul {
		padding-left: 12px;
	}

	li {
		margin-bottom: 8px;
	}
`;

const FileInfo = styled.div`
	color: var(--color-text-heading);
`;

const FileStats = styled.div`
	font-size: 12px;
`;

const ErrorList = styled.ul`
	font-size: 12px;
`;

const FileStatus = styled.div`
	margin-left: auto;
	text-transform: capitalize;
`;
